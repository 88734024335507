import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';

import { PerformanceService } from 'src/app/services/performance.service';
import { environment } from 'src/environments/environment';
import { BrowserThrottlingCompositeDetector } from '../../../../projects/performance-tools/src/lib/browser-throttling/browser-throttling-composite-detector';
import { BrowserThrottlingDetectorMetrics } from '../../../../projects/performance-tools/src/lib/browser-throttling/browser-throttling-detector-metrics';
import { DebugFlagService } from '../../../../projects/performance-tools/src/lib/debug-flag.service';
import { UserIdleDetectorMetrics } from '../../../../projects/performance-tools/src/lib/user-idle-detector/user-idle-detector-metrics';
import { UserNetworkLatencyMetrics } from '../../../../projects/performance-tools/src/lib/user-network-latency-metrics';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _performanceService: PerformanceService,
    private debugFlagService: DebugFlagService,
    private browserThrottlingDetector: BrowserThrottlingDetectorMetrics,
    private browserThrottlingCompositeDetector: BrowserThrottlingCompositeDetector,
    private idleDetector: UserIdleDetectorMetrics,
    private userNetworkLatency: UserNetworkLatencyMetrics,
    private renderer: Renderer2,
  ) {
    if (environment.isPerformanceInfoEnabled) {
      this._performanceService.start();
    }
    if (environment.isBrowserThrottlingDetectorEnabled) {
      this.browserThrottlingDetector.start();
      this.browserThrottlingCompositeDetector.startThrottlingDetection();
    }
    if (environment.isUserIdleDetectionEnabled) {
      this.idleDetector.start();
    }
    if (environment.isUserNetworkLatencyMeasuringEnabled) {
      this.userNetworkLatency.startRegularMeasurements();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    this.debugFlagService.handleKeyboardEvent(event);
  }

  ngOnInit() {
    this.removePreBootstrapSpinner();
  }

  private removePreBootstrapSpinner(): void {
    const spinner: HTMLElement = document.getElementById(
      'pre-bootstrap-spinner',
    );
    if (spinner) {
      this.renderer.setStyle(spinner, 'display', 'none');
    }
  }
}
