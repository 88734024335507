// @todo Fix types
export const defaultIndicatorTemplates: any[] = [
  {
    name: 'BarStats',
    settings: {
      general: {
        font: {
          fontSize: 11,
          fontColor: '#fff',
          fontStyle: '400',
          textAlign: 'right',
          fontFamily: 'Arial',
        },
        header: {
          position: 'left',
          backColor: '#0C62F7',
          textAlign: 'left',
          textColor: '#ffffff',
        },
        xPadding: 1,
        rowHeight: 20,
        rowMargin: 1,
        marginText: 7,
      },
      groupsRows: {
        delta: {
          order: 1,
          enabled: true,
          negative: '#999999',
          positive: '#0C62F7',
        },
        ticks: {
          order: 11,
          enabled: false,
        },
        volume: {
          order: 0,
          enabled: true,
          background: '#0C62F7',
        },
        deltaday: {
          order: 2,
          enabled: true,
          negative: '#999999',
          positive: '#0C62F7',
        },
        deltamax: {
          order: 7,
          enabled: false,
          negative: '#999999',
          positive: '#0C62F7',
        },
        deltamin: {
          order: 8,
          enabled: false,
          negative: '#999999',
          positive: '#0C62F7',
        },
        buyvolume: {
          order: 4,
          enabled: false,
          background: '#0C62F7',
        },
        sellvolume: {
          order: 10,
          enabled: false,
          background: '#999999',
        },
        deltafinish: {
          order: 6,
          enabled: false,
          negative: '#999999',
          positive: '#0C62F7',
        },
        deltaPercentDay: {
          order: 5,
          enabled: false,
          negative: '#999999',
          positive: '#0C62F7',
        },
        buyPercentVolume: {
          order: 3,
          enabled: false,
          negative: '#999999',
          positive: '#0C62F7',
        },
        sellPercentVolume: {
          order: 9,
          enabled: false,
          negative: '#999999',
          positive: '#0C62F7',
        },
      },
      groupsTradeVolumeFilter: [
        {
          name: 'Full',
        },
      ],
    },
  },
  {
    name: 'CompositeProfile',
    settings: {
      lines: {
        va: {
          enabled: false,
          strokeColor: '#FFFF00',
          strokeTheme: {
            width: 1,
            lineStyle: 'solid',
            strokeColor: '#FFFF00',
          },
          labelEnabled: false,
        },
        poc: {
          enabled: false,
          strokeColor: '#FE00E5',
          strokeTheme: {
            width: 1,
            lineStyle: 'solid',
            strokeColor: '#FFFF00',
          },
          labelEnabled: false,
        },
      },
      general: {
        va: 70,
        type: 'volume',
        align: 'right',
        width: {
          unit: 'pixel',
          value: 70,
        },
        smoothed: {
          value: 9,
          enabled: false,
        },
        customTickSize: {
          value: 5,
          enabled: false,
        },
      },
      profile: {
        eth: {
          type: 'solidgram',
          color: {
            type: 'profileColor',
            value: '#0C62F7',
          },
          session: null,
        },
        rth: {
          type: 'solidgram',
          color: {
            type: 'profileColor',
            value: '#4C4C4C',
          },
        },
        overlayEthOverRth: false,
      },
    },
  },
  {
    name: 'Footprint',
    settings: {
      font: {
        fontSize: 11,
        fillColor: '#666666',
        fontFamily: 'Open Sans',
        fontWeight: '400',
      },
      main: {
        mode: 'volume',
        fillColor: '#3131316d',
        strokeColor: '#85857A00',
        barStrokeColor: '#85857A00  ',
        closeOpenColor: '#1ADE5D',
        customTickSize: {
          value: 1,
          enabled: false,
        },
        openCloseColor: '#FF2E00',
        enableOpenClose: true,
      },
      text: {
        margin: 4,
        hideSides: false,
        separator: 'x',
        textStyle: 'bidAsk',
        initialBarWidth: 80,
        barWidthTextThreshold: 60,
        barHeightTextThreshold: 5,
      },
      profile: {
        filterValue: 4000,
        strokeWidth: 1,
        profileFilter: 'autoMaxProfile',
        autoProfilingRange: 'visible',
        showPocOnlyOnBiggestBar: false,
      },
      intraBar: {
        barColor: '#A9A9A9',
        barStyle: 'volumeProfileBars',
        barPocColor: '#FFA500',
        barAlignment: 'left',
        buyPocBarColor: 'gold',
        sellPocBarColor: 'gold',
        buyShadeBarColor: '#0C62F7',
        buyVolumeBarColor: 'silver',
        sellShadeBarColor: '#E95050',
        sellVolumeBarColor: 'silver',
        lastPriceOutlineColor: '#e8e8e8',
        useDeltaShadingColors: true,
      },
      pullback: {
        enabled: true,
        fillColor: '#3F3F3F',
        textColor: '#E5E5E5',
        strokeColor: 'transparent',
        currentBidAskColor: '#8B6D00',
      },
      volumeFilter: {
        mode: 'greaterThan',
        value: 100,
        enabled: false,
      },
      deltaImbalance: {
        enabled: true,
        threshold: 350,
        weakAskVolumeColor: '#0C62F7',
        weakBidVolumeColor: '#0C62F7',
        enableWeakAskVolume: false,
        enableWeakBidVolume: false,
        strongAskVolumeColor: '#00BD62',
        strongBidVolumeColor: '#FF6347',
      },
    },
  },
  {
    name: 'VWAP',
    settings: {
      bands: {
        band1: {
          isShow: false,
          styleLine: {
            width: 1,
            lineStyle: 'solid',
          },
          coefficient: 1,
          strokeColor: '#FFFF00',
        },
        band2: {
          isShow: false,
          styleLine: {
            width: 1,
            lineStyle: 'solid',
          },
          coefficient: 2,
          strokeColor: '#FFFF00',
        },
        band3: {
          isShow: false,
          styleLine: {
            width: 1,
            lineStyle: 'solid',
          },
          coefficient: 3,
          strokeColor: '#FFFF00',
        },
      },
      label: {
        width: {
          unit: 'pixel',
          value: 400,
        },
        margin: {
          unit: 'pixel',
          value: 0,
        },
        fontSize: 10,
        fillColor: 'rgb(0,0,0,1)',
        lineStyle: {
          width: 1,
          lineStyle: 'solid',
        },
        fontFamily: 'Open Sans',
        fontWeight: '400',
        isShowVWAPLabel: false,
      },
      general: {
        styleLine: {
          style: 'hollow',
          lineStyle: {
            width: 1,
            lineStyle: 'solid',
          },
          strokeColor: '#00E5DD',
        },
        customTimes: null,
        customDuration: 'day',
        isShowCustomDuration: false,
      },
    },
  },
  {
    name: 'PriceStats',
    settings: {
      general: {
        va: 70,
        type: 'volume',
        align: 'right',
        sessions: {
          days: {
            count: 10,
            width: {
              unit: 'pixel',
              value: 100,
            },
            enabled: false,
            includeCurrentSession: false,
          },
          prev: {
            width: {
              unit: 'pixel',
              value: 125,
            },
            enabled: true,
          },
          current: {
            width: {
              unit: 'pixel',
              value: 125,
            },
            enabled: true,
          },
        },
        customTickSize: {
          value: 1,
          enabled: true,
        },
      },
      profile: {
        eth: {
          va: {
            va: false,
            enabled: true,
            strokeColor: '#FFFF00',
          },
          poc: {
            dev: false,
            enabled: true,
            strokeColor: '#FFFF00',
          },
          type: 'solidgram',
          color: {
            type: 'profileColor',
            value: '#0C62F7',
          },
          session: null,
        },
        rth: {
          va: {
            enabled: false,
            devEnabled: false,
            strokeColor: '#A1A2A5',
          },
          poc: {
            enabled: true,
            devEnabled: false,
            strokeColor: '#FE00E5',
          },
          type: 'solidgram',
          color: {
            type: 'heatMap',
          },
          session: {
            id: 628,
            name: 'US Extended Trading Hours',
            exchange: 'CME',
            isDefault: true,
            timezoneId: 'America/New_York',
            workingTimes: [
              {
                id: 0,
                endDay: 5,
                endTime: 61200000,
                startDay: 4,
                sessionId: 628,
                startTime: 64800000,
                tradingDay: 5,
              },
              {
                id: 0,
                endDay: 4,
                endTime: 61200000,
                startDay: 3,
                sessionId: 628,
                startTime: 64800000,
                tradingDay: 4,
              },
              {
                id: 0,
                endDay: 3,
                endTime: 61200000,
                startDay: 2,
                sessionId: 628,
                startTime: 64800000,
                tradingDay: 3,
              },
              {
                id: 0,
                endDay: 2,
                endTime: 61200000,
                startDay: 1,
                sessionId: 628,
                startTime: 64800000,
                tradingDay: 2,
              },
              {
                id: 0,
                endDay: 1,
                endTime: 61200000,
                startDay: 0,
                sessionId: 628,
                startTime: 64800000,
                tradingDay: 1,
              },
            ],
          },
        },
        overlayEthOverRth: false,
      },
      highlight: {
        va: {
          value: '#A1A2A5',
          enabled: false,
        },
        poc: {
          value: '#FE00E5',
          enabled: true,
        },
      },
    },
  },
  {
    name: 'VolumeBreakdown',
    settings: {
      general: {
        mode: 'deltaBars',
        type: 'volume',
        zeroLine: {
          enabled: true,
          strokeColor: '#FFFF00',
          strokeTheme: {
            width: 1,
            lineStyle: 'solid',
            strokeColor: '#FFFF00',
          },
        },
        deltaType: 'bidAsk',
        accumulate: {
          value: 'day',
          enabled: true,
        },
        upFillColor: '#0C62F7',
        avaragePeriod: {
          value: 3,
          enabled: false,
        },
        downFillColor: '#C93B3B',
        upStrokeColor: '#000000',
        downStrokeColor: '#000000',
      },
      sizeFilter: {
        sizeFilter: {
          value: 10,
          enabled: false,
        },
      },
    },
  },
  {
    name: 'VolumeProfile',
    settings: {
      lines: {
        eth: {
          dev: {
            va: {
              enabled: false,
              strokeColor: '#8D8E30',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#8D8E30',
              },
            },
            poc: {
              enabled: false,
              strokeColor: '#8D8E30',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#8D8E30',
              },
            },
          },
          prev: {
            va: {
              enabled: false,
              strokeColor: '#8D8E30',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#8D8E30',
              },
            },
            poc: {
              enabled: false,
              strokeColor: '#8D8E30',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#8D8E30',
              },
            },
          },
          current: {
            va: {
              enabled: false,
              strokeColor: '#FFFF00',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#FFFF00',
              },
              labelEnabled: false,
            },
            poc: {
              enabled: true,
              strokeColor: '#600056',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#FFFF00',
              },
              labelEnabled: false,
            },
          },
        },
        rth: {
          dev: {
            va: {
              enabled: false,
              strokeColor: '#8D8E30',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#8D8E30',
              },
            },
            poc: {
              enabled: false,
              strokeColor: '#8D8E30',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#8D8E30',
              },
            },
          },
          prev: {
            va: {
              enabled: false,
              strokeColor: '#8D8E30',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#8D8E30',
              },
            },
            poc: {
              enabled: false,
              strokeColor: '#8D8E30',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#8D8E30',
              },
            },
          },
          current: {
            va: {
              enabled: false,
              strokeColor: '#FFFF00',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#FFFF00',
              },
              labelEnabled: false,
            },
            poc: {
              enabled: true,
              strokeColor: '#FE00E5',
              strokeTheme: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: '#FFFF00',
              },
              labelEnabled: false,
            },
          },
        },
      },
      general: {
        va: 70,
        hide: {
          value: 'lastProfile',
          enabled: false,
        },
        type: 'volume',
        align: 'left',
        period: {
          date: null,
          type: 'every',
          unit: 'day',
          value: 1,
        },
        smoothed: {
          value: 9,
          enabled: false,
        },
        transparency: 33,
        customTickSize: {
          value: 5,
          enabled: false,
        },
        calculateXProfiles: 999,
      },
      profile: {
        eth: {
          type: 'solidgram',
          color: {
            type: 'profileColor',
            value: '#666666',
          },
          width: 30,
          session: {
            id: 2,
            name: 'US ETH',
            exchange: 'CME',
            timezoneId: 'America/New_York',
            connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
            workingTimes: [
              {
                endDay: 1,
                endTime: 34200000,
                startDay: 0,
                startTime: 64800000,
              },
              {
                endDay: 2,
                endTime: 34200000,
                startDay: 1,
                startTime: 64800000,
              },
              {
                endDay: 3,
                endTime: 34200000,
                startDay: 2,
                startTime: 64800000,
              },
              {
                endDay: 4,
                endTime: 34200000,
                startDay: 3,
                startTime: 64800000,
              },
              {
                endDay: 5,
                endTime: 34200000,
                startDay: 4,
                startTime: 64800000,
              },
            ],
          },
          extendNaked: {
            type: 'closesHighsLows',
            enabled: false,
            strokeColor: '#0C62F7',
            strokeTheme: {
              width: 1,
              lineStyle: 'solid',
              strokeColor: '#0C62F7',
            },
          },
          extendNakedPocs: true,
          vaInsideOpacity: 50,
          vaOutsideOpacity: 50,
        },
        rth: {
          type: 'solidgram',
          color: {
            type: 'profileColor',
            value: '#0C62F7',
          },
          width: 80,
          session: {
            id: 1,
            name: 'US RTH',
            exchange: 'CME',
            timezoneId: 'America/New_York',
            connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
            workingTimes: [
              {
                endDay: 1,
                endTime: 61200000,
                startDay: 1,
                startTime: 34200000,
              },
              {
                endDay: 2,
                endTime: 61200000,
                startDay: 2,
                startTime: 34200000,
              },
              {
                endDay: 3,
                endTime: 61200000,
                startDay: 3,
                startTime: 34200000,
              },
              {
                endDay: 4,
                endTime: 61200000,
                startDay: 4,
                startTime: 34200000,
              },
              {
                endDay: 5,
                endTime: 61200000,
                startDay: 5,
                startTime: 34200000,
              },
            ],
          },
          extendNaked: {
            type: 'closesHighsLows',
            enabled: false,
            strokeColor: '#0C62F7',
            strokeTheme: {
              width: 1,
              lineStyle: 'solid',
              strokeColor: '#0C62F7',
            },
          },
          extendNakedPocs: true,
          vaInsideOpacity: 50,
          vaOutsideOpacity: 50,
        },
        splitProfile: true,
        overlayEthOverRth: false,
      },
      graphics: {
        showPrices: true,
        summaryFont: {
          fontSize: 12,
          fillColor: '#0C62F7',
          fontFamily: 'Open Sans',
          fontWeight: '400',
        },
        summaryEnabled: true,
      },
    },
  },
  {
    name: 'Volume',
    settings: {
      'Line Color': '#3452F1',
    },
  },
  {
    name: 'SessionStats',
    settings: {
      general: {
        style: 'line',
        width: {
          value: 200,
          unit: 'pixel',
        },
        margin: {
          value: 5,
          unit: 'pixel',
        },
      },
      font: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fillColor: '#000000',
        fontWeight: '400',
      },
      lines: {
        rthHigh: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#006534',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'RTH High',
          strokeColor: '#006534',
        },
        rthLow: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#BC0606',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'RTH Low',
          strokeColor: '#BC0606',
        },
        ethHigh: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#006534',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'ETH High',
          strokeColor: '#006534',
        },
        ethLow: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#BC0606',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'ETH Low',
          strokeColor: '#BC0606',
        },
        ibHigh: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#FFFF00',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'IB High',
          strokeColor: '#FFFF00',
        },
        ibLow: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#FFFF00',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'IB Low',
          strokeColor: '#FFFF00',
        },
        prevRthHigh: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#00BD62',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'Prev RTH High',
          strokeColor: '#00BD62',
        },
        prevRthLow: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#FF6C1C',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'Prev RTH Low',
          strokeColor: '#FF6C1C',
        },
        rthMid: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#1E4EF6',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'RTH Mid',
          strokeColor: '#1E4EF6',
        },
        ethMid: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#005B9C',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'ETH Mid',
          strokeColor: '#005B9C',
        },
        rthSettle: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#8B6D00',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'RTH Settle',
          strokeColor: '#8B6D00',
        },
        rthOpen: {
          enabled: true,
          strokeTheme: {
            width: 1,
            strokeColor: '#007A76',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'RTH Open',
          strokeColor: '#007A76',
        },
        prevWkHigh: {
          enabled: false,
          strokeTheme: {
            width: 1,
            strokeColor: '#FFFF00',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'Prev WK High',
          strokeColor: '#FFFF00',
        },
        prevWkLow: {
          enabled: false,
          strokeTheme: {
            width: 1,
            strokeColor: '#FFFF00',
            lineStyle: 'solid',
          },
          devEnabled: false,
          labelEnabled: true,
          label: 'Prev WK Low',
          strokeColor: '#FFFF00',
        },
      },
      rthSessionId: 1,
      ethSessionId: 2,
      workingTimes: {
        rth: {
          id: 1,
          name: 'US RTH',
          exchange: 'CME',
          timezoneId: 'America/New_York',
          connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
          workingTimes: [
            {
              endDay: 1,
              endTime: 61200000,
              startDay: 1,
              startTime: 34200000,
            },
            {
              endDay: 2,
              endTime: 61200000,
              startDay: 2,
              startTime: 34200000,
            },
            {
              endDay: 3,
              endTime: 61200000,
              startDay: 3,
              startTime: 34200000,
            },
            {
              endDay: 4,
              endTime: 61200000,
              startDay: 4,
              startTime: 34200000,
            },
            {
              endDay: 5,
              endTime: 61200000,
              startDay: 5,
              startTime: 34200000,
            },
          ],
        },
        eth: {
          id: 2,
          name: 'US ETH',
          exchange: 'CME',
          timezoneId: 'America/New_York',
          connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
          workingTimes: [
            {
              endDay: 1,
              endTime: 34200000,
              startDay: 0,
              startTime: 64800000,
            },
            {
              endDay: 2,
              endTime: 34200000,
              startDay: 1,
              startTime: 64800000,
            },
            {
              endDay: 3,
              endTime: 34200000,
              startDay: 2,
              startTime: 64800000,
            },
            {
              endDay: 4,
              endTime: 34200000,
              startDay: 3,
              startTime: 64800000,
            },
            {
              endDay: 5,
              endTime: 34200000,
              startDay: 4,
              startTime: 64800000,
            },
          ],
        },
      },
      sessions: {
        rth: {
          id: 1,
          name: 'US RTH',
          exchange: 'CME',
          timezoneId: 'America/New_York',
          connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
          workingTimes: [
            {
              endDay: 1,
              endTime: 61200000,
              startDay: 1,
              startTime: 34200000,
            },
            {
              endDay: 2,
              endTime: 61200000,
              startDay: 2,
              startTime: 34200000,
            },
            {
              endDay: 3,
              endTime: 61200000,
              startDay: 3,
              startTime: 34200000,
            },
            {
              endDay: 4,
              endTime: 61200000,
              startDay: 4,
              startTime: 34200000,
            },
            {
              endDay: 5,
              endTime: 61200000,
              startDay: 5,
              startTime: 34200000,
            },
          ],
        },
        eth: {
          id: 2,
          name: 'US ETH',
          exchange: 'CME',
          timezoneId: 'America/New_York',
          connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
          workingTimes: [
            {
              endDay: 1,
              endTime: 34200000,
              startDay: 0,
              startTime: 64800000,
            },
            {
              endDay: 2,
              endTime: 34200000,
              startDay: 1,
              startTime: 64800000,
            },
            {
              endDay: 3,
              endTime: 34200000,
              startDay: 2,
              startTime: 64800000,
            },
            {
              endDay: 4,
              endTime: 34200000,
              startDay: 3,
              startTime: 64800000,
            },
            {
              endDay: 5,
              endTime: 34200000,
              startDay: 4,
              startTime: 64800000,
            },
          ],
        },
      },
    },
  },
];
