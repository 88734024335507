export enum DomSettingsBetaFeaturesEnum {
  'resetOnNewSession' = 'resetOnNewSession',
  'useCustomTickSize' = 'useCustomTickSize',
}

export interface IDomSettingsBetaFeaturesAvailabilityChange {
  [DomSettingsBetaFeaturesEnum.resetOnNewSession]?: boolean;
  [DomSettingsBetaFeaturesEnum.useCustomTickSize]?: boolean;
}

export enum ShiftToStopOrderEnum {
  rightClick = 'rightClick',
  shiftAndLeftClick = 'shiftAndLeftClick',
}

export enum DomSettingsTabOrderEnum {
  'General',
  'Hotkeys',
  'Trading',
  'Columns',
}
