<button
  nz-dropdown
  nzTrigger="click"
  nzPlacement="bottomRight"
  [nzClickHide]="false"
  [nzDropdownMenu]="menu"
  [(nzVisible)]="visible"
  (nzVisibleChange)="handleToggleDropdown.emit($event)"
  class="icon-user-icon"
></button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul *ngIf="isAuthenticated" nz-menu class="side-navigation">
    <li nz-menu-item class="change-name account-menu-item">
      <span [title]="userName" class="text-ellipsis username">
        <i
          class="icon-user-icon account-menu-item__icon account-menu-item__icon--user"
        ></i
        >{{ userName }}</span
      >
    </li>

    <li class="divider"></li>

    <li
      *ngIf="isAuthenticated"
      nz-menu-item
      (click)="isUserFeedbackVisible = true; this.visible = false"
      class="account-menu-item"
    >
      <i
        class="icon-pencil-form account-menu-item__icon account-menu-item__icon--user-feedback"
      ></i>
      Send Feedback
    </li>
    <li nz-menu-item class="account-menu-item">
      <a
        href="https://tradrr.com/resources/"
        class="account-menu-item__link"
        target="_blank"
      >
        <i
          class="icon-popup account-menu-item__icon account-menu-item__icon--docs"
        ></i>
        Tradrr Docs
      </a>
    </li>

    <li class="divider"></li>

    <li nz-menu-item class="account-menu-item" (click)="logout()">
      <i
        class="icon-log-out account-menu-item__icon account-menu-item__icon--log-out"
      ></i>
      Log out
    </li>
  </ul>
  <ul *ngIf="!isAuthenticated" nz-menu class="side-navigation">
    <li nz-menu-item class="change-name">
      <a [href]="loginLink" class="link"> Login with Identity </a>
    </li>
  </ul>
</nz-dropdown-menu>

<app-user-feedback
  *ngIf="isUserFeedbackVisible"
  (onClose)="isUserFeedbackVisible = false"
></app-user-feedback>
