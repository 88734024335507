import { IHashCode } from '../hash-map';
import { HashSet } from '../hash-set';
import { KeyCode } from '../keycode.enum';
import { IKeyBindingPartDTO, KeyBindingPart } from './key-binding-part';

export interface IKeyBindingDTO {
  parts: Array<IKeyBindingPartDTO>;
}

export class KeyBinding implements IHashCode {
  protected seperator: string = ' + ';
  protected _parts: Set<KeyBindingPart> = new HashSet();

  get _hmuid_() {
    return this.hashCode();
  }

  constructor(parts: Array<KeyBindingPart> = []) {
    parts.forEach((p) => this._parts.add(p));
  }

  push(part: KeyBindingPart) {
    this._parts.add(part);
  }

  remove(part: KeyBindingPart) {
    this._parts.delete(part);
  }

  clear() {
    this._parts.clear();
  }

  toUIString() {
    return Array.from(this._parts.values())
      .map((p) => p.toUIString())
      .join(this.seperator);
  }

  toDTO(): IKeyBindingDTO {
    return {
      parts: Array.from(this._parts.values()).map((p) => p.toDTO()),
    };
  }

  hashCode() {
    return Array.from(this._parts.values())
      .map((p) => p.hashCode())
      .join();
  }

  equals(binding: KeyBinding) {
    if (binding._parts.size !== this._parts.size) return false;
    return this.hashCode() === binding.hashCode();
  }

  clone() {
    return KeyBinding.fromDTO(JSON.parse(JSON.stringify(this.toDTO())));
  }

  static fromDTO(dto: IKeyBindingDTO) {
    return new KeyBinding(dto.parts.map((p) => KeyBindingPart.fromDTO(p)));
  }
}
