import { forkJoin, Observable } from 'rxjs';

import { AccountsManager } from 'accounts-manager';
import { StringHelper } from 'base-components';
import { Id, RProtocolConnectionWebSocketService } from 'communication';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotifierService } from 'notifier';
import { rti } from 'projects/communication/src/services/rprotocol/messages-js/otps_proto_pool';
import { RProtocolOrderPlantService } from 'projects/communication/src/services/rprotocol/rprotocol-order-plant.service';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import {
  getPrice,
  getPriceSpecs,
  IConnection,
  IOrder,
  OrdersFeed,
  OrderSide,
  OrderStatus,
  OrderType,
} from 'trading';

import { TradeLockService } from 'src/app/components';
import {
  ContextItemField,
  TradingContextService,
} from '../../../../src/app/services/trading-context.service';
import { StockChartXOrderSideMarker } from '../chart.model';
import { ChartService } from '../chart.service';
import { ChartObjects } from './chart-objects';

declare const StockChartX: any;

const { uncapitalize } = StringHelper;

export enum OrderSideMarkerOrigination {
  ClickCancelOrder = 'ClickCancelOrder',
  ClickTradingArea = 'ClickTradingArea',
  DragOrderPriceChange = 'DragOrderPriceChange',
}

/**
 * @todo change file name
 */

export class OrderSideMarkers extends ChartObjects<IOrder> {
  private _accountsManager: AccountsManager =
    this._injector.get(AccountsManager);
  private _chartService: ChartService = this._injector.get(ChartService);
  private _tradeLockService: TradeLockService =
    this._injector.get(TradeLockService);
  private _webSocketRegistryService: WebSocketRegistryService =
    this._injector.get(WebSocketRegistryService);
  private _rProtocolOrderPLantService: RProtocolOrderPlantService =
    this._injector.get(RProtocolOrderPlantService);

  private _notifierService: NotifierService =
    this._injector.get(NotifierService);

  protected _modal: NzModalService;
  protected _dataFeed = this._injector.get(OrdersFeed);
  protected _visible = false;

  get requestParams() {
    return {
      symbol: this._instance.instrument.symbol,
      exchange: this._instance.instrument.exchange,
      accountId: this._instance.accountId,
      hideStopped: true,
    };
  }

  init() {
    super.init();
    this._tradingContext.pushContext({
      [ContextItemField.Entry]: 'OrderSideMarker',
    });
    this._modal = this._injector.get(NzModalService);

    this._chart.on(
      StockChartX.OrderSideMarkerEvents.CANCEL_ORDER_CLICKED,
      this.addTradingContext(
        this._cancelOrder,
        OrderSideMarkerOrigination.ClickCancelOrder,
      ),
    );
    this._chart.on(
      StockChartX.OrderSideMarkerEvents.ORDER_PRICE_CHANGED,
      this.addTradingContext(
        this._updatePrice,
        OrderSideMarkerOrigination.DragOrderPriceChange,
      ),
    );
    this._chart.on(
      StockChartX.OrderSideMarkerEvents.ORDER_SETTINGS_CLICKED,
      this._updateOrder,
    );
    this._chart.on(
      StockChartX.OrderSideMarkerEvents.CREATE_ORDER_SETTINGS_CLICKED,
      this._openDialog,
    );
    this._chart.on(
      StockChartX.TradingPanelEvents.TRADING_AREA_CLICKED,
      this.addTradingContext(
        this._tradingAreaClicked,
        OrderSideMarkerOrigination.ClickTradingArea,
      ),
    );
    // @todo Subscribe to the orders datafeed directly?
    this._instance.$ordersFetched.subscribe(this._processOrders.bind(this));
  }

  private _processOrders(orders: IOrder[]): void {
    const allOrdersToCheck: IOrder[] = [...orders, ...this._instance.orders];
    const allUniqueOrdersToCheck: IOrder[] = [
      ...new Map(
        allOrdersToCheck.map((order: IOrder) => [order.id, order]),
      ).values(),
    ];

    const filteredOrders: IOrder[] = allUniqueOrdersToCheck.filter(
      (order: IOrder): boolean =>
        this._isInstrumentMatching(order) && this._isValid(order),
    );

    this.items = filteredOrders;

    filteredOrders.forEach((order: IOrder): void => {
      this.handle(order);
    });
    this._onDataLoaded();
    this._updateAllMarkersQuantity();
    this._updateAllMarkersVisibility();
  }

  private _isInstrumentMatching(order: IOrder): boolean {
    const chartSymbol: string =
      this._instance?.instrument?.tradingSymbol ||
      this._instance?.instrument?.symbol;
    const orderSymbol: string =
      order?.instrument?.tradingSymbol || order?.instrument?.symbol;

    return chartSymbol === orderSymbol;
  }

  protected _loadItems() {
    if (!this._instance.accountId) {
      return;
    }

    this._instance.fetchOrders().then(() => {
      // Orders have been fetched, nothing else to do here
    });
  }

  _tradingAreaClicked = (event): void => {
    const tradingContext: TradingContextService = event.tradingContext;
    this._instance.createOrderWithConfirm(
      {
        side: sideMap[event.value.side],
        price: event.value.price,
        ...(tradingContext ? { tradingContext } : {}),
      },
      event.value.event,
    );
  };

  createOcoOrder(config): void {
    const bar = this.createMarker(config);

    bar.locked = true;
    bar.setDisplayedQuantity(bar.order.quantity); // = true;
    this._instance.chart.mainPanel.addObjects(bar);
    this._markersMap['oco' + config.side] = bar;
  }

  clearOcoOrders(): void {
    const sellBar = this._markersMap['oco' + OrderSide.Sell];

    if (sellBar) {
      sellBar.remove();
      delete this._markersMap['oco' + OrderSide.Sell];
    }
    const buyBar = this._markersMap['oco' + OrderSide.Buy];
    if (buyBar) {
      buyBar.remove();
      delete this._markersMap['oco' + OrderSide.Buy];
    }
  }

  createMarker(model): StockChartXOrderSideMarker {
    return new StockChartX.OrderSideMarker({
      order: this._map(model),
    });
  }

  getOrders(side?: OrderSide) {
    return Object.values(this._markersMap)
      .filter((item: any) => {
        if (!side) return true;
        if (item.order.isOco) return false;
        return item.order.side === side;
      })
      .map((item: any) => item.order);
  }

  private _openDialog = (event): void => {
    const price = event.value.order.price;
    this._instance.createOrder({ price, side: OrderSide.Buy });
  };

  private _updatePrice = ($event): void => {
    if (!this._instance.isTradingEnabled$.getValue()) {
      const oldOrderState: IOrder = $event.oldValue;
      const newOrderState: IOrder = $event.value;
      if (
        oldOrderState &&
        newOrderState &&
        oldOrderState.price !== newOrderState.price
      ) {
        newOrderState.price = oldOrderState.price;
      }
      this._notifierService.showError(
        'Cannot update orders when trading is locked',
      );

      return;
    }
    const orderPlantWebSocket: RProtocolConnectionWebSocketService =
      this._getOrderPlantWebSocket();
    const requests: Observable<rti.RequestModifyOrder>[] = [];
    const priceType: string =
      $event.target.order.type === OrderType.StopMarket
        ? 'triggerPrice'
        : 'price';
    const oldPrice: number = $event.oldValue[priceType];
    const newPrice: number = getPriceSpecs(
      $event.target.order,
      $event.target.order.price,
      this._instance.instrument.tickSize,
    ).price;
    const order: IOrder = this._mapToIOrder($event.target.order, true);
    const orderAccountId: Id = order.accountId || order.account.id;
    let ordersToUpdate: IOrder[] = [
      order,
      ...this.items
        .filter((item: IOrder): boolean => {
          const itemAccountId: Id = item.accountId || item.account.id;

          return (
            item[priceType] === oldPrice &&
            item.id !== order.id &&
            orderAccountId === itemAccountId
          );
        })
        .map((item: IOrder): IOrder => {
          return {
            ...item,
            exchange: item.exchange ?? item.instrument.exchange,
            symbol: item.symbol ?? item.instrument.symbol,
          };
        }),
    ];
    const ordersToUpdateIDs: Id[] = ordersToUpdate.map(
      (item: IOrder): Id => item.id,
    );

    if ($event.tradingContext) {
      ordersToUpdate = ordersToUpdate.map(
        (item: IOrder): IOrder => ({
          ...item,
          tradingContext: $event.tradingContext,
        }),
      );
    }

    ordersToUpdate.forEach((orderToBeUpdated: IOrder): void => {
      let modifiedOrder: IOrder = {
        ...orderToBeUpdated,
        price: newPrice,
      };

      if (order.type === OrderType.StopMarket) {
        modifiedOrder.triggerPrice = newPrice;
      }

      requests.push(
        this._rProtocolOrderPLantService.requestModifyOrder(
          modifiedOrder,
          orderPlantWebSocket,
          this._instance.isTradingEnabled$.getValue(),
        ),
      );
    });

    forkJoin(requests).subscribe(
      (): void => {
        this.items = this.items.map((item: IOrder): IOrder => {
          let modifiedItem: IOrder;

          if (!ordersToUpdateIDs.includes(item.id)) {
            return item;
          }

          modifiedItem = {
            ...item,
          };

          modifiedItem.price = newPrice;

          if (modifiedItem.type === OrderType.StopMarket) {
            modifiedItem.triggerPrice = newPrice;
          }

          return modifiedItem;
        });
        this._updateAllMarkersQuantity();
        this._updateAllMarkersVisibility();
      },
      (): void => {
        this._notifier.showError('Failed to modify order(s).');
      },
    );
  };

  protected _updateAllMarkersVisibility(): void {
    for (let item in this._markersMap) {
      this._markersMap[item].visible = this.shouldBarBeVisible(
        this._markersMap[item].order,
      );
    }
  }

  private _updateAllMarkersQuantity(): void {
    for (let item in this._markersMap) {
      this._markersMap[item].setDisplayedQuantity(
        this._getQuantityByPrice(this._markersMap[item].order),
      );
    }
  }

  private _updateOrder = (event) => {
    // this._instance.openOrderPanel();
  };

  handle(model: any): void {
    const marker: any = this._markersMap[model.id];

    super.handle(model);

    if (!marker) {
      return;
    }

    marker.setDisplayedQuantity(this._getQuantityByPrice(model));
    this._updateAllMarkersQuantity();
  }

  private _mapToIOrder(order, update = false): IOrder {
    const priceSpecs: any = {};
    const typeMap = {
      stop: OrderType.StopMarket,
    };
    const type = typeMap[order.kind] || StringHelper.capitalize(order.kind);

    if (update) {
      const orderId = order.id;
      order = { ...order, orderId };
    }

    if ([OrderType.Limit, OrderType.StopLimit].includes(type)) {
      priceSpecs.limitPrice = order.price;
    }
    if ([OrderType.StopMarket, OrderType.StopLimit].includes(type)) {
      priceSpecs.stopPrice = order.price;
    }
    if (type === OrderType.StopLimit) {
      priceSpecs.limitPrice = order.price;
      priceSpecs.stopPrice = order.stopPrice;
    }

    return {
      instrument: order.instument,
      account: order.account,
      quantity: order.quantity,
      orderId: order.orderId,
      iceQuantity: order.iceQuantity,
      id: order.id,
      ...priceSpecs,
      duration: order.duration,
      type,
      price: order.price,
      side: StringHelper.capitalize(order.action),
      ...this.requestParams,
    };
  }

  private _cancelOrder = (eventData): void => {
    const { value } = eventData;
    if (!value || !value.order) {
      return;
    }
    const { order, event } = value;
    const orderPlantWebSocket: RProtocolConnectionWebSocketService =
      this._getOrderPlantWebSocket();
    const requests: Observable<rti.RequestCancelOrder>[] = [];
    const priceType: string =
      order.type === OrderType.StopMarket ? 'triggerPrice' : 'price';
    let ordersToCancel: IOrder[] = this._chartService.getOrdersGroup(
      this.getOrders(),
      order[priceType],
      order.type,
      order.side,
      this._instance.accountId,
    );
    const tradingContext: TradingContextService = eventData.tradingContext;
    if (tradingContext) {
      ordersToCancel = ordersToCancel.map(
        (order: IOrder): IOrder => ({ ...order, tradingContext }),
      );
    }
    const ordersToCancelIDs: Id[] = ordersToCancel.map(
      (item: IOrder): Id => item.id,
    );
    ordersToCancel.forEach((order: IOrder): void => {
      requests.push(
        this._rProtocolOrderPLantService.requestCancelOrder(
          order,
          orderPlantWebSocket,
          this._instance.isTradingEnabled$.getValue(),
        ),
      );
    });
    this._instance
      .cancelOrderWithConfirm(this._mapToIOrder(order), event)
      .then((res): void => {
        if (!res) {
          return;
        }

        const { create, dontShowAgain } = res;
        this._instance.showCancelConfirm = !dontShowAgain;

        if (!create) {
          return;
        }

        if (!order.id) {
          value.remove();
        } else {
          forkJoin(requests).subscribe(
            (): void => {
              this.items = this.items.filter(
                (item: IOrder): boolean => !ordersToCancelIDs.includes(item.id),
              );
              this._updateAllMarkersVisibility();
              this._updateAllMarkersQuantity();
            },
            (): void => {
              this._notifier.showError('Failed to cancel order(s).');
            },
          );
        }
      });

    if (order.isOco) {
      this._instance.clearOcoOrders();
      return;
    }
  };

  destroy() {
    super.destroy();
    this._chart?.off(
      StockChartX.OrderSideMarkerEvents.CANCEL_ORDER_CLICKED,
      this._cancelOrder,
    );
    this._chart?.off(
      StockChartX.OrderSideMarkerEvents.ORDER_PRICE_CHANGED,
      this._updatePrice,
    );
    this._chart?.off(
      StockChartX.OrderSideMarkerEvents.ORDER_UPDATED,
      this._updateOrder,
    );
    this._chart?.off(
      StockChartX.OrderSideMarkerEvents.CREATE_ORDER_SETTINGS_CLICKED,
      this._openDialog,
    );
    this._chart?.off(
      StockChartX.TradingPanelEvents.TRADING_AREA_CLICKED,
      this._tradingAreaClicked,
    );
  }

  protected _isValid(item: IOrder): boolean {
    return ![
      OrderStatus.Canceled,
      OrderStatus.Rejected,
      OrderStatus.Filled,
    ].includes(item.status);
  }

  protected _map(item: IOrder, _price?) {
    const kindMap = {
      [OrderType.StopMarket]: 'stop',
    };

    return {
      ...item,
      quantity: item.quantity - (item?.filledQuantity ?? 0),
      price: getPrice(item),
      action: uncapitalize(item.side),
      iceQuantity: item.iceQuantity,
      kind: kindMap[item.type] || uncapitalize(item.type),
      state: statusMap[item.status],
    };
  }

  shouldBarBeVisible(model: Partial<IOrder>): boolean {
    const accountId: Id = model.accountId || model.account?.id;

    return (
      super.shouldBarBeVisible() &&
      this._visible &&
      accountId === this._instance.accountId &&
      this.isAlreadyRepresentedByMarkerWithSamePrice(model) === false
    );
  }

  private isAlreadyRepresentedByMarkerWithSamePrice(
    order: Partial<IOrder>,
  ): boolean {
    const priceType: 'triggerPrice' | 'price' =
      order.type === OrderType.StopMarket ? 'triggerPrice' : 'price';
    let orderPrice: number = order[priceType];
    let itemsWithSamePrice: IOrder[];
    let index: number;

    if (
      order.id &&
      this.items.find((item: IOrder): boolean => item.id === order.id)
    ) {
      orderPrice = this.items.find(
        (item: IOrder): boolean => item.id === order.id,
      )[priceType];
    }

    itemsWithSamePrice = this._chartService.getOrdersGroup(
      this.items,
      orderPrice,
      order.type,
      order.side,
      this._instance.accountId,
    );
    index = itemsWithSamePrice.findIndex(
      (item): boolean => item.id === order.id,
    );

    return index === 0 || index === -1 ? false : true;
  }

  private _getQuantityByPrice(order: IOrder): number {
    const realOrder: IOrder = this.items.find(
      (item: IOrder): boolean => item.id === order.id,
    );
    const priceType: string =
      order.type === OrderType.StopMarket ? 'triggerPrice' : 'price';
    const realOrderPrice: number = realOrder
      ? realOrder[priceType]
      : order[priceType];
    const ordersGroup: IOrder[] = this._chartService.getOrdersGroup(
      this.items,
      realOrderPrice,
      order.type,
      order.side,
      this._instance.accountId,
    );
    const quantity: number =
      this._chartService.getOrdersGroupQuantity(ordersGroup);

    if (realOrder?.quantity === 0 && quantity === 0) {
      return order.quantity;
    }

    return quantity;
  }

  setVisibility(visible: boolean): void {
    if (this._visible !== visible) {
      Object.values(this._markersMap).forEach((item: any): void => {
        if (!item.order.isOco) {
          item.visible = visible;
        }
      });
      this._visible = visible;
    }
    this._updateAllMarkersVisibility();
  }

  private _getOrderPlantWebSocket(): RProtocolConnectionWebSocketService {
    const connection: IConnection = this._instance.accountId
      ? this._accountsManager.getConnectionByAccountId(this._instance.accountId)
      : this._accountsManager.getFirstActiveConnection();

    return this._webSocketRegistryService.getRProtocolOrderPlant(
      connection,
    ) as RProtocolConnectionWebSocketService;
  }
}

const sideMap = {
  buy: OrderSide.Buy,
  sell: OrderSide.Sell,
};

const statusMap = {
  [OrderStatus.Canceled]: 'pendingCancel',
  [OrderStatus.Rejected]: 'pendingCancel',
  [OrderStatus.Pending]: 'pendingReplace',
  [OrderStatus.New]: 'pendingSubmit',
  [OrderStatus.Filled]: 'submitted',
  [OrderStatus.PartialFilled]: 'accepted',
};
