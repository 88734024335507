export enum SAVE_DALEY {
  FIVE_MIN = 300000,
  AUTO_SAVE = 'AUTO_SAVE',
  MANUAL_SAVE = 'MANUAL_SAVE',
}

export enum TABS {
  GENERAL = 'general',
  HOTKEYS = 'hotkeys',
  SOUNDS = 'sounds',
  ADMIN = 'admin',
}
