import { AppVersion } from '../../../../../src/app/app.config';

/**
 * @description
 * This class is used to store the version details of the application.
 * It contains the web app version, desktop app version, and versions of some dependencies.
 */
export class VersionDetails {
  appConfigEnv?: string;
  appHostUrl?: string;
  webAppVersion?: AppVersion;
  desktopAppVersion?: AppVersion;
  dependencyVersions?: {
    [key: string]: string | number;
  };
  constructor(
    webAppVersion: AppVersion,
    desktopAppVersion?: AppVersion,
    dependencyVersions: { [key: string]: string | number } = {},
  ) {
    this.webAppVersion = webAppVersion;
    this.desktopAppVersion = desktopAppVersion;
    this.dependencyVersions = dependencyVersions;
  }

  getDependencies(): { name: string; version: string | number }[] {
    if (!this.dependencyVersions) {
      return [];
    }
    return Object.entries(this.dependencyVersions).map(([name, version]) => ({
      name,
      version,
    }));
  }
}
