<div class="version-details" *ngIf="versionDetails">
  <div class="version-section" *ngIf="versionDetails.desktopAppVersion">
    <h5>Environment Details</h5>
    <div class="env-details">
      <span class="env-item">
        <strong>Environment:</strong> {{ versionDetails.appConfigEnv }}
      </span>
      <span class="env-item">
        <strong>Host URL:</strong> {{ versionDetails.appHostUrl }}
      </span>
    </div>
  </div>

  <div class="version-section">
    <h5>Application Versions</h5>
    <div class="version-grid">
      <div class="version-item">
        <h6>Web App</h6>
        <span class="version" *ngIf="versionDetails.webAppVersion">
          <strong>Version:</strong>
          {{ versionDetails.webAppVersion.version }}
          <span *ngIf="versionDetails.webAppVersion.hash" class="hash">
            ({{ versionDetails.webAppVersion.hash }})
          </span>
        </span>
      </div>

      <div class="version-item" *ngIf="versionDetails.desktopAppVersion">
        <h6>Desktop App</h6>
        <span class="version">
          <strong>Version:</strong>
          {{ versionDetails.desktopAppVersion.version }}
          <span *ngIf="versionDetails.desktopAppVersion.hash" class="hash">
            ({{ versionDetails.desktopAppVersion.hash }})
          </span>
        </span>
      </div>
    </div>
  </div>

  <div class="version-section" *ngIf="versionDetails.dependencyVersions">
    <h5>Desktop Dependencies</h5>
    <div class="dependency-grid">
      <div class="dependency-item" *ngFor="let dependency of versionDetails.getDependencies()">
        <strong>{{ dependency.name }}:</strong> {{ dependency.version }}
      </div>
    </div>
  </div>
</div>
