import { Injectable } from '@angular/core';

import { RITHMIC_INFRA_TYPE, WEB_SOCKET_TYPE } from 'communication';
import { OrderMapper } from 'projects/communication/src/services/rprotocol/order-mapper';
import { IOrder } from 'trading';

import { LogConfig } from '../../../../../src/Loggable';
import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';

@Injectable()
export class RealOrdersFeed extends RealFeed<IOrder> {
  public logConfig: LogConfig = {
    enabled: false,
    configureMetadata: (args: string[]) => {},
  };

  private orderMapper: OrderMapper = new OrderMapper();

  feedType = RealtimeType.Order;

  get webSocketType(): WEB_SOCKET_TYPE {
    return WEB_SOCKET_TYPE.RPROTOCOL;
  }

  get infraType(): RITHMIC_INFRA_TYPE {
    return RITHMIC_INFRA_TYPE.ORDER_PLANT;
  }

  get useFeedRelay(): boolean {
    return true;
  }

  /**
   * Called by `RealtimeGridComponent._subscribeToDataFeed()` when a new update is received.
   *
   * @param oldItem
   * @param newItem
   */
  merge(oldItem: IOrder, newItem: IOrder): IOrder {
    return this.orderMapper.merge(oldItem, newItem);
  }
}
