import { TextAlignEnum } from './../../../dynamic-form/src/lib/text-align/text-align.model';

export enum ColumnSortDirectionEnum {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export enum ColumnSortType {
  NUMERICAL = 'NUMERICAL',
  LEXICAL = 'LEXICAL',
}

export interface IColumn {
  name: string;
  title?: string;
  subtitle?: string;
  tableViewName?: string;
  visible: boolean;
  disabled?: boolean;
  // represents should column be hidden in table settings
  hidden?: boolean;
  canHide: boolean;
  draw?: (context) => boolean;
  style?: {
    [key: string]: any;
    textAlign?: TextAlignEnum;
  };
  width?: number;
  type?: string;
  sortDirection?: ColumnSortDirectionEnum;
  sortCompare?: ColumnSortDirectionEnum;
  sortType?: ColumnSortType;
  sortable?: boolean;
}
