import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayoutComponent } from 'layout';
import { NzModalRef, NzModalService, NzSubMenuComponent } from 'ng-zorro-antd';
import { SettingsData, SettingsService } from 'settings';
import { IBaseTemplate, TemplatesService } from 'templates';
import { ConfirmModalComponent, RenameModalComponent } from 'ui';
import { WindowManagerService } from 'window-manager';
import { WorkspacesManager, WorkspaceWindow } from 'workspace-manager';

import {
  ComponentNames,
  Components,
} from 'src/app/components/components.model';
import { FastMarketModeToggleHandler } from '../../../../../projects/dom/src/lib/fast-market-mode.toggle-handler';
import { DebugFlagService } from '../../../../../projects/performance-tools/src/lib/debug-flag.service';
import {
  bottomWidgetList,
  WidgetConfig,
  widgetList,
} from '../component-options';

@UntilDestroy()
@Component({
  selector: 'app-drag-drawer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './drag-drawer.component.html',
  styleUrls: ['./drag-drawer.component.scss'],
})
export class DragDrawerComponent implements OnInit, OnDestroy {
  @Input() layout: LayoutComponent;
  @Output() handleToggleDropdown: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(NzSubMenuComponent) submenu: NzSubMenuComponent;

  opened = false;
  private _isOrderTicketAvailable: boolean = false;
  private _isMarketWatchAvailable: boolean = false;
  items: WidgetConfig[] = this._getItems();
  itemsWithPresets: WidgetConfig[] = this._getItemsWithPresets();
  bottomItems = bottomWidgetList;
  templates: { [component: string]: IBaseTemplate[] } = {};
  componentNames: typeof ComponentNames = ComponentNames;

  readonly components: typeof Components = Components;
  private _templatesSubscription: Subscription;
  private isDebugMode: boolean = false;

  constructor(
    public readonly templatesService: TemplatesService,
    private _modalService: NzModalService,
    private _changeDetectorRef: ChangeDetectorRef,
    public fastMarketModeHandler: FastMarketModeToggleHandler,
    private _workspacesService: WorkspacesManager,
    private _debugFlagService: DebugFlagService,
    private _settingsService: SettingsService,
    private _windowManagerService: WindowManagerService,
  ) {
    this._settingsService.settings
      .pipe(take(2))
      .subscribe((settings: SettingsData): void => {
        this._handleMarketWatchAvailability(
          settings.admin?.betaFeatures?.components?.marketWatch,
        );
        this._handleOrderTicketAvailability(
          settings.admin?.betaFeatures?.components?.orderTicket,
        );
      });
    this._settingsService.marketWatchAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isMarketWatchAvailable: boolean): void => {
        this._handleMarketWatchAvailability(isMarketWatchAvailable);
      });
    this._settingsService.orderTicketAvailabilityChange$
      .pipe(untilDestroyed(this))
      .subscribe((isOrderTicketAvailable: boolean): void => {
        this._handleOrderTicketAvailability(isOrderTicketAvailable);
      });
  }
  ngOnInit(): void {
    this._templatesSubscription = this.templatesService.subscribe(
      (data): void => {
        this.templates = {};
        (data?.items || []).forEach((template: IBaseTemplate): void => {
          this.templates[template.type] = this.templates.hasOwnProperty(
            template.type,
          )
            ? [...this.templates[template.type], template]
            : [template];
        });
        this._changeDetectorRef.detectChanges();
      },
    );
    this._debugFlagService.debugMode$.subscribe(
      (isDebugMode: boolean): void => {
        this.isDebugMode = isDebugMode;
        const menuItemExists: boolean = bottomWidgetList.some(
          (item) => item.component === Components.PerformanceTools,
        );
        if (this.isDebugMode && !menuItemExists) {
          bottomWidgetList.push({
            icon: 'icon-solidgram',
            name: 'Metrics',
            component: Components.PerformanceTools,
          });
        } else if (!this.isDebugMode && menuItemExists) {
          const index: number = bottomWidgetList.findIndex(
            (item) => item.component === Components.PerformanceTools,
          );
          bottomWidgetList.splice(index, 1);
        }
      },
    );
  }

  private _getAvailableItems(): WidgetConfig[] {
    return widgetList.filter((item: WidgetConfig): boolean => {
      if (
        (!this._isOrderTicketAvailable &&
          item.component === Components.OrderForm) ||
        (!this._isMarketWatchAvailable &&
          item.component === Components.MarketWatch)
      ) {
        return false;
      }

      return true;
    });
  }

  private _handleMarketWatchAvailability(
    isMarketWatchAvailable: boolean,
  ): void {
    if (this._isMarketWatchAvailable === isMarketWatchAvailable) {
      return;
    }

    this._isMarketWatchAvailable = isMarketWatchAvailable;
    this.items = this._getItems();
    this.itemsWithPresets = this._getItemsWithPresets();
  }

  private _handleOrderTicketAvailability(
    isOrderTicketAvailable: boolean,
  ): void {
    if (this._isOrderTicketAvailable === isOrderTicketAvailable) {
      return;
    }

    this._isOrderTicketAvailable = isOrderTicketAvailable;
    this.items = this._getItems();
    this.itemsWithPresets = this._getItemsWithPresets();
  }

  private _getItems(): WidgetConfig[] {
    return this._getAvailableItems().filter(
      (item: WidgetConfig): boolean => !item.hasTemplates,
    );
  }

  private _getItemsWithPresets(): WidgetConfig[] {
    return this._getAvailableItems().filter(
      (item: WidgetConfig): boolean => item.hasTemplates,
    );
  }

  create(item, template?: IBaseTemplate): void {
    const defaultTemplate: IBaseTemplate | undefined = this.templates[
      item.component
    ]
      ? this.templatesService.getDefaultTemplate(this.templates[item.component])
      : undefined;
    this.layout.addComponent({
      component: {
        name: item.component,
        template: template ? template : defaultTemplate,
      },
      ...item.options,
    });
  }

  handleDropdownToggle(opened: boolean): void {
    this.opened = opened;
    this.handleToggleDropdown.emit(opened);
    this._changeDetectorRef.detectChanges();
  }

  editChartTemplate(template: IBaseTemplate, event: MouseEvent): void {
    event.stopPropagation();

    this._windowManagerService.blurActiveWindow();

    const modal = this._modalService.create({
      nzWidth: 440,
      nzTitle: 'Edit name',
      nzContent: RenameModalComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        name: template.name,
        label: 'Template name',
      },
    });

    modal.afterClose.subscribe((name): void => {
      this._windowManagerService.focusActiveWindow();

      if (!name) {
        return;
      }

      this.templatesService
        .updateItem({ ...template, name })
        .subscribe((): void => {
          this._changeDetectorRef.detectChanges();
        });
    });

    this._changeDetectorRef.detectChanges();
  }

  deleteChartTemplate(template: IBaseTemplate, event: MouseEvent): void {
    event.stopPropagation();

    const modal: NzModalRef = this._modalService.create({
      nzTitle: 'Delete window',
      nzContent: ConfirmModalComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        message: `Do you want to delete "${template.name}"?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
      },
    });

    modal.afterClose.subscribe((result): void => {
      if (result?.confirmed) {
        this.templatesService.deleteItem(template.id).subscribe();
      }

      this._changeDetectorRef.detectChanges();
    });

    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._templatesSubscription.unsubscribe();
  }

  toggleFastMarketMode() {
    this.fastMarketModeHandler.toggle();
  }

  canAddComponent(widgetConfig: WidgetConfig): boolean {
    if (widgetConfig.component !== Components.Dom) {
      return true;
    }

    const isPopupWindow: boolean = !!window.opener;

    if (isPopupWindow) {
      return false;
    }
    const currentWindow: WorkspaceWindow =
      this._workspacesService.getCurrentWindow();

    return currentWindow?.isOnStartUp;
  }

  hasCustomTemplates(item: WidgetConfig): boolean {
    return (
      this.templates[item.component] &&
      this.templatesService.hasCustomTemplates(this.templates[item.component])
    );
  }

  hasDefaultTemplate(item: WidgetConfig): boolean {
    return (
      this.templates[item.component] &&
      this.templatesService.hasDefaultTemplate(this.templates[item.component])
    );
  }

  resetDefaultTemplate(item: WidgetConfig): void {
    const defaultTemplate: IBaseTemplate | undefined =
      this.templatesService.getDefaultTemplate(this.templates[item.component]);

    if (!defaultTemplate) {
      return;
    }

    const modal: NzModalRef = this._modalService.create({
      nzTitle: 'Confirm Reset',
      nzContent: ConfirmModalComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzComponentParams: {
        message:
          'Are you sure you want to reset all your settings to their Default settings? This cannot be reversed.',
        confirmText: 'Reset',
        cancelText: 'Cancel',
      },
    });

    modal.afterClose.subscribe((result): void => {
      if (result?.confirmed) {
        this.templatesService.deleteItem(defaultTemplate.id).subscribe();
      }
    });
  }
}
