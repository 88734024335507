import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AccountsManager } from 'accounts-manager';
import {
  Id,
  RProtocolConnectionWebSocketService,
  WSEventType,
} from 'communication';
import { RProtocolMessageTemplateNameEnum } from 'projects/communication/src/services/rprotocol/rprotocol-message-template-name-enum';
import { WebSocketRegistryService } from 'projects/communication/src/services/web-socket-registry.service';
import { IInstrument, TradePrint } from 'trading';

import { FeedRelayService } from './feed-relay.service';
import { RealFeed } from './real-feed';
import { RealtimeType } from './realtime';
import { WSMessageTypes } from './ws-message-types';

@Injectable()
export class RealOrderBookSnapshotDataFeed extends RealFeed<
  TradePrint,
  IInstrument
> {
  feedType = RealtimeType.OrderBookSnapshot;
  subscribeType = WSMessageTypes.SUBSCRIBE;
  unsubscribeType = WSMessageTypes.UNSUBSCRIBE;
  private response$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    protected _injector: Injector,
    @Inject(WebSocketRegistryService)
    protected _webSocketRegistryService: WebSocketRegistryService,
    @Inject(AccountsManager) protected _accountsManager: AccountsManager,
    @Inject(FeedRelayService) protected _feedRelay: FeedRelayService,
  ) {
    super(_injector, _webSocketRegistryService, _accountsManager, _feedRelay);

    this._webSocketService.on(WSEventType.Message, (event: any) => {
      if (
        event.type === RProtocolMessageTemplateNameEnum.ResponseMarketDataUpdate
      ) {
        this.response$.next(event.message);
      }
    });
  }

  /**
   * Requests an order book snapshot for a given instrument. It must first unsubscribe and then resubscribe having the OrderBook bit on.
   * @param instrument
   * @param connectionId
   */
  requestSnapshot(instrument: IInstrument, connectionId: Id): void {
    this.subscribe(instrument, connectionId);

    // const requestUid: string = this.unsubscribe(instrument, connectionId);

    // this.response$.subscribe((message: any) => {
    //   if (message?.requestUid === requestUid) {
    //     this.subscribe(instrument, connectionId);
    //   }
    // });
  }

  subscribe(instrument: IInstrument, connectionId: Id): string {
    const webSocket = this._webSocketService.getTickerPlantWebSocket(
      connectionId,
    ) as RProtocolConnectionWebSocketService;
    return webSocket.subscribeToOrderBookSnapshot(instrument);
  }

  unsubscribe(instrument: IInstrument, connectionId: Id): string {
    const webSocket = this._webSocketService.getTickerPlantWebSocket(
      connectionId,
    ) as RProtocolConnectionWebSocketService;
    return webSocket.unsubscribeFromOrderBookSnapshot(instrument);
  }
}
