import { ShiftToStopOrderEnum } from 'projects/dom/src/lib/dom-settings/dom-settings.model';
import {
  IInstrument,
  InstrumentType,
} from 'projects/trading/src/trading/models/instrument';

const defaultInstrument1: IInstrument = {
  id: 1892,
  symbol: 'ESZ4',
  exchange: 'CME',
  stringTypeRepresentation: 'Future',
  productCode: 'ES',
  description: 'E-Mini S\u0026P 500',
  tickSize: 0.25,
  increment: 0.25,
  precision: 2,
  contractSize: 50,
  // expiration: '2024-12-20T00:00:00',
  type: InstrumentType.Future,
};

const defaultInstrument2: IInstrument = {
  id: 2965,
  symbol: 'MESZ4',
  exchange: 'CME',
  stringTypeRepresentation: 'Future',
  productCode: 'MES',
  description: 'Micro E-mini S\u0026P 500',
  tickSize: 0.25,
  increment: 0.25,
  precision: 2,
  contractSize: 5,
  // expiration: '2024-12-20T00:00:00',
  type: InstrumentType.Future,
};

/**
 * Used to create the default Window in the default Workspace for new users
 */
export const defaultWorkspaceWindowConfigData = {
  id: 0,
  name: 'Window',
  config: [
    {
      id: '1733073361931_0.3514296062818718',
      maximizable: false,
      minimizable: false,
      resizable: true,
      minWidth: 225,
      minHeight: 150,
      allowPopup: false,
      visible: true,
      x: 1064.8515625,
      y: 36,
      width: 225,
      height: 230.00741577148438,
      component: {
        name: 'notification-list',
      },
      order: 0,
    },
    {
      id: '1690454474127_0.5829419327601297',
      maximizable: true,
      minimizable: true,
      resizable: true,
      minWidth: 470,
      minHeight: 150,
      allowPopup: true,
      visible: true,
      x: 1289.1044921875,
      y: 36,
      width: 715,
      height: 1121,
      component: {
        state: {
          instrument: {
            id: 'ESZ4.CME',
            type: 'Future',
            symbol: 'ESZ4',
            currency: 'USD',
            exchange: 'CME',
            tickSize: 0.25,
            precision: 2,
            expiration: '2024-12-20T00:00:00',
            description: 'E-Mini S&P 500 Dec24',
            productCode: 'ES',
            connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
            contractSize: 50,
            instrumentTimePeriod: 'Dec24',
            stringTypeRepresentation: 'Future',
          },
          componentInstanceId: 1690454474103,
          settings: {
            _columns: [
              {
                name: 'orders',
                type: 'string',
                index: 0,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#D0D0D2',
                  textAlign: 'center',
                  split: false,
                  showPL: true,
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
                title: 'ORDERS',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 0,
                tableViewName: 'ORDERS',
              },
              {
                name: 'buyOrders',
                type: 'string',
                index: 1,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#D0D0D2',
                  textAlign: 'center',
                  split: false,
                  showPL: true,
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247, 0.5)',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                  hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
                  hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
                },
                title: 'BUY ORDERS',
                width: 100,
                hidden: true,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 1,
                tableViewName: 'BUY ORDERS',
              },
              {
                name: 'sellOrders',
                type: 'string',
                index: 2,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#D0D0D2',
                  textAlign: 'center',
                  split: false,
                  showPL: true,
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                  hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
                  hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
                },
                title: 'SELL ORDERS',
                width: 100,
                hidden: true,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 2,
                tableViewName: 'SELL ORDERS',
              },
              {
                name: 'volume',
                type: 'histogram',
                index: 3,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#fff',
                  textAlign: 'right',
                  ltq: false,
                  poc: true,
                  VWAP: true,
                  sessions: {
                    rth: {
                      id: 127,
                      name: 'US ETH+RTH',
                      exchange: 'CME',
                      timezoneId: 'America/New_York',
                      connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                      workingTimes: [
                        {
                          endDay: 1,
                          endTime: 61200000,
                          startDay: 0,
                          startTime: 64800000,
                        },
                        {
                          endDay: 2,
                          endTime: 61200000,
                          startDay: 1,
                          startTime: 64800000,
                        },
                        {
                          endDay: 3,
                          endTime: 61200000,
                          startDay: 2,
                          startTime: 64800000,
                        },
                        {
                          endDay: 4,
                          endTime: 61200000,
                          startDay: 3,
                          startTime: 64800000,
                        },
                        {
                          endDay: 5,
                          endTime: 61200000,
                          startDay: 4,
                          startTime: 64800000,
                        },
                      ],
                    },
                  },
                  valueArea: true,
                  textOverflow: false,
                  histogramColor: 'rgba(73, 187, 169, 0.5)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
                  histogramOrientation: 'right',
                  valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
                  highlightBackgroundColor: '#9D0A0A',
                  lastTradingBackgroundColor: '#fff',
                  pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
                },
                title: 'VOLUME',
                width: 90,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 3,
                tableViewName: 'VOLUME',
              },
              {
                name: 'price',
                type: 'string',
                index: 4,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: 'rgba(208, 208, 210, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  highlightColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  priceMarkerColor: 'black',
                  tradedPriceColor: 'rgba(208, 208, 210, 1)',
                  hoveredhighlightColor: '#fff',
                  hoveredBackgroundColor: '#383A40',
                  hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
                  highlightBackgroundColor: '#383A40',
                  priceMarkerBackgroundColor: '#FFDF6F',
                  tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
                  priceMarkerpriceMarkerColor: 'black',
                  hoveredhighlightBackgroundColor: '#383A40',
                  longPositionOpenBackgroundColor: '#0C62F7',
                  shortPositionOpenBackgroundColor: '#C93B3BFF',
                  hoveredtradedPriceBackgroundColor: '#383A40',
                  priceMarkerpriceMarkerBackgroundColor: '#FFA500',
                  hoveredlongPositionOpenBackgroundColor: '#383A40',
                  hoveredshortPositionOpenBackgroundColor: '#383A40',
                },
                title: 'PRICE',
                width: 62,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 4,
                tableViewName: 'PRICE',
              },
              {
                name: 'delta',
                type: 'string',
                index: 5,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#D0D0D2',
                  textAlign: 'left',
                  textOverflow: false,
                  askDeltaColor: '#ffffff',
                  askDeltacolor: '#ffffff',
                  bidDeltaColor: '#fff',
                  bidDeltacolor: '#fff',
                  titleUpperCase: true,
                  askDeltaTextAlign: 'center',
                  askDeltatextAlign: 'center',
                  bidDeltaTextAlign: 'center',
                  bidDeltatextAlign: 'center',
                  askDeltaMinToVisible: 0,
                  askDeltaminToVisible: 0,
                  bidDeltaMinToVisible: 0,
                  bidDeltaminToVisible: 0,
                  askDeltaOverlayOrders: true,
                  askDeltaoverlayOrders: true,
                  bidDeltaBuyOrderColor: '#fff',
                  bidDeltaOverlayOrders: true,
                  bidDeltabuyOrderColor: '#fff',
                  bidDeltaoverlayOrders: true,
                  askDeltaSellOrderColor: '#fff',
                  askDeltahighlightColor: '#ffffff',
                  askDeltasellOrderColor: '#fff',
                  bidDeltahighlightColor: '#fff',
                  askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
                  askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
                  bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
                  bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
                  askDeltahighlightTextAlign: 'center',
                  bidDeltahighlightTextAlign: 'center',
                  askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                  askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                  bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                  bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                  bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  askDeltaHighlightBackgroundColor: '#682a2d',
                  askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  askDeltahighlightBackgroundColor: '#682a2d',
                  askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  bidDeltaHighlightBackgroundColor: '#2b486e',
                  bidDeltahighlightBackgroundColor: '#2b486e',
                  askDeltaHoveredhighlightBackgroundColor:
                    'rgba(184, 50, 50, 1)',
                  askDeltahoveredhighlightBackgroundColor:
                    'rgba(184, 50, 50, 1)',
                  bidDeltaHoveredhighlightBackgroundColor:
                    'rgba(7, 88, 228, 1)',
                  bidDeltahoveredhighlightBackgroundColor:
                    'rgba(7, 88, 228, 1)',
                },
                title: 'P/S',
                width: 58,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 5,
                tableViewName: 'PULL/STACK',
              },
              {
                name: 'bidDelta',
                type: 'string',
                index: 6,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#fff',
                  textAlign: 'center',
                  minToVisible: 0,
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  overlayOrders: true,
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247,0.2)',
                  hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  highlightBackgroundColor: '#2b486e',
                  hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
                },
                title: 'P/S.BID',
                width: 68,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 6,
                tableViewName: 'PULL/STACK BID',
              },
              {
                name: 'bid',
                type: 'histogram',
                index: 7,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#fff',
                  textAlign: 'center',
                  largeSize: 14,
                  totalColor: '#fff',
                  textOverflow: false,
                  clearInterval: 9999,
                  highlightLarge: false,
                  histogramColor: 'rgba(72,149,245,0,3)',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247, 0.3)',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                  highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
                  hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
                },
                title: 'BID',
                width: 88,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 7,
                tableViewName: 'BID',
              },
              {
                name: 'ltq',
                type: 'string',
                index: 8,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#fff',
                  textAlign: 'center',
                  textOverflow: false,
                  histogramColor: 'rgba(56, 58, 64, 0.5)',
                  titleUpperCase: true,
                  backgroundColor: '#012B36',
                  accumulateTrades: true,
                  buyBackgroundColor: '#0C62F7',
                  sellBackgroundColor: 'rgba(201, 59, 59, 1)',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'LTQ',
                width: 49,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 8,
                tableViewName: 'LTQ',
              },
              {
                name: 'currentBid',
                type: 'histogram',
                index: 9,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#EB5A5A',
                  textAlign: 'center',
                  clearOnBest: true,
                  textOverflow: false,
                  clearInterval: 9999,
                  levelInterval: 500,
                  momentumTails: true,
                  histogramColor: 'rgba(201, 59, 59, 0.4)',
                  tailInsideBold: false,
                  tailInsideFont: '700 12px "Open Sans", sans-serif',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  tailInsideColor: 'rgba(255, 255, 255, 1)',
                  histogramEnabled: false,
                  clearTradersTimer: 9999,
                  tailInsidelevel1Bold: false,
                  tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel2Bold: false,
                  tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel3Bold: false,
                  tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel4Bold: false,
                  tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel5Bold: false,
                  tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel6Bold: false,
                  tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel7Bold: false,
                  tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel8Bold: false,
                  tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel9Bold: false,
                  tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
                  insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                  level1BackgroundColor: 'rgba(128, 64, 64, 1)',
                  level2BackgroundColor: 'rgba(112, 61, 63, 1)',
                  level3BackgroundColor: 'rgba(96, 59, 62, 1)',
                  level4BackgroundColor: 'rgba(80, 56, 60, 1)',
                  level5BackgroundColor: 'rgba(64, 54, 59, 1)',
                  level6BackgroundColor: 'rgba(48, 51, 58, 1)',
                  level7BackgroundColor: 'rgba(32, 48, 57, 1)',
                  level8BackgroundColor: 'rgba(16, 46, 55, 1)',
                  tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                  highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
                  tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
                  tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
                  tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
                  tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
                  tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
                  tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
                  tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
                  tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
                },
                title: 'I.BID',
                width: 50,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 9,
                tableViewName: 'INSIDE@BID',
              },
              {
                name: 'currentAsk',
                type: 'histogram',
                index: 10,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: 'rgba(12,98,247,1)',
                  textAlign: 'center',
                  clearOnBest: true,
                  textOverflow: false,
                  clearInterval: 9999,
                  levelInterval: 500,
                  momentumTails: true,
                  histogramColor: 'rgba(4, 63, 128, 1)',
                  tailInsideBold: false,
                  tailInsideFont: '700 12px "Open Sans", sans-serif',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  tailInsideColor: 'rgba(255, 255, 255, 1)',
                  histogramEnabled: false,
                  clearTradersTimer: 9999,
                  tailInsidelevel1Bold: false,
                  tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel2Bold: false,
                  tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel3Bold: false,
                  tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel4Bold: false,
                  tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel5Bold: false,
                  tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel6Bold: false,
                  tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel7Bold: false,
                  tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel8Bold: false,
                  tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel9Bold: false,
                  tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
                  insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                  level1BackgroundColor: 'rgba(4, 63, 128, 1)',
                  level2BackgroundColor: 'rgba(3, 60, 119, 1)',
                  level3BackgroundColor: 'rgba(3, 59, 110, 1)',
                  level4BackgroundColor: 'rgba(2, 56, 100, 1)',
                  level5BackgroundColor: 'rgba(2, 54, 91, 1)',
                  level6BackgroundColor: 'rgba(2, 51, 82, 1)',
                  level7BackgroundColor: 'rgba(1, 48, 73, 1)',
                  level8BackgroundColor: 'rgba(1, 46, 63, 1)',
                  tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                  highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
                  tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
                  tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
                  tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
                  tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
                  tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
                  tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
                  tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
                  tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
                },
                title: 'I.ASK',
                width: 50,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 10,
                tableViewName: 'INSIDE@ASK',
              },
              {
                name: 'ask',
                type: 'histogram',
                index: 11,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#fff',
                  textAlign: 'center',
                  largeSize: 14,
                  totalColor: '#fff',
                  textOverflow: false,
                  clearInterval: 9999,
                  highlightLarge: false,
                  histogramColor: 'rgba(201, 59, 59, 0.4)',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(201, 59, 59, 0.3)',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                  highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
                  hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
                },
                title: 'ASK',
                width: 88,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 11,
                tableViewName: 'ASK',
              },
              {
                name: 'askDelta',
                type: 'string',
                index: 12,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: '#ffffff',
                  textAlign: 'center',
                  minToVisible: 0,
                  textOverflow: false,
                  overlayOrders: true,
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(201, 59, 59, 0.2)',
                  hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                  highlightBackgroundColor: '#682a2d',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
                },
                title: 'P/S.ASK',
                width: 68,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 12,
                tableViewName: 'PULL/STACK ASK',
              },
              {
                name: 'totalBid',
                type: 'histogram',
                index: 13,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: 'rgba(235, 90, 90, 1)',
                  textAlign: 'left',
                  textOverflow: false,
                  histogramColor: 'rgba(201, 59, 59, 0.3)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  histogramOrientation: 'right',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'T.BID',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 13,
                tableViewName: 'TOTAL@BID',
              },
              {
                name: 'totalAsk',
                type: 'histogram',
                index: 14,
                style: {
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  color: 'rgba(12,98,247, 1)',
                  textAlign: 'right',
                  textOverflow: false,
                  histogramColor: 'rgba(12,98,247, 0.3)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'T.ASK',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 14,
                tableViewName: 'TOTAL@ASK',
              },
            ],
            general: {
              currentTradesAllWindows: false,
              currentTotalAllWindows: false,
              recenterTotalAllWindows: false,
              closeOutstandingOrders: false,
              clearInsidePrints: false,
              clearTotalPrints: false,
              recenter: true,
              commonView: {
                autoCenter: true,
                centerLine: true,
                autoCenterTicks: 20,
              },
              marketDepth: {
                marketDepth: 40,
                bidAskDeltaDepth: 9,
                bidAskDeltaFilter: 0,
              },
              digitsToHide: 4,
              hideAccountName: false,
              hideFromLeft: false,
              hideFromRight: false,
              intervals: {
                momentumTails: true,
                updateInterval: 100,
                clearTradersTimer: 9999,
                orderQuantityStep: 7,
                momentumIntervalMs: 500,
                resetDeltaInterval: '5000',
                scrollWheelSensitivity: 1,
              },
            },
            hotkeys: {
              shiftToStopOrder: ShiftToStopOrderEnum.shiftAndLeftClick,
              quantity1: null,
              quantity2: null,
              quantity3: null,
              quantity4: null,
              quantity5: null,
              autoCenter: null,
              quantityToPos: null,
              hitBid: null,
              joinBid: null,
              liftOffer: null,
              joinAsk: null,
              stopsToPrice: null,
              limitsToPrice: null,
              autoCenterAllWindows: null,
              clearAllTotals: null,
              clearInsidePrintsDown: null,
              clearInsidePrintsUp: null,
              clearVolumeProfile: null,
              clearTotalPrintsDown: null,
              clearTotalPrintsUp: null,
              clearInsidePrintsAllWindows: null,
              clearInsidePrintsDownAllWindows: null,
              clearInsidePrintsUpAllWindows: null,
              clearTotalPrintsDownAllWindows: null,
              clearTotalPrintsUpAllWindows: null,
              clearAllPriceMarkers: null,
              oco: null,
              flatten: null,
              buyMarket: null,
              sellMarket: null,
              clearAlerts: null,
              cancelAllOrders: null,
              clearAlertsAllWindow: null,
            },
            common: {
              fontFamily: '"Open Sans", sans-serif',
              fontSize: 12,
              fontWeight: '',
              generalColors: {
                gridLineColor: '#24262C',
                centerLineColor: '#A1A2A5',
                orderGridLineColor: 'rgba(88, 110, 117, 1)',
                enableOrderGridColor: true,
              },
              askDelta: true,
              ask: true,
              bidDelta: true,
              bid: true,
              ltq: true,
              delta: false,
              notes: true,
              orders: false,
              buyOrders: false,
              sellOrders: false,
              price: true,
              totalAsk: false,
              totalBid: false,
              currentBid: true,
              currentAsk: true,
              volume: true,
              rowHeight: 15,
            },
            ltq: {
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              backgroundColor: '#012B36',
              buyBackgroundColor: '#0C62F7',
              color: '#fff',
              accumulateTrades: true,
              histogramColor: 'rgba(56, 58, 64, 0.5)',
              sellBackgroundColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
            },
            trading: {
              amountButtons: [
                {
                  value: 1,
                },
                {
                  black: true,
                  value: 1,
                },
                {
                  value: 3,
                },
                {
                  value: 5,
                },
                {
                  value: 10,
                },
                {
                  value: 25,
                },
              ],
              formData: {
                quantity: 1,
              },
              orderArea: {
                roundPL: false,
                settings: {
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: false,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                },
                showPLInfo: true,
                showOHLVInfo: true,
                includeRealizedPL: false,
                showInstrumentChange: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
              },
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              trading: {
                split: false,
                overlayOrders: true,
                showOrderConfirm: false,
                showCancelConfirm: false,
              },
            },
            price: {
              color: 'rgba(208, 208, 210, 1)',
              highlightColor: '#fff',
              tradedPriceColor: 'rgba(208, 208, 210, 1)',
              hoveredhighlightColor: '#fff',
              hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
              backgroundColor: 'transparent',
              highlightBackgroundColor: '#383A40',
              tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
              longPositionOpenBackgroundColor: '#0C62F7',
              shortPositionOpenBackgroundColor: '#C93B3BFF',
              hoveredBackgroundColor: '#383A40',
              hoveredhighlightBackgroundColor: '#383A40',
              hoveredtradedPriceBackgroundColor: '#383A40',
              hoveredlongPositionOpenBackgroundColor: '#383A40',
              hoveredshortPositionOpenBackgroundColor: '#383A40',
              textAlign: 'center',
              priceMarkerColor: 'black',
              priceMarkerBackgroundColor: '#FFDF6F',
              priceMarkerpriceMarkerColor: 'black',
              priceMarkerpriceMarkerBackgroundColor: '#FFA500',
            },
            bidDelta: {
              backgroundColor: 'rgba(12,98,247,0.2)',
              highlightBackgroundColor: '#2b486e',
              color: '#fff',
              textAlign: 'center',
              minToVisible: 0,
              buyOrderColor: '#fff',
              overlayOrders: true,
              hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            askDelta: {
              backgroundColor: 'rgba(201, 59, 59, 0.2)',
              textAlign: 'center',
              highlightBackgroundColor: '#682a2d',
              color: '#ffffff',
              minToVisible: 0,
              overlayOrders: true,
              sellOrderColor: '#fff',
              hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
            bid: {
              color: '#fff',
              backgroundColor: 'rgba(12,98,247, 0.3)',
              highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
              textAlign: 'center',
              histogramOrientation: 'left',
              largeSize: 14,
              highlightLarge: false,
              histogramColor: 'rgba(72,149,245,0,3)',
              histogramEnabled: true,
              totalColor: '#fff',
              clearInterval: 9999,
              hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            ask: {
              color: '#fff',
              backgroundColor: 'rgba(201, 59, 59, 0.3)',
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              histogramOrientation: 'left',
              largeSize: 14,
              highlightLarge: false,
              histogramEnabled: true,
              totalColor: '#fff',
              clearInterval: 9999,
              hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
            totalAsk: {
              histogramColor: 'rgba(12,98,247, 0.3)',
              textAlign: 'right',
              color: 'rgba(12,98,247, 1)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              histogramOrientation: 'left',
            },
            totalBid: {
              histogramColor: 'rgba(201, 59, 59, 0.3)',
              textAlign: 'left',
              color: 'rgba(235, 90, 90, 1)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              histogramOrientation: 'right',
            },
            volume: {
              highlightBackgroundColor: '#9D0A0A',
              textAlign: 'right',
              backgroundColor: 'transparent',
              lastTradingBackgroundColor: '#fff',
              valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
              color: '#fff',
              histogramColor: 'rgba(73, 187, 169, 0.5)',
              histogramOrientation: 'right',
              pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
              VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
              VWAP: true,
              histogramEnabled: true,
              ltq: false,
              poc: true,
              valueArea: true,
              sessions: {
                rth: {
                  id: 127,
                  name: 'US ETH+RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                  workingTimes: [
                    {
                      endDay: 1,
                      endTime: 61200000,
                      startDay: 0,
                      startTime: 64800000,
                    },
                    {
                      endDay: 2,
                      endTime: 61200000,
                      startDay: 1,
                      startTime: 64800000,
                    },
                    {
                      endDay: 3,
                      endTime: 61200000,
                      startDay: 2,
                      startTime: 64800000,
                    },
                    {
                      endDay: 4,
                      endTime: 61200000,
                      startDay: 3,
                      startTime: 64800000,
                    },
                    {
                      endDay: 5,
                      endTime: 61200000,
                      startDay: 4,
                      startTime: 64800000,
                    },
                  ],
                },
              },
            },
            orders: {
              backgroundColor: 'transparent',
              textAlign: 'center',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              buyOrderColor: '#fff',
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              highlightColor: 'rgba(29, 73, 127, 1)',
              inProfitBackgroundColor: 'transparent',
              inProfitColor: 'rgba(12,98,247,1)',
              lossBackgroundColor: 'transparent',
              lossColor: 'rgba(201, 59, 59, 1)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrderColor: '#fff',
              sellOrderBorderColor: '#C93B3B',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              showPL: true,
              split: false,
              includeRealizedPL: false,
              overlayOrders: true,
            },
            currentBid: {
              color: '#EB5A5A',
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              backgroundColor: 'transparent',
              highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
              histogramEnabled: false,
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              tailInsideBold: false,
              level1BackgroundColor: 'rgba(128, 64, 64, 1)',
              level2BackgroundColor: 'rgba(112, 61, 63, 1)',
              level3BackgroundColor: 'rgba(96, 59, 62, 1)',
              level4BackgroundColor: 'rgba(80, 56, 60, 1)',
              level5BackgroundColor: 'rgba(64, 54, 59, 1)',
              level6BackgroundColor: 'rgba(48, 51, 58, 1)',
              level7BackgroundColor: 'rgba(32, 48, 57, 1)',
              level8BackgroundColor: 'rgba(16, 46, 55, 1)',
              textAlign: 'center',
              clearOnBest: true,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              tailInsideFont: '700 12px "Open Sans", sans-serif',
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
            },
            note: {
              addedOrdersColor: 'rgba(53, 104, 147, 1)',
              backgroundColor: 'transparent',
              color: '#fff',
              pulledOrdersColor: 'rgba(143, 60, 65, 1)',
            },
            currentAsk: {
              color: 'rgba(12,98,247,1)',
              histogramColor: 'rgba(4, 63, 128, 1)',
              backgroundColor: 'transparent',
              histogramEnabled: false,
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              tailInsideBold: false,
              level1BackgroundColor: 'rgba(4, 63, 128, 1)',
              level2BackgroundColor: 'rgba(3, 60, 119, 1)',
              level3BackgroundColor: 'rgba(3, 59, 110, 1)',
              level4BackgroundColor: 'rgba(2, 56, 100, 1)',
              level5BackgroundColor: 'rgba(2, 54, 91, 1)',
              level6BackgroundColor: 'rgba(2, 51, 82, 1)',
              level7BackgroundColor: 'rgba(1, 48, 73, 1)',
              level8BackgroundColor: 'rgba(1, 46, 63, 1)',
              textAlign: 'center',
              clearOnBest: true,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              tailInsideFont: '700 12px "Open Sans", sans-serif',
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
            },
            buyOrders: {
              backgroundColor: 'rgba(12,98,247, 0.5)',
              textAlign: 'center',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              buyOrderColor: '#fff',
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              highlightColor: 'rgba(29, 73, 127, 1)',
              inProfitBackgroundColor: 'transparent',
              inProfitColor: 'rgba(12,98,247,1)',
              lossBackgroundColor: 'transparent',
              lossColor: 'rgba(201, 59, 59, 1)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrderColor: '#fff',
              sellOrderBorderColor: '#C93B3B',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              showPL: true,
              split: false,
              includeRealizedPL: false,
              overlayOrders: true,
              hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
              hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            sellOrders: {
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              textAlign: 'center',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              buyOrderColor: '#fff',
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              highlightColor: 'rgba(29, 73, 127, 1)',
              inProfitBackgroundColor: 'transparent',
              inProfitColor: 'rgba(12,98,247,1)',
              lossBackgroundColor: 'transparent',
              lossColor: 'rgba(201, 59, 59, 1)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrderColor: '#fff',
              sellOrderBorderColor: '#C93B3B',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              showPL: true,
              split: false,
              includeRealizedPL: false,
              overlayOrders: true,
              hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
              hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
            delta: {
              bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
              bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
              bidDeltahighlightBackgroundColor: '#2b486e',
              bidDeltaHighlightBackgroundColor: '#2b486e',
              bidDeltacolor: '#fff',
              bidDeltaColor: '#fff',
              bidDeltatextAlign: 'center',
              bidDeltaTextAlign: 'center',
              bidDeltaminToVisible: 0,
              bidDeltaMinToVisible: 0,
              bidDeltabuyOrderColor: '#fff',
              bidDeltaBuyOrderColor: '#fff',
              bidDeltaoverlayOrders: true,
              bidDeltaOverlayOrders: true,
              bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              bidDeltahoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltaHoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
              askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
              askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
              askDeltatextAlign: 'center',
              askDeltaTextAlign: 'center',
              askDeltahighlightBackgroundColor: '#682a2d',
              askDeltaHighlightBackgroundColor: '#682a2d',
              askDeltacolor: '#ffffff',
              askDeltaColor: '#ffffff',
              askDeltaminToVisible: 0,
              askDeltaMinToVisible: 0,
              askDeltaoverlayOrders: true,
              askDeltaOverlayOrders: true,
              askDeltasellOrderColor: '#fff',
              askDeltaSellOrderColor: '#fff',
              askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              askDeltahoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
              askDeltaHoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
              askDeltahighlightColor: '#ffffff',
              askDeltahighlightTextAlign: 'center',
              bidDeltahighlightColor: '#fff',
              bidDeltahighlightTextAlign: 'center',
            },
          },
          columns: [
            {
              name: 'orders',
              type: 'string',
              index: 0,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#D0D0D2',
                textAlign: 'center',
                split: false,
                showPL: true,
                lossColor: 'rgba(201, 59, 59, 1)',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              title: 'ORDERS',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 0,
              tableViewName: 'ORDERS',
            },
            {
              name: 'buyOrders',
              type: 'string',
              index: 1,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#D0D0D2',
                textAlign: 'center',
                split: false,
                showPL: true,
                lossColor: 'rgba(201, 59, 59, 1)',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247, 0.5)',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
                hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
              },
              title: 'BUY ORDERS',
              width: 100,
              hidden: true,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 1,
              tableViewName: 'BUY ORDERS',
            },
            {
              name: 'sellOrders',
              type: 'string',
              index: 2,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#D0D0D2',
                textAlign: 'center',
                split: false,
                showPL: true,
                lossColor: 'rgba(201, 59, 59, 1)',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
                hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
              },
              title: 'SELL ORDERS',
              width: 100,
              hidden: true,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 2,
              tableViewName: 'SELL ORDERS',
            },
            {
              name: 'volume',
              type: 'histogram',
              index: 3,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#fff',
                textAlign: 'right',
                ltq: false,
                poc: true,
                VWAP: true,
                sessions: {
                  rth: {
                    id: 127,
                    name: 'US ETH+RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                    workingTimes: [
                      {
                        endDay: 1,
                        endTime: 61200000,
                        startDay: 0,
                        startTime: 64800000,
                      },
                      {
                        endDay: 2,
                        endTime: 61200000,
                        startDay: 1,
                        startTime: 64800000,
                      },
                      {
                        endDay: 3,
                        endTime: 61200000,
                        startDay: 2,
                        startTime: 64800000,
                      },
                      {
                        endDay: 4,
                        endTime: 61200000,
                        startDay: 3,
                        startTime: 64800000,
                      },
                      {
                        endDay: 5,
                        endTime: 61200000,
                        startDay: 4,
                        startTime: 64800000,
                      },
                    ],
                  },
                },
                valueArea: true,
                textOverflow: false,
                histogramColor: 'rgba(73, 187, 169, 0.5)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
                histogramOrientation: 'right',
                valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
                highlightBackgroundColor: '#9D0A0A',
                lastTradingBackgroundColor: '#fff',
                pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
              },
              title: 'VOLUME',
              width: 90,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 3,
              tableViewName: 'VOLUME',
            },
            {
              name: 'price',
              type: 'string',
              index: 4,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: 'rgba(208, 208, 210, 1)',
                textAlign: 'center',
                textOverflow: false,
                highlightColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                priceMarkerColor: 'black',
                tradedPriceColor: 'rgba(208, 208, 210, 1)',
                hoveredhighlightColor: '#fff',
                hoveredBackgroundColor: '#383A40',
                hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
                highlightBackgroundColor: '#383A40',
                priceMarkerBackgroundColor: '#FFDF6F',
                tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
                priceMarkerpriceMarkerColor: 'black',
                hoveredhighlightBackgroundColor: '#383A40',
                longPositionOpenBackgroundColor: '#0C62F7',
                shortPositionOpenBackgroundColor: '#C93B3BFF',
                hoveredtradedPriceBackgroundColor: '#383A40',
                priceMarkerpriceMarkerBackgroundColor: '#FFA500',
                hoveredlongPositionOpenBackgroundColor: '#383A40',
                hoveredshortPositionOpenBackgroundColor: '#383A40',
              },
              title: 'PRICE',
              width: 62,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 4,
              tableViewName: 'PRICE',
            },
            {
              name: 'delta',
              type: 'string',
              index: 5,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#D0D0D2',
                textAlign: 'left',
                textOverflow: false,
                askDeltaColor: '#ffffff',
                askDeltacolor: '#ffffff',
                bidDeltaColor: '#fff',
                bidDeltacolor: '#fff',
                titleUpperCase: true,
                askDeltaTextAlign: 'center',
                askDeltatextAlign: 'center',
                bidDeltaTextAlign: 'center',
                bidDeltatextAlign: 'center',
                askDeltaMinToVisible: 0,
                askDeltaminToVisible: 0,
                bidDeltaMinToVisible: 0,
                bidDeltaminToVisible: 0,
                askDeltaOverlayOrders: true,
                askDeltaoverlayOrders: true,
                bidDeltaBuyOrderColor: '#fff',
                bidDeltaOverlayOrders: true,
                bidDeltabuyOrderColor: '#fff',
                bidDeltaoverlayOrders: true,
                askDeltaSellOrderColor: '#fff',
                askDeltahighlightColor: '#ffffff',
                askDeltasellOrderColor: '#fff',
                bidDeltahighlightColor: '#fff',
                askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
                askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
                bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
                bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
                askDeltahighlightTextAlign: 'center',
                bidDeltahighlightTextAlign: 'center',
                askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                askDeltaHighlightBackgroundColor: '#682a2d',
                askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                askDeltahighlightBackgroundColor: '#682a2d',
                askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                bidDeltaHighlightBackgroundColor: '#2b486e',
                bidDeltahighlightBackgroundColor: '#2b486e',
                askDeltaHoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
                askDeltahoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
                bidDeltaHoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
                bidDeltahoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
              },
              title: 'P/S',
              width: 58,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 5,
              tableViewName: 'PULL/STACK',
            },
            {
              name: 'bidDelta',
              type: 'string',
              index: 6,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#fff',
                textAlign: 'center',
                minToVisible: 0,
                textOverflow: false,
                buyOrderColor: '#fff',
                overlayOrders: true,
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247,0.2)',
                hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                highlightBackgroundColor: '#2b486e',
                hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
              },
              title: 'P/S.BID',
              width: 68,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 6,
              tableViewName: 'PULL/STACK BID',
            },
            {
              name: 'bid',
              type: 'histogram',
              index: 7,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#fff',
                textAlign: 'center',
                largeSize: 14,
                totalColor: '#fff',
                textOverflow: false,
                clearInterval: 9999,
                highlightLarge: false,
                histogramColor: 'rgba(72,149,245,0,3)',
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247, 0.3)',
                histogramEnabled: true,
                histogramOrientation: 'left',
                hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
                hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
              },
              title: 'BID',
              width: 88,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 7,
              tableViewName: 'BID',
            },
            {
              name: 'ltq',
              type: 'string',
              index: 8,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#fff',
                textAlign: 'center',
                textOverflow: false,
                histogramColor: 'rgba(56, 58, 64, 0.5)',
                titleUpperCase: true,
                backgroundColor: '#012B36',
                accumulateTrades: true,
                buyBackgroundColor: '#0C62F7',
                sellBackgroundColor: 'rgba(201, 59, 59, 1)',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'LTQ',
              width: 49,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 8,
              tableViewName: 'LTQ',
            },
            {
              name: 'currentBid',
              type: 'histogram',
              index: 9,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#EB5A5A',
                textAlign: 'center',
                clearOnBest: true,
                textOverflow: false,
                clearInterval: 9999,
                levelInterval: 500,
                momentumTails: true,
                histogramColor: 'rgba(201, 59, 59, 0.4)',
                tailInsideBold: false,
                tailInsideFont: '700 12px "Open Sans", sans-serif',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                tailInsideColor: 'rgba(255, 255, 255, 1)',
                histogramEnabled: false,
                clearTradersTimer: 9999,
                tailInsidelevel1Bold: false,
                tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel2Bold: false,
                tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel3Bold: false,
                tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel4Bold: false,
                tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel5Bold: false,
                tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel6Bold: false,
                tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel7Bold: false,
                tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel8Bold: false,
                tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel9Bold: false,
                tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
                insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                level1BackgroundColor: 'rgba(128, 64, 64, 1)',
                level2BackgroundColor: 'rgba(112, 61, 63, 1)',
                level3BackgroundColor: 'rgba(96, 59, 62, 1)',
                level4BackgroundColor: 'rgba(80, 56, 60, 1)',
                level5BackgroundColor: 'rgba(64, 54, 59, 1)',
                level6BackgroundColor: 'rgba(48, 51, 58, 1)',
                level7BackgroundColor: 'rgba(32, 48, 57, 1)',
                level8BackgroundColor: 'rgba(16, 46, 55, 1)',
                tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
                tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
                tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
                tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
                tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
                tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
                tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
                tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
                tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
              },
              title: 'I.BID',
              width: 50,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 9,
              tableViewName: 'INSIDE@BID',
            },
            {
              name: 'currentAsk',
              type: 'histogram',
              index: 10,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: 'rgba(12,98,247,1)',
                textAlign: 'center',
                clearOnBest: true,
                textOverflow: false,
                clearInterval: 9999,
                levelInterval: 500,
                momentumTails: true,
                histogramColor: 'rgba(4, 63, 128, 1)',
                tailInsideBold: false,
                tailInsideFont: '700 12px "Open Sans", sans-serif',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                tailInsideColor: 'rgba(255, 255, 255, 1)',
                histogramEnabled: false,
                clearTradersTimer: 9999,
                tailInsidelevel1Bold: false,
                tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel2Bold: false,
                tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel3Bold: false,
                tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel4Bold: false,
                tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel5Bold: false,
                tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel6Bold: false,
                tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel7Bold: false,
                tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel8Bold: false,
                tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel9Bold: false,
                tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
                insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                level1BackgroundColor: 'rgba(4, 63, 128, 1)',
                level2BackgroundColor: 'rgba(3, 60, 119, 1)',
                level3BackgroundColor: 'rgba(3, 59, 110, 1)',
                level4BackgroundColor: 'rgba(2, 56, 100, 1)',
                level5BackgroundColor: 'rgba(2, 54, 91, 1)',
                level6BackgroundColor: 'rgba(2, 51, 82, 1)',
                level7BackgroundColor: 'rgba(1, 48, 73, 1)',
                level8BackgroundColor: 'rgba(1, 46, 63, 1)',
                tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
                tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
                tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
                tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
                tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
                tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
                tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
                tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
                tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
              },
              title: 'I.ASK',
              width: 50,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 10,
              tableViewName: 'INSIDE@ASK',
            },
            {
              name: 'ask',
              type: 'histogram',
              index: 11,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#fff',
                textAlign: 'center',
                largeSize: 14,
                totalColor: '#fff',
                textOverflow: false,
                clearInterval: 9999,
                highlightLarge: false,
                histogramColor: 'rgba(201, 59, 59, 0.4)',
                titleUpperCase: true,
                backgroundColor: 'rgba(201, 59, 59, 0.3)',
                histogramEnabled: true,
                histogramOrientation: 'left',
                hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
                hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
              },
              title: 'ASK',
              width: 88,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 11,
              tableViewName: 'ASK',
            },
            {
              name: 'askDelta',
              type: 'string',
              index: 12,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: '#ffffff',
                textAlign: 'center',
                minToVisible: 0,
                textOverflow: false,
                overlayOrders: true,
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(201, 59, 59, 0.2)',
                hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                highlightBackgroundColor: '#682a2d',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
              },
              title: 'P/S.ASK',
              width: 68,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 12,
              tableViewName: 'PULL/STACK ASK',
            },
            {
              name: 'totalBid',
              type: 'histogram',
              index: 13,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: 'rgba(235, 90, 90, 1)',
                textAlign: 'left',
                textOverflow: false,
                histogramColor: 'rgba(201, 59, 59, 0.3)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                histogramOrientation: 'right',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'T.BID',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 13,
              tableViewName: 'TOTAL@BID',
            },
            {
              name: 'totalAsk',
              type: 'histogram',
              index: 14,
              style: {
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                color: 'rgba(12,98,247, 1)',
                textAlign: 'right',
                textOverflow: false,
                histogramColor: 'rgba(12,98,247, 0.3)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                histogramOrientation: 'left',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'T.ASK',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 14,
              tableViewName: 'TOTAL@ASK',
            },
          ],
          contextMenuState: {
            showHeaderPanel: true,
            showColumnHeaders: true,
          },
          orderForm: {
            formData: {
              quantity: 1,
              stopLoss: {
                stopLoss: true,
                type: 'StopMarket',
                unitItem: 12,
                unit: 'ticks',
                amount: 1,
                ticks: 12,
              },
              takeProfit: {
                takeProfit: true,
                unitItem: 12,
                unit: 'ticks',
                ticks: 12,
              },
            },
            amountButtons: [
              {
                value: 1,
              },
              {
                black: true,
                value: 1,
              },
              {
                value: 3,
              },
              {
                value: 5,
              },
              {
                value: 10,
              },
              {
                value: 25,
              },
            ],
            settings: {
              buyButtonsBackgroundColor: '#0C62F7',
              flatButtonsBackgroundColor: '#51535A',
              buyButtonsFontColor: '#D0D0D2',
              flatButtonsFontColor: '#D0D0D2',
              sellButtonsBackgroundColor: '#C93B3B',
              cancelButtonBackgroundColor: '#51535A',
              sellButtonsFontColor: '#D0D0D2',
              cancelButtonFontColor: '#D0D0D2',
              closePositionFontColor: '#D0D0D2',
              closePositionBackgroundColor: '#51535A',
              icebergBackgroundColor: '#51535A',
              icebergFontColor: '#fff',
              formSettings: {
                showIcebergButton: false,
                showFlattenButton: true,
                showLiquidateButton: true,
                showCancelButton: true,
                showBuyButton: true,
                showSellButton: true,
                showInstrumentChange: true,
                showOHLVInfo: true,
                showPLInfo: true,
                roundPL: false,
                showCancelConfirm: false,
                showOrderConfirm: false,
                includeRealizedPL: false,
              },
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              amountButtons: [
                {
                  value: 1,
                },
                {
                  black: true,
                  value: 1,
                },
                {
                  value: 3,
                },
                {
                  value: 5,
                },
                {
                  value: 10,
                },
                {
                  value: 25,
                },
              ],
            },
          },
          isOrderFormOpen: true,
          orderFormDirection: 'window-right',
          isTradingEnabled: false,
          priceMarkers: [
            '5796.00',
            '5736.50',
            '5785.00',
            '5781.00',
            '5770.00',
            '5770.25',
            '5933.00',
            '5917.25',
          ],
          width: 715,
          preferredAccounts: {},
        },
        name: 'dom',
      },
      order: 1,
    },
    {
      id: '1698770407984_0.7079912167002815',
      maximizable: true,
      minimizable: true,
      resizable: true,
      minWidth: 350,
      minHeight: 150,
      allowPopup: true,
      visible: true,
      x: 0,
      y: 158.734375,
      width: 1289.9638671875,
      height: 997.75,
      component: {
        state: {
          isToolbarVisible: false,
          showOHLV: false,
          showChanges: false,
          showChartForm: true,
          enableOrderForm: false,
          link: 0.5337737957836111,
          showCancelConfirm: true,
          instrument: {
            id: 'ESZ4.CME',
            type: 'Future',
            symbol: 'ESZ4',
            currency: 'USD',
            exchange: 'CME',
            tickSize: 0.25,
            precision: 2,
            expiration: '2024-12-20T00:00:00',
            description: 'E-Mini S&P 500 Dec24',
            productCode: 'ES',
            connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
            contractSize: 50,
            instrumentTimePeriod: 'Dec24',
            stringTypeRepresentation: 'Future',
          },
          timeFrame: {
            interval: 5,
            periodicity: '',
          },
          periodToLoad: {
            disabled: false,
            interval: 1,
            periodicity: 'd',
          },
          stockChartXState: {
            chart: {
              theme: {
                name: 'Dark',
                chart: {
                  background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
                  border: {
                    width: 1,
                    strokeColor: 'grey',
                    lineStyle: 'solid',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontFamily: 'Arial',
                      fontSize: 70,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                    details: {
                      fontFamily: 'Arial',
                      fontSize: 40,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                  },
                },
                splitter: {
                  fillColor: '#292C38',
                  hoverFillColor: 'white',
                },
                chartPanel: {
                  grid: {
                    width: 1,
                    strokeColor: '#24262C',
                  },
                  title: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fontStyle: 'normal',
                    fillColor: 'white',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontFamily: 'Arial',
                      fontSize: 70,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                    details: {
                      fontFamily: 'Arial',
                      fontSize: 40,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                  },
                },
                tradingPanel: {
                  tradingBarUnit: 'pixels',
                  tradingBarLength: 40,
                  buyPanelBackground: 'rgba(12,98,247, 0.3)',
                  sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
                  buyMarkerBackground: '#0C62F7',
                  sellMarkerBackground: '#C93B3B',
                  markerFontColor: '#fff',
                  showPLInfo: true,
                  includeRealizedPL: true,
                  displayUnit: 'points',
                  positivePLFontColor: 'white',
                  positivePLBackgroundColor: 'black',
                  negativePLFontColor: 'red',
                  negativePLBackgroundColor: 'black',
                },
                chartMarkers: {
                  showBarMarkerTobBottom: true,
                  showBarMarketExecutionPrice: true,
                  showBarTextWithSizePrice: true,
                  dataBoxBackgroundColor: 'black',
                  buyMarkerColor: 'blue',
                  buyMarkerType: 'triangle',
                  buyMarkerFontWeight: 1,
                  sellMarkerColor: 'red',
                  sellMarkerType: 'square',
                  sellMarkerFontWeight: 1,
                },
                valueScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  text: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'normal',
                    fillColor: '#fff',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  border: {
                    width: 0,
                    strokeColor: 'darkgray',
                    lineStyle: 'solid',
                  },
                  valueMarker: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'black',
                    },
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                dateScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  text: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fillColor: '#fff',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  border: {
                    width: 0,
                    strokeColor: 'darkgray',
                    lineStyle: 'solid',
                  },
                  dateMarker: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: '#FFFFFF',
                    },
                    fill: {
                      fillColor: 'green',
                    },
                    stroke: {
                      strokePriority: 'color',
                      strokeColor: '#696969',
                      width: 1,
                      lineStyle: 'solid',
                      lineJoin: 'miter',
                      lineCap: 'butt',
                    },
                  },
                  breakLine: {
                    stroke: {
                      strokeColor: '#545454',
                      width: 1,
                      lineStyle: 'dash',
                    },
                  },
                },
                crossHair: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: '#000000',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'darkgray',
                    lineStyle: 'dash',
                  },
                  fill: {
                    fillColor: '#FFFFFF',
                  },
                },
                zoomIn: {
                  border: {
                    width: 1,
                    strokeColor: 'white',
                    lineStyle: 'solid',
                  },
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                plot: {
                  point: {
                    dot: {
                      fill: {
                        fillColor: 'white',
                      },
                    },
                  },
                  line: {
                    simple: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    mountain: {
                      line: {
                        width: 1,
                        strokeColor: '#fff',
                      },
                      fill: {
                        linearGradient: [
                          'rgba(63, 167, 225, 0.4) ',
                          'rgba(63, 167, 225, 0)',
                        ],
                      },
                    },
                    step: {
                      width: 1,
                      strokeColor: 'white',
                    },
                  },
                  histogram: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    coloredLine: {
                      upBar: {
                        width: 1,
                        strokeColor: 'grey',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                    column: {
                      line: {
                        strokeEnabled: false,
                        width: 1,
                        strokeColor: 'white',
                      },
                      fill: {
                        fillColor: 'blue',
                      },
                    },
                    coloredColumn: {
                      upBar: {
                        line: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: 'green',
                        },
                      },
                      downBar: {
                        line: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: 'red',
                        },
                      },
                    },
                  },
                  bar: {
                    OHLC: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    HLC: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    HL: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    coloredOHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#0C62F7',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#C93B3B',
                      },
                    },
                    coloredHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    coloredHL: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    candle: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                    },
                    heikinAshi: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                      },
                    },
                    renko: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: '#0C62F7',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    lineBreak: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillEnabled: true,
                          fillColor: '#0C62F7',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillEnabled: true,
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    hollowCandle: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      upHollowCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                      },
                      downHollowCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    pointAndFigure: {
                      upCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    kagi: {
                      upCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    candleVolume: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                    },
                    equiVolume: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#0C62F7',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    equiVolumeShadow: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#0C62F7',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                  },
                  indicator: {
                    line1: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'white',
                    },
                    line2: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#3FA7E1',
                    },
                    line3: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#E85050',
                    },
                    line4: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0000FF',
                    },
                    line5: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'yellow',
                    },
                    histogram: {
                      color: '#32CD32',
                    },
                    blackText: {
                      fontFamily: 'Arial',
                      fontSize: 10,
                      fontStyle: 'normal',
                      fillColor: 'black',
                    },
                    levels: {
                      line1: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FF0000',
                      },
                      line2: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#32CD32',
                      },
                      line3: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFD500',
                      },
                      line4: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0000FF',
                      },
                      line5: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#808080',
                      },
                    },
                  },
                },
                selectionMarker: {
                  line: {
                    strokeColor: 'white',
                    width: 1,
                  },
                  fill: {
                    fillColor: 'black',
                  },
                },
                drawing: {
                  note: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: 'orange',
                    },
                    centerPointFill: {
                      fillColor: 'rgb(68, 68, 68)',
                    },
                  },
                  measure: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                      lineStyle: 'dash',
                    },
                    border: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                      lineStyle: 'dash',
                    },
                    fill: {
                      fillEnabled: true,
                      fillColor: 'rgba(255, 255, 255, 0.5)',
                    },
                    balloon: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                      },
                      border: {
                        width: 1,
                        strokeColor: 'darkgray',
                        strokeEnabled: true,
                        lineStyle: 'solid',
                      },
                      fill: {
                        fillEnabled: true,
                        fillColor: 'rgba(255, 255, 255, 0.5)',
                      },
                    },
                  },
                  measureTool: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  },
                  abstract: {
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                    },
                  },
                  trendAngle: {
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                    },
                    arc: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dot',
                    },
                    horizontalLine: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dot',
                    },
                  },
                  abstractMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                  fibonacci: {
                    trendLine: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dash',
                    },
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                    },
                  },
                  arrowUp: {
                    fill: {
                      fillColor: 'limegreen',
                    },
                  },
                  arrowDown: {
                    fill: {
                      fillColor: 'red',
                    },
                  },
                  text: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: '#FFFFFF',
                      decoration: '',
                    },
                  },
                  image: {
                    noImage: {
                      line: {
                        strokeColor: 'red',
                        width: 1,
                      },
                    },
                  },
                },
                tooltip: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 12,
                    fillColor: 'white',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    decoration: '',
                  },
                  border: {
                    enabled: true,
                    width: 1,
                    color: 'orange',
                    lineStyle: 'solid',
                  },
                  fill: {
                    enabled: true,
                    color: 'rgb(44, 44, 44)',
                  },
                },
                spread: {
                  ask: {
                    line: {
                      width: 1,
                      strokeColor: '#00D533',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#00D533',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                      },
                    },
                  },
                  bid: {
                    line: {
                      width: 1,
                      strokeColor: '#F20500',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#F20500',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                      },
                    },
                  },
                },
                highlightedColumn: {
                  fill: {
                    fillColor: 'rgba(90, 130, 182, 0.45)',
                  },
                },
                button: {
                  accept: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      strokeColor: 'green',
                      width: 1,
                    },
                  },
                  cancel: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      strokeColor: 'red',
                      width: 1,
                    },
                  },
                  none: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                positionSideMarker: {
                  showPLInfo: true,
                  includeRealizedPL: true,
                  displayUnit: 'points',
                  positivePLFontColor: 'white',
                  positivePLBackgroundColor: 'black',
                  negativePLFontColor: 'red',
                  negativePLBackgroundColor: 'black',
                  positionBarLength: 100,
                  positionBarUnit: 'pixels',
                  short: {
                    length: 1,
                    lengthUnit: 'pixels',
                    lineColor: '#FF0000',
                    lineType: 'solid',
                    line: {
                      strokeColor: 'red',
                      width: 1,
                    },
                    kind: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    quantity: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    price: {
                      fill: {
                        fillColor: 'red',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                  },
                  long: {
                    length: 1,
                    lengthUnit: 'pixels',
                    lineColor: '#0C62F7',
                    lineType: 'solid',
                    line: {
                      strokeColor: 'green',
                      width: 1,
                    },
                    kind: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    quantity: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    price: {
                      fill: {
                        fillColor: 'blue',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                  },
                },
                orderSideMarker: {
                  buy: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#33537C',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#33537C',
                    },
                  },
                  sell: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#C93B3B',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#C93B3B',
                    },
                  },
                  ocoStopLimit: '#FFFF00',
                  ocoStopOrder: '#FFFF00',
                  orderBarUnit: 'pixels',
                  orderBarLength: 100,
                },
                orderChartMarker: {
                  showTopBottomMarkers: true,
                  showMarkerAtExecutionPrice: true,
                  showTextWithSizeAndPrice: true,
                  buy: {
                    stroke: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'blue',
                    },
                    markerType: 'triangle',
                    tooltip: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 12,
                        fillColor: 'blue',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        decoration: '',
                      },
                      border: {
                        enabled: false,
                        width: 1,
                        color: 'orange',
                        lineStyle: 'solid',
                      },
                      fill: {
                        enabled: true,
                        color: 'black',
                      },
                    },
                  },
                  sell: {
                    stroke: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'red',
                    },
                    markerType: 'triangle',
                    tooltip: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 12,
                        fillColor: 'red',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        decoration: '',
                      },
                      border: {
                        enabled: false,
                        width: 1,
                        color: 'orange',
                        lineStyle: 'solid',
                      },
                      fill: {
                        enabled: true,
                        color: 'black',
                      },
                    },
                  },
                },
              },
              locale: 'en',
              enableKeyboardEvents: false,
              enableMouseEvents: true,
              showBarInfoInTitle: true,
              priceStyle: 'candle',
              showInstrumentWatermark: false,
              xGridVisible: true,
              yGridVisible: true,
              scrollEnabled: true,
              zoomEnabled: true,
              zoomAutoSave: false,
              scale: 2,
            },
            timeFrame: {
              interval: 5,
              periodicity: '',
            },
            periodToLoad: {
              disabled: false,
              interval: 1,
              periodicity: 'd',
            },
            priceStyle: {
              className: 'candle',
              options: {
                showValueLines: false,
                extendValueLines: false,
              },
            },
            dateScale: {
              allowPartialRecords: true,
              firstVisibleRecord: 416.7772600266179,
              lastVisibleRecord: 431.2276112766179,
              minVisibleRecords: 5,
              textPadding: {
                top: null,
                left: 3,
                right: 3,
                bottom: 3,
              },
              height: 15,
              useManualHeight: false,
              scrollKind: 'autoscaled',
              zoomKind: 'autoscaled',
              zoomMode: 'pin_mouse',
              autoScrollKind: 'visible_bar',
              autoScrollMode: 'new_bar',
              rightAdditionalSpaceRatio: 0.5,
              majorTickMarkLength: 5,
              minorTickMarkLength: 3,
              formatter: {
                className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
                locale: 'en',
                timeInterval: 300000,
              },
              calibrator: {
                className: 'StockChartX.CustomDateScaleCalibrator',
                options: {},
              },
            },
            valueScales: [
              {
                showLeftPanel: false,
                showRightPanel: true,
                width: 100,
                useManualWidth: false,
              },
            ],
            crossHair: {
              crossHairType: 'crossBars',
            },
            chartPanelsContainer: {
              newPanelHeightRatio: 0.2,
              panelPadding: {
                top: 10,
                left: 5,
                right: 5,
                bottom: 10,
              },
              panels: [
                {
                  options: {
                    minHeightRatio: 0.05,
                    maxHeightRatio: 1,
                    heightRatio: 1,
                    showXGrid: true,
                    showYGrid: true,
                    moveDirection: 'any',
                    moveKind: 'normal',
                    state: 0,
                    visible: true,
                    showIndicatorTitles: true,
                    drawValueScale: true,
                  },
                  valueScales: [
                    {
                      options: {
                        minVisibleValue: 6045.137,
                        maxVisibleValue: 6068.863,
                        minAllowedValue: null,
                        maxAllowedValue: null,
                        minAllowedValueRatio: 0.8,
                        maxAllowedValueRatio: 0.8,
                        minValueRangeRatio: 0.1,
                        maxValueRangeRatio: 5,
                        majorTickMarkLength: 3,
                        minorTickMarkLength: 3,
                        padding: {
                          top: 3,
                          left: 6,
                          right: 3,
                          bottom: 3,
                        },
                      },
                      formatter: {
                        className: 'StockChartX.IntlNumberFormat',
                        options: {
                          locale: 'en',
                          numberingSystem: 'latn',
                          style: 'decimal',
                          minimumIntegerDigits: 1,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          useGrouping: 'auto',
                          notation: 'standard',
                          signDisplay: 'auto',
                          roundingIncrement: 1,
                          roundingMode: 'halfExpand',
                          roundingPriority: 'auto',
                          trailingZeroDisplay: 'auto',
                        },
                      },
                      calibrator: {
                        className: 'StockChartX.IntervalValueScaleCalibrator',
                        options: {
                          suffix: null,
                          divider: null,
                        },
                      },
                    },
                  ],
                },
              ],
            },
            instrumentComparisonHandler: {
              instrumentsOptions: [],
            },
            indicators: [
              {
                panelHeightRatio: 1,
                showParamsInTitle: true,
                allowContextMenu: true,
                showValueMarkers: true,
                showValuesInTitle: true,
                allowSettingsDialog: false,
                visible: true,
                showTitle: true,
                showLineLevels: false,
                coloredVolumeTheme: null,
                zIndex: 997,
                parameters: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                panelIndex: 0,
                className: 'Footprint',
                _prevParams: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
              },
              {
                panelHeightRatio: 1,
                showParamsInTitle: true,
                allowContextMenu: true,
                showValueMarkers: true,
                showValuesInTitle: true,
                allowSettingsDialog: false,
                visible: true,
                showTitle: true,
                showLineLevels: false,
                coloredVolumeTheme: null,
                zIndex: 998,
                parameters: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                panelIndex: 0,
                className: 'PriceStats',
                _prevParams: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
              },
              {
                panelHeightRatio: 1,
                showParamsInTitle: true,
                allowContextMenu: true,
                showValueMarkers: true,
                showValuesInTitle: true,
                allowSettingsDialog: false,
                visible: true,
                showTitle: true,
                showLineLevels: false,
                coloredVolumeTheme: null,
                zIndex: 999,
                parameters: {
                  Periods: 0,
                  'Line Color': 'white',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                panelIndex: 0,
                className: 'VWAP',
                _prevParams: {
                  Periods: 0,
                  'Line Color': 'white',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
              },
              {
                panelHeightRatio: 1,
                showParamsInTitle: true,
                allowContextMenu: true,
                showValueMarkers: true,
                showValuesInTitle: true,
                allowSettingsDialog: false,
                visible: true,
                showTitle: true,
                showLineLevels: false,
                coloredVolumeTheme: null,
                zIndex: 1000,
                parameters: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                panelIndex: 0,
                className: 'SessionStats',
                _prevParams: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
              },
            ],
            barsCount: false,
            plots: {
              Footprint: {
                main: {
                  fillColor: '#3131316d',
                  strokeColor: '#85857A00',
                  barStrokeColor: '#85857A00  ',
                  enableOpenClose: true,
                  closeOpenColor: '#1ADE5D',
                  openCloseColor: '#FF2E00',
                  mode: 'volume',
                  customTickSize: {
                    enabled: false,
                    value: 1,
                  },
                },
                font: {
                  fontFamily: 'Open Sans',
                  fontSize: 11,
                  fillColor: '#666666',
                  fontWeight: '400',
                },
                intraBar: {
                  barStyle: 'volumeProfileBars',
                  useDeltaShadingColors: true,
                  sellShadeBarColor: '#E95050',
                  buyShadeBarColor: '#0C62F7',
                  barColor: '#A9A9A9',
                  barPocColor: '#FFA500',
                  barAlignment: 'left',
                  sellVolumeBarColor: 'silver',
                  sellPocBarColor: 'gold',
                  buyVolumeBarColor: 'silver',
                  buyPocBarColor: 'gold',
                  lastPriceOutlineColor: '#e8e8e8',
                },
                text: {
                  textStyle: 'bidAsk',
                  margin: 4,
                  hideSides: false,
                  separator: 'x',
                  initialBarWidth: 80,
                  barWidthTextThreshold: 60,
                  barHeightTextThreshold: 5,
                },
                profile: {
                  profileFilter: 'autoMaxProfile',
                  filterValue: 4000,
                  autoProfilingRange: 'visible',
                  showPocOnlyOnBiggestBar: false,
                  strokeWidth: 1,
                },
                volumeFilter: {
                  enabled: false,
                  mode: 'greaterThan',
                  value: 100,
                },
                deltaImbalance: {
                  enabled: true,
                  threshold: 3.5,
                  strongBidVolumeColor: '#FF6347',
                  weakBidVolumeColor: '#0C62F7',
                  strongAskVolumeColor: '#00BD62',
                  weakAskVolumeColor: '#0C62F7',
                  enableWeakBidVolume: false,
                  enableWeakAskVolume: false,
                },
                pullback: {
                  enabled: false,
                  fillColor: '#3F3F3F',
                  strokeColor: 'transparent',
                  textColor: '#E5E5E5',
                  currentBidAskColor: '#8B6D00',
                },
              },
              PriceStats: {
                general: {
                  type: 'volume',
                  period: {
                    type: 'every',
                    value: 1,
                    unit: 'day',
                    date: null,
                  },
                  hide: {
                    enabled: false,
                    value: 'lastProfile',
                  },
                  vaCorrelation: 0.7,
                  smoothed: {
                    enabled: false,
                    value: 9,
                  },
                  customTickSize: {
                    enabled: true,
                    value: 1,
                  },
                  calculateXProfiles: 999,
                  align: 'right',
                  width: {
                    value: 70,
                    unit: 'pixel',
                  },
                  margin: {
                    value: 4,
                    unit: 'pixel',
                  },
                  sessions: {
                    current: {
                      enabled: true,
                      width: {
                        value: 125,
                        unit: 'pixel',
                      },
                    },
                    prev: {
                      enabled: true,
                      width: {
                        value: 125,
                        unit: 'pixel',
                      },
                    },
                    days: {
                      enabled: false,
                      count: 10,
                      includeCurrentSession: false,
                      width: {
                        value: 100,
                        unit: 'pixel',
                      },
                    },
                  },
                  transparency: 35,
                },
                graphics: {
                  summaryEnabled: true,
                  showPrices: true,
                  summaryFont: {
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fillColor: '#0C62F7',
                    fontWeight: '400',
                  },
                },
                session: {
                  id: 3,
                  name: 'US ETH+RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  isDefault: true,
                  workingTimes: [
                    {
                      endDay: 1,
                      endTime: 61200000,
                      startDay: 0,
                      startTime: 64800000,
                    },
                    {
                      endDay: 2,
                      endTime: 61200000,
                      startDay: 1,
                      startTime: 64800000,
                    },
                    {
                      endDay: 3,
                      endTime: 61200000,
                      startDay: 2,
                      startTime: 64800000,
                    },
                    {
                      endDay: 4,
                      endTime: 61200000,
                      startDay: 3,
                      startTime: 64800000,
                    },
                    {
                      endDay: 5,
                      endTime: 61200000,
                      startDay: 4,
                      startTime: 64800000,
                    },
                  ],
                  connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                },
                profile: {
                  type: 'solidgram',
                  color: {
                    type: 'heatMap',
                    value: '#0C62F7',
                  },
                  widthCorrelation: 0.5,
                  extendNakedPocs: true,
                  extendNaked: {
                    enabled: false,
                    type: 'closesHighsLows',
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#0C62F7',
                      lineStyle: 'solid',
                    },
                  },
                  vaInsideOpacity: 0.5,
                  vaOutsideOpacity: 0.5,
                  profileColor: '',
                },
                lines: {
                  current: {
                    poc: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#FE00E5',
                        lineStyle: 'solid',
                      },
                      labelEnabled: false,
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#A1A2A5',
                        lineStyle: 'solid',
                      },
                      labelEnabled: false,
                    },
                  },
                  prev: {
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#A1A2A5',
                        lineStyle: 'solid',
                      },
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#A1A2A5',
                        lineStyle: 'solid',
                      },
                    },
                  },
                  dev: {
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#FE00E5',
                        lineStyle: 'solid',
                      },
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#A1A2A5',
                        lineStyle: 'solid',
                      },
                    },
                  },
                },
                eth: {
                  session: null,
                  profile: {
                    type: 'solidgram',
                    color: {
                      type: 'profileColor',
                      value: '#0C62F7',
                    },
                    widthCorrelation: 0.5,
                    extendNakedPocs: true,
                    extendNaked: {
                      enabled: false,
                      type: 'closesHighsLows',
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#0C62F7',
                        lineStyle: 'solid',
                      },
                    },
                    vaInsideOpacity: 0.5,
                    vaOutsideOpacity: 0.5,
                    profileColor: '',
                  },
                  lines: {
                    current: {
                      poc: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                        labelEnabled: false,
                      },
                      va: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                        labelEnabled: false,
                      },
                    },
                    prev: {
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                    },
                    dev: {
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                      },
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                      },
                    },
                  },
                },
                splitProfile: false,
                overlayEthOverRth: false,
                highlight: {
                  poc: {
                    enabled: true,
                    value: '#FE00E5',
                  },
                  va: {
                    enabled: false,
                    value: '#A1A2A5',
                  },
                },
              },
              VWAP: {
                bands: {
                  band1: {
                    isShow: false,
                    styleLine: {
                      width: 1,
                      lineStyle: 'solid',
                    },
                    coefficient: 1,
                    strokeColor: '#FFFF00',
                  },
                  band2: {
                    isShow: false,
                    styleLine: {
                      width: 1,
                      lineStyle: 'solid',
                    },
                    coefficient: 2,
                    strokeColor: '#FFFF00',
                  },
                  band3: {
                    isShow: false,
                    styleLine: {
                      width: 1,
                      lineStyle: 'solid',
                    },
                    coefficient: 3,
                    strokeColor: '#FFFF00',
                  },
                },
                label: {
                  width: {
                    unit: 'pixel',
                    value: 400,
                  },
                  margin: {
                    unit: 'pixel',
                    value: 0,
                  },
                  fontSize: 10,
                  fillColor: 'rgb(0,0,0,1)',
                  lineStyle: {
                    width: 1,
                    lineStyle: 'solid',
                  },
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                  isShowVWAPLabel: false,
                },
                general: {
                  styleLine: {
                    style: 'hollow',
                    lineStyle: {
                      width: 1,
                      lineStyle: 'solid',
                    },
                    strokeColor: '#00E5DD',
                  },
                  customTimes: {
                    id: 3,
                    name: 'US ETH+RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    workingTimes: [
                      {
                        startDay: 0,
                        startTime: 64800000,
                        endDay: 1,
                        endTime: 61200000,
                      },
                      {
                        startDay: 1,
                        startTime: 64800000,
                        endDay: 2,
                        endTime: 61200000,
                      },
                      {
                        startDay: 2,
                        startTime: 64800000,
                        endDay: 3,
                        endTime: 61200000,
                      },
                      {
                        startDay: 3,
                        startTime: 64800000,
                        endDay: 4,
                        endTime: 61200000,
                      },
                      {
                        startDay: 4,
                        startTime: 64800000,
                        endDay: 5,
                        endTime: 61200000,
                      },
                    ],
                    connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                  },
                  customDuration: 'day',
                  isShowCustomDuration: false,
                },
              },
              SessionStats: {
                general: {
                  style: 'line',
                  width: {
                    value: 200,
                    unit: 'pixel',
                  },
                  margin: {
                    value: 5,
                    unit: 'pixel',
                  },
                },
                font: {
                  fontFamily: 'Open Sans',
                  fontSize: 10,
                  fillColor: '#000000',
                  fontWeight: '400',
                },
                workingTimes: {
                  rth: {
                    id: 1,
                    name: 'US RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
                    workingTimes: [
                      {
                        endDay: 1,
                        endTime: 61200000,
                        startDay: 1,
                        startTime: 34200000,
                      },
                      {
                        endDay: 2,
                        endTime: 61200000,
                        startDay: 2,
                        startTime: 34200000,
                      },
                      {
                        endDay: 3,
                        endTime: 61200000,
                        startDay: 3,
                        startTime: 34200000,
                      },
                      {
                        endDay: 4,
                        endTime: 61200000,
                        startDay: 4,
                        startTime: 34200000,
                      },
                      {
                        endDay: 5,
                        endTime: 61200000,
                        startDay: 5,
                        startTime: 34200000,
                      },
                    ],
                  },
                  eth: {
                    id: 2,
                    name: 'US ETH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                    workingTimes: [
                      {
                        endDay: 1,
                        endTime: 34200000,
                        startDay: 0,
                        startTime: 64800000,
                      },
                      {
                        endDay: 2,
                        endTime: 34200000,
                        startDay: 1,
                        startTime: 64800000,
                      },
                      {
                        endDay: 3,
                        endTime: 34200000,
                        startDay: 2,
                        startTime: 64800000,
                      },
                      {
                        endDay: 4,
                        endTime: 34200000,
                        startDay: 3,
                        startTime: 64800000,
                      },
                      {
                        endDay: 5,
                        endTime: 34200000,
                        startDay: 4,
                        startTime: 64800000,
                      },
                    ],
                  },
                },
                lines: {
                  rthHigh: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#006534',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'RTH High',
                  },
                  rthLow: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#BC0606',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'RTH Low',
                  },
                  ethHigh: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#006534',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'ETH High',
                  },
                  ethLow: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#BC0606',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'ETH Low',
                  },
                  ibHigh: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#FFFF00',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'IB High',
                  },
                  ibLow: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#FFFF00',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'IB Low',
                  },
                  prevRthHigh: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#00BD62',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'Prev RTH High',
                  },
                  prevRthLow: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#FF6C1C',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'Prev RTH Low',
                  },
                  rthMid: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#1E4EF6',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'RTH Mid',
                  },
                  ethMid: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#005B9C',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'ETH Mid',
                  },
                  rthSettle: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#8B6D00',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'RTH Settle',
                  },
                  rthOpen: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#007A76',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'RTH Open',
                  },
                  prevWkHigh: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#FFFF00',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'Prev WK High',
                  },
                  prevWkLow: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#FFFF00',
                      lineStyle: 'solid',
                    },
                    devEnabled: false,
                    labelEnabled: true,
                    label: 'Prev WK Low',
                  },
                },
                ethSessionId: 2,
                rthSessionId: 1,
              },
            },
            drawings: [
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'html',
                  text: '<div>Buy 15 <br> @5809.35</div>',
                  theme: {
                    fill: {
                      color: 'black',
                      enabled: true,
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'blue',
                      fontStyle: 'normal',
                      decoration: '',
                      fontFamily: 'Calibri',
                      fontWeight: 'normal',
                    },
                    border: {
                      color: 'orange',
                      width: 1,
                      enabled: false,
                      lineStyle: 'solid',
                    },
                  },
                  position: 'bottom',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [
                    {
                      date: '2024-10-28T16:05:27.627Z',
                      value: 5871.718139773895,
                    },
                  ],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'horizontalLine',
              },
              {
                options: {
                  locked: false,
                  points: [
                    {
                      date: '2024-10-28T15:57:08.149Z',
                      value: 5872.248715313463,
                    },
                    {
                      date: '2024-10-28T16:13:42.872Z',
                      value: 5870.899537512847,
                    },
                  ],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'rectangle',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'html',
                  text: '<div>Buy 20 <br> @5774.38</div>',
                  theme: {
                    fill: {
                      color: 'black',
                      enabled: true,
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'blue',
                      fontStyle: 'normal',
                      decoration: '',
                      fontFamily: 'Calibri',
                      fontWeight: 'normal',
                    },
                    border: {
                      color: 'orange',
                      width: 1,
                      enabled: false,
                      lineStyle: 'solid',
                    },
                  },
                  position: 'bottom',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'html',
                  text: '<div>Sell 20 <br> @5776.13</div>',
                  theme: {
                    fill: {
                      color: 'black',
                      enabled: true,
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'red',
                      fontStyle: 'normal',
                      decoration: '',
                      fontFamily: 'Calibri',
                      fontWeight: 'normal',
                    },
                    border: {
                      color: 'orange',
                      width: 1,
                      enabled: false,
                      lineStyle: 'solid',
                    },
                  },
                  position: 'top',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'html',
                  text: '<div>Sell 20 <br> @5911.75</div>',
                  theme: {
                    fill: {
                      color: 'black',
                      enabled: true,
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'red',
                      fontStyle: 'normal',
                      decoration: '',
                      fontFamily: 'Calibri',
                      fontWeight: 'normal',
                    },
                    border: {
                      color: 'orange',
                      width: 1,
                      enabled: false,
                      lineStyle: 'solid',
                    },
                  },
                  position: 'top',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
            ],
          },
          intervalOptions: [
            {
              active: false,
              period: 'RevBars',
              timeFrames: [
                {
                  interval: 4,
                  periodicity: 'revs',
                },
                {
                  interval: 8,
                  periodicity: 'revs',
                },
                {
                  interval: 12,
                  periodicity: 'revs',
                },
                {
                  interval: 16,
                  periodicity: 'revs',
                },
              ],
              periodicities: ['revs'],
            },
            {
              active: false,
              period: 'Seconds',
              timeFrames: [
                {
                  interval: 30,
                  periodicity: 's',
                },
                {
                  interval: 40,
                  periodicity: 's',
                },
              ],
              periodicities: ['s'],
            },
            {
              active: false,
              period: 'Minutes',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: '',
                },
                {
                  interval: 3,
                  periodicity: '',
                },
                {
                  interval: 5,
                  periodicity: '',
                },
                {
                  interval: 15,
                  periodicity: '',
                },
                {
                  interval: 30,
                  periodicity: '',
                },
              ],
              periodicities: [''],
            },
            {
              active: false,
              period: 'Hours',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'h',
                },
                {
                  interval: 2,
                  periodicity: 'h',
                },
                {
                  interval: 3,
                  periodicity: 'h',
                },
                {
                  interval: 4,
                  periodicity: 'h',
                },
              ],
              periodicities: ['h'],
            },
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 1,
                  periodicity: 'm',
                },
              ],
              periodicities: ['d', 'm', 'w', 'y'],
            },
          ],
          periodOptions: [
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 5,
                  periodicity: 'd',
                },
              ],
              periodicity: 'd',
            },
            {
              active: false,
              period: 'Weeks',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 2,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'w',
                },
              ],
              periodicity: 'w',
            },
            {
              active: false,
              period: 'Months',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 6,
                  periodicity: 'm',
                },
              ],
              periodicity: 'm',
            },
          ],
          componentInstanceId: 1698770407970,
          settings: {
            general: {
              font: {
                fontSize: 10,
                textColor: '#fff',
                fontFamily: 'Arial',
              },
              gradient1: 'rgb(27, 29, 34)',
              gradient2: 'rgb(27, 29, 34)',
              gridColor: '#24262C',
              lineColor: '#fff',
              wickColor: '#fff',
              upCandleColor: '#0C62F7',
              dateScaleColor: 'rgb(27, 29, 34)',
              downCandleColor: '#C93B3B',
              valueScaleColor: 'rgb(27, 29, 34)',
              upCandleBorderColor: '#D0D0D2',
              downCandleBorderColor: '#D0D0D2',
              upCandleBorderColorEnabled: false,
              downCandleBorderColorEnabled: false,
            },
            session: {
              sessionEnabled: true,
              sessionTemplate: {
                id: 3,
                name: 'US ETH+RTH',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                workingTimes: [
                  {
                    endDay: 1,
                    endTime: 61200000,
                    startDay: 0,
                    startTime: 64800000,
                  },
                  {
                    endDay: 2,
                    endTime: 61200000,
                    startDay: 1,
                    startTime: 64800000,
                  },
                  {
                    endDay: 3,
                    endTime: 61200000,
                    startDay: 2,
                    startTime: 64800000,
                  },
                  {
                    endDay: 4,
                    endTime: 61200000,
                    startDay: 3,
                    startTime: 64800000,
                  },
                  {
                    endDay: 5,
                    endTime: 61200000,
                    startDay: 4,
                    startTime: 64800000,
                  },
                ],
              },
            },
            trading: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              trading: {
                showPLInfo: true,
                displayUnit: 'points',
                orderBarUnit: 'pixels',
                showOHLVInfo: false,
                bracketButton: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: true,
                tradingBarLength: 40,
                includeRealizedPL: true,
                showCancelConfirm: true,
                showWorkingOrders: true,
                negativePLFontColor: 'red',
                positivePLFontColor: 'white',
                showInstrumentChange: false,
                negativePLBackgroundColor: 'black',
                positivePLBackgroundColor: 'black',
              },
              orderArea: {
                formData: {
                  quantity: 1,
                  stopLoss: {
                    stopLoss: false,
                    type: 'StopMarket',
                  },
                  takeProfit: {
                    takeProfit: false,
                  },
                },
                amountButtons: [
                  {
                    value: 1,
                  },
                  {
                    black: true,
                    value: 1,
                  },
                  {
                    value: 3,
                  },
                  {
                    value: 5,
                  },
                  {
                    value: 10,
                  },
                  {
                    value: 25,
                  },
                ],
                settings: {
                  buyButtonsBackgroundColor: '#0C62F7',
                  flatButtonsBackgroundColor: '#51535A',
                  buyButtonsFontColor: '#D0D0D2',
                  flatButtonsFontColor: '#D0D0D2',
                  sellButtonsBackgroundColor: '#C93B3B',
                  cancelButtonBackgroundColor: '#51535A',
                  sellButtonsFontColor: '#D0D0D2',
                  cancelButtonFontColor: '#D0D0D2',
                  closePositionFontColor: '#D0D0D2',
                  closePositionBackgroundColor: '#51535A',
                  icebergBackgroundColor: '#51535A',
                  icebergFontColor: '#fff',
                  formSettings: {
                    showIcebergButton: true,
                    showFlattenButton: true,
                    closePositionButton: true,
                    showLiquidateButton: true,
                    showCancelButton: true,
                    showBuyButton: true,
                    showSellButton: true,
                    showBracket: true,
                    showPLInfo: true,
                  },
                  tif: {
                    DAY: true,
                    GTC: true,
                    default: 'DAY',
                  },
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  orderArea: {
                    showPLInfo: true,
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                },
              },
              chartMarkers: {
                buyMarkerType: 'triangle',
                buyMarkerColor: 'blue',
                sellMarkerType: 'triangle',
                sellMarkerColor: 'red',
                buyMarkerFontWeight: 12,
                sellMarkerFontWeight: 12,
                dataBoxBackgroundColor: 'black',
                showBarMarkerTobBottom: true,
                showBarTextWithSizePrice: true,
                showBarMarketExecutionPrice: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
                orderBarUnit: 'pixels',
                orderBarLength: 100,
              },
            },
            valueScale: {
              valueScale: {
                isAutomatic: 'pixels-price',
                pixelsPrice: 10,
              },
            },
          },
          preferredAccounts: {},
        },
        name: 'chart',
      },
      order: 2,
    },
    {
      id: '1733073344604_0.036506420016581886',
      maximizable: true,
      minimizable: true,
      resizable: true,
      minWidth: 350,
      minHeight: 150,
      allowPopup: true,
      visible: true,
      x: 0,
      y: 36,
      width: 545.0986939370632,
      height: 367.8527526855469,
      component: {
        state: {
          isToolbarVisible: false,
          showOHLV: false,
          showChanges: false,
          enableOrderForm: false,
          link: 0.36923744970654826,
          showCancelConfirm: true,
          instrument: {
            currency: 'USD',
            stringTypeRepresentation: 'Future',
            productCode: 'NQ',
            expiration: '2024-12-20T00:00:00',
            type: 'Future',
            instrumentTimePeriod: 'Dec24',
            tickSize: 0.25,
            connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
            id: 'NQZ4.CME',
            symbol: 'NQZ4',
            description: 'E-Mini Nasdaq-100 Dec24',
            exchange: 'CME',
            contractSize: 20,
            precision: 2,
          },
          timeFrame: {
            interval: 5,
            periodicity: '',
          },
          periodToLoad: {
            interval: 3,
            periodicity: 'd',
          },
          stockChartXState: {
            chart: {
              theme: {
                name: 'Dark',
                chart: {
                  background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
                  border: {
                    width: 1,
                    strokeColor: 'grey',
                    lineStyle: 'solid',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontFamily: 'Arial',
                      fontSize: 70,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                    details: {
                      fontFamily: 'Arial',
                      fontSize: 40,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                  },
                },
                splitter: {
                  fillColor: '#292C38',
                  hoverFillColor: 'white',
                },
                chartPanel: {
                  grid: {
                    width: 1,
                    strokeColor: '#24262C',
                  },
                  title: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fontStyle: 'normal',
                    fillColor: 'white',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontFamily: 'Arial',
                      fontSize: 70,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                    details: {
                      fontFamily: 'Arial',
                      fontSize: 40,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                  },
                },
                tradingPanel: {
                  tradingBarUnit: 'pixels',
                  tradingBarLength: 40,
                  buyPanelBackground: 'rgba(12,98,247, 0.3)',
                  sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
                  buyMarkerBackground: '#0C62F7',
                  sellMarkerBackground: '#C93B3B',
                  markerFontColor: '#fff',
                  showPLInfo: true,
                  includeRealizedPL: true,
                  displayUnit: 'points',
                  positivePLFontColor: 'white',
                  positivePLBackgroundColor: 'black',
                  negativePLFontColor: 'red',
                  negativePLBackgroundColor: 'black',
                },
                chartMarkers: {
                  showBarMarkerTobBottom: true,
                  showBarMarketExecutionPrice: true,
                  showBarTextWithSizePrice: true,
                  dataBoxBackgroundColor: 'black',
                  buyMarkerColor: 'blue',
                  buyMarkerType: 'triangle',
                  buyMarkerFontWeight: 1,
                  sellMarkerColor: 'red',
                  sellMarkerType: 'square',
                  sellMarkerFontWeight: 1,
                },
                valueScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  text: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'normal',
                    fillColor: '#fff',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  border: {
                    width: 0,
                    strokeColor: 'darkgray',
                    lineStyle: 'solid',
                  },
                  valueMarker: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: '#0C62F7',
                    },
                  },
                },
                dateScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  text: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fillColor: '#fff',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  border: {
                    width: 0,
                    strokeColor: 'darkgray',
                    lineStyle: 'solid',
                  },
                  dateMarker: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: '#FFFFFF',
                    },
                    fill: {
                      fillColor: 'green',
                    },
                    stroke: {
                      strokePriority: 'color',
                      strokeColor: '#696969',
                      width: 1,
                      lineStyle: 'solid',
                      lineJoin: 'miter',
                      lineCap: 'butt',
                    },
                  },
                  breakLine: {
                    stroke: {
                      strokeColor: '#545454',
                      width: 1,
                      lineStyle: 'dash',
                    },
                  },
                },
                crossHair: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: '#000000',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'darkgray',
                    lineStyle: 'dash',
                  },
                  fill: {
                    fillColor: '#FFFFFF',
                  },
                },
                zoomIn: {
                  border: {
                    width: 1,
                    strokeColor: 'white',
                    lineStyle: 'solid',
                  },
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                plot: {
                  point: {
                    dot: {
                      fill: {
                        fillColor: 'white',
                      },
                    },
                  },
                  line: {
                    simple: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    mountain: {
                      line: {
                        width: 1,
                        strokeColor: '#fff',
                      },
                      fill: {
                        linearGradient: [
                          'rgba(63, 167, 225, 0.4) ',
                          'rgba(63, 167, 225, 0)',
                        ],
                      },
                    },
                    step: {
                      width: 1,
                      strokeColor: 'white',
                    },
                  },
                  histogram: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    coloredLine: {
                      upBar: {
                        width: 1,
                        strokeColor: 'grey',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                    column: {
                      line: {
                        strokeEnabled: false,
                        width: 1,
                        strokeColor: 'white',
                      },
                      fill: {
                        fillColor: 'blue',
                      },
                    },
                    coloredColumn: {
                      upBar: {
                        line: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: 'green',
                        },
                      },
                      downBar: {
                        line: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: 'red',
                        },
                      },
                    },
                  },
                  bar: {
                    OHLC: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    HLC: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    HL: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    coloredOHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#FFE8E8',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#C93B3B',
                      },
                    },
                    coloredHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    coloredHL: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    candle: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                    },
                    heikinAshi: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                      },
                    },
                    renko: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    lineBreak: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillEnabled: true,
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillEnabled: true,
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    hollowCandle: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      upHollowCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downHollowCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    pointAndFigure: {
                      upCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    kagi: {
                      upCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    candleVolume: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                    },
                    equiVolume: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    equiVolumeShadow: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                  },
                  indicator: {
                    line1: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'white',
                    },
                    line2: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#3FA7E1',
                    },
                    line3: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#E85050',
                    },
                    line4: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0000FF',
                    },
                    line5: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'yellow',
                    },
                    histogram: {
                      color: '#32CD32',
                    },
                    blackText: {
                      fontFamily: 'Arial',
                      fontSize: 10,
                      fontStyle: 'normal',
                      fillColor: 'black',
                    },
                    levels: {
                      line1: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FF0000',
                      },
                      line2: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#32CD32',
                      },
                      line3: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFD500',
                      },
                      line4: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0000FF',
                      },
                      line5: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#808080',
                      },
                    },
                  },
                },
                selectionMarker: {
                  line: {
                    strokeColor: 'white',
                    width: 1,
                  },
                  fill: {
                    fillColor: 'black',
                  },
                },
                drawing: {
                  note: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: 'orange',
                    },
                    centerPointFill: {
                      fillColor: 'rgb(68, 68, 68)',
                    },
                  },
                  measure: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                      lineStyle: 'dash',
                    },
                    border: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                      lineStyle: 'dash',
                    },
                    fill: {
                      fillEnabled: true,
                      fillColor: 'rgba(255, 255, 255, 0.5)',
                    },
                    balloon: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                      },
                      border: {
                        width: 1,
                        strokeColor: 'darkgray',
                        strokeEnabled: true,
                        lineStyle: 'solid',
                      },
                      fill: {
                        fillEnabled: true,
                        fillColor: 'rgba(255, 255, 255, 0.5)',
                      },
                    },
                  },
                  measureTool: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  },
                  abstract: {
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                    },
                  },
                  trendAngle: {
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                    },
                    arc: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dot',
                    },
                    horizontalLine: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dot',
                    },
                  },
                  abstractMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                  fibonacci: {
                    trendLine: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dash',
                    },
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                    },
                  },
                  arrowUp: {
                    fill: {
                      fillColor: 'limegreen',
                    },
                  },
                  arrowDown: {
                    fill: {
                      fillColor: 'red',
                    },
                  },
                  text: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: '#FFFFFF',
                      decoration: '',
                    },
                  },
                  image: {
                    noImage: {
                      line: {
                        strokeColor: 'red',
                        width: 1,
                      },
                    },
                  },
                },
                tooltip: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 12,
                    fillColor: 'white',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    decoration: '',
                  },
                  border: {
                    enabled: true,
                    width: 1,
                    color: 'orange',
                    lineStyle: 'solid',
                  },
                  fill: {
                    enabled: true,
                    color: 'rgb(44, 44, 44)',
                  },
                },
                spread: {
                  ask: {
                    line: {
                      width: 1,
                      strokeColor: '#00D533',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#00D533',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                      },
                    },
                  },
                  bid: {
                    line: {
                      width: 1,
                      strokeColor: '#F20500',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#F20500',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                      },
                    },
                  },
                },
                highlightedColumn: {
                  fill: {
                    fillColor: 'rgba(90, 130, 182, 0.45)',
                  },
                },
                button: {
                  accept: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      strokeColor: 'green',
                      width: 1,
                    },
                  },
                  cancel: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      strokeColor: 'red',
                      width: 1,
                    },
                  },
                  none: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                positionSideMarker: {
                  showPLInfo: true,
                  includeRealizedPL: true,
                  displayUnit: 'points',
                  positivePLFontColor: 'white',
                  positivePLBackgroundColor: 'black',
                  negativePLFontColor: 'red',
                  negativePLBackgroundColor: 'black',
                  positionBarLength: 100,
                  positionBarUnit: 'pixels',
                  short: {
                    length: 1,
                    lengthUnit: 'pixels',
                    lineColor: '#FF0000',
                    lineType: 'solid',
                    line: {
                      strokeColor: 'red',
                      width: 1,
                    },
                    kind: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    quantity: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    price: {
                      fill: {
                        fillColor: 'red',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                  },
                  long: {
                    length: 1,
                    lengthUnit: 'pixels',
                    lineColor: '#0C62F7',
                    lineType: 'solid',
                    line: {
                      strokeColor: 'green',
                      width: 1,
                    },
                    kind: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    quantity: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    price: {
                      fill: {
                        fillColor: 'blue',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                  },
                },
                orderSideMarker: {
                  buy: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#33537C',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#33537C',
                    },
                  },
                  sell: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#C93B3B',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#C93B3B',
                    },
                  },
                  ocoStopLimit: '#FFFF00',
                  ocoStopOrder: '#FFFF00',
                  orderBarUnit: 'pixels',
                  orderBarLength: 100,
                },
                orderChartMarker: {
                  showTopBottomMarkers: true,
                  showMarkerAtExecutionPrice: true,
                  showTextWithSizeAndPrice: true,
                  buy: {
                    stroke: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'blue',
                    },
                    markerType: 'triangle',
                    tooltip: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 12,
                        fillColor: 'blue',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        decoration: '',
                      },
                      border: {
                        enabled: false,
                        width: 1,
                        color: 'orange',
                        lineStyle: 'solid',
                      },
                      fill: {
                        enabled: true,
                        color: 'black',
                      },
                    },
                  },
                  sell: {
                    stroke: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'red',
                    },
                    markerType: 'square',
                    tooltip: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 12,
                        fillColor: 'red',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        decoration: '',
                      },
                      border: {
                        enabled: false,
                        width: 1,
                        color: 'orange',
                        lineStyle: 'solid',
                      },
                      fill: {
                        enabled: true,
                        color: 'black',
                      },
                    },
                  },
                },
              },
              locale: 'en',
              enableKeyboardEvents: false,
              enableMouseEvents: true,
              showBarInfoInTitle: true,
              priceStyle: 'candle',
              showInstrumentWatermark: false,
              xGridVisible: true,
              yGridVisible: true,
              scrollEnabled: true,
              zoomEnabled: true,
              zoomAutoSave: false,
              scale: 2,
            },
            timeFrame: {
              interval: 5,
              periodicity: '',
            },
            periodToLoad: {
              interval: 3,
              periodicity: 'd',
            },
            priceStyle: {
              className: 'candle',
              options: {},
            },
            dateScale: {
              allowPartialRecords: true,
              firstVisibleRecord: 328,
              lastVisibleRecord: 428,
              minVisibleRecords: 5,
              textPadding: {
                top: null,
                left: 3,
                right: 3,
                bottom: 3,
              },
              height: 15,
              useManualHeight: false,
              scrollKind: 'autoscaled',
              zoomKind: 'autoscaled',
              zoomMode: 'pin_mouse',
              autoScrollKind: 'visible_bar',
              autoScrollMode: 'new_bar',
              rightAdditionalSpaceRatio: 0.5,
              majorTickMarkLength: 5,
              minorTickMarkLength: 3,
              formatter: {
                className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
                locale: 'en',
                timeInterval: 300000,
              },
              calibrator: {
                className: 'StockChartX.CustomDateScaleCalibrator',
                options: {},
              },
            },
            valueScales: [
              {
                showLeftPanel: false,
                showRightPanel: true,
                width: 100,
                useManualWidth: false,
              },
            ],
            crossHair: {
              crossHairType: 'crossBars',
            },
            chartPanelsContainer: {
              newPanelHeightRatio: 0.2,
              panelPadding: {
                top: 10,
                left: 5,
                right: 5,
                bottom: 10,
              },
              panels: [
                {
                  options: {
                    minHeightRatio: 0.05,
                    maxHeightRatio: 1,
                    heightRatio: 1,
                    showXGrid: true,
                    showYGrid: true,
                    moveDirection: 'any',
                    moveKind: 'normal',
                    state: 0,
                    visible: true,
                    showIndicatorTitles: true,
                    drawValueScale: true,
                  },
                  valueScales: [
                    {
                      options: {
                        minVisibleValue: 20939.170846394984,
                        maxVisibleValue: 21203.670846394984,
                        minAllowedValue: null,
                        maxAllowedValue: null,
                        minAllowedValueRatio: 0.8,
                        maxAllowedValueRatio: 0.8,
                        minValueRangeRatio: 0.1,
                        maxValueRangeRatio: 5,
                        majorTickMarkLength: 3,
                        minorTickMarkLength: 3,
                        padding: {
                          top: 3,
                          left: 6,
                          right: 3,
                          bottom: 3,
                        },
                      },
                      formatter: {
                        className: 'StockChartX.IntlNumberFormat',
                        options: {
                          locale: 'en',
                          numberingSystem: 'latn',
                          style: 'decimal',
                          minimumIntegerDigits: 1,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          useGrouping: 'auto',
                          notation: 'standard',
                          signDisplay: 'auto',
                          roundingIncrement: 1,
                          roundingMode: 'halfExpand',
                          roundingPriority: 'auto',
                          trailingZeroDisplay: 'auto',
                        },
                      },
                      calibrator: {
                        className: 'StockChartX.IntervalValueScaleCalibrator',
                        options: {
                          suffix: null,
                          divider: null,
                        },
                      },
                    },
                  ],
                },
              ],
            },
            instrumentComparisonHandler: {
              instrumentsOptions: [],
            },
            indicators: [
              {
                panelHeightRatio: 1,
                showParamsInTitle: true,
                allowContextMenu: true,
                showValueMarkers: true,
                showValuesInTitle: true,
                allowSettingsDialog: false,
                visible: true,
                showTitle: true,
                showLineLevels: false,
                coloredVolumeTheme: null,
                zIndex: 1000,
                parameters: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                panelIndex: 0,
                className: 'VolumeProfile',
                _prevParams: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
              },
            ],
            barsCount: false,
            plots: {
              VolumeProfile: {
                general: {
                  type: 'volume',
                  period: {
                    type: 'every',
                    value: 1,
                    unit: 'day',
                    date: null,
                  },
                  hide: {
                    enabled: false,
                    value: 'lastProfile',
                  },
                  vaCorrelation: 0.7,
                  smoothed: {
                    enabled: false,
                    value: 9,
                  },
                  customTickSize: {
                    enabled: false,
                    value: 5,
                  },
                  calculateXProfiles: 999,
                  align: 'right',
                  width: {
                    value: 70,
                    unit: 'pixel',
                  },
                  margin: {
                    value: 4,
                    unit: 'pixel',
                  },
                  sessions: {
                    current: {
                      enabled: true,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                    prev: {
                      enabled: true,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                    days: {
                      enabled: true,
                      count: 10,
                      includeCurrentSession: false,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                  },
                  transparency: 35,
                },
                graphics: {
                  summaryEnabled: true,
                  showPrices: true,
                  summaryFont: {
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fillColor: '#0C62F7',
                    fontWeight: '400',
                  },
                },
                session: null,
                profile: {
                  type: 'solidgram',
                  color: {
                    type: 'profileColor',
                    value: '#0C62F7',
                  },
                  widthCorrelation: 0.5,
                  extendNakedPocs: true,
                  extendNaked: {
                    enabled: false,
                    type: 'closesHighsLows',
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#0C62F7',
                      lineStyle: 'solid',
                    },
                  },
                  vaInsideOpacity: 0.5,
                  vaOutsideOpacity: 0.5,
                  profileColor: '',
                },
                lines: {
                  current: {
                    poc: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#FFFF00',
                        lineStyle: 'solid',
                      },
                      labelEnabled: false,
                    },
                    va: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#FFFF00',
                        lineStyle: 'solid',
                      },
                      labelEnabled: false,
                    },
                  },
                  prev: {
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                  },
                  dev: {
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                  },
                },
                eth: {
                  session: null,
                  profile: {
                    type: 'solidgram',
                    color: {
                      type: 'profileColor',
                      value: '#0C62F7',
                    },
                    widthCorrelation: 0.5,
                    extendNakedPocs: true,
                    extendNaked: {
                      enabled: false,
                      type: 'closesHighsLows',
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#0C62F7',
                        lineStyle: 'solid',
                      },
                    },
                    vaInsideOpacity: 0.5,
                    vaOutsideOpacity: 0.5,
                    profileColor: '',
                  },
                  lines: {
                    current: {
                      poc: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                        labelEnabled: false,
                      },
                      va: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                        labelEnabled: false,
                      },
                    },
                    prev: {
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                    },
                    dev: {
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                    },
                  },
                },
                splitProfile: false,
                overlayEthOverRth: false,
                highlight: {
                  poc: {
                    enabled: true,
                    value: '#A1A2A5',
                  },
                  va: {
                    enabled: false,
                    value: '#A1A2A5',
                  },
                },
              },
            },
            drawings: [
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
            ],
          },
          intervalOptions: [
            {
              active: false,
              period: 'RevBars',
              timeFrames: [
                {
                  interval: 4,
                  periodicity: 'revs',
                },
                {
                  interval: 8,
                  periodicity: 'revs',
                },
                {
                  interval: 12,
                  periodicity: 'revs',
                },
                {
                  interval: 16,
                  periodicity: 'revs',
                },
              ],
              periodicities: ['revs'],
            },
            {
              active: false,
              period: 'Seconds',
              timeFrames: [
                {
                  interval: 30,
                  periodicity: 's',
                },
                {
                  interval: 40,
                  periodicity: 's',
                },
              ],
              periodicities: ['s'],
            },
            {
              active: false,
              period: 'Minutes',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: '',
                },
                {
                  interval: 3,
                  periodicity: '',
                },
                {
                  interval: 5,
                  periodicity: '',
                },
                {
                  interval: 15,
                  periodicity: '',
                },
                {
                  interval: 30,
                  periodicity: '',
                },
              ],
              periodicities: [''],
            },
            {
              active: false,
              period: 'Hours',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'h',
                },
                {
                  interval: 2,
                  periodicity: 'h',
                },
                {
                  interval: 3,
                  periodicity: 'h',
                },
                {
                  interval: 4,
                  periodicity: 'h',
                },
              ],
              periodicities: ['h'],
            },
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 1,
                  periodicity: 'm',
                },
              ],
              periodicities: ['d', 'm', 'w', 'y'],
            },
          ],
          periodOptions: [
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 5,
                  periodicity: 'd',
                },
              ],
              periodicity: 'd',
            },
            {
              active: false,
              period: 'Weeks',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 2,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'w',
                },
              ],
              periodicity: 'w',
            },
            {
              active: false,
              period: 'Months',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 6,
                  periodicity: 'm',
                },
              ],
              periodicity: 'm',
            },
          ],
          componentInstanceId: 1733073344595,
          settings: {
            general: {
              font: {
                fontSize: 10,
                textColor: '#fff',
                fontFamily: 'Arial',
              },
              gradient1: 'rgb(27, 29, 34)',
              gradient2: 'rgb(27, 29, 34)',
              gridColor: '#24262C',
              lineColor: '#fff',
              wickColor: '#fff',
              upCandleColor: '#FFE8E8',
              dateScaleColor: 'rgb(27, 29, 34)',
              downCandleColor: '#C93B3B',
              valueScaleColor: 'rgb(27, 29, 34)',
              upCandleBorderColor: '#D0D0D2',
              downCandleBorderColor: '#D0D0D2',
              upCandleBorderColorEnabled: false,
              downCandleBorderColorEnabled: false,
            },
            session: {
              sessionEnabled: true,
              sessionTemplate: {
                id: 3,
                name: 'US ETH+RTH',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                workingTimes: [
                  {
                    endDay: 1,
                    endTime: 61200000,
                    startDay: 0,
                    startTime: 64800000,
                  },
                  {
                    endDay: 2,
                    endTime: 61200000,
                    startDay: 1,
                    startTime: 64800000,
                  },
                  {
                    endDay: 3,
                    endTime: 61200000,
                    startDay: 2,
                    startTime: 64800000,
                  },
                  {
                    endDay: 4,
                    endTime: 61200000,
                    startDay: 3,
                    startTime: 64800000,
                  },
                  {
                    endDay: 5,
                    endTime: 61200000,
                    startDay: 4,
                    startTime: 64800000,
                  },
                ],
              },
            },
            trading: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              trading: {
                showPLInfo: true,
                displayUnit: 'points',
                orderBarUnit: 'pixels',
                showOHLVInfo: false,
                bracketButton: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: true,
                tradingBarLength: 40,
                includeRealizedPL: true,
                showCancelConfirm: true,
                showWorkingOrders: true,
                negativePLFontColor: 'red',
                positivePLFontColor: 'white',
                showInstrumentChange: false,
                negativePLBackgroundColor: 'black',
                positivePLBackgroundColor: 'black',
              },
              orderArea: {
                formData: {
                  quantity: 10,
                  stopLoss: {
                    stopLoss: false,
                    type: 'StopMarket',
                  },
                  takeProfit: {
                    takeProfit: false,
                  },
                },
                amountButtons: [
                  {
                    value: 1,
                  },
                  {
                    black: true,
                    value: 1,
                  },
                  {
                    value: 3,
                  },
                  {
                    value: 5,
                  },
                  {
                    value: 10,
                  },
                  {
                    value: 25,
                  },
                ],
                settings: {
                  buyButtonsBackgroundColor: '#0C62F7',
                  flatButtonsBackgroundColor: '#51535A',
                  buyButtonsFontColor: '#D0D0D2',
                  flatButtonsFontColor: '#D0D0D2',
                  sellButtonsBackgroundColor: '#C93B3B',
                  cancelButtonBackgroundColor: '#51535A',
                  sellButtonsFontColor: '#D0D0D2',
                  cancelButtonFontColor: '#D0D0D2',
                  closePositionFontColor: '#D0D0D2',
                  closePositionBackgroundColor: '#51535A',
                  icebergBackgroundColor: '#51535A',
                  icebergFontColor: '#fff',
                  formSettings: {
                    showIcebergButton: true,
                    showFlattenButton: true,
                    closePositionButton: true,
                    showLiquidateButton: true,
                    showCancelButton: true,
                    showBuyButton: true,
                    showSellButton: true,
                    showBracket: true,
                    showPLInfo: true,
                  },
                  tif: {
                    DAY: true,
                    GTC: true,
                    default: 'DAY',
                  },
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  orderArea: {
                    showPLInfo: true,
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                },
              },
              chartMarkers: {
                buyMarkerType: 'triangle',
                buyMarkerColor: 'blue',
                sellMarkerType: 'square',
                sellMarkerColor: 'red',
                buyMarkerFontWeight: 12,
                sellMarkerFontWeight: 12,
                dataBoxBackgroundColor: 'black',
                showBarMarkerTobBottom: true,
                showBarTextWithSizePrice: true,
                showBarMarketExecutionPrice: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
                orderBarUnit: 'pixels',
                orderBarLength: 100,
              },
            },
            valueScale: {
              valueScale: {
                isAutomatic: 'automatic',
                pixelsPrice: 2,
              },
            },
          },
          preferredAccounts: {},
        },
        name: 'chart',
      },
      order: 3,
    },
    {
      id: '1730733147232_0.9148566808085228',
      maximizable: true,
      minimizable: true,
      resizable: true,
      minWidth: 350,
      minHeight: 150,
      allowPopup: true,
      visible: true,
      x: 544.2574462890625,
      y: 36.11328125,
      width: 522.0365601480007,
      height: 367.8527526855469,
      component: {
        state: {
          isToolbarVisible: false,
          showOHLV: false,
          showChanges: false,
          enableOrderForm: false,
          link: 0.36923744970654826,
          showCancelConfirm: true,
          instrument: {
            id: 'ESZ4.CME',
            type: 'Future',
            symbol: 'ESZ4',
            currency: 'USD',
            exchange: 'CME',
            tickSize: 0.25,
            precision: 2,
            expiration: '2024-12-20T00:00:00',
            description: 'E-Mini S&P 500 Dec24',
            productCode: 'ES',
            connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
            contractSize: 50,
            instrumentTimePeriod: 'Dec24',
            stringTypeRepresentation: 'Future',
          },
          timeFrame: {
            interval: 5,
            periodicity: '',
          },
          periodToLoad: {
            interval: 3,
            periodicity: 'd',
          },
          stockChartXState: {
            chart: {
              theme: {
                name: 'Dark',
                chart: {
                  background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
                  border: {
                    width: 1,
                    strokeColor: 'grey',
                    lineStyle: 'solid',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontFamily: 'Arial',
                      fontSize: 70,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                    details: {
                      fontFamily: 'Arial',
                      fontSize: 40,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                  },
                },
                splitter: {
                  fillColor: '#292C38',
                  hoverFillColor: 'white',
                },
                chartPanel: {
                  grid: {
                    width: 1,
                    strokeColor: '#24262C',
                  },
                  title: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fontStyle: 'normal',
                    fillColor: 'white',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontFamily: 'Arial',
                      fontSize: 70,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                    details: {
                      fontFamily: 'Arial',
                      fontSize: 40,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                  },
                },
                tradingPanel: {
                  tradingBarUnit: 'pixels',
                  tradingBarLength: 40,
                  buyPanelBackground: 'rgba(12,98,247, 0.3)',
                  sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
                  buyMarkerBackground: '#0C62F7',
                  sellMarkerBackground: '#C93B3B',
                  markerFontColor: '#fff',
                  showPLInfo: true,
                  includeRealizedPL: true,
                  displayUnit: 'points',
                  positivePLFontColor: 'white',
                  positivePLBackgroundColor: 'black',
                  negativePLFontColor: 'red',
                  negativePLBackgroundColor: 'black',
                },
                chartMarkers: {
                  showBarMarkerTobBottom: true,
                  showBarMarketExecutionPrice: true,
                  showBarTextWithSizePrice: true,
                  dataBoxBackgroundColor: 'black',
                  buyMarkerColor: 'blue',
                  buyMarkerType: 'triangle',
                  buyMarkerFontWeight: 1,
                  sellMarkerColor: 'red',
                  sellMarkerType: 'square',
                  sellMarkerFontWeight: 1,
                },
                valueScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  text: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'normal',
                    fillColor: '#fff',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  border: {
                    width: 0,
                    strokeColor: 'darkgray',
                    lineStyle: 'solid',
                  },
                  valueMarker: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: '#0C62F7',
                    },
                  },
                },
                dateScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  text: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fillColor: '#fff',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  border: {
                    width: 0,
                    strokeColor: 'darkgray',
                    lineStyle: 'solid',
                  },
                  dateMarker: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: '#FFFFFF',
                    },
                    fill: {
                      fillColor: 'green',
                    },
                    stroke: {
                      strokePriority: 'color',
                      strokeColor: '#696969',
                      width: 1,
                      lineStyle: 'solid',
                      lineJoin: 'miter',
                      lineCap: 'butt',
                    },
                  },
                  breakLine: {
                    stroke: {
                      strokeColor: '#545454',
                      width: 1,
                      lineStyle: 'dash',
                    },
                  },
                },
                crossHair: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: '#000000',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'darkgray',
                    lineStyle: 'dash',
                  },
                  fill: {
                    fillColor: '#FFFFFF',
                  },
                },
                zoomIn: {
                  border: {
                    width: 1,
                    strokeColor: 'white',
                    lineStyle: 'solid',
                  },
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                plot: {
                  point: {
                    dot: {
                      fill: {
                        fillColor: 'white',
                      },
                    },
                  },
                  line: {
                    simple: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    mountain: {
                      line: {
                        width: 1,
                        strokeColor: '#fff',
                      },
                      fill: {
                        linearGradient: [
                          'rgba(63, 167, 225, 0.4) ',
                          'rgba(63, 167, 225, 0)',
                        ],
                      },
                    },
                    step: {
                      width: 1,
                      strokeColor: 'white',
                    },
                  },
                  histogram: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    coloredLine: {
                      upBar: {
                        width: 1,
                        strokeColor: 'grey',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                    column: {
                      line: {
                        strokeEnabled: false,
                        width: 1,
                        strokeColor: 'white',
                      },
                      fill: {
                        fillColor: 'blue',
                      },
                    },
                    coloredColumn: {
                      upBar: {
                        line: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: 'green',
                        },
                      },
                      downBar: {
                        line: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: 'red',
                        },
                      },
                    },
                  },
                  bar: {
                    OHLC: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    HLC: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    HL: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    coloredOHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#FFE8E8',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#C93B3B',
                      },
                    },
                    coloredHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    coloredHL: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    candle: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                    },
                    heikinAshi: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                      },
                    },
                    renko: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    lineBreak: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillEnabled: true,
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillEnabled: true,
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    hollowCandle: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      upHollowCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downHollowCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    pointAndFigure: {
                      upCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    kagi: {
                      upCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    candleVolume: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                    },
                    equiVolume: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    equiVolumeShadow: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                  },
                  indicator: {
                    line1: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'white',
                    },
                    line2: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#3FA7E1',
                    },
                    line3: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#E85050',
                    },
                    line4: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0000FF',
                    },
                    line5: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'yellow',
                    },
                    histogram: {
                      color: '#32CD32',
                    },
                    blackText: {
                      fontFamily: 'Arial',
                      fontSize: 10,
                      fontStyle: 'normal',
                      fillColor: 'black',
                    },
                    levels: {
                      line1: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FF0000',
                      },
                      line2: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#32CD32',
                      },
                      line3: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFD500',
                      },
                      line4: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0000FF',
                      },
                      line5: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#808080',
                      },
                    },
                  },
                },
                selectionMarker: {
                  line: {
                    strokeColor: 'white',
                    width: 1,
                  },
                  fill: {
                    fillColor: 'black',
                  },
                },
                drawing: {
                  note: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: 'orange',
                    },
                    centerPointFill: {
                      fillColor: 'rgb(68, 68, 68)',
                    },
                  },
                  measure: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                      lineStyle: 'dash',
                    },
                    border: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                      lineStyle: 'dash',
                    },
                    fill: {
                      fillEnabled: true,
                      fillColor: 'rgba(255, 255, 255, 0.5)',
                    },
                    balloon: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                      },
                      border: {
                        width: 1,
                        strokeColor: 'darkgray',
                        strokeEnabled: true,
                        lineStyle: 'solid',
                      },
                      fill: {
                        fillEnabled: true,
                        fillColor: 'rgba(255, 255, 255, 0.5)',
                      },
                    },
                  },
                  measureTool: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  },
                  abstract: {
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                    },
                  },
                  trendAngle: {
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                    },
                    arc: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dot',
                    },
                    horizontalLine: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dot',
                    },
                  },
                  abstractMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                  fibonacci: {
                    trendLine: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dash',
                    },
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                    },
                  },
                  arrowUp: {
                    fill: {
                      fillColor: 'limegreen',
                    },
                  },
                  arrowDown: {
                    fill: {
                      fillColor: 'red',
                    },
                  },
                  text: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: '#FFFFFF',
                      decoration: '',
                    },
                  },
                  image: {
                    noImage: {
                      line: {
                        strokeColor: 'red',
                        width: 1,
                      },
                    },
                  },
                },
                tooltip: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 12,
                    fillColor: 'white',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    decoration: '',
                  },
                  border: {
                    enabled: true,
                    width: 1,
                    color: 'orange',
                    lineStyle: 'solid',
                  },
                  fill: {
                    enabled: true,
                    color: 'rgb(44, 44, 44)',
                  },
                },
                spread: {
                  ask: {
                    line: {
                      width: 1,
                      strokeColor: '#00D533',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#00D533',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                      },
                    },
                  },
                  bid: {
                    line: {
                      width: 1,
                      strokeColor: '#F20500',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#F20500',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                      },
                    },
                  },
                },
                highlightedColumn: {
                  fill: {
                    fillColor: 'rgba(90, 130, 182, 0.45)',
                  },
                },
                button: {
                  accept: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      strokeColor: 'green',
                      width: 1,
                    },
                  },
                  cancel: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      strokeColor: 'red',
                      width: 1,
                    },
                  },
                  none: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                positionSideMarker: {
                  showPLInfo: true,
                  includeRealizedPL: true,
                  displayUnit: 'points',
                  positivePLFontColor: 'white',
                  positivePLBackgroundColor: 'black',
                  negativePLFontColor: 'red',
                  negativePLBackgroundColor: 'black',
                  positionBarLength: 100,
                  positionBarUnit: 'pixels',
                  short: {
                    length: 1,
                    lengthUnit: 'pixels',
                    lineColor: '#FF0000',
                    lineType: 'solid',
                    line: {
                      strokeColor: 'red',
                      width: 1,
                    },
                    kind: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    quantity: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    price: {
                      fill: {
                        fillColor: 'red',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                  },
                  long: {
                    length: 1,
                    lengthUnit: 'pixels',
                    lineColor: '#0C62F7',
                    lineType: 'solid',
                    line: {
                      strokeColor: 'green',
                      width: 1,
                    },
                    kind: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    quantity: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    price: {
                      fill: {
                        fillColor: 'blue',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                  },
                },
                orderSideMarker: {
                  buy: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#33537C',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#33537C',
                    },
                  },
                  sell: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#C93B3B',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#C93B3B',
                    },
                  },
                  ocoStopLimit: '#FFFF00',
                  ocoStopOrder: '#FFFF00',
                  orderBarUnit: 'pixels',
                  orderBarLength: 100,
                },
                orderChartMarker: {
                  showTopBottomMarkers: true,
                  showMarkerAtExecutionPrice: true,
                  showTextWithSizeAndPrice: true,
                  buy: {
                    stroke: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'blue',
                    },
                    markerType: 'triangle',
                    tooltip: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 12,
                        fillColor: 'blue',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        decoration: '',
                      },
                      border: {
                        enabled: false,
                        width: 1,
                        color: 'orange',
                        lineStyle: 'solid',
                      },
                      fill: {
                        enabled: true,
                        color: 'black',
                      },
                    },
                  },
                  sell: {
                    stroke: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'red',
                    },
                    markerType: 'square',
                    tooltip: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 12,
                        fillColor: 'red',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        decoration: '',
                      },
                      border: {
                        enabled: false,
                        width: 1,
                        color: 'orange',
                        lineStyle: 'solid',
                      },
                      fill: {
                        enabled: true,
                        color: 'black',
                      },
                    },
                  },
                },
              },
              locale: 'en',
              enableKeyboardEvents: false,
              enableMouseEvents: true,
              showBarInfoInTitle: true,
              priceStyle: 'candle',
              showInstrumentWatermark: false,
              xGridVisible: true,
              yGridVisible: true,
              scrollEnabled: true,
              zoomEnabled: true,
              zoomAutoSave: false,
              scale: 2,
            },
            timeFrame: {
              interval: 5,
              periodicity: '',
            },
            periodToLoad: {
              interval: 3,
              periodicity: 'd',
            },
            priceStyle: {
              className: 'candle',
              options: {},
            },
            dateScale: {
              allowPartialRecords: true,
              firstVisibleRecord: 328,
              lastVisibleRecord: 428,
              minVisibleRecords: 5,
              textPadding: {
                top: null,
                left: 3,
                right: 3,
                bottom: 3,
              },
              height: 15,
              useManualHeight: false,
              scrollKind: 'autoscaled',
              zoomKind: 'autoscaled',
              zoomMode: 'pin_mouse',
              autoScrollKind: 'visible_bar',
              autoScrollMode: 'new_bar',
              rightAdditionalSpaceRatio: 0.5,
              majorTickMarkLength: 5,
              minorTickMarkLength: 3,
              formatter: {
                className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
                locale: 'en',
                timeInterval: 300000,
              },
              calibrator: {
                className: 'StockChartX.CustomDateScaleCalibrator',
                options: {},
              },
            },
            valueScales: [
              {
                showLeftPanel: false,
                showRightPanel: true,
                width: 100,
                useManualWidth: false,
              },
            ],
            crossHair: {
              crossHairType: 'crossBars',
            },
            chartPanelsContainer: {
              newPanelHeightRatio: 0.2,
              panelPadding: {
                top: 10,
                left: 5,
                right: 5,
                bottom: 10,
              },
              panels: [
                {
                  options: {
                    minHeightRatio: 0.05,
                    maxHeightRatio: 1,
                    heightRatio: 1,
                    showXGrid: true,
                    showYGrid: true,
                    moveDirection: 'any',
                    moveKind: 'normal',
                    state: 0,
                    visible: true,
                    showIndicatorTitles: true,
                    drawValueScale: true,
                  },
                  valueScales: [
                    {
                      options: {
                        minVisibleValue: 6037.5,
                        maxVisibleValue: 6062,
                        minAllowedValue: null,
                        maxAllowedValue: null,
                        minAllowedValueRatio: 0.8,
                        maxAllowedValueRatio: 0.8,
                        minValueRangeRatio: 0.1,
                        maxValueRangeRatio: 5,
                        majorTickMarkLength: 3,
                        minorTickMarkLength: 3,
                        padding: {
                          top: 3,
                          left: 6,
                          right: 3,
                          bottom: 3,
                        },
                      },
                      formatter: {
                        className: 'StockChartX.IntlNumberFormat',
                        options: {
                          locale: 'en',
                          numberingSystem: 'latn',
                          style: 'decimal',
                          minimumIntegerDigits: 1,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          useGrouping: 'auto',
                          notation: 'standard',
                          signDisplay: 'auto',
                          roundingIncrement: 1,
                          roundingMode: 'halfExpand',
                          roundingPriority: 'auto',
                          trailingZeroDisplay: 'auto',
                        },
                      },
                      calibrator: {
                        className: 'StockChartX.IntervalValueScaleCalibrator',
                        options: {
                          suffix: null,
                          divider: null,
                        },
                      },
                    },
                  ],
                },
              ],
            },
            instrumentComparisonHandler: {
              instrumentsOptions: [],
            },
            indicators: [
              {
                panelHeightRatio: 1,
                showParamsInTitle: true,
                allowContextMenu: true,
                showValueMarkers: true,
                showValuesInTitle: true,
                allowSettingsDialog: false,
                visible: true,
                showTitle: true,
                showLineLevels: false,
                coloredVolumeTheme: null,
                zIndex: 1000,
                parameters: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                panelIndex: 0,
                className: 'VolumeProfile',
                _prevParams: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
              },
            ],
            barsCount: false,
            plots: {
              VolumeProfile: {
                general: {
                  type: 'volume',
                  period: {
                    type: 'every',
                    value: 1,
                    unit: 'day',
                    date: null,
                  },
                  hide: {
                    enabled: false,
                    value: 'lastProfile',
                  },
                  vaCorrelation: 0.7,
                  smoothed: {
                    enabled: false,
                    value: 9,
                  },
                  customTickSize: {
                    enabled: false,
                    value: 5,
                  },
                  calculateXProfiles: 999,
                  align: 'right',
                  width: {
                    value: 70,
                    unit: 'pixel',
                  },
                  margin: {
                    value: 4,
                    unit: 'pixel',
                  },
                  sessions: {
                    current: {
                      enabled: true,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                    prev: {
                      enabled: true,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                    days: {
                      enabled: true,
                      count: 10,
                      includeCurrentSession: false,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                  },
                  transparency: 35,
                },
                graphics: {
                  summaryEnabled: true,
                  showPrices: true,
                  summaryFont: {
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fillColor: '#0C62F7',
                    fontWeight: '400',
                  },
                },
                session: null,
                profile: {
                  type: 'solidgram',
                  color: {
                    type: 'profileColor',
                    value: '#0C62F7',
                  },
                  widthCorrelation: 0.5,
                  extendNakedPocs: true,
                  extendNaked: {
                    enabled: false,
                    type: 'closesHighsLows',
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#0C62F7',
                      lineStyle: 'solid',
                    },
                  },
                  vaInsideOpacity: 0.5,
                  vaOutsideOpacity: 0.5,
                  profileColor: '',
                },
                lines: {
                  current: {
                    poc: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#FFFF00',
                        lineStyle: 'solid',
                      },
                      labelEnabled: false,
                    },
                    va: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#FFFF00',
                        lineStyle: 'solid',
                      },
                      labelEnabled: false,
                    },
                  },
                  prev: {
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                  },
                  dev: {
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                  },
                },
                eth: {
                  session: null,
                  profile: {
                    type: 'solidgram',
                    color: {
                      type: 'profileColor',
                      value: '#0C62F7',
                    },
                    widthCorrelation: 0.5,
                    extendNakedPocs: true,
                    extendNaked: {
                      enabled: false,
                      type: 'closesHighsLows',
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#0C62F7',
                        lineStyle: 'solid',
                      },
                    },
                    vaInsideOpacity: 0.5,
                    vaOutsideOpacity: 0.5,
                    profileColor: '',
                  },
                  lines: {
                    current: {
                      poc: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                        labelEnabled: false,
                      },
                      va: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                        labelEnabled: false,
                      },
                    },
                    prev: {
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                    },
                    dev: {
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                    },
                  },
                },
                splitProfile: false,
                overlayEthOverRth: false,
                highlight: {
                  poc: {
                    enabled: true,
                    value: '#A1A2A5',
                  },
                  va: {
                    enabled: false,
                    value: '#A1A2A5',
                  },
                },
              },
            },
            drawings: [
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
            ],
          },
          intervalOptions: [
            {
              active: false,
              period: 'RevBars',
              timeFrames: [
                {
                  interval: 4,
                  periodicity: 'revs',
                },
                {
                  interval: 8,
                  periodicity: 'revs',
                },
                {
                  interval: 12,
                  periodicity: 'revs',
                },
                {
                  interval: 16,
                  periodicity: 'revs',
                },
              ],
              periodicities: ['revs'],
            },
            {
              active: false,
              period: 'Seconds',
              timeFrames: [
                {
                  interval: 30,
                  periodicity: 's',
                },
                {
                  interval: 40,
                  periodicity: 's',
                },
              ],
              periodicities: ['s'],
            },
            {
              active: false,
              period: 'Minutes',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: '',
                },
                {
                  interval: 3,
                  periodicity: '',
                },
                {
                  interval: 5,
                  periodicity: '',
                },
                {
                  interval: 15,
                  periodicity: '',
                },
                {
                  interval: 30,
                  periodicity: '',
                },
              ],
              periodicities: [''],
            },
            {
              active: false,
              period: 'Hours',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'h',
                },
                {
                  interval: 2,
                  periodicity: 'h',
                },
                {
                  interval: 3,
                  periodicity: 'h',
                },
                {
                  interval: 4,
                  periodicity: 'h',
                },
              ],
              periodicities: ['h'],
            },
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 1,
                  periodicity: 'm',
                },
              ],
              periodicities: ['d', 'm', 'w', 'y'],
            },
          ],
          periodOptions: [
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 5,
                  periodicity: 'd',
                },
              ],
              periodicity: 'd',
            },
            {
              active: false,
              period: 'Weeks',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 2,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'w',
                },
              ],
              periodicity: 'w',
            },
            {
              active: false,
              period: 'Months',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 6,
                  periodicity: 'm',
                },
              ],
              periodicity: 'm',
            },
          ],
          componentInstanceId: 1730733147214,
          settings: {
            general: {
              font: {
                fontSize: 10,
                textColor: '#fff',
                fontFamily: 'Arial',
              },
              gradient1: 'rgb(27, 29, 34)',
              gradient2: 'rgb(27, 29, 34)',
              gridColor: '#24262C',
              lineColor: '#fff',
              wickColor: '#fff',
              upCandleColor: '#FFE8E8',
              dateScaleColor: 'rgb(27, 29, 34)',
              downCandleColor: '#C93B3B',
              valueScaleColor: 'rgb(27, 29, 34)',
              upCandleBorderColor: '#D0D0D2',
              downCandleBorderColor: '#D0D0D2',
              upCandleBorderColorEnabled: false,
              downCandleBorderColorEnabled: false,
            },
            session: {
              sessionEnabled: true,
              sessionTemplate: {
                id: 3,
                name: 'US ETH+RTH',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
                workingTimes: [
                  {
                    endDay: 1,
                    endTime: 61200000,
                    startDay: 0,
                    startTime: 64800000,
                  },
                  {
                    endDay: 2,
                    endTime: 61200000,
                    startDay: 1,
                    startTime: 64800000,
                  },
                  {
                    endDay: 3,
                    endTime: 61200000,
                    startDay: 2,
                    startTime: 64800000,
                  },
                  {
                    endDay: 4,
                    endTime: 61200000,
                    startDay: 3,
                    startTime: 64800000,
                  },
                  {
                    endDay: 5,
                    endTime: 61200000,
                    startDay: 4,
                    startTime: 64800000,
                  },
                ],
              },
            },
            trading: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              trading: {
                showPLInfo: true,
                displayUnit: 'points',
                orderBarUnit: 'pixels',
                showOHLVInfo: false,
                bracketButton: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: true,
                tradingBarLength: 40,
                includeRealizedPL: true,
                showCancelConfirm: true,
                showWorkingOrders: true,
                negativePLFontColor: 'red',
                positivePLFontColor: 'white',
                showInstrumentChange: false,
                negativePLBackgroundColor: 'black',
                positivePLBackgroundColor: 'black',
              },
              orderArea: {
                formData: {
                  quantity: 10,
                  stopLoss: {
                    stopLoss: false,
                    type: 'StopMarket',
                  },
                  takeProfit: {
                    takeProfit: false,
                  },
                },
                amountButtons: [
                  {
                    value: 1,
                  },
                  {
                    black: true,
                    value: 1,
                  },
                  {
                    value: 3,
                  },
                  {
                    value: 5,
                  },
                  {
                    value: 10,
                  },
                  {
                    value: 25,
                  },
                ],
                settings: {
                  buyButtonsBackgroundColor: '#0C62F7',
                  flatButtonsBackgroundColor: '#51535A',
                  buyButtonsFontColor: '#D0D0D2',
                  flatButtonsFontColor: '#D0D0D2',
                  sellButtonsBackgroundColor: '#C93B3B',
                  cancelButtonBackgroundColor: '#51535A',
                  sellButtonsFontColor: '#D0D0D2',
                  cancelButtonFontColor: '#D0D0D2',
                  closePositionFontColor: '#D0D0D2',
                  closePositionBackgroundColor: '#51535A',
                  icebergBackgroundColor: '#51535A',
                  icebergFontColor: '#fff',
                  formSettings: {
                    showIcebergButton: true,
                    showFlattenButton: true,
                    closePositionButton: true,
                    showLiquidateButton: true,
                    showCancelButton: true,
                    showBuyButton: true,
                    showSellButton: true,
                    showBracket: true,
                    showPLInfo: true,
                  },
                  tif: {
                    DAY: true,
                    GTC: true,
                    default: 'DAY',
                  },
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  orderArea: {
                    showPLInfo: true,
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                },
              },
              chartMarkers: {
                buyMarkerType: 'triangle',
                buyMarkerColor: 'blue',
                sellMarkerType: 'square',
                sellMarkerColor: 'red',
                buyMarkerFontWeight: 12,
                sellMarkerFontWeight: 12,
                dataBoxBackgroundColor: 'black',
                showBarMarkerTobBottom: true,
                showBarTextWithSizePrice: true,
                showBarMarketExecutionPrice: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
                orderBarUnit: 'pixels',
                orderBarLength: 100,
              },
            },
            valueScale: {
              valueScale: {
                isAutomatic: 'automatic',
                pixelsPrice: 2,
              },
            },
          },
          preferredAccounts: {},
        },
        name: 'chart',
      },
      order: 4,
    },
  ],
  isSelected: true,
  isOnStartUp: true,
};
