import { IFieldConfig } from 'dynamic-form';

import { getVWAPConfig } from '../fields';
import { Indicator } from './Indicator';

export class VWAP extends Indicator {
  name = VWAP.name;
  config: IFieldConfig[];

  constructor(instance: any, isStandardDeviationAvailable?: boolean) {
    super(instance);
    this.config = getVWAPConfig(isStandardDeviationAvailable);
  }

  protected _mapGetSettings(settings: any) {
    return settings;
  }

  protected _mapSetSettings(settings: any) {
    return settings;
  }
}
