export interface LogConfig {
  enabled?: boolean;
  configureMetadata: (args: string[]) => void;
}

export class Loggable {
  public logConfig: LogConfig = {
    enabled: false,
    configureMetadata: (args: string[]) => {},
  };

  private makeLogMessage(consoleFn: () => void, ...argumentsRest: any): void {
    consoleFn.apply(console, argumentsRest);
  }

  private prependLogMetadata(...argumentsRest: any): any[] {
    const time: string = new Intl.DateTimeFormat('en-GB', {
      // @ts-ignore
      timeStyle: 'medium',
    }).format(new Date());
    const args: any[] = [];
    args.push('[' + time + ']');
    args.push('[' + this.constructor.name + ']');
    this.logConfig.configureMetadata(args);
    for (const item of argumentsRest) {
      args.push(item);
    }
    return args;
  }

  enableLogging(): void {
    this.logConfig.enabled = true;
  }

  disableLogging(): void {
    this.logConfig.enabled = false;
  }

  shouldPrintLog(): boolean {
    return this.logConfig.enabled || (window as any).debug;
  }

  log(...argumentsRest: any): void {
    if (!this.shouldPrintLog()) return;
    const args: any[] = this.prependLogMetadata(...argumentsRest);
    this.makeLogMessage(console.log, ...args);
  }

  warn(...argumentsRest: any): void {
    if (!this.shouldPrintLog()) return;
    const args = this.prependLogMetadata(...argumentsRest);
    this.makeLogMessage(console.warn, ...args);
  }
}
