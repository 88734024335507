import {
  IInstrument,
  InstrumentType,
} from 'projects/trading/src/trading/models/instrument';
import {
  IOrder,
  OrderAccount,
  OrderDuration,
  OrderSide,
  OrderStatus,
  OrderType,
} from 'projects/trading/src/trading/models/order';

import { Loggable } from 'src/Loggable';
import { getTimestamp } from '../../common/misc';
import { rti } from './messages-js/otps_proto_pool';
import { RProtocolMessageTemplateNameEnum } from './rprotocol-message-template-name-enum';
import { RapiConstants } from './rprotocol.model';

export type RProtocolOrderNotification =
  | rti.ExchangeOrderNotification
  | rti.RithmicOrderNotification;

export type RProtocolDetailedOrderNotification =
  | (rti.ExchangeOrderNotification & {
      orderHistory?: RProtocolOrderNotification[];
    })
  | (rti.RithmicOrderNotification & {
      orderHistory?: RProtocolOrderNotification[];
    });

/**
 * @description Transforms order data from R Protocol format to application-specific structure.
 */
export class OrderMapper extends Loggable {
  map(orderNotifications: RProtocolOrderNotification[]): IOrder[] {
    return orderNotifications.map(this.mapItem.bind(this));
  }

  mapItem(orderNotification: RProtocolDetailedOrderNotification): IOrder {
    const instrument: IInstrument = {
      id: `${orderNotification.symbol}.${orderNotification.exchange}`,
      stringTypeRepresentation: orderNotification.instrumentType,
      symbol: orderNotification.symbol,
      exchange: orderNotification.exchange,
      tradingExchange: orderNotification.exchange,
      tradingSymbol: orderNotification.symbol,
      type: <InstrumentType>orderNotification.instrumentType,
      tickSize: undefined,
      // contractSize: Math.random(), // @TODO fetch this from Reference Data via R Protocol
      // description: symbolData?.symbolName,
      // increment: Math.random(),
      // precision: Math.random(),
      // productCode: '',
    };

    const orderTime: number = getTimestamp(orderNotification);
    // if order is older than 1s, consider it part of response for a Show Order History request
    const isHistorical: boolean = orderTime < Date.now() - 1000;

    let orderHistory = null;
    if (orderNotification.orderHistory?.length > 0) {
      orderHistory = orderNotification.orderHistory;
    }

    let filledQuantity: number = 0; // orderNotification.fillSize || 0
    if (
      orderNotification.constructor.name ===
      RProtocolMessageTemplateNameEnum.ExchangeOrderNotification
    ) {
      filledQuantity =
        (orderNotification as rti.ExchangeOrderNotification).fillSize || 0;
    }

    return {
      accountId: orderNotification.accountId,
      origData: {
        __class__: orderNotification.constructor.name,
        ...orderNotification,
      },
      isHistorical,
      account: {
        fcmId: orderNotification.fcmId,
        ibId: orderNotification.ibId,
        id: orderNotification.accountId,
        name: '',
        // @todo Do we even need this? What for? If not, remove. An order shouldn't carry so much nested detail anyway.
        // name: this._accountsManager.getAccountById(orderNotification.accountId)
        //   ?.name,
      } as OrderAccount,
      averageFillPrice: orderNotification.avgFillPrice,
      currentSequenceNumber: orderNotification.corSequenceNumber,
      description: instrument.description ?? '',
      duration: <OrderDuration>(
        this._capitalize(
          rti.ExchangeOrderNotification.Duration[orderNotification.duration],
        )
      ),
      exchange: orderNotification.exchange,
      filledQuantity,
      iceQuantity: null,
      id: orderNotification.basketId,
      instrument,
      price: orderNotification.price,
      quantity: orderNotification.quantity,
      side: <OrderSide>(
        this._capitalize(
          rti.ExchangeOrderNotification.TransactionType[
            orderNotification.transactionType
          ],
        )
      ),
      status: this._getOrderStatusFromOrderNotification(orderNotification),
      symbol: orderNotification.symbol,
      timestamp: parseInt(
        `${orderNotification.ssboe}${('' + orderNotification.usecs).substring(0, 3)}`,
      ),
      triggerPrice: orderNotification.triggerPrice,
      type: this._getOrderTypeForProcessedOrderItem(
        orderNotification.priceType,
      ),
      ...(orderHistory ? { orderHistory } : {}),
    } as IOrder;
  }

  /**
   * Verifies whether an order update is up-to-date update. Called by `RealtimeGridComponent._subscribeToDataFeed()` when a new update is received.
   * @param existingItem
   * @param updateItem
   */
  canMerge(existingItem: IOrder, updateItem: IOrder): boolean {
    let canMerge: boolean = true;
    if (existingItem.status === OrderStatus.Filled) {
      // A fully filled order shouldn't receive any new updates anymore
      canMerge = false;
    } else if (existingItem.status === OrderStatus.Canceled) {
      // A canceled order shouldn't receive any new updates anymore
      canMerge = false;
    } else {
      const oldItemTimestamp = getTimestamp(existingItem.origData);
      const newItemTimestamp = getTimestamp(updateItem.origData);
      if (newItemTimestamp < oldItemTimestamp) {
        // The `updateItem` item is older than the "existingItem" item, so drop this update
        canMerge = false;
      }
    }

    return canMerge;
  }

  merge(existingItem: IOrder, updateItem: IOrder): IOrder {
    return this.canMerge(existingItem, updateItem) ? updateItem : existingItem;
  }

  private _capitalize(value: string): string {
    const lowercase: string = value.toLowerCase();
    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
  }

  private _getOrderTypeForProcessedOrderItem(
    type: rti.ExchangeOrderNotification.PriceType,
  ): OrderType {
    switch (rti.ExchangeOrderNotification.PriceType[type]) {
      case 'LIMIT':
        return OrderType.Limit;
      case 'MARKET':
        return OrderType.Market;
      case 'STOP_LIMIT':
        return OrderType.StopLimit;
      case 'STOP_MARKET':
        return OrderType.StopMarket;
    }

    throw new Error(`Unknown order type: ${type}`);
  }

  private _getStatusForOrderItem(
    status: string,
    completionReason?: string,
  ): OrderStatus {
    switch (true) {
      case !status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_CANCEL_FAILED === status:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_CANCEL_PENDING === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_CANCEL_RCVD_BY_EXCH_GWAY === status:
        return OrderStatus.Canceled;
      case RapiConstants.LINE_STATUS_CANCEL_RECEIVED === status:
        return OrderStatus.Canceled;
      case RapiConstants.LINE_STATUS_CANCEL_SENT_TO_EXCH === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_FILL:
        return OrderStatus.Filled;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_PFBC:
        return OrderStatus.Canceled;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_REJECT:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_FAILURE:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_COMPLETE === status &&
        completionReason === RapiConstants.COMPLETION_REASON_CANCEL:
        return OrderStatus.Canceled;
      case RapiConstants.LINE_STATUS_COMPLETE === status && !completionReason:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_MOD_TRIGGER_PENDING === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_MODIFIED === status:
        return OrderStatus.New;
      case RapiConstants.LINE_STATUS_MODIFY_FAILED === status:
        return OrderStatus.Rejected;
      case RapiConstants.LINE_STATUS_MODIFY_PENDING === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_MODIFY_RCVD_BY_EXCH_GWAY === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_MODIFY_RECEIVED === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_MODIFY_SENT_TO_EXCH === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_OPEN === status:
        return OrderStatus.New;
      case RapiConstants.LINE_STATUS_OPEN_PENDING === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_ORDER_RCVD_BY_EXCH_GWAY === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_ORDER_RECEIVED === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_ORDER_SENT_TO_EXCH === status:
        return OrderStatus.Pending;
      case RapiConstants.LINE_STATUS_PARTIAL === status:
        return OrderStatus.PartialFilled;
      case RapiConstants.LINE_STATUS_TRIGGER_PENDING === status:
        return OrderStatus.Pending;
      default:
        return OrderStatus.Rejected;
    }
  }

  private _getOrderStatusFromOrderNotification(
    notification: rti.ExchangeOrderNotification | rti.RithmicOrderNotification,
  ): OrderStatus {
    let status: OrderStatus = OrderStatus.Rejected;
    if (
      notification.constructor.name ==
      RProtocolMessageTemplateNameEnum.RithmicOrderNotification
    ) {
      notification = notification as rti.RithmicOrderNotification;
      status = this._getStatusForOrderItem(
        notification.status,
        notification.completionReason,
      );
    } else {
      notification = notification as rti.ExchangeOrderNotification;
      const statusLinesForPending = [
        RapiConstants.LINE_STATUS_OPEN,
        RapiConstants.LINE_STATUS_OPEN_PENDING,
        RapiConstants.LINE_STATUS_MOD_TRIGGER_PENDING,
        RapiConstants.LINE_STATUS_TRIGGER_PENDING,
        RapiConstants.LINE_STATUS_MODIFIED,
        RapiConstants.LINE_STATUS_MODIFY_PENDING,
      ];
      if (!notification.status) {
        if (notification.reportType === RapiConstants.REPORT_TYPE_FILL) {
          status = OrderStatus.Filled;
          if (notification.totalUnfilledSize > 0) {
            status = OrderStatus.PartialFilled;
          }
        }
        status = OrderStatus.Pending;
      } else if (
        statusLinesForPending.includes(notification.status as RapiConstants)
      ) {
        status = OrderStatus.Pending;
      } else if (notification.status === RapiConstants.LINE_STATUS_COMPLETE) {
        switch (notification.reportType) {
          case RapiConstants.REPORT_TYPE_FILL:
            status = OrderStatus.Filled;
            if (notification.totalUnfilledSize > 0) {
              status = OrderStatus.PartialFilled;
            }
            break;
          case RapiConstants.REPORT_TYPE_CANCEL:
            status = OrderStatus.Canceled;
            break;
          case RapiConstants.REPORT_TYPE_REJECT:
            status = OrderStatus.Rejected;
            break;
          default:
            status = OrderStatus.Rejected;
            break;
        }
      }
    }

    return status;
  }
}
