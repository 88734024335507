import { ShiftToStopOrderEnum } from 'projects/dom/src/lib/dom-settings/dom-settings.model';
import { IBaseTemplate } from 'templates';

// @todo Fix types
export const defaultGlobalTemplates: any[] | IBaseTemplate[] = [
  {
    id: 1733175140001,
    name: 'Default',
    type: 'account-info',
    state: {
      columns: [
        {
          name: 'account',
          type: 'string',
          index: 0,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'ACCOUNT',
          width: 100,
          hidden: false,
          canHide: false,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 0,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Account',
        },
        {
          name: 'name',
          type: 'string',
          index: 1,
          style: {
            color: '#fff',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'NAME',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 1,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Name',
        },
        {
          name: 'currency',
          type: 'string',
          index: 2,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'CURRENCY',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 2,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Currency',
        },
        {
          name: 'fcmId',
          type: 'string',
          index: 3,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'FCM ID',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 3,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'FCM ID',
        },
        {
          name: 'ibId',
          type: 'string',
          index: 4,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'IB ID',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 4,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'IB ID',
        },
        {
          name: 'total',
          type: 'string',
          index: 5,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            titleUpperCase: false,
            lossBorderColor: '#C93B3B',
            inProfitBorderColor: '#0C62F7',
            lossBackgroundColor: '#C93B3B',
            hoveredBackgroundColor: '#2B2D33',
            hoveredlossBorderColor: '#C93B3B',
            inProfitBackgroundColor: '#0C62F7',
            hoveredinProfitBorderColor: '#0C62F7',
            hoveredlossBackgroundColor: '#C93B3B',
            hoveredinProfitBackgroundColor: '#0C62F7',
          },
          title: 'T/PL',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 11,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Total PL',
        },
        {
          name: 'closedPnl',
          type: 'string',
          index: 6,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            titleUpperCase: false,
            lossBorderColor: '#C93B3B',
            inProfitBorderColor: '#0C62F7',
            lossBackgroundColor: '#C93B3B',
            hoveredBackgroundColor: '#2B2D33',
            hoveredlossBorderColor: '#C93B3B',
            inProfitBackgroundColor: '#0C62F7',
            hoveredinProfitBorderColor: '#0C62F7',
            hoveredlossBackgroundColor: '#C93B3B',
            hoveredinProfitBackgroundColor: '#0C62F7',
          },
          title: 'R/PL',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 12,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Realized PL',
        },
        {
          name: 'accountBalance',
          type: 'string',
          index: 7,
          style: {
            color: '#fff',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'BALANCE',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 5,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Balance',
        },
        {
          name: 'autoLiquidateThreshold',
          type: 'string',
          index: 8,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'AUTO LIQUIDATE THRESHOLD',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 6,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Auto Liquidate Threshold',
        },
        {
          name: 'autoLiquidateThresholdCurrentValue',
          type: 'string',
          index: 9,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'AUTO LIQUIDATE THRESHOLD CURRENT VALUE',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 7,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Auto Liquidate Threshold Current Value',
        },
        {
          name: 'availableBuyingPower',
          type: 'string',
          index: 10,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'AVAILABLE BUYING POWER',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 8,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Available Buying Power',
        },
        {
          name: 'usedBuyingPower',
          type: 'string',
          index: 11,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'USED BUYING POWER',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 9,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Available Buying Power',
        },
        {
          name: 'reservedBuyingPower',
          type: 'string',
          index: 12,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'RESERVED BUYING POWER',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 10,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Reserved Buying Power',
        },
        {
          name: 'openPnl',
          type: 'string',
          index: 13,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            titleUpperCase: false,
            lossBorderColor: '#C93B3B',
            inProfitBorderColor: '#0C62F7',
            lossBackgroundColor: '#C93B3B',
            hoveredBackgroundColor: '#2B2D33',
            hoveredlossBorderColor: '#C93B3B',
            inProfitBackgroundColor: '#0C62F7',
            hoveredinProfitBorderColor: '#0C62F7',
            hoveredlossBackgroundColor: '#C93B3B',
            hoveredinProfitBackgroundColor: '#0C62F7',
          },
          title: 'F/PL',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 13,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Floating PL',
        },
        {
          name: 'lossLimit',
          type: 'string',
          index: 14,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'LOSS LIMIT',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 14,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Loss Limit',
        },
        {
          name: 'position',
          type: 'string',
          index: 15,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            textOverflow: false,
            titleUpperCase: false,
            lossBorderColor: '#C93B3B',
            inProfitBorderColor: '#0C62F7',
            lossBackgroundColor: '#C93B3B',
            hoveredBackgroundColor: '#2B2D33',
            hoveredlossBorderColor: '#C93B3B',
            inProfitBackgroundColor: '#0C62F7',
            hoveredinProfitBorderColor: '#0C62F7',
            hoveredlossBackgroundColor: '#C93B3B',
            hoveredinProfitBackgroundColor: '#0C62F7',
          },
          title: 'POSITION',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 15,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Position',
        },
        {
          name: 'cashOnHand',
          type: 'string',
          index: 16,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'CASH ON HAND',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 16,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Cash On Hand',
        },
        {
          name: 'impliedMarginReserved',
          type: 'string',
          index: 17,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'IMPLIED MARGIN RESERVED',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 17,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Implied Margin Reserved',
        },
        {
          name: 'marginBalance',
          type: 'string',
          index: 18,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'MARGIN BALANCE',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 18,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Margin Balance',
        },
        {
          name: 'reservedMargin',
          type: 'string',
          index: 19,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'RESERVED MARGIN',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 19,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Reserved Margin',
        },
        {
          name: 'buyQty',
          type: 'string',
          index: 20,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'BUY QUANTITY',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 20,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Buy Quantity',
        },
        {
          name: 'sellQty',
          type: 'string',
          index: 21,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            titleUpperCase: false,
            hoveredBackgroundColor: '#2B2D33',
          },
          title: 'SELL QUANTITY',
          width: 100,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          sortType: null,
          sortable: false,
          columnIndex: 21,
          moveRequest: false,
          sortDirection: 'none',
          tableViewName: 'Sell Quantity',
        },
      ],
      contextMenuState: {
        showSummary: true,
        showHeaderPanel: true,
        showColumnHeaders: true,
      },
    },
    tabState: {
      id: '1730248902435_0.015286929300405427',
      width: 1086.6027526855469,
      height: 522.3768310546875,
      visible: true,
      minWidth: 320,
      component: {
        name: 'account-info',
        state: {
          columns: [
            {
              name: 'account',
              type: 'string',
              index: 0,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'ACCOUNT',
              width: 100,
              hidden: false,
              canHide: false,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 0,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Account',
            },
            {
              name: 'name',
              type: 'string',
              index: 1,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'NAME',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 1,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Name',
            },
            {
              name: 'currency',
              type: 'string',
              index: 2,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'CURRENCY',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 2,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Currency',
            },
            {
              name: 'fcmId',
              type: 'string',
              index: 3,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'FCM ID',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 3,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'FCM ID',
            },
            {
              name: 'ibId',
              type: 'string',
              index: 4,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'IB ID',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 4,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'IB ID',
            },
            {
              name: 'total',
              type: 'string',
              index: 5,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                titleUpperCase: false,
                lossBorderColor: '#C93B3B',
                inProfitBorderColor: '#0C62F7',
                lossBackgroundColor: '#C93B3B',
                hoveredBackgroundColor: '#2B2D33',
                hoveredlossBorderColor: '#C93B3B',
                inProfitBackgroundColor: '#0C62F7',
                hoveredinProfitBorderColor: '#0C62F7',
                hoveredlossBackgroundColor: '#C93B3B',
                hoveredinProfitBackgroundColor: '#0C62F7',
              },
              title: 'T/PL',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 11,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Total PL',
            },
            {
              name: 'closedPnl',
              type: 'string',
              index: 6,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                titleUpperCase: false,
                lossBorderColor: '#C93B3B',
                inProfitBorderColor: '#0C62F7',
                lossBackgroundColor: '#C93B3B',
                hoveredBackgroundColor: '#2B2D33',
                hoveredlossBorderColor: '#C93B3B',
                inProfitBackgroundColor: '#0C62F7',
                hoveredinProfitBorderColor: '#0C62F7',
                hoveredlossBackgroundColor: '#C93B3B',
                hoveredinProfitBackgroundColor: '#0C62F7',
              },
              title: 'R/PL',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 12,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Realized PL',
            },
            {
              name: 'accountBalance',
              type: 'string',
              index: 7,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'BALANCE',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 5,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Balance',
            },
            {
              name: 'autoLiquidateThreshold',
              type: 'string',
              index: 8,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'AUTO LIQUIDATE THRESHOLD',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 6,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Auto Liquidate Threshold',
            },
            {
              name: 'autoLiquidateThresholdCurrentValue',
              type: 'string',
              index: 9,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'AUTO LIQUIDATE THRESHOLD CURRENT VALUE',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 7,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Auto Liquidate Threshold Current Value',
            },
            {
              name: 'availableBuyingPower',
              type: 'string',
              index: 10,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'AVAILABLE BUYING POWER',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 8,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Available Buying Power',
            },
            {
              name: 'usedBuyingPower',
              type: 'string',
              index: 11,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'USED BUYING POWER',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 9,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Available Buying Power',
            },
            {
              name: 'reservedBuyingPower',
              type: 'string',
              index: 12,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'RESERVED BUYING POWER',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 10,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Reserved Buying Power',
            },
            {
              name: 'openPnl',
              type: 'string',
              index: 13,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                titleUpperCase: false,
                lossBorderColor: '#C93B3B',
                inProfitBorderColor: '#0C62F7',
                lossBackgroundColor: '#C93B3B',
                hoveredBackgroundColor: '#2B2D33',
                hoveredlossBorderColor: '#C93B3B',
                inProfitBackgroundColor: '#0C62F7',
                hoveredinProfitBorderColor: '#0C62F7',
                hoveredlossBackgroundColor: '#C93B3B',
                hoveredinProfitBackgroundColor: '#0C62F7',
              },
              title: 'F/PL',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 13,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Floating PL',
            },
            {
              name: 'lossLimit',
              type: 'string',
              index: 14,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'LOSS LIMIT',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 14,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Loss Limit',
            },
            {
              name: 'position',
              type: 'string',
              index: 15,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                titleUpperCase: false,
                lossBorderColor: '#C93B3B',
                inProfitBorderColor: '#0C62F7',
                lossBackgroundColor: '#C93B3B',
                hoveredBackgroundColor: '#2B2D33',
                hoveredlossBorderColor: '#C93B3B',
                inProfitBackgroundColor: '#0C62F7',
                hoveredinProfitBorderColor: '#0C62F7',
                hoveredlossBackgroundColor: '#C93B3B',
                hoveredinProfitBackgroundColor: '#0C62F7',
              },
              title: 'POSITION',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 15,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Position',
            },
            {
              name: 'cashOnHand',
              type: 'string',
              index: 16,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'CASH ON HAND',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 16,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Cash On Hand',
            },
            {
              name: 'impliedMarginReserved',
              type: 'string',
              index: 17,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'IMPLIED MARGIN RESERVED',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 17,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Implied Margin Reserved',
            },
            {
              name: 'marginBalance',
              type: 'string',
              index: 18,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'MARGIN BALANCE',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 18,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Margin Balance',
            },
            {
              name: 'reservedMargin',
              type: 'string',
              index: 19,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'RESERVED MARGIN',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 19,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Reserved Margin',
            },
            {
              name: 'buyQty',
              type: 'string',
              index: 20,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'BUY QUANTITY',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 20,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Buy Quantity',
            },
            {
              name: 'sellQty',
              type: 'string',
              index: 21,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                titleUpperCase: false,
                hoveredBackgroundColor: '#2B2D33',
              },
              title: 'SELL QUANTITY',
              width: 100,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              sortType: null,
              sortable: false,
              columnIndex: 21,
              moveRequest: false,
              sortDirection: 'none',
              tableViewName: 'Sell Quantity',
            },
          ],
          contextMenuState: {
            showSummary: true,
            showHeaderPanel: true,
            showColumnHeaders: true,
          },
        },
      },
      minHeight: 150,
      resizable: true,
      allowPopup: false,
      maximizable: true,
      minimizable: true,
    },
    isDefault: true,
  },
  {
    id: 1733175140002,
    name: 'Default',
    type: 'dom',
    state: {
      width: 715,
      columns: [
        {
          name: 'orders',
          type: 'string',
          index: 0,
          style: {
            color: '#D0D0D2',
            split: false,
            showPL: true,
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            lossColor: 'rgba(201, 59, 59, 1)',
            textAlign: 'center',
            textOverflow: false,
            buyOrderColor: '#fff',
            inProfitColor: 'rgba(12,98,247,1)',
            overlayOrders: true,
            highlightColor: 'rgba(29, 73, 127, 1)',
            sellOrderColor: '#fff',
            titleUpperCase: true,
            backgroundColor: 'transparent',
            includeRealizedPL: false,
            buyOrderBorderColor: 'rgba(12,98,247, 1)',
            lossBackgroundColor: 'transparent',
            sellOrderBorderColor: '#C93B3B',
            buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
            inProfitBackgroundColor: 'transparent',
            buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
            sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
            sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
          },
          title: 'ORDERS',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          columnIndex: 0,
          tableViewName: 'ORDERS',
        },
        {
          name: 'buyOrders',
          type: 'string',
          index: 1,
          style: {
            color: '#D0D0D2',
            split: false,
            showPL: true,
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            lossColor: 'rgba(201, 59, 59, 1)',
            textAlign: 'center',
            textOverflow: false,
            buyOrderColor: '#fff',
            inProfitColor: 'rgba(12,98,247,1)',
            overlayOrders: true,
            highlightColor: 'rgba(29, 73, 127, 1)',
            sellOrderColor: '#fff',
            titleUpperCase: true,
            backgroundColor: 'rgba(12,98,247, 0.5)',
            includeRealizedPL: false,
            buyOrderBorderColor: 'rgba(12,98,247, 1)',
            lossBackgroundColor: 'transparent',
            sellOrderBorderColor: '#C93B3B',
            buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
            inProfitBackgroundColor: 'transparent',
            buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
            sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
            sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
            hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
          },
          title: 'BUY ORDERS',
          width: 100,
          hidden: true,
          canHide: true,
          visible: false,
          rowIndex: -1,
          columnIndex: 1,
          tableViewName: 'BUY ORDERS',
        },
        {
          name: 'sellOrders',
          type: 'string',
          index: 2,
          style: {
            color: '#D0D0D2',
            split: false,
            showPL: true,
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            lossColor: 'rgba(201, 59, 59, 1)',
            textAlign: 'center',
            textOverflow: false,
            buyOrderColor: '#fff',
            inProfitColor: 'rgba(12,98,247,1)',
            overlayOrders: true,
            highlightColor: 'rgba(29, 73, 127, 1)',
            sellOrderColor: '#fff',
            titleUpperCase: true,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            includeRealizedPL: false,
            buyOrderBorderColor: 'rgba(12,98,247, 1)',
            lossBackgroundColor: 'transparent',
            sellOrderBorderColor: '#C93B3B',
            buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
            inProfitBackgroundColor: 'transparent',
            buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
            sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
            sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
            hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
          },
          title: 'SELL ORDERS',
          width: 100,
          hidden: true,
          canHide: true,
          visible: false,
          rowIndex: -1,
          columnIndex: 2,
          tableViewName: 'SELL ORDERS',
        },
        {
          name: 'volume',
          type: 'histogram',
          index: 3,
          style: {
            ltq: false,
            poc: true,
            VWAP: true,
            color: '#fff',
            sessions: {
              eth: {
                id: 63,
                name: 'US ETH--RTH',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                workingTimes: [
                  {
                    endDay: 1,
                    endTime: 34200000,
                    startDay: 0,
                    startTime: 64800000,
                  },
                  {
                    endDay: 3,
                    endTime: 34200000,
                    startDay: 2,
                    startTime: 58500000,
                  },
                  {
                    endDay: 5,
                    endTime: 34200000,
                    startDay: 4,
                    startTime: 58500000,
                  },
                  {
                    endDay: 4,
                    endTime: 34200000,
                    startDay: 3,
                    startTime: 58500000,
                  },
                  {
                    endDay: 2,
                    endTime: 34200000,
                    startDay: 1,
                    startTime: 58500000,
                  },
                ],
              },
              rth: {
                id: 64,
                name: 'US RTH',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                workingTimes: [
                  {
                    endDay: 4,
                    endTime: 58500000,
                    startDay: 4,
                    startTime: 34200000,
                  },
                  {
                    endDay: 5,
                    endTime: 58500000,
                    startDay: 5,
                    startTime: 34200000,
                  },
                  {
                    endDay: 2,
                    endTime: 58500000,
                    startDay: 2,
                    startTime: 34200000,
                  },
                  {
                    endDay: 3,
                    endTime: 58500000,
                    startDay: 3,
                    startTime: 34200000,
                  },
                  {
                    endDay: 1,
                    endTime: 58500000,
                    startDay: 1,
                    startTime: 34200000,
                  },
                ],
              },
              histogramEnabled: false,
              overlayLineColor: '#fff',
            },
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            valueArea: true,
            textOverflow: false,
            histogramColor: 'rgba(73, 187, 169, 0.5)',
            titleUpperCase: true,
            backgroundColor: 'transparent',
            histogramEnabled: true,
            VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
            histogramOrientation: 'right',
            valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
            highlightBackgroundColor: '#9D0A0A',
            lastTradingBackgroundColor: '#fff',
            pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
          },
          title: 'VOLUME',
          width: 90,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 3,
          tableViewName: 'VOLUME',
        },
        {
          name: 'price',
          type: 'string',
          index: 4,
          style: {
            color: 'rgba(208, 208, 210, 1)',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            textOverflow: false,
            highlightColor: '#fff',
            titleUpperCase: true,
            backgroundColor: 'transparent',
            priceMarkerColor: 'black',
            tradedPriceColor: 'rgba(208, 208, 210, 1)',
            hoveredhighlightColor: '#fff',
            hoveredBackgroundColor: '#383A40',
            hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
            highlightBackgroundColor: '#383A40',
            priceMarkerBackgroundColor: '#FFDF6F',
            tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
            priceMarkerpriceMarkerColor: 'black',
            hoveredhighlightBackgroundColor: '#383A40',
            longPositionOpenBackgroundColor: '#0C62F7',
            shortPositionOpenBackgroundColor: '#C93B3BFF',
            hoveredtradedPriceBackgroundColor: '#383A40',
            priceMarkerpriceMarkerBackgroundColor: '#FFA500',
            hoveredlongPositionOpenBackgroundColor: '#383A40',
            hoveredshortPositionOpenBackgroundColor: '#383A40',
          },
          title: 'PRICE',
          width: 62,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 4,
          tableViewName: 'PRICE',
        },
        {
          name: 'delta',
          type: 'string',
          index: 5,
          style: {
            color: '#D0D0D2',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            askDeltaColor: '#ffffff',
            askDeltacolor: '#ffffff',
            bidDeltaColor: '#fff',
            bidDeltacolor: '#fff',
            titleUpperCase: true,
            askDeltaTextAlign: 'center',
            askDeltatextAlign: 'center',
            bidDeltaTextAlign: 'center',
            bidDeltatextAlign: 'center',
            askDeltaMinToVisible: 0,
            askDeltaminToVisible: 0,
            bidDeltaMinToVisible: 0,
            bidDeltaminToVisible: 0,
            askDeltaOverlayOrders: true,
            askDeltaoverlayOrders: true,
            bidDeltaBuyOrderColor: '#fff',
            bidDeltaOverlayOrders: true,
            bidDeltabuyOrderColor: '#fff',
            bidDeltaoverlayOrders: true,
            askDeltaSellOrderColor: '#fff',
            askDeltahighlightColor: '#ffffff',
            askDeltasellOrderColor: '#fff',
            bidDeltahighlightColor: '#fff',
            askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
            askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
            bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
            bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
            askDeltahighlightTextAlign: 'center',
            bidDeltahighlightTextAlign: 'center',
            askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
            askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
            bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
            bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
            bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
            bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
            askDeltaHighlightBackgroundColor: '#682a2d',
            askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
            askDeltahighlightBackgroundColor: '#682a2d',
            askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
            bidDeltaHighlightBackgroundColor: '#2b486e',
            bidDeltahighlightBackgroundColor: '#2b486e',
            askDeltaHoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
            askDeltahoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
            bidDeltaHoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
            bidDeltahoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
          },
          title: 'P/S',
          width: 58,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          columnIndex: 5,
          tableViewName: 'PULL/STACK',
        },
        {
          name: 'bidDelta',
          type: 'string',
          index: 6,
          style: {
            color: '#fff',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            minToVisible: 0,
            textOverflow: false,
            buyOrderColor: '#fff',
            overlayOrders: true,
            titleUpperCase: true,
            backgroundColor: 'rgba(12,98,247,0.2)',
            hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
            buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
            highlightBackgroundColor: '#2b486e',
            hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
          },
          title: 'P/S.BID',
          width: 68,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 6,
          tableViewName: 'PULL/STACK BID',
        },
        {
          name: 'bid',
          type: 'histogram',
          index: 7,
          style: {
            color: '#fff',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            largeSize: 14,
            textAlign: 'center',
            totalColor: '#fff',
            textOverflow: false,
            clearInterval: 9999,
            highlightLarge: false,
            histogramColor: 'rgba(72,149,245,0,3)',
            titleUpperCase: true,
            backgroundColor: 'rgba(12,98,247, 0.3)',
            histogramEnabled: true,
            histogramOrientation: 'left',
            hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
            highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
            hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
          },
          title: 'BID',
          width: 88,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 7,
          tableViewName: 'BID',
        },
        {
          name: 'ltq',
          type: 'string',
          index: 8,
          style: {
            color: '#fff',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            textOverflow: false,
            histogramColor: 'rgba(56, 58, 64, 0.5)',
            titleUpperCase: true,
            backgroundColor: '#012B36',
            accumulateTrades: true,
            buyBackgroundColor: '#0C62F7',
            sellBackgroundColor: 'rgba(201, 59, 59, 1)',
            highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
          },
          title: 'LTQ',
          width: 49,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 8,
          tableViewName: 'LTQ',
        },
        {
          name: 'currentBid',
          type: 'histogram',
          index: 9,
          style: {
            color: '#EB5A5A',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            clearOnBest: true,
            textOverflow: false,
            clearInterval: 9999,
            levelInterval: 500,
            momentumTails: true,
            histogramColor: 'rgba(201, 59, 59, 0.4)',
            tailInsideBold: false,
            tailInsideFont: '700 12px "Open Sans", sans-serif',
            titleUpperCase: true,
            backgroundColor: 'transparent',
            tailInsideColor: 'rgba(255, 255, 255, 1)',
            histogramEnabled: false,
            clearTradersTimer: 9999,
            tailInsidelevel1Bold: false,
            tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel2Bold: false,
            tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel3Bold: false,
            tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel4Bold: false,
            tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel5Bold: false,
            tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel6Bold: false,
            tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel7Bold: false,
            tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel8Bold: false,
            tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel9Bold: false,
            tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
            insideBackgroundColor: 'rgba(0, 0, 0, 1)',
            level1BackgroundColor: 'rgba(128, 64, 64, 1)',
            level2BackgroundColor: 'rgba(112, 61, 63, 1)',
            level3BackgroundColor: 'rgba(96, 59, 62, 1)',
            level4BackgroundColor: 'rgba(80, 56, 60, 1)',
            level5BackgroundColor: 'rgba(64, 54, 59, 1)',
            level6BackgroundColor: 'rgba(48, 51, 58, 1)',
            level7BackgroundColor: 'rgba(32, 48, 57, 1)',
            level8BackgroundColor: 'rgba(16, 46, 55, 1)',
            tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
            highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
            tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
            tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
            tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
            tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
            tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
            tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
            tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
            tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
          },
          title: 'I.BID',
          width: 50,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 9,
          tableViewName: 'INSIDE@BID',
        },
        {
          name: 'currentAsk',
          type: 'histogram',
          index: 10,
          style: {
            color: 'rgba(12,98,247,1)',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            clearOnBest: true,
            textOverflow: false,
            clearInterval: 9999,
            levelInterval: 500,
            momentumTails: true,
            histogramColor: 'rgba(4, 63, 128, 1)',
            tailInsideBold: false,
            tailInsideFont: '700 12px "Open Sans", sans-serif',
            titleUpperCase: true,
            backgroundColor: 'transparent',
            tailInsideColor: 'rgba(255, 255, 255, 1)',
            histogramEnabled: false,
            clearTradersTimer: 9999,
            tailInsidelevel1Bold: false,
            tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel2Bold: false,
            tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel3Bold: false,
            tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel4Bold: false,
            tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel5Bold: false,
            tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel6Bold: false,
            tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel7Bold: false,
            tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel8Bold: false,
            tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
            tailInsidelevel9Bold: false,
            tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
            insideBackgroundColor: 'rgba(0, 0, 0, 1)',
            level1BackgroundColor: 'rgba(4, 63, 128, 1)',
            level2BackgroundColor: 'rgba(3, 60, 119, 1)',
            level3BackgroundColor: 'rgba(3, 59, 110, 1)',
            level4BackgroundColor: 'rgba(2, 56, 100, 1)',
            level5BackgroundColor: 'rgba(2, 54, 91, 1)',
            level6BackgroundColor: 'rgba(2, 51, 82, 1)',
            level7BackgroundColor: 'rgba(1, 48, 73, 1)',
            level8BackgroundColor: 'rgba(1, 46, 63, 1)',
            tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
            tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
            highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
            tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
            tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
            tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
            tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
            tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
            tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
            tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
            tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
          },
          title: 'I.ASK',
          width: 50,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 10,
          tableViewName: 'INSIDE@ASK',
        },
        {
          name: 'ask',
          type: 'histogram',
          index: 11,
          style: {
            color: '#fff',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            largeSize: 14,
            textAlign: 'center',
            totalColor: '#fff',
            textOverflow: false,
            clearInterval: 9999,
            highlightLarge: false,
            histogramColor: 'rgba(201, 59, 59, 0.4)',
            titleUpperCase: true,
            backgroundColor: 'rgba(201, 59, 59, 0.3)',
            histogramEnabled: true,
            histogramOrientation: 'left',
            hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
            highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
            hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
          },
          title: 'ASK',
          width: 88,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 11,
          tableViewName: 'ASK',
        },
        {
          name: 'askDelta',
          type: 'string',
          index: 12,
          style: {
            color: '#ffffff',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'center',
            minToVisible: 0,
            textOverflow: false,
            overlayOrders: true,
            sellOrderColor: '#fff',
            titleUpperCase: true,
            backgroundColor: 'rgba(201, 59, 59, 0.2)',
            hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
            highlightBackgroundColor: '#682a2d',
            sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
            hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
          },
          title: 'P/S.ASK',
          width: 68,
          hidden: false,
          canHide: true,
          visible: true,
          rowIndex: -1,
          columnIndex: 12,
          tableViewName: 'PULL/STACK ASK',
        },
        {
          name: 'totalBid',
          type: 'histogram',
          index: 13,
          style: {
            color: 'rgba(235, 90, 90, 1)',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'left',
            textOverflow: false,
            histogramColor: 'rgba(201, 59, 59, 0.3)',
            titleUpperCase: true,
            backgroundColor: 'transparent',
            histogramEnabled: true,
            histogramOrientation: 'right',
            highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
          },
          title: 'T.BID',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          columnIndex: 13,
          tableViewName: 'TOTAL@BID',
        },
        {
          name: 'totalAsk',
          type: 'histogram',
          index: 14,
          style: {
            color: 'rgba(12,98,247, 1)',
            histogram: {
              color: '#0C62F7',
              enabled: true,
              orientation: 'left',
            },
            textAlign: 'right',
            textOverflow: false,
            histogramColor: 'rgba(12,98,247, 0.3)',
            titleUpperCase: true,
            backgroundColor: 'transparent',
            histogramEnabled: true,
            histogramOrientation: 'left',
            highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
          },
          title: 'T.ASK',
          width: 100,
          hidden: false,
          canHide: true,
          visible: false,
          rowIndex: -1,
          columnIndex: 14,
          tableViewName: 'TOTAL@ASK',
        },
      ],
      settings: {
        ask: {
          color: '#fff',
          largeSize: 14,
          textAlign: 'center',
          totalColor: '#fff',
          clearInterval: 9999,
          highlightLarge: false,
          histogramColor: 'rgba(201, 59, 59, 0.4)',
          backgroundColor: 'rgba(201, 59, 59, 0.3)',
          histogramEnabled: true,
          histogramOrientation: 'left',
          hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
          highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
          hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
        },
        bid: {
          color: '#fff',
          largeSize: 14,
          textAlign: 'center',
          totalColor: '#fff',
          clearInterval: 9999,
          highlightLarge: false,
          histogramColor: 'rgba(72,149,245,0,3)',
          backgroundColor: 'rgba(12,98,247, 0.3)',
          histogramEnabled: true,
          histogramOrientation: 'left',
          hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
          highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
          hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
        },
        ltq: {
          color: '#fff',
          textAlign: 'center',
          histogramColor: 'rgba(56, 58, 64, 0.5)',
          backgroundColor: '#012B36',
          accumulateTrades: true,
          buyBackgroundColor: '#0C62F7',
          sellBackgroundColor: 'rgba(201, 59, 59, 1)',
          highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
        },
        note: {
          color: '#fff',
          backgroundColor: 'transparent',
          addedOrdersColor: 'rgba(53, 104, 147, 1)',
          pulledOrdersColor: 'rgba(143, 60, 65, 1)',
        },
        delta: {
          askDeltaColor: '#ffffff',
          askDeltacolor: '#ffffff',
          bidDeltaColor: '#fff',
          bidDeltacolor: '#fff',
          askDeltaTextAlign: 'center',
          askDeltatextAlign: 'center',
          bidDeltaTextAlign: 'center',
          bidDeltatextAlign: 'center',
          askDeltaMinToVisible: 0,
          askDeltaminToVisible: 0,
          bidDeltaMinToVisible: 0,
          bidDeltaminToVisible: 0,
          askDeltaOverlayOrders: true,
          askDeltaoverlayOrders: true,
          bidDeltaBuyOrderColor: '#fff',
          bidDeltaOverlayOrders: true,
          bidDeltabuyOrderColor: '#fff',
          bidDeltaoverlayOrders: true,
          askDeltaSellOrderColor: '#fff',
          askDeltahighlightColor: '#ffffff',
          askDeltasellOrderColor: '#fff',
          bidDeltahighlightColor: '#fff',
          askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
          askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
          bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
          bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
          askDeltahighlightTextAlign: 'center',
          bidDeltahighlightTextAlign: 'center',
          askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
          askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
          bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
          bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
          bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
          bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
          askDeltaHighlightBackgroundColor: '#682a2d',
          askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
          askDeltahighlightBackgroundColor: '#682a2d',
          askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
          bidDeltaHighlightBackgroundColor: '#2b486e',
          bidDeltahighlightBackgroundColor: '#2b486e',
          askDeltaHoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
          askDeltahoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
          bidDeltaHoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
          bidDeltahoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
        },
        price: {
          color: 'rgba(208, 208, 210, 1)',
          textAlign: 'center',
          highlightColor: '#fff',
          backgroundColor: 'transparent',
          priceMarkerColor: 'black',
          tradedPriceColor: 'rgba(208, 208, 210, 1)',
          hoveredhighlightColor: '#fff',
          hoveredBackgroundColor: '#383A40',
          hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
          highlightBackgroundColor: '#383A40',
          priceMarkerBackgroundColor: '#FFDF6F',
          tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
          priceMarkerpriceMarkerColor: 'black',
          hoveredhighlightBackgroundColor: '#383A40',
          longPositionOpenBackgroundColor: '#0C62F7',
          shortPositionOpenBackgroundColor: '#C93B3BFF',
          hoveredtradedPriceBackgroundColor: '#383A40',
          priceMarkerpriceMarkerBackgroundColor: '#FFA500',
          hoveredlongPositionOpenBackgroundColor: '#383A40',
          hoveredshortPositionOpenBackgroundColor: '#383A40',
        },
        common: {
          ask: true,
          bid: true,
          ltq: true,
          delta: false,
          notes: true,
          price: true,
          orders: false,
          volume: true,
          askDelta: true,
          bidDelta: true,
          fontSize: 12,
          totalAsk: false,
          totalBid: false,
          buyOrders: false,
          rowHeight: 15,
          currentAsk: true,
          currentBid: true,
          fontFamily: '"Open Sans", sans-serif',
          fontWeight: '',
          sellOrders: false,
          generalColors: {
            gridLineColor: '#24262C',
            centerLineColor: '#A1A2A5',
            orderGridLineColor: 'rgba(88, 110, 117, 1)',
            enableOrderGridColor: true,
          },
        },
        orders: {
          split: false,
          showPL: true,
          lossColor: 'rgba(201, 59, 59, 1)',
          textAlign: 'center',
          buyOrderColor: '#fff',
          inProfitColor: 'rgba(12,98,247,1)',
          overlayOrders: true,
          highlightColor: 'rgba(29, 73, 127, 1)',
          sellOrderColor: '#fff',
          backgroundColor: 'transparent',
          includeRealizedPL: false,
          buyOrderBorderColor: 'rgba(12,98,247, 1)',
          lossBackgroundColor: 'transparent',
          sellOrderBorderColor: '#C93B3B',
          buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
          inProfitBackgroundColor: 'transparent',
          buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
          sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
          sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
        },
        volume: {
          ltq: false,
          poc: true,
          VWAP: true,
          color: '#fff',
          sessions: {
            eth: {
              id: 63,
              name: 'US ETH--RTH',
              exchange: 'CME',
              timezoneId: 'America/New_York',
              workingTimes: [
                {
                  endDay: 1,
                  endTime: 34200000,
                  startDay: 0,
                  startTime: 64800000,
                },
                {
                  endDay: 3,
                  endTime: 34200000,
                  startDay: 2,
                  startTime: 58500000,
                },
                {
                  endDay: 5,
                  endTime: 34200000,
                  startDay: 4,
                  startTime: 58500000,
                },
                {
                  endDay: 4,
                  endTime: 34200000,
                  startDay: 3,
                  startTime: 58500000,
                },
                {
                  endDay: 2,
                  endTime: 34200000,
                  startDay: 1,
                  startTime: 58500000,
                },
              ],
            },
            rth: {
              id: 64,
              name: 'US RTH',
              exchange: 'CME',
              timezoneId: 'America/New_York',
              workingTimes: [
                {
                  endDay: 4,
                  endTime: 58500000,
                  startDay: 4,
                  startTime: 34200000,
                },
                {
                  endDay: 5,
                  endTime: 58500000,
                  startDay: 5,
                  startTime: 34200000,
                },
                {
                  endDay: 2,
                  endTime: 58500000,
                  startDay: 2,
                  startTime: 34200000,
                },
                {
                  endDay: 3,
                  endTime: 58500000,
                  startDay: 3,
                  startTime: 34200000,
                },
                {
                  endDay: 1,
                  endTime: 58500000,
                  startDay: 1,
                  startTime: 34200000,
                },
              ],
            },
            histogramEnabled: false,
            overlayLineColor: '#fff',
          },
          textAlign: 'right',
          valueArea: true,
          histogramColor: 'rgba(73, 187, 169, 0.5)',
          backgroundColor: 'transparent',
          histogramEnabled: true,
          VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
          histogramOrientation: 'right',
          valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
          highlightBackgroundColor: '#9D0A0A',
          lastTradingBackgroundColor: '#fff',
          pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
        },
        general: {
          recenter: true,
          intervals: {
            momentumTails: true,
            updateInterval: 100,
            clearTradersTimer: 9999,
            orderQuantityStep: 7,
            momentumIntervalMs: 500,
            resetDeltaInterval: '5000',
            scrollWheelSensitivity: 1,
          },
          commonView: {
            autoCenter: true,
            centerLine: true,
            autoCenterTicks: 20,
          },
          marketDepth: {
            marketDepth: 40,
            bidAskDeltaDepth: 9,
            bidAskDeltaFilter: 0,
          },
          digitsToHide: 4,
          hideFromLeft: false,
          hideFromRight: false,
          hideAccountName: false,
          clearTotalPrints: false,
          closeOutstandingOrders: false,
          clearInsidePrints: false,
          currentTotalAllWindows: false,
          currentTradesAllWindows: false,
          recenterTotalAllWindows: false,
        },
        hotkeys: {
          shiftToStopOrder: ShiftToStopOrderEnum.shiftAndLeftClick,
          oco: null,
          hitBid: null,
          flatten: null,
          joinAsk: null,
          joinBid: null,
          buyMarket: null,
          liftOffer: null,
          quantity1: null,
          quantity2: null,
          quantity3: null,
          quantity4: null,
          quantity5: null,
          autoCenter: null,
          sellMarket: null,
          clearAlerts: null,
          stopsToPrice: null,
          limitsToPrice: null,
          quantityToPos: null,
          clearAllTotals: null,
          cancelAllOrders: null,
          clearTotalPrintsUp: null,
          clearVolumeProfile: null,
          clearInsidePrintsUp: null,
          autoCenterAllWindows: null,
          clearAlertsAllWindow: null,
          clearAllPriceMarkers: null,
          clearTotalPrintsDown: null,
          clearInsidePrintsDown: null,
          clearInsidePrintsAllWindows: null,
          clearTotalPrintsUpAllWindows: null,
          clearInsidePrintsUpAllWindows: null,
          clearTotalPrintsDownAllWindows: null,
          clearInsidePrintsDownAllWindows: null,
        },
        trading: {
          tif: {
            DAY: true,
            GTC: true,
            default: 'DAY',
          },
          trading: {
            split: false,
            overlayOrders: true,
            showOrderConfirm: false,
            showCancelConfirm: false,
          },
          formData: {
            quantity: 1,
          },
          orderArea: {
            roundPL: false,
            settings: {
              flatten: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              cancelButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              icebergButton: {
                font: '#fff',
                enabled: false,
                background: '#51535A',
              },
              buyMarketButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#0C62F7',
              },
              sellMarketButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#C93B3B',
              },
              showLiquidateButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
            },
            showPLInfo: true,
            showOHLVInfo: true,
            includeRealizedPL: false,
            showInstrumentChange: true,
          },
          ordersColors: {
            buy: {
              stop: {
                length: 1,
                lineType: 'solid',
                lineColor: '#33537C',
              },
              limit: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#0C62F7',
              },
              market: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#0C62F7',
              },
              stopLimit: {
                length: 1,
                lineType: 'dotted',
                lineColor: '#33537C',
              },
            },
            sell: {
              stop: {
                length: 1,
                lineType: 'solid',
                lineColor: '#C93B3B',
              },
              limit: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#FF0000',
              },
              market: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#FF0000',
              },
              stopLimit: {
                length: 1,
                lineType: 'dotted',
                lineColor: '#C93B3B',
              },
            },
            ocoStopLimit: '#FFFF00',
            ocoStopOrder: '#FFFF00',
          },
          amountButtons: [
            {
              value: 1,
            },
            {
              black: true,
              value: 1,
            },
            {
              value: 3,
            },
            {
              value: 5,
            },
            {
              value: 10,
            },
            {
              value: 25,
            },
          ],
        },
        _columns: [
          {
            name: 'orders',
            type: 'string',
            index: 0,
            style: {
              color: '#D0D0D2',
              split: false,
              showPL: true,
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              textOverflow: false,
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              titleUpperCase: true,
              backgroundColor: 'transparent',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            title: 'ORDERS',
            width: 100,
            hidden: false,
            canHide: true,
            visible: false,
            rowIndex: -1,
            columnIndex: 0,
            tableViewName: 'ORDERS',
          },
          {
            name: 'buyOrders',
            type: 'string',
            index: 1,
            style: {
              color: '#D0D0D2',
              split: false,
              showPL: true,
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              textOverflow: false,
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              titleUpperCase: true,
              backgroundColor: 'rgba(12,98,247, 0.5)',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
              hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            title: 'BUY ORDERS',
            width: 100,
            hidden: true,
            canHide: true,
            visible: false,
            rowIndex: -1,
            columnIndex: 1,
            tableViewName: 'BUY ORDERS',
          },
          {
            name: 'sellOrders',
            type: 'string',
            index: 2,
            style: {
              color: '#D0D0D2',
              split: false,
              showPL: true,
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              textOverflow: false,
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              titleUpperCase: true,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
              hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
            title: 'SELL ORDERS',
            width: 100,
            hidden: true,
            canHide: true,
            visible: false,
            rowIndex: -1,
            columnIndex: 2,
            tableViewName: 'SELL ORDERS',
          },
          {
            name: 'volume',
            type: 'histogram',
            index: 3,
            style: {
              ltq: false,
              poc: true,
              VWAP: true,
              color: '#fff',
              sessions: {
                eth: {
                  id: 63,
                  name: 'US ETH--RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  workingTimes: [
                    {
                      endDay: 1,
                      endTime: 34200000,
                      startDay: 0,
                      startTime: 64800000,
                    },
                    {
                      endDay: 3,
                      endTime: 34200000,
                      startDay: 2,
                      startTime: 58500000,
                    },
                    {
                      endDay: 5,
                      endTime: 34200000,
                      startDay: 4,
                      startTime: 58500000,
                    },
                    {
                      endDay: 4,
                      endTime: 34200000,
                      startDay: 3,
                      startTime: 58500000,
                    },
                    {
                      endDay: 2,
                      endTime: 34200000,
                      startDay: 1,
                      startTime: 58500000,
                    },
                  ],
                },
                rth: {
                  id: 64,
                  name: 'US RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  workingTimes: [
                    {
                      endDay: 4,
                      endTime: 58500000,
                      startDay: 4,
                      startTime: 34200000,
                    },
                    {
                      endDay: 5,
                      endTime: 58500000,
                      startDay: 5,
                      startTime: 34200000,
                    },
                    {
                      endDay: 2,
                      endTime: 58500000,
                      startDay: 2,
                      startTime: 34200000,
                    },
                    {
                      endDay: 3,
                      endTime: 58500000,
                      startDay: 3,
                      startTime: 34200000,
                    },
                    {
                      endDay: 1,
                      endTime: 58500000,
                      startDay: 1,
                      startTime: 34200000,
                    },
                  ],
                },
                histogramEnabled: false,
                overlayLineColor: '#fff',
              },
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'right',
              valueArea: true,
              textOverflow: false,
              histogramColor: 'rgba(73, 187, 169, 0.5)',
              titleUpperCase: true,
              backgroundColor: 'transparent',
              histogramEnabled: true,
              VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
              histogramOrientation: 'right',
              valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
              highlightBackgroundColor: '#9D0A0A',
              lastTradingBackgroundColor: '#fff',
              pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
            },
            title: 'VOLUME',
            width: 90,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 3,
            tableViewName: 'VOLUME',
          },
          {
            name: 'price',
            type: 'string',
            index: 4,
            style: {
              color: 'rgba(208, 208, 210, 1)',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'center',
              textOverflow: false,
              highlightColor: '#fff',
              titleUpperCase: true,
              backgroundColor: 'transparent',
              priceMarkerColor: 'black',
              tradedPriceColor: 'rgba(208, 208, 210, 1)',
              hoveredhighlightColor: '#fff',
              hoveredBackgroundColor: '#383A40',
              hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
              highlightBackgroundColor: '#383A40',
              priceMarkerBackgroundColor: '#FFDF6F',
              tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
              priceMarkerpriceMarkerColor: 'black',
              hoveredhighlightBackgroundColor: '#383A40',
              longPositionOpenBackgroundColor: '#0C62F7',
              shortPositionOpenBackgroundColor: '#C93B3BFF',
              hoveredtradedPriceBackgroundColor: '#383A40',
              priceMarkerpriceMarkerBackgroundColor: '#FFA500',
              hoveredlongPositionOpenBackgroundColor: '#383A40',
              hoveredshortPositionOpenBackgroundColor: '#383A40',
            },
            title: 'PRICE',
            width: 62,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 4,
            tableViewName: 'PRICE',
          },
          {
            name: 'delta',
            type: 'string',
            index: 5,
            style: {
              color: '#D0D0D2',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'left',
              textOverflow: false,
              askDeltaColor: '#ffffff',
              askDeltacolor: '#ffffff',
              bidDeltaColor: '#fff',
              bidDeltacolor: '#fff',
              titleUpperCase: true,
              askDeltaTextAlign: 'center',
              askDeltatextAlign: 'center',
              bidDeltaTextAlign: 'center',
              bidDeltatextAlign: 'center',
              askDeltaMinToVisible: 0,
              askDeltaminToVisible: 0,
              bidDeltaMinToVisible: 0,
              bidDeltaminToVisible: 0,
              askDeltaOverlayOrders: true,
              askDeltaoverlayOrders: true,
              bidDeltaBuyOrderColor: '#fff',
              bidDeltaOverlayOrders: true,
              bidDeltabuyOrderColor: '#fff',
              bidDeltaoverlayOrders: true,
              askDeltaSellOrderColor: '#fff',
              askDeltahighlightColor: '#ffffff',
              askDeltasellOrderColor: '#fff',
              bidDeltahighlightColor: '#fff',
              askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
              askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
              bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
              bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
              askDeltahighlightTextAlign: 'center',
              bidDeltahighlightTextAlign: 'center',
              askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              askDeltaHighlightBackgroundColor: '#682a2d',
              askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              askDeltahighlightBackgroundColor: '#682a2d',
              askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              bidDeltaHighlightBackgroundColor: '#2b486e',
              bidDeltahighlightBackgroundColor: '#2b486e',
              askDeltaHoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
              askDeltahoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
              bidDeltaHoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltahoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            title: 'P/S',
            width: 58,
            hidden: false,
            canHide: true,
            visible: false,
            rowIndex: -1,
            columnIndex: 5,
            tableViewName: 'PULL/STACK',
          },
          {
            name: 'bidDelta',
            type: 'string',
            index: 6,
            style: {
              color: '#fff',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'center',
              minToVisible: 0,
              textOverflow: false,
              buyOrderColor: '#fff',
              overlayOrders: true,
              titleUpperCase: true,
              backgroundColor: 'rgba(12,98,247,0.2)',
              hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              highlightBackgroundColor: '#2b486e',
              hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            title: 'P/S.BID',
            width: 68,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 6,
            tableViewName: 'PULL/STACK BID',
          },
          {
            name: 'bid',
            type: 'histogram',
            index: 7,
            style: {
              color: '#fff',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              largeSize: 14,
              textAlign: 'center',
              totalColor: '#fff',
              textOverflow: false,
              clearInterval: 9999,
              highlightLarge: false,
              histogramColor: 'rgba(72,149,245,0,3)',
              titleUpperCase: true,
              backgroundColor: 'rgba(12,98,247, 0.3)',
              histogramEnabled: true,
              histogramOrientation: 'left',
              hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
              hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            title: 'BID',
            width: 88,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 7,
            tableViewName: 'BID',
          },
          {
            name: 'ltq',
            type: 'string',
            index: 8,
            style: {
              color: '#fff',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'center',
              textOverflow: false,
              histogramColor: 'rgba(56, 58, 64, 0.5)',
              titleUpperCase: true,
              backgroundColor: '#012B36',
              accumulateTrades: true,
              buyBackgroundColor: '#0C62F7',
              sellBackgroundColor: 'rgba(201, 59, 59, 1)',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            title: 'LTQ',
            width: 49,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 8,
            tableViewName: 'LTQ',
          },
          {
            name: 'currentBid',
            type: 'histogram',
            index: 9,
            style: {
              color: '#EB5A5A',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'center',
              clearOnBest: true,
              textOverflow: false,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              tailInsideBold: false,
              tailInsideFont: '700 12px "Open Sans", sans-serif',
              titleUpperCase: true,
              backgroundColor: 'transparent',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              histogramEnabled: false,
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              level1BackgroundColor: 'rgba(128, 64, 64, 1)',
              level2BackgroundColor: 'rgba(112, 61, 63, 1)',
              level3BackgroundColor: 'rgba(96, 59, 62, 1)',
              level4BackgroundColor: 'rgba(80, 56, 60, 1)',
              level5BackgroundColor: 'rgba(64, 54, 59, 1)',
              level6BackgroundColor: 'rgba(48, 51, 58, 1)',
              level7BackgroundColor: 'rgba(32, 48, 57, 1)',
              level8BackgroundColor: 'rgba(16, 46, 55, 1)',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
              tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
            },
            title: 'I.BID',
            width: 50,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 9,
            tableViewName: 'INSIDE@BID',
          },
          {
            name: 'currentAsk',
            type: 'histogram',
            index: 10,
            style: {
              color: 'rgba(12,98,247,1)',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'center',
              clearOnBest: true,
              textOverflow: false,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              histogramColor: 'rgba(4, 63, 128, 1)',
              tailInsideBold: false,
              tailInsideFont: '700 12px "Open Sans", sans-serif',
              titleUpperCase: true,
              backgroundColor: 'transparent',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              histogramEnabled: false,
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              level1BackgroundColor: 'rgba(4, 63, 128, 1)',
              level2BackgroundColor: 'rgba(3, 60, 119, 1)',
              level3BackgroundColor: 'rgba(3, 59, 110, 1)',
              level4BackgroundColor: 'rgba(2, 56, 100, 1)',
              level5BackgroundColor: 'rgba(2, 54, 91, 1)',
              level6BackgroundColor: 'rgba(2, 51, 82, 1)',
              level7BackgroundColor: 'rgba(1, 48, 73, 1)',
              level8BackgroundColor: 'rgba(1, 46, 63, 1)',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
              tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
            },
            title: 'I.ASK',
            width: 50,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 10,
            tableViewName: 'INSIDE@ASK',
          },
          {
            name: 'ask',
            type: 'histogram',
            index: 11,
            style: {
              color: '#fff',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              largeSize: 14,
              textAlign: 'center',
              totalColor: '#fff',
              textOverflow: false,
              clearInterval: 9999,
              highlightLarge: false,
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              titleUpperCase: true,
              backgroundColor: 'rgba(201, 59, 59, 0.3)',
              histogramEnabled: true,
              histogramOrientation: 'left',
              hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
              hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
            title: 'ASK',
            width: 88,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 11,
            tableViewName: 'ASK',
          },
          {
            name: 'askDelta',
            type: 'string',
            index: 12,
            style: {
              color: '#ffffff',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'center',
              minToVisible: 0,
              textOverflow: false,
              overlayOrders: true,
              sellOrderColor: '#fff',
              titleUpperCase: true,
              backgroundColor: 'rgba(201, 59, 59, 0.2)',
              hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              highlightBackgroundColor: '#682a2d',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
            title: 'P/S.ASK',
            width: 68,
            hidden: false,
            canHide: true,
            visible: true,
            rowIndex: -1,
            columnIndex: 12,
            tableViewName: 'PULL/STACK ASK',
          },
          {
            name: 'totalBid',
            type: 'histogram',
            index: 13,
            style: {
              color: 'rgba(235, 90, 90, 1)',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'left',
              textOverflow: false,
              histogramColor: 'rgba(201, 59, 59, 0.3)',
              titleUpperCase: true,
              backgroundColor: 'transparent',
              histogramEnabled: true,
              histogramOrientation: 'right',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            title: 'T.BID',
            width: 100,
            hidden: false,
            canHide: true,
            visible: false,
            rowIndex: -1,
            columnIndex: 13,
            tableViewName: 'TOTAL@BID',
          },
          {
            name: 'totalAsk',
            type: 'histogram',
            index: 14,
            style: {
              color: 'rgba(12,98,247, 1)',
              histogram: {
                color: '#0C62F7',
                enabled: true,
                orientation: 'left',
              },
              textAlign: 'right',
              textOverflow: false,
              histogramColor: 'rgba(12,98,247, 0.3)',
              titleUpperCase: true,
              backgroundColor: 'transparent',
              histogramEnabled: true,
              histogramOrientation: 'left',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            title: 'T.ASK',
            width: 100,
            hidden: false,
            canHide: true,
            visible: false,
            rowIndex: -1,
            columnIndex: 14,
            tableViewName: 'TOTAL@ASK',
          },
        ],
        askDelta: {
          color: '#ffffff',
          textAlign: 'center',
          minToVisible: 0,
          overlayOrders: true,
          sellOrderColor: '#fff',
          backgroundColor: 'rgba(201, 59, 59, 0.2)',
          hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
          highlightBackgroundColor: '#682a2d',
          sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
          hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
        },
        bidDelta: {
          color: '#fff',
          textAlign: 'center',
          minToVisible: 0,
          buyOrderColor: '#fff',
          overlayOrders: true,
          backgroundColor: 'rgba(12,98,247,0.2)',
          hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
          buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
          highlightBackgroundColor: '#2b486e',
          hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
        },
        totalAsk: {
          color: 'rgba(12,98,247, 1)',
          textAlign: 'right',
          histogramColor: 'rgba(12,98,247, 0.3)',
          backgroundColor: 'transparent',
          histogramEnabled: true,
          histogramOrientation: 'left',
          highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
        },
        totalBid: {
          color: 'rgba(235, 90, 90, 1)',
          textAlign: 'left',
          histogramColor: 'rgba(201, 59, 59, 0.3)',
          backgroundColor: 'transparent',
          histogramEnabled: true,
          histogramOrientation: 'right',
          highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
        },
        buyOrders: {
          split: false,
          showPL: true,
          lossColor: 'rgba(201, 59, 59, 1)',
          textAlign: 'center',
          buyOrderColor: '#fff',
          inProfitColor: 'rgba(12,98,247,1)',
          overlayOrders: true,
          highlightColor: 'rgba(29, 73, 127, 1)',
          sellOrderColor: '#fff',
          backgroundColor: 'rgba(12,98,247, 0.5)',
          includeRealizedPL: false,
          buyOrderBorderColor: 'rgba(12,98,247, 1)',
          lossBackgroundColor: 'transparent',
          sellOrderBorderColor: '#C93B3B',
          buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
          inProfitBackgroundColor: 'transparent',
          buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
          sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
          sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
          hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
          hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
        },
        currentAsk: {
          color: 'rgba(12,98,247,1)',
          textAlign: 'center',
          clearOnBest: true,
          clearInterval: 9999,
          levelInterval: 500,
          momentumTails: true,
          histogramColor: 'rgba(4, 63, 128, 1)',
          tailInsideBold: false,
          tailInsideFont: '700 12px "Open Sans", sans-serif',
          backgroundColor: 'transparent',
          tailInsideColor: 'rgba(255, 255, 255, 1)',
          histogramEnabled: false,
          clearTradersTimer: 9999,
          tailInsidelevel1Bold: false,
          tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel2Bold: false,
          tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel3Bold: false,
          tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel4Bold: false,
          tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel5Bold: false,
          tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel6Bold: false,
          tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel7Bold: false,
          tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel8Bold: false,
          tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel9Bold: false,
          tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
          insideBackgroundColor: 'rgba(0, 0, 0, 1)',
          level1BackgroundColor: 'rgba(4, 63, 128, 1)',
          level2BackgroundColor: 'rgba(3, 60, 119, 1)',
          level3BackgroundColor: 'rgba(3, 59, 110, 1)',
          level4BackgroundColor: 'rgba(2, 56, 100, 1)',
          level5BackgroundColor: 'rgba(2, 54, 91, 1)',
          level6BackgroundColor: 'rgba(2, 51, 82, 1)',
          level7BackgroundColor: 'rgba(1, 48, 73, 1)',
          level8BackgroundColor: 'rgba(1, 46, 63, 1)',
          tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
          highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
          tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
          tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
          tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
          tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
          tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
          tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
          tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
          tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
        },
        currentBid: {
          color: '#EB5A5A',
          textAlign: 'center',
          clearOnBest: true,
          clearInterval: 9999,
          levelInterval: 500,
          momentumTails: true,
          histogramColor: 'rgba(201, 59, 59, 0.4)',
          tailInsideBold: false,
          tailInsideFont: '700 12px "Open Sans", sans-serif',
          backgroundColor: 'transparent',
          tailInsideColor: 'rgba(255, 255, 255, 1)',
          histogramEnabled: false,
          clearTradersTimer: 9999,
          tailInsidelevel1Bold: false,
          tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel2Bold: false,
          tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel3Bold: false,
          tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel4Bold: false,
          tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel5Bold: false,
          tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel6Bold: false,
          tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel7Bold: false,
          tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel8Bold: false,
          tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
          tailInsidelevel9Bold: false,
          tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
          insideBackgroundColor: 'rgba(0, 0, 0, 1)',
          level1BackgroundColor: 'rgba(128, 64, 64, 1)',
          level2BackgroundColor: 'rgba(112, 61, 63, 1)',
          level3BackgroundColor: 'rgba(96, 59, 62, 1)',
          level4BackgroundColor: 'rgba(80, 56, 60, 1)',
          level5BackgroundColor: 'rgba(64, 54, 59, 1)',
          level6BackgroundColor: 'rgba(48, 51, 58, 1)',
          level7BackgroundColor: 'rgba(32, 48, 57, 1)',
          level8BackgroundColor: 'rgba(16, 46, 55, 1)',
          tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
          tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
          highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
          tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
          tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
          tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
          tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
          tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
          tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
          tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
          tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
        },
        sellOrders: {
          split: false,
          showPL: true,
          lossColor: 'rgba(201, 59, 59, 1)',
          textAlign: 'center',
          buyOrderColor: '#fff',
          inProfitColor: 'rgba(12,98,247,1)',
          overlayOrders: true,
          highlightColor: 'rgba(29, 73, 127, 1)',
          sellOrderColor: '#fff',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          includeRealizedPL: false,
          buyOrderBorderColor: 'rgba(12,98,247, 1)',
          lossBackgroundColor: 'transparent',
          sellOrderBorderColor: '#C93B3B',
          buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
          inProfitBackgroundColor: 'transparent',
          buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
          sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
          sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
          hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
          hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
        },
      },
      orderForm: {
        formData: {
          quantity: 1,
          stopLoss: {
            type: 'StopMarket',
            unit: 'ticks',
            ticks: 12,
            amount: 1,
            stopLoss: true,
            unitItem: 12,
          },
          takeProfit: {
            unit: 'ticks',
            ticks: 12,
            unitItem: 12,
            takeProfit: true,
          },
        },
        settings: {
          tif: {
            DAY: true,
            GTC: true,
            default: 'DAY',
          },
          formSettings: {
            roundPL: false,
            showPLInfo: true,
            showOHLVInfo: true,
            showBuyButton: true,
            showSellButton: true,
            showCancelButton: true,
            showOrderConfirm: false,
            includeRealizedPL: false,
            showCancelConfirm: false,
            showFlattenButton: true,
            showIcebergButton: false,
            showLiquidateButton: true,
            showInstrumentChange: true,
          },
          amountButtons: [
            {
              value: 1,
            },
            {
              black: true,
              value: 1,
            },
            {
              value: 3,
            },
            {
              value: 5,
            },
            {
              value: 10,
            },
            {
              value: 25,
            },
          ],
          icebergFontColor: '#fff',
          buyButtonsFontColor: '#D0D0D2',
          flatButtonsFontColor: '#D0D0D2',
          sellButtonsFontColor: '#D0D0D2',
          cancelButtonFontColor: '#D0D0D2',
          closePositionFontColor: '#D0D0D2',
          icebergBackgroundColor: '#51535A',
          buyButtonsBackgroundColor: '#0C62F7',
          flatButtonsBackgroundColor: '#51535A',
          sellButtonsBackgroundColor: '#C93B3B',
          cancelButtonBackgroundColor: '#51535A',
          closePositionBackgroundColor: '#51535A',
        },
        amountButtons: [
          {
            value: 1,
          },
          {
            black: true,
            value: 1,
          },
          {
            value: 3,
          },
          {
            value: 5,
          },
          {
            value: 10,
          },
          {
            value: 25,
          },
        ],
      },
      instrument: {
        id: 'ESZ4.CME',
        type: 'Future',
        symbol: 'ESZ4',
        currency: 'USD',
        exchange: 'CME',
        tickSize: 0.25,
        precision: 2,
        expiration: '2024-12-20T00:00:00',
        description: 'E-Mini S&P 500 Dec24',
        productCode: 'ES',
        connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
        contractSize: 50,
        instrumentTimePeriod: 'Dec24',
        stringTypeRepresentation: 'Future',
      },
      priceMarkers: [
        '5796.00',
        '5736.50',
        '5785.00',
        '5781.00',
        '5770.00',
        '5770.25',
        '5933.00',
        '5917.25',
      ],
      isOrderFormOpen: true,
      contextMenuState: {
        showHeaderPanel: true,
        showColumnHeaders: true,
      },
      isTradingEnabled: false,
      preferredAccounts: {
        'c288af9a-d4c1-4e4a-af1d-b034fbca8776': 'APEX-41272-106',
        'd4135277-3f43-4d35-a646-8d7b2c7673b1': 'S1Nov208384304',
        'd428b3e6-bede-4e2a-bab6-325dcd1339e7': '42432ppr3',
        'e1c9560d-3714-4320-b114-34fd00c258c5': 'FTT-GT-8922',
      },
      orderFormDirection: 'window-right',
      componentInstanceId: 1690454474103,
    },
    tabState: {
      id: '1690454474127_0.5829419327601297',
      width: 715,
      height: 1121,
      visible: true,
      minWidth: 470,
      component: {
        name: 'dom',
        state: {
          width: 715,
          columns: [
            {
              name: 'orders',
              type: 'string',
              index: 0,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              },
              title: 'ORDERS',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 0,
              tableViewName: 'ORDERS',
            },
            {
              name: 'buyOrders',
              type: 'string',
              index: 1,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247, 0.5)',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
                hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
              },
              title: 'BUY ORDERS',
              width: 100,
              hidden: true,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 1,
              tableViewName: 'BUY ORDERS',
            },
            {
              name: 'sellOrders',
              type: 'string',
              index: 2,
              style: {
                color: '#D0D0D2',
                split: false,
                showPL: true,
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                lossColor: 'rgba(201, 59, 59, 1)',
                textAlign: 'center',
                textOverflow: false,
                buyOrderColor: '#fff',
                inProfitColor: 'rgba(12,98,247,1)',
                overlayOrders: true,
                highlightColor: 'rgba(29, 73, 127, 1)',
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                includeRealizedPL: false,
                buyOrderBorderColor: 'rgba(12,98,247, 1)',
                lossBackgroundColor: 'transparent',
                sellOrderBorderColor: '#C93B3B',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                inProfitBackgroundColor: 'transparent',
                buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
                hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
              },
              title: 'SELL ORDERS',
              width: 100,
              hidden: true,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 2,
              tableViewName: 'SELL ORDERS',
            },
            {
              name: 'volume',
              type: 'histogram',
              index: 3,
              style: {
                ltq: false,
                poc: true,
                VWAP: true,
                color: '#fff',
                sessions: {
                  eth: {
                    id: 63,
                    name: 'US ETH--RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    workingTimes: [
                      {
                        endDay: 1,
                        endTime: 34200000,
                        startDay: 0,
                        startTime: 64800000,
                      },
                      {
                        endDay: 3,
                        endTime: 34200000,
                        startDay: 2,
                        startTime: 58500000,
                      },
                      {
                        endDay: 5,
                        endTime: 34200000,
                        startDay: 4,
                        startTime: 58500000,
                      },
                      {
                        endDay: 4,
                        endTime: 34200000,
                        startDay: 3,
                        startTime: 58500000,
                      },
                      {
                        endDay: 2,
                        endTime: 34200000,
                        startDay: 1,
                        startTime: 58500000,
                      },
                    ],
                  },
                  rth: {
                    id: 64,
                    name: 'US RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    workingTimes: [
                      {
                        endDay: 4,
                        endTime: 58500000,
                        startDay: 4,
                        startTime: 34200000,
                      },
                      {
                        endDay: 5,
                        endTime: 58500000,
                        startDay: 5,
                        startTime: 34200000,
                      },
                      {
                        endDay: 2,
                        endTime: 58500000,
                        startDay: 2,
                        startTime: 34200000,
                      },
                      {
                        endDay: 3,
                        endTime: 58500000,
                        startDay: 3,
                        startTime: 34200000,
                      },
                      {
                        endDay: 1,
                        endTime: 58500000,
                        startDay: 1,
                        startTime: 34200000,
                      },
                    ],
                  },
                  histogramEnabled: false,
                  overlayLineColor: '#fff',
                },
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                valueArea: true,
                textOverflow: false,
                histogramColor: 'rgba(73, 187, 169, 0.5)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
                histogramOrientation: 'right',
                valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
                highlightBackgroundColor: '#9D0A0A',
                lastTradingBackgroundColor: '#fff',
                pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
              },
              title: 'VOLUME',
              width: 90,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 3,
              tableViewName: 'VOLUME',
            },
            {
              name: 'price',
              type: 'string',
              index: 4,
              style: {
                color: 'rgba(208, 208, 210, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                highlightColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                priceMarkerColor: 'black',
                tradedPriceColor: 'rgba(208, 208, 210, 1)',
                hoveredhighlightColor: '#fff',
                hoveredBackgroundColor: '#383A40',
                hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
                highlightBackgroundColor: '#383A40',
                priceMarkerBackgroundColor: '#FFDF6F',
                tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
                priceMarkerpriceMarkerColor: 'black',
                hoveredhighlightBackgroundColor: '#383A40',
                longPositionOpenBackgroundColor: '#0C62F7',
                shortPositionOpenBackgroundColor: '#C93B3BFF',
                hoveredtradedPriceBackgroundColor: '#383A40',
                priceMarkerpriceMarkerBackgroundColor: '#FFA500',
                hoveredlongPositionOpenBackgroundColor: '#383A40',
                hoveredshortPositionOpenBackgroundColor: '#383A40',
              },
              title: 'PRICE',
              width: 62,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 4,
              tableViewName: 'PRICE',
            },
            {
              name: 'delta',
              type: 'string',
              index: 5,
              style: {
                color: '#D0D0D2',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                askDeltaColor: '#ffffff',
                askDeltacolor: '#ffffff',
                bidDeltaColor: '#fff',
                bidDeltacolor: '#fff',
                titleUpperCase: true,
                askDeltaTextAlign: 'center',
                askDeltatextAlign: 'center',
                bidDeltaTextAlign: 'center',
                bidDeltatextAlign: 'center',
                askDeltaMinToVisible: 0,
                askDeltaminToVisible: 0,
                bidDeltaMinToVisible: 0,
                bidDeltaminToVisible: 0,
                askDeltaOverlayOrders: true,
                askDeltaoverlayOrders: true,
                bidDeltaBuyOrderColor: '#fff',
                bidDeltaOverlayOrders: true,
                bidDeltabuyOrderColor: '#fff',
                bidDeltaoverlayOrders: true,
                askDeltaSellOrderColor: '#fff',
                askDeltahighlightColor: '#ffffff',
                askDeltasellOrderColor: '#fff',
                bidDeltahighlightColor: '#fff',
                askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
                askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
                bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
                bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
                askDeltahighlightTextAlign: 'center',
                bidDeltahighlightTextAlign: 'center',
                askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                askDeltaHighlightBackgroundColor: '#682a2d',
                askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                askDeltahighlightBackgroundColor: '#682a2d',
                askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                bidDeltaHighlightBackgroundColor: '#2b486e',
                bidDeltahighlightBackgroundColor: '#2b486e',
                askDeltaHoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
                askDeltahoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
                bidDeltaHoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
                bidDeltahoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
              },
              title: 'P/S',
              width: 58,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 5,
              tableViewName: 'PULL/STACK',
            },
            {
              name: 'bidDelta',
              type: 'string',
              index: 6,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                minToVisible: 0,
                textOverflow: false,
                buyOrderColor: '#fff',
                overlayOrders: true,
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247,0.2)',
                hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                highlightBackgroundColor: '#2b486e',
                hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
              },
              title: 'P/S.BID',
              width: 68,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 6,
              tableViewName: 'PULL/STACK BID',
            },
            {
              name: 'bid',
              type: 'histogram',
              index: 7,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                largeSize: 14,
                textAlign: 'center',
                totalColor: '#fff',
                textOverflow: false,
                clearInterval: 9999,
                highlightLarge: false,
                histogramColor: 'rgba(72,149,245,0,3)',
                titleUpperCase: true,
                backgroundColor: 'rgba(12,98,247, 0.3)',
                histogramEnabled: true,
                histogramOrientation: 'left',
                hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
                hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
              },
              title: 'BID',
              width: 88,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 7,
              tableViewName: 'BID',
            },
            {
              name: 'ltq',
              type: 'string',
              index: 8,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                textOverflow: false,
                histogramColor: 'rgba(56, 58, 64, 0.5)',
                titleUpperCase: true,
                backgroundColor: '#012B36',
                accumulateTrades: true,
                buyBackgroundColor: '#0C62F7',
                sellBackgroundColor: 'rgba(201, 59, 59, 1)',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'LTQ',
              width: 49,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 8,
              tableViewName: 'LTQ',
            },
            {
              name: 'currentBid',
              type: 'histogram',
              index: 9,
              style: {
                color: '#EB5A5A',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                clearOnBest: true,
                textOverflow: false,
                clearInterval: 9999,
                levelInterval: 500,
                momentumTails: true,
                histogramColor: 'rgba(201, 59, 59, 0.4)',
                tailInsideBold: false,
                tailInsideFont: '700 12px "Open Sans", sans-serif',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                tailInsideColor: 'rgba(255, 255, 255, 1)',
                histogramEnabled: false,
                clearTradersTimer: 9999,
                tailInsidelevel1Bold: false,
                tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel2Bold: false,
                tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel3Bold: false,
                tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel4Bold: false,
                tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel5Bold: false,
                tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel6Bold: false,
                tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel7Bold: false,
                tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel8Bold: false,
                tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel9Bold: false,
                tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
                insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                level1BackgroundColor: 'rgba(128, 64, 64, 1)',
                level2BackgroundColor: 'rgba(112, 61, 63, 1)',
                level3BackgroundColor: 'rgba(96, 59, 62, 1)',
                level4BackgroundColor: 'rgba(80, 56, 60, 1)',
                level5BackgroundColor: 'rgba(64, 54, 59, 1)',
                level6BackgroundColor: 'rgba(48, 51, 58, 1)',
                level7BackgroundColor: 'rgba(32, 48, 57, 1)',
                level8BackgroundColor: 'rgba(16, 46, 55, 1)',
                tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
                tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
                tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
                tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
                tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
                tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
                tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
                tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
                tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
              },
              title: 'I.BID',
              width: 50,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 9,
              tableViewName: 'INSIDE@BID',
            },
            {
              name: 'currentAsk',
              type: 'histogram',
              index: 10,
              style: {
                color: 'rgba(12,98,247,1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                clearOnBest: true,
                textOverflow: false,
                clearInterval: 9999,
                levelInterval: 500,
                momentumTails: true,
                histogramColor: 'rgba(4, 63, 128, 1)',
                tailInsideBold: false,
                tailInsideFont: '700 12px "Open Sans", sans-serif',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                tailInsideColor: 'rgba(255, 255, 255, 1)',
                histogramEnabled: false,
                clearTradersTimer: 9999,
                tailInsidelevel1Bold: false,
                tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel2Bold: false,
                tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel3Bold: false,
                tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel4Bold: false,
                tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel5Bold: false,
                tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel6Bold: false,
                tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel7Bold: false,
                tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel8Bold: false,
                tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
                tailInsidelevel9Bold: false,
                tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
                insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                level1BackgroundColor: 'rgba(4, 63, 128, 1)',
                level2BackgroundColor: 'rgba(3, 60, 119, 1)',
                level3BackgroundColor: 'rgba(3, 59, 110, 1)',
                level4BackgroundColor: 'rgba(2, 56, 100, 1)',
                level5BackgroundColor: 'rgba(2, 54, 91, 1)',
                level6BackgroundColor: 'rgba(2, 51, 82, 1)',
                level7BackgroundColor: 'rgba(1, 48, 73, 1)',
                level8BackgroundColor: 'rgba(1, 46, 63, 1)',
                tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
                tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
                tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
                tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
                tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
                tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
                tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
                tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
                tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
              },
              title: 'I.ASK',
              width: 50,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 10,
              tableViewName: 'INSIDE@ASK',
            },
            {
              name: 'ask',
              type: 'histogram',
              index: 11,
              style: {
                color: '#fff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                largeSize: 14,
                textAlign: 'center',
                totalColor: '#fff',
                textOverflow: false,
                clearInterval: 9999,
                highlightLarge: false,
                histogramColor: 'rgba(201, 59, 59, 0.4)',
                titleUpperCase: true,
                backgroundColor: 'rgba(201, 59, 59, 0.3)',
                histogramEnabled: true,
                histogramOrientation: 'left',
                hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
                hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
              },
              title: 'ASK',
              width: 88,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 11,
              tableViewName: 'ASK',
            },
            {
              name: 'askDelta',
              type: 'string',
              index: 12,
              style: {
                color: '#ffffff',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'center',
                minToVisible: 0,
                textOverflow: false,
                overlayOrders: true,
                sellOrderColor: '#fff',
                titleUpperCase: true,
                backgroundColor: 'rgba(201, 59, 59, 0.2)',
                hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                highlightBackgroundColor: '#682a2d',
                sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
              },
              title: 'P/S.ASK',
              width: 68,
              hidden: false,
              canHide: true,
              visible: true,
              rowIndex: -1,
              columnIndex: 12,
              tableViewName: 'PULL/STACK ASK',
            },
            {
              name: 'totalBid',
              type: 'histogram',
              index: 13,
              style: {
                color: 'rgba(235, 90, 90, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'left',
                textOverflow: false,
                histogramColor: 'rgba(201, 59, 59, 0.3)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                histogramOrientation: 'right',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'T.BID',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 13,
              tableViewName: 'TOTAL@BID',
            },
            {
              name: 'totalAsk',
              type: 'histogram',
              index: 14,
              style: {
                color: 'rgba(12,98,247, 1)',
                histogram: {
                  color: '#0C62F7',
                  enabled: true,
                  orientation: 'left',
                },
                textAlign: 'right',
                textOverflow: false,
                histogramColor: 'rgba(12,98,247, 0.3)',
                titleUpperCase: true,
                backgroundColor: 'transparent',
                histogramEnabled: true,
                histogramOrientation: 'left',
                highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
              },
              title: 'T.ASK',
              width: 100,
              hidden: false,
              canHide: true,
              visible: false,
              rowIndex: -1,
              columnIndex: 14,
              tableViewName: 'TOTAL@ASK',
            },
          ],
          settings: {
            ask: {
              color: '#fff',
              largeSize: 14,
              textAlign: 'center',
              totalColor: '#fff',
              clearInterval: 9999,
              highlightLarge: false,
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              backgroundColor: 'rgba(201, 59, 59, 0.3)',
              histogramEnabled: true,
              histogramOrientation: 'left',
              hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
              hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
            bid: {
              color: '#fff',
              largeSize: 14,
              textAlign: 'center',
              totalColor: '#fff',
              clearInterval: 9999,
              highlightLarge: false,
              histogramColor: 'rgba(72,149,245,0,3)',
              backgroundColor: 'rgba(12,98,247, 0.3)',
              histogramEnabled: true,
              histogramOrientation: 'left',
              hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
              hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            ltq: {
              color: '#fff',
              textAlign: 'center',
              histogramColor: 'rgba(56, 58, 64, 0.5)',
              backgroundColor: '#012B36',
              accumulateTrades: true,
              buyBackgroundColor: '#0C62F7',
              sellBackgroundColor: 'rgba(201, 59, 59, 1)',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            note: {
              color: '#fff',
              backgroundColor: 'transparent',
              addedOrdersColor: 'rgba(53, 104, 147, 1)',
              pulledOrdersColor: 'rgba(143, 60, 65, 1)',
            },
            delta: {
              askDeltaColor: '#ffffff',
              askDeltacolor: '#ffffff',
              bidDeltaColor: '#fff',
              bidDeltacolor: '#fff',
              askDeltaTextAlign: 'center',
              askDeltatextAlign: 'center',
              bidDeltaTextAlign: 'center',
              bidDeltatextAlign: 'center',
              askDeltaMinToVisible: 0,
              askDeltaminToVisible: 0,
              bidDeltaMinToVisible: 0,
              bidDeltaminToVisible: 0,
              askDeltaOverlayOrders: true,
              askDeltaoverlayOrders: true,
              bidDeltaBuyOrderColor: '#fff',
              bidDeltaOverlayOrders: true,
              bidDeltabuyOrderColor: '#fff',
              bidDeltaoverlayOrders: true,
              askDeltaSellOrderColor: '#fff',
              askDeltahighlightColor: '#ffffff',
              askDeltasellOrderColor: '#fff',
              bidDeltahighlightColor: '#fff',
              askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
              askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
              bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
              bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
              askDeltahighlightTextAlign: 'center',
              bidDeltahighlightTextAlign: 'center',
              askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              askDeltaHighlightBackgroundColor: '#682a2d',
              askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              askDeltahighlightBackgroundColor: '#682a2d',
              askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              bidDeltaHighlightBackgroundColor: '#2b486e',
              bidDeltahighlightBackgroundColor: '#2b486e',
              askDeltaHoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
              askDeltahoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
              bidDeltaHoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
              bidDeltahoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            price: {
              color: 'rgba(208, 208, 210, 1)',
              textAlign: 'center',
              highlightColor: '#fff',
              backgroundColor: 'transparent',
              priceMarkerColor: 'black',
              tradedPriceColor: 'rgba(208, 208, 210, 1)',
              hoveredhighlightColor: '#fff',
              hoveredBackgroundColor: '#383A40',
              hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
              highlightBackgroundColor: '#383A40',
              priceMarkerBackgroundColor: '#FFDF6F',
              tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
              priceMarkerpriceMarkerColor: 'black',
              hoveredhighlightBackgroundColor: '#383A40',
              longPositionOpenBackgroundColor: '#0C62F7',
              shortPositionOpenBackgroundColor: '#C93B3BFF',
              hoveredtradedPriceBackgroundColor: '#383A40',
              priceMarkerpriceMarkerBackgroundColor: '#FFA500',
              hoveredlongPositionOpenBackgroundColor: '#383A40',
              hoveredshortPositionOpenBackgroundColor: '#383A40',
            },
            common: {
              ask: true,
              bid: true,
              ltq: true,
              delta: false,
              notes: true,
              price: true,
              orders: false,
              volume: true,
              askDelta: true,
              bidDelta: true,
              fontSize: 12,
              totalAsk: false,
              totalBid: false,
              buyOrders: false,
              rowHeight: 15,
              currentAsk: true,
              currentBid: true,
              fontFamily: '"Open Sans", sans-serif',
              fontWeight: '',
              sellOrders: false,
              generalColors: {
                gridLineColor: '#24262C',
                centerLineColor: '#A1A2A5',
                orderGridLineColor: 'rgba(88, 110, 117, 1)',
                enableOrderGridColor: true,
              },
            },
            orders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'transparent',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            volume: {
              ltq: false,
              poc: true,
              VWAP: true,
              color: '#fff',
              sessions: {
                eth: {
                  id: 63,
                  name: 'US ETH--RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  workingTimes: [
                    {
                      endDay: 1,
                      endTime: 34200000,
                      startDay: 0,
                      startTime: 64800000,
                    },
                    {
                      endDay: 3,
                      endTime: 34200000,
                      startDay: 2,
                      startTime: 58500000,
                    },
                    {
                      endDay: 5,
                      endTime: 34200000,
                      startDay: 4,
                      startTime: 58500000,
                    },
                    {
                      endDay: 4,
                      endTime: 34200000,
                      startDay: 3,
                      startTime: 58500000,
                    },
                    {
                      endDay: 2,
                      endTime: 34200000,
                      startDay: 1,
                      startTime: 58500000,
                    },
                  ],
                },
                rth: {
                  id: 64,
                  name: 'US RTH',
                  exchange: 'CME',
                  timezoneId: 'America/New_York',
                  workingTimes: [
                    {
                      endDay: 4,
                      endTime: 58500000,
                      startDay: 4,
                      startTime: 34200000,
                    },
                    {
                      endDay: 5,
                      endTime: 58500000,
                      startDay: 5,
                      startTime: 34200000,
                    },
                    {
                      endDay: 2,
                      endTime: 58500000,
                      startDay: 2,
                      startTime: 34200000,
                    },
                    {
                      endDay: 3,
                      endTime: 58500000,
                      startDay: 3,
                      startTime: 34200000,
                    },
                    {
                      endDay: 1,
                      endTime: 58500000,
                      startDay: 1,
                      startTime: 34200000,
                    },
                  ],
                },
                histogramEnabled: false,
                overlayLineColor: '#fff',
              },
              textAlign: 'right',
              valueArea: true,
              histogramColor: 'rgba(73, 187, 169, 0.5)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
              histogramOrientation: 'right',
              valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
              highlightBackgroundColor: '#9D0A0A',
              lastTradingBackgroundColor: '#fff',
              pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
            },
            general: {
              recenter: true,
              intervals: {
                momentumTails: true,
                updateInterval: 100,
                clearTradersTimer: 9999,
                orderQuantityStep: 7,
                momentumIntervalMs: 500,
                resetDeltaInterval: '5000',
                scrollWheelSensitivity: 1,
              },
              commonView: {
                autoCenter: true,
                centerLine: true,
                autoCenterTicks: 20,
              },
              marketDepth: {
                marketDepth: 40,
                bidAskDeltaDepth: 9,
                bidAskDeltaFilter: 0,
              },
              digitsToHide: 4,
              hideFromLeft: false,
              hideFromRight: false,
              hideAccountName: false,
              clearTotalPrints: false,
              closeOutstandingOrders: false,
              clearInsidePrints: false,
              currentTotalAllWindows: false,
              currentTradesAllWindows: false,
              recenterTotalAllWindows: false,
            },
            hotkeys: {
              shiftToStopOrder: ShiftToStopOrderEnum.shiftAndLeftClick,
              oco: null,
              hitBid: null,
              flatten: null,
              joinAsk: null,
              joinBid: null,
              buyMarket: null,
              liftOffer: null,
              quantity1: null,
              quantity2: null,
              quantity3: null,
              quantity4: null,
              quantity5: null,
              autoCenter: null,
              sellMarket: null,
              clearAlerts: null,
              stopsToPrice: null,
              limitsToPrice: null,
              quantityToPos: null,
              clearAllTotals: null,
              cancelAllOrders: null,
              clearTotalPrintsUp: null,
              clearVolumeProfile: null,
              clearInsidePrintsUp: null,
              autoCenterAllWindows: null,
              clearAlertsAllWindow: null,
              clearAllPriceMarkers: null,
              clearTotalPrintsDown: null,
              clearInsidePrintsDown: null,
              clearInsidePrintsAllWindows: null,
              clearTotalPrintsUpAllWindows: null,
              clearInsidePrintsUpAllWindows: null,
              clearTotalPrintsDownAllWindows: null,
              clearInsidePrintsDownAllWindows: null,
            },
            trading: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              trading: {
                split: false,
                overlayOrders: true,
                showOrderConfirm: false,
                showCancelConfirm: false,
              },
              formData: {
                quantity: 1,
              },
              orderArea: {
                roundPL: false,
                settings: {
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: false,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                },
                showPLInfo: true,
                showOHLVInfo: true,
                includeRealizedPL: false,
                showInstrumentChange: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
              },
              amountButtons: [
                {
                  value: 1,
                },
                {
                  black: true,
                  value: 1,
                },
                {
                  value: 3,
                },
                {
                  value: 5,
                },
                {
                  value: 10,
                },
                {
                  value: 25,
                },
              ],
            },
            _columns: [
              {
                name: 'orders',
                type: 'string',
                index: 0,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
                title: 'ORDERS',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 0,
                tableViewName: 'ORDERS',
              },
              {
                name: 'buyOrders',
                type: 'string',
                index: 1,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247, 0.5)',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                  hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
                  hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
                },
                title: 'BUY ORDERS',
                width: 100,
                hidden: true,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 1,
                tableViewName: 'BUY ORDERS',
              },
              {
                name: 'sellOrders',
                type: 'string',
                index: 2,
                style: {
                  color: '#D0D0D2',
                  split: false,
                  showPL: true,
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  lossColor: 'rgba(201, 59, 59, 1)',
                  textAlign: 'center',
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  inProfitColor: 'rgba(12,98,247,1)',
                  overlayOrders: true,
                  highlightColor: 'rgba(29, 73, 127, 1)',
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  includeRealizedPL: false,
                  buyOrderBorderColor: 'rgba(12,98,247, 1)',
                  lossBackgroundColor: 'transparent',
                  sellOrderBorderColor: '#C93B3B',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  inProfitBackgroundColor: 'transparent',
                  buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                  hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
                  hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
                },
                title: 'SELL ORDERS',
                width: 100,
                hidden: true,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 2,
                tableViewName: 'SELL ORDERS',
              },
              {
                name: 'volume',
                type: 'histogram',
                index: 3,
                style: {
                  ltq: false,
                  poc: true,
                  VWAP: true,
                  color: '#fff',
                  sessions: {
                    eth: {
                      id: 63,
                      name: 'US ETH--RTH',
                      exchange: 'CME',
                      timezoneId: 'America/New_York',
                      workingTimes: [
                        {
                          endDay: 1,
                          endTime: 34200000,
                          startDay: 0,
                          startTime: 64800000,
                        },
                        {
                          endDay: 3,
                          endTime: 34200000,
                          startDay: 2,
                          startTime: 58500000,
                        },
                        {
                          endDay: 5,
                          endTime: 34200000,
                          startDay: 4,
                          startTime: 58500000,
                        },
                        {
                          endDay: 4,
                          endTime: 34200000,
                          startDay: 3,
                          startTime: 58500000,
                        },
                        {
                          endDay: 2,
                          endTime: 34200000,
                          startDay: 1,
                          startTime: 58500000,
                        },
                      ],
                    },
                    rth: {
                      id: 64,
                      name: 'US RTH',
                      exchange: 'CME',
                      timezoneId: 'America/New_York',
                      workingTimes: [
                        {
                          endDay: 4,
                          endTime: 58500000,
                          startDay: 4,
                          startTime: 34200000,
                        },
                        {
                          endDay: 5,
                          endTime: 58500000,
                          startDay: 5,
                          startTime: 34200000,
                        },
                        {
                          endDay: 2,
                          endTime: 58500000,
                          startDay: 2,
                          startTime: 34200000,
                        },
                        {
                          endDay: 3,
                          endTime: 58500000,
                          startDay: 3,
                          startTime: 34200000,
                        },
                        {
                          endDay: 1,
                          endTime: 58500000,
                          startDay: 1,
                          startTime: 34200000,
                        },
                      ],
                    },
                    histogramEnabled: false,
                    overlayLineColor: '#fff',
                  },
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'right',
                  valueArea: true,
                  textOverflow: false,
                  histogramColor: 'rgba(73, 187, 169, 0.5)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  VWAPHistogramColor: 'rgba(203, 75, 22, 1)',
                  histogramOrientation: 'right',
                  valueAreaHistogramColor: 'rgba(109, 112, 196, 1)',
                  highlightBackgroundColor: '#9D0A0A',
                  lastTradingBackgroundColor: '#fff',
                  pointOfControlHistogramColor: 'rgba(211, 53, 130, 1)',
                },
                title: 'VOLUME',
                width: 90,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 3,
                tableViewName: 'VOLUME',
              },
              {
                name: 'price',
                type: 'string',
                index: 4,
                style: {
                  color: 'rgba(208, 208, 210, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  textOverflow: false,
                  highlightColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  priceMarkerColor: 'black',
                  tradedPriceColor: 'rgba(208, 208, 210, 1)',
                  hoveredhighlightColor: '#fff',
                  hoveredBackgroundColor: '#383A40',
                  hoveredtradedPriceColor: 'rgba(208, 208, 210, 1)',
                  highlightBackgroundColor: '#383A40',
                  priceMarkerBackgroundColor: '#FFDF6F',
                  tradedPriceBackgroundColor: 'rgba(16, 17, 20, 1)',
                  priceMarkerpriceMarkerColor: 'black',
                  hoveredhighlightBackgroundColor: '#383A40',
                  longPositionOpenBackgroundColor: '#0C62F7',
                  shortPositionOpenBackgroundColor: '#C93B3BFF',
                  hoveredtradedPriceBackgroundColor: '#383A40',
                  priceMarkerpriceMarkerBackgroundColor: '#FFA500',
                  hoveredlongPositionOpenBackgroundColor: '#383A40',
                  hoveredshortPositionOpenBackgroundColor: '#383A40',
                },
                title: 'PRICE',
                width: 62,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 4,
                tableViewName: 'PRICE',
              },
              {
                name: 'delta',
                type: 'string',
                index: 5,
                style: {
                  color: '#D0D0D2',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'left',
                  textOverflow: false,
                  askDeltaColor: '#ffffff',
                  askDeltacolor: '#ffffff',
                  bidDeltaColor: '#fff',
                  bidDeltacolor: '#fff',
                  titleUpperCase: true,
                  askDeltaTextAlign: 'center',
                  askDeltatextAlign: 'center',
                  bidDeltaTextAlign: 'center',
                  bidDeltatextAlign: 'center',
                  askDeltaMinToVisible: 0,
                  askDeltaminToVisible: 0,
                  bidDeltaMinToVisible: 0,
                  bidDeltaminToVisible: 0,
                  askDeltaOverlayOrders: true,
                  askDeltaoverlayOrders: true,
                  bidDeltaBuyOrderColor: '#fff',
                  bidDeltaOverlayOrders: true,
                  bidDeltabuyOrderColor: '#fff',
                  bidDeltaoverlayOrders: true,
                  askDeltaSellOrderColor: '#fff',
                  askDeltahighlightColor: '#ffffff',
                  askDeltasellOrderColor: '#fff',
                  bidDeltahighlightColor: '#fff',
                  askDeltaBackgroundColor: 'rgba(201, 59, 59, 0.2)',
                  askDeltabackgroundColor: 'rgba(201, 59, 59, 0.2)',
                  bidDeltaBackgroundColor: 'rgba(12,98,247,0.2)',
                  bidDeltabackgroundColor: 'rgba(12,98,247,0.2)',
                  askDeltahighlightTextAlign: 'center',
                  bidDeltahighlightTextAlign: 'center',
                  askDeltaHoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                  askDeltahoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                  bidDeltaHoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                  bidDeltahoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                  bidDeltaBuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  bidDeltabuyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  askDeltaHighlightBackgroundColor: '#682a2d',
                  askDeltaSellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  askDeltahighlightBackgroundColor: '#682a2d',
                  askDeltasellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  bidDeltaHighlightBackgroundColor: '#2b486e',
                  bidDeltahighlightBackgroundColor: '#2b486e',
                  askDeltaHoveredhighlightBackgroundColor:
                    'rgba(184, 50, 50, 1)',
                  askDeltahoveredhighlightBackgroundColor:
                    'rgba(184, 50, 50, 1)',
                  bidDeltaHoveredhighlightBackgroundColor:
                    'rgba(7, 88, 228, 1)',
                  bidDeltahoveredhighlightBackgroundColor:
                    'rgba(7, 88, 228, 1)',
                },
                title: 'P/S',
                width: 58,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 5,
                tableViewName: 'PULL/STACK',
              },
              {
                name: 'bidDelta',
                type: 'string',
                index: 6,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  minToVisible: 0,
                  textOverflow: false,
                  buyOrderColor: '#fff',
                  overlayOrders: true,
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247,0.2)',
                  hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                  buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
                  highlightBackgroundColor: '#2b486e',
                  hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
                },
                title: 'P/S.BID',
                width: 68,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 6,
                tableViewName: 'PULL/STACK BID',
              },
              {
                name: 'bid',
                type: 'histogram',
                index: 7,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  largeSize: 14,
                  textAlign: 'center',
                  totalColor: '#fff',
                  textOverflow: false,
                  clearInterval: 9999,
                  highlightLarge: false,
                  histogramColor: 'rgba(72,149,245,0,3)',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(12,98,247, 0.3)',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
                  highlightBackgroundColor: 'rgba(12,98,247, 0.4)',
                  hoveredtotalBackgroundColor: 'rgba(7, 88, 228, 1)',
                },
                title: 'BID',
                width: 88,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 7,
                tableViewName: 'BID',
              },
              {
                name: 'ltq',
                type: 'string',
                index: 8,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  textOverflow: false,
                  histogramColor: 'rgba(56, 58, 64, 0.5)',
                  titleUpperCase: true,
                  backgroundColor: '#012B36',
                  accumulateTrades: true,
                  buyBackgroundColor: '#0C62F7',
                  sellBackgroundColor: 'rgba(201, 59, 59, 1)',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'LTQ',
                width: 49,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 8,
                tableViewName: 'LTQ',
              },
              {
                name: 'currentBid',
                type: 'histogram',
                index: 9,
                style: {
                  color: '#EB5A5A',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  clearOnBest: true,
                  textOverflow: false,
                  clearInterval: 9999,
                  levelInterval: 500,
                  momentumTails: true,
                  histogramColor: 'rgba(201, 59, 59, 0.4)',
                  tailInsideBold: false,
                  tailInsideFont: '700 12px "Open Sans", sans-serif',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  tailInsideColor: 'rgba(255, 255, 255, 1)',
                  histogramEnabled: false,
                  clearTradersTimer: 9999,
                  tailInsidelevel1Bold: false,
                  tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel2Bold: false,
                  tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel3Bold: false,
                  tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel4Bold: false,
                  tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel5Bold: false,
                  tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel6Bold: false,
                  tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel7Bold: false,
                  tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel8Bold: false,
                  tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel9Bold: false,
                  tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
                  insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                  level1BackgroundColor: 'rgba(128, 64, 64, 1)',
                  level2BackgroundColor: 'rgba(112, 61, 63, 1)',
                  level3BackgroundColor: 'rgba(96, 59, 62, 1)',
                  level4BackgroundColor: 'rgba(80, 56, 60, 1)',
                  level5BackgroundColor: 'rgba(64, 54, 59, 1)',
                  level6BackgroundColor: 'rgba(48, 51, 58, 1)',
                  level7BackgroundColor: 'rgba(32, 48, 57, 1)',
                  level8BackgroundColor: 'rgba(16, 46, 55, 1)',
                  tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                  highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
                  tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
                  tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
                  tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
                  tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
                  tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
                  tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
                  tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
                  tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
                },
                title: 'I.BID',
                width: 50,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 9,
                tableViewName: 'INSIDE@BID',
              },
              {
                name: 'currentAsk',
                type: 'histogram',
                index: 10,
                style: {
                  color: 'rgba(12,98,247,1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  clearOnBest: true,
                  textOverflow: false,
                  clearInterval: 9999,
                  levelInterval: 500,
                  momentumTails: true,
                  histogramColor: 'rgba(4, 63, 128, 1)',
                  tailInsideBold: false,
                  tailInsideFont: '700 12px "Open Sans", sans-serif',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  tailInsideColor: 'rgba(255, 255, 255, 1)',
                  histogramEnabled: false,
                  clearTradersTimer: 9999,
                  tailInsidelevel1Bold: false,
                  tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel2Bold: false,
                  tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel3Bold: false,
                  tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel4Bold: false,
                  tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel5Bold: false,
                  tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel6Bold: false,
                  tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel7Bold: false,
                  tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel8Bold: false,
                  tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
                  tailInsidelevel9Bold: false,
                  tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
                  insideBackgroundColor: 'rgba(0, 0, 0, 1)',
                  level1BackgroundColor: 'rgba(4, 63, 128, 1)',
                  level2BackgroundColor: 'rgba(3, 60, 119, 1)',
                  level3BackgroundColor: 'rgba(3, 59, 110, 1)',
                  level4BackgroundColor: 'rgba(2, 56, 100, 1)',
                  level5BackgroundColor: 'rgba(2, 54, 91, 1)',
                  level6BackgroundColor: 'rgba(2, 51, 82, 1)',
                  level7BackgroundColor: 'rgba(1, 48, 73, 1)',
                  level8BackgroundColor: 'rgba(1, 46, 63, 1)',
                  tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
                  tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
                  highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
                  tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
                  tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
                  tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
                  tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
                  tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
                  tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
                  tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
                  tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
                },
                title: 'I.ASK',
                width: 50,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 10,
                tableViewName: 'INSIDE@ASK',
              },
              {
                name: 'ask',
                type: 'histogram',
                index: 11,
                style: {
                  color: '#fff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  largeSize: 14,
                  textAlign: 'center',
                  totalColor: '#fff',
                  textOverflow: false,
                  clearInterval: 9999,
                  highlightLarge: false,
                  histogramColor: 'rgba(201, 59, 59, 0.4)',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(201, 59, 59, 0.3)',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                  highlightBackgroundColor: 'rgba(201, 59, 59, 1)',
                  hoveredtotalBackgroundColor: 'rgba(184, 50, 50, 1)',
                },
                title: 'ASK',
                width: 88,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 11,
                tableViewName: 'ASK',
              },
              {
                name: 'askDelta',
                type: 'string',
                index: 12,
                style: {
                  color: '#ffffff',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'center',
                  minToVisible: 0,
                  textOverflow: false,
                  overlayOrders: true,
                  sellOrderColor: '#fff',
                  titleUpperCase: true,
                  backgroundColor: 'rgba(201, 59, 59, 0.2)',
                  hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
                  highlightBackgroundColor: '#682a2d',
                  sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
                  hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
                },
                title: 'P/S.ASK',
                width: 68,
                hidden: false,
                canHide: true,
                visible: true,
                rowIndex: -1,
                columnIndex: 12,
                tableViewName: 'PULL/STACK ASK',
              },
              {
                name: 'totalBid',
                type: 'histogram',
                index: 13,
                style: {
                  color: 'rgba(235, 90, 90, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'left',
                  textOverflow: false,
                  histogramColor: 'rgba(201, 59, 59, 0.3)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  histogramOrientation: 'right',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'T.BID',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 13,
                tableViewName: 'TOTAL@BID',
              },
              {
                name: 'totalAsk',
                type: 'histogram',
                index: 14,
                style: {
                  color: 'rgba(12,98,247, 1)',
                  histogram: {
                    color: '#0C62F7',
                    enabled: true,
                    orientation: 'left',
                  },
                  textAlign: 'right',
                  textOverflow: false,
                  histogramColor: 'rgba(12,98,247, 0.3)',
                  titleUpperCase: true,
                  backgroundColor: 'transparent',
                  histogramEnabled: true,
                  histogramOrientation: 'left',
                  highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
                },
                title: 'T.ASK',
                width: 100,
                hidden: false,
                canHide: true,
                visible: false,
                rowIndex: -1,
                columnIndex: 14,
                tableViewName: 'TOTAL@ASK',
              },
            ],
            askDelta: {
              color: '#ffffff',
              textAlign: 'center',
              minToVisible: 0,
              overlayOrders: true,
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(201, 59, 59, 0.2)',
              hoveredBackgroundColor: 'rgba(184, 50, 50, 1)',
              highlightBackgroundColor: '#682a2d',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              hoveredhighlightBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
            bidDelta: {
              color: '#fff',
              textAlign: 'center',
              minToVisible: 0,
              buyOrderColor: '#fff',
              overlayOrders: true,
              backgroundColor: 'rgba(12,98,247,0.2)',
              hoveredBackgroundColor: 'rgba(7, 88, 228, 1)',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              highlightBackgroundColor: '#2b486e',
              hoveredhighlightBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            totalAsk: {
              color: 'rgba(12,98,247, 1)',
              textAlign: 'right',
              histogramColor: 'rgba(12,98,247, 0.3)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              histogramOrientation: 'left',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            totalBid: {
              color: 'rgba(235, 90, 90, 1)',
              textAlign: 'left',
              histogramColor: 'rgba(201, 59, 59, 0.3)',
              backgroundColor: 'transparent',
              histogramEnabled: true,
              histogramOrientation: 'right',
              highlightBackgroundColor: 'rgba(56, 58, 64, 1)',
            },
            buyOrders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(12,98,247, 0.5)',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              hoveredlossBackgroundColor: 'rgba(7, 88, 228, 1)',
              hoveredinProfitBackgroundColor: 'rgba(7, 88, 228, 1)',
            },
            currentAsk: {
              color: 'rgba(12,98,247,1)',
              textAlign: 'center',
              clearOnBest: true,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              histogramColor: 'rgba(4, 63, 128, 1)',
              tailInsideBold: false,
              tailInsideFont: '700 12px "Open Sans", sans-serif',
              backgroundColor: 'transparent',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              histogramEnabled: false,
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              level1BackgroundColor: 'rgba(4, 63, 128, 1)',
              level2BackgroundColor: 'rgba(3, 60, 119, 1)',
              level3BackgroundColor: 'rgba(3, 59, 110, 1)',
              level4BackgroundColor: 'rgba(2, 56, 100, 1)',
              level5BackgroundColor: 'rgba(2, 54, 91, 1)',
              level6BackgroundColor: 'rgba(2, 51, 82, 1)',
              level7BackgroundColor: 'rgba(1, 48, 73, 1)',
              level8BackgroundColor: 'rgba(1, 46, 63, 1)',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              highlightBackgroundColor: 'rgba(88, 110, 117, 1)',
              tailInsidelevel1BackgroundColor: 'rgba(4, 63, 128, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(3, 60, 119, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(3, 59, 110, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(2, 56, 100, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(2, 54, 91, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(2, 51, 82, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(1, 48, 73, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(1, 46, 63, 1)',
            },
            currentBid: {
              color: '#EB5A5A',
              textAlign: 'center',
              clearOnBest: true,
              clearInterval: 9999,
              levelInterval: 500,
              momentumTails: true,
              histogramColor: 'rgba(201, 59, 59, 0.4)',
              tailInsideBold: false,
              tailInsideFont: '700 12px "Open Sans", sans-serif',
              backgroundColor: 'transparent',
              tailInsideColor: 'rgba(255, 255, 255, 1)',
              histogramEnabled: false,
              clearTradersTimer: 9999,
              tailInsidelevel1Bold: false,
              tailInsidelevel1Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel2Bold: false,
              tailInsidelevel2Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel3Bold: false,
              tailInsidelevel3Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel4Bold: false,
              tailInsidelevel4Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel5Bold: false,
              tailInsidelevel5Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel6Bold: false,
              tailInsidelevel6Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel7Bold: false,
              tailInsidelevel7Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel8Bold: false,
              tailInsidelevel8Font: '700 12px "Open Sans", sans-serif',
              tailInsidelevel9Bold: false,
              tailInsidelevel9Font: '700 12px "Open Sans", sans-serif',
              insideBackgroundColor: 'rgba(0, 0, 0, 1)',
              level1BackgroundColor: 'rgba(128, 64, 64, 1)',
              level2BackgroundColor: 'rgba(112, 61, 63, 1)',
              level3BackgroundColor: 'rgba(96, 59, 62, 1)',
              level4BackgroundColor: 'rgba(80, 56, 60, 1)',
              level5BackgroundColor: 'rgba(64, 54, 59, 1)',
              level6BackgroundColor: 'rgba(48, 51, 58, 1)',
              level7BackgroundColor: 'rgba(32, 48, 57, 1)',
              level8BackgroundColor: 'rgba(16, 46, 55, 1)',
              tailInsidelevel1Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel2Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel3Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel4Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel5Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel6Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel7Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel8Color: 'rgba(255, 255, 255, 1)',
              tailInsidelevel9Color: 'rgba(255, 255, 255, 1)',
              highlightBackgroundColor: 'rgba(201, 59, 59, 0.4)',
              tailInsidelevel1BackgroundColor: 'rgba(128, 64, 64, 1)',
              tailInsidelevel2BackgroundColor: 'rgba(112, 61, 63, 1)',
              tailInsidelevel3BackgroundColor: 'rgba(96, 59, 62, 1)',
              tailInsidelevel4BackgroundColor: 'rgba(80, 56, 60, 1)',
              tailInsidelevel5BackgroundColor: 'rgba(64, 54, 59, 1)',
              tailInsidelevel6BackgroundColor: 'rgba(48, 51, 58, 1)',
              tailInsidelevel7BackgroundColor: 'rgba(32, 48, 57, 1)',
              tailInsidelevel8BackgroundColor: 'rgba(16, 46, 55, 1)',
            },
            sellOrders: {
              split: false,
              showPL: true,
              lossColor: 'rgba(201, 59, 59, 1)',
              textAlign: 'center',
              buyOrderColor: '#fff',
              inProfitColor: 'rgba(12,98,247,1)',
              overlayOrders: true,
              highlightColor: 'rgba(29, 73, 127, 1)',
              sellOrderColor: '#fff',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              includeRealizedPL: false,
              buyOrderBorderColor: 'rgba(12,98,247, 1)',
              lossBackgroundColor: 'transparent',
              sellOrderBorderColor: '#C93B3B',
              buyOrderBackgroundColor: 'rgba(12,98,247, 0.7)',
              inProfitBackgroundColor: 'transparent',
              buyOrdersBackgroundColor: 'rgba(12,98,247, 0.5)',
              sellOrderBackgroundColor: 'rgba(201, 59, 59, 0.7)',
              sellOrdersBackgroundColor: 'rgba(255, 255, 255, 0.5)',
              hoveredlossBackgroundColor: 'rgba(184, 50, 50, 1)',
              hoveredinProfitBackgroundColor: 'rgba(184, 50, 50, 1)',
            },
          },
          orderForm: {
            formData: {
              quantity: 1,
              stopLoss: {
                type: 'StopMarket',
                unit: 'ticks',
                ticks: 12,
                amount: 1,
                stopLoss: true,
                unitItem: 12,
              },
              takeProfit: {
                unit: 'ticks',
                ticks: 12,
                unitItem: 12,
                takeProfit: true,
              },
            },
            settings: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              formSettings: {
                roundPL: false,
                showPLInfo: true,
                showOHLVInfo: true,
                showBuyButton: true,
                showSellButton: true,
                showCancelButton: true,
                showOrderConfirm: false,
                includeRealizedPL: false,
                showCancelConfirm: false,
                showFlattenButton: true,
                showIcebergButton: false,
                showLiquidateButton: true,
                showInstrumentChange: true,
              },
              amountButtons: [
                {
                  value: 1,
                },
                {
                  black: true,
                  value: 1,
                },
                {
                  value: 3,
                },
                {
                  value: 5,
                },
                {
                  value: 10,
                },
                {
                  value: 25,
                },
              ],
              icebergFontColor: '#fff',
              buyButtonsFontColor: '#D0D0D2',
              flatButtonsFontColor: '#D0D0D2',
              sellButtonsFontColor: '#D0D0D2',
              cancelButtonFontColor: '#D0D0D2',
              closePositionFontColor: '#D0D0D2',
              icebergBackgroundColor: '#51535A',
              buyButtonsBackgroundColor: '#0C62F7',
              flatButtonsBackgroundColor: '#51535A',
              sellButtonsBackgroundColor: '#C93B3B',
              cancelButtonBackgroundColor: '#51535A',
              closePositionBackgroundColor: '#51535A',
            },
            amountButtons: [
              {
                value: 1,
              },
              {
                black: true,
                value: 1,
              },
              {
                value: 3,
              },
              {
                value: 5,
              },
              {
                value: 10,
              },
              {
                value: 25,
              },
            ],
          },
          instrument: {
            id: 'ESZ4.CME',
            type: 'Future',
            symbol: 'ESZ4',
            currency: 'USD',
            exchange: 'CME',
            tickSize: 0.25,
            precision: 2,
            expiration: '2024-12-20T00:00:00',
            description: 'E-Mini S&P 500 Dec24',
            productCode: 'ES',
            connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
            contractSize: 50,
            instrumentTimePeriod: 'Dec24',
            stringTypeRepresentation: 'Future',
          },
          priceMarkers: [
            '5796.00',
            '5736.50',
            '5785.00',
            '5781.00',
            '5770.00',
            '5770.25',
            '5933.00',
            '5917.25',
          ],
          isOrderFormOpen: true,
          contextMenuState: {
            showHeaderPanel: true,
            showColumnHeaders: true,
          },
          isTradingEnabled: false,
          preferredAccounts: {
            'c288af9a-d4c1-4e4a-af1d-b034fbca8776': 'APEX-41272-106',
            'd4135277-3f43-4d35-a646-8d7b2c7673b1': 'S1Nov208384304',
            'd428b3e6-bede-4e2a-bab6-325dcd1339e7': '42432ppr3',
            'e1c9560d-3714-4320-b114-34fd00c258c5': 'FTT-GT-8922',
          },
          orderFormDirection: 'window-right',
          componentInstanceId: 1690454474103,
        },
      },
      minHeight: 150,
      resizable: true,
      allowPopup: true,
      maximizable: true,
      minimizable: true,
    },
    isDefault: true,
  },
  {
    state: {
      isToolbarVisible: false,
      showOHLV: false,
      showChanges: false,
      enableOrderForm: false,
      link: 0.36923744970654826,
      showCancelConfirm: true,
      instrument: {
        id: 'ESZ4.CME',
        type: 'Future',
        symbol: 'ESZ4',
        currency: 'USD',
        exchange: 'CME',
        tickSize: 0.25,
        precision: 2,
        expiration: '2024-12-20T00:00:00',
        description: 'E-Mini S&P 500 Dec24',
        productCode: 'ES',
        connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
        contractSize: 50,
        instrumentTimePeriod: 'Dec24',
        stringTypeRepresentation: 'Future',
      },
      timeFrame: {
        interval: 5,
        periodicity: '',
      },
      periodToLoad: {
        interval: 3,
        periodicity: 'd',
      },
      stockChartXState: {
        chart: {
          theme: {
            name: 'Dark',
            chart: {
              background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
              border: {
                width: 1,
                strokeColor: 'grey',
                lineStyle: 'solid',
              },
              instrumentWatermark: {
                symbol: {
                  fontFamily: 'Arial',
                  fontSize: 70,
                  fontStyle: 'normal',
                  fillColor: 'white',
                },
                details: {
                  fontFamily: 'Arial',
                  fontSize: 40,
                  fontStyle: 'normal',
                  fillColor: 'white',
                },
              },
            },
            splitter: {
              fillColor: '#292C38',
              hoverFillColor: 'white',
            },
            chartPanel: {
              grid: {
                width: 1,
                strokeColor: '#24262C',
              },
              title: {
                fontFamily: 'Calibri',
                fontSize: 11,
                fontStyle: 'normal',
                fillColor: 'white',
              },
              instrumentWatermark: {
                symbol: {
                  fontFamily: 'Arial',
                  fontSize: 70,
                  fontStyle: 'normal',
                  fillColor: 'white',
                },
                details: {
                  fontFamily: 'Arial',
                  fontSize: 40,
                  fontStyle: 'normal',
                  fillColor: 'white',
                },
              },
            },
            tradingPanel: {
              tradingBarUnit: 'pixels',
              tradingBarLength: 40,
              buyPanelBackground: 'rgba(12,98,247, 0.3)',
              sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
              buyMarkerBackground: '#0C62F7',
              sellMarkerBackground: '#C93B3B',
              markerFontColor: '#fff',
              showPLInfo: true,
              includeRealizedPL: true,
              displayUnit: 'points',
              positivePLFontColor: 'white',
              positivePLBackgroundColor: 'black',
              negativePLFontColor: 'red',
              negativePLBackgroundColor: 'black',
            },
            chartMarkers: {
              showBarMarkerTobBottom: true,
              showBarMarketExecutionPrice: true,
              showBarTextWithSizePrice: true,
              dataBoxBackgroundColor: 'black',
              buyMarkerColor: 'blue',
              buyMarkerType: 'triangle',
              buyMarkerFontWeight: 1,
              sellMarkerColor: 'red',
              sellMarkerType: 'square',
              sellMarkerFontWeight: 1,
            },
            valueScale: {
              fill: {
                fillColor: 'rgb(27, 29, 34)',
              },
              text: {
                fontFamily: 'Arial',
                fontSize: 10,
                fontStyle: 'normal',
                fillColor: '#fff',
              },
              line: {
                width: 1,
                strokeColor: '#505050',
              },
              border: {
                width: 0,
                strokeColor: 'darkgray',
                lineStyle: 'solid',
              },
              valueMarker: {
                text: {
                  fontFamily: 'Calibri',
                  fontSize: 11,
                  fillColor: 'white',
                },
                fill: {
                  fillColor: '#0C62F7',
                },
              },
            },
            dateScale: {
              fill: {
                fillColor: 'rgb(27, 29, 34)',
              },
              text: {
                fontFamily: 'Arial',
                fontSize: 10,
                fillColor: '#fff',
              },
              line: {
                width: 1,
                strokeColor: '#505050',
              },
              border: {
                width: 0,
                strokeColor: 'darkgray',
                lineStyle: 'solid',
              },
              dateMarker: {
                text: {
                  fontFamily: 'Calibri',
                  fontSize: 11,
                  fillColor: '#FFFFFF',
                },
                fill: {
                  fillColor: 'green',
                },
                stroke: {
                  strokePriority: 'color',
                  strokeColor: '#696969',
                  width: 1,
                  lineStyle: 'solid',
                  lineJoin: 'miter',
                  lineCap: 'butt',
                },
              },
              breakLine: {
                stroke: {
                  strokeColor: '#545454',
                  width: 1,
                  lineStyle: 'dash',
                },
              },
            },
            crossHair: {
              text: {
                fontFamily: 'Calibri',
                fontSize: 11,
                fillColor: '#000000',
              },
              line: {
                width: 1,
                strokeColor: 'darkgray',
                lineStyle: 'dash',
              },
              fill: {
                fillColor: '#FFFFFF',
              },
            },
            zoomIn: {
              border: {
                width: 1,
                strokeColor: 'white',
                lineStyle: 'solid',
              },
              fill: {
                fillColor: 'rgba(255, 255, 255, 0.5)',
              },
            },
            plot: {
              point: {
                dot: {
                  fill: {
                    fillColor: 'white',
                  },
                },
              },
              line: {
                simple: {
                  width: 1,
                  strokeColor: '#fff',
                },
                mountain: {
                  line: {
                    width: 1,
                    strokeColor: '#fff',
                  },
                  fill: {
                    linearGradient: [
                      'rgba(63, 167, 225, 0.4) ',
                      'rgba(63, 167, 225, 0)',
                    ],
                  },
                },
                step: {
                  width: 1,
                  strokeColor: 'white',
                },
              },
              histogram: {
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                coloredLine: {
                  upBar: {
                    width: 1,
                    strokeColor: 'grey',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: 'red',
                  },
                },
                column: {
                  line: {
                    strokeEnabled: false,
                    width: 1,
                    strokeColor: 'white',
                  },
                  fill: {
                    fillColor: 'blue',
                  },
                },
                coloredColumn: {
                  upBar: {
                    line: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'green',
                    },
                  },
                  downBar: {
                    line: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'red',
                    },
                  },
                },
              },
              bar: {
                OHLC: {
                  width: 1,
                  strokeColor: '#fff',
                },
                HLC: {
                  width: 1,
                  strokeColor: 'white',
                },
                HL: {
                  width: 1,
                  strokeColor: 'white',
                },
                coloredOHLC: {
                  upBar: {
                    width: 1,
                    strokeColor: '#FFE8E8',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#C93B3B',
                  },
                },
                coloredHLC: {
                  upBar: {
                    width: 1,
                    strokeColor: '#3FA7E1',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#E85050',
                  },
                },
                coloredHL: {
                  upBar: {
                    width: 1,
                    strokeColor: '#3FA7E1',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#E85050',
                  },
                },
                candle: {
                  upCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                  },
                },
                heikinAshi: {
                  upCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: 'white',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: 'white',
                    },
                  },
                },
                renko: {
                  upCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: '#C93B3B',
                    },
                  },
                },
                lineBreak: {
                  upCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillEnabled: true,
                      fillColor: '#FFE8E8',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillEnabled: true,
                      fillColor: '#C93B3B',
                    },
                  },
                },
                hollowCandle: {
                  upCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                  },
                  upHollowCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#FFE8E8',
                    },
                  },
                  downHollowCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#C93B3B',
                    },
                  },
                },
                pointAndFigure: {
                  upCandle: {
                    border: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: '#FFE8E8',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: '#C93B3B',
                    },
                  },
                },
                kagi: {
                  upCandle: {
                    border: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: '#FFE8E8',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: '#C93B3B',
                    },
                  },
                },
                candleVolume: {
                  upCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                  },
                },
                equiVolume: {
                  upCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#C93B3B',
                    },
                  },
                },
                equiVolumeShadow: {
                  upCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                  },
                  downCandle: {
                    border: {
                      strokeEnabled: false,
                      width: 1,
                      strokeColor: '#D0D0D2',
                    },
                    fill: {
                      fillColor: '#C93B3B',
                    },
                  },
                },
              },
              indicator: {
                line1: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: 'white',
                },
                line2: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#3FA7E1',
                },
                line3: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#E85050',
                },
                line4: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#0000FF',
                },
                line5: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: 'yellow',
                },
                histogram: {
                  color: '#32CD32',
                },
                blackText: {
                  fontFamily: 'Arial',
                  fontSize: 10,
                  fontStyle: 'normal',
                  fillColor: 'black',
                },
                levels: {
                  line1: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FF0000',
                  },
                  line2: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#32CD32',
                  },
                  line3: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FFD500',
                  },
                  line4: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#0000FF',
                  },
                  line5: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#808080',
                  },
                },
              },
            },
            selectionMarker: {
              line: {
                strokeColor: 'white',
                width: 1,
              },
              fill: {
                fillColor: 'black',
              },
            },
            drawing: {
              note: {
                text: {
                  fontFamily: 'Calibri',
                  fontSize: 11,
                  fillColor: 'white',
                },
                fill: {
                  fillColor: 'orange',
                },
                centerPointFill: {
                  fillColor: 'rgb(68, 68, 68)',
                },
              },
              measure: {
                line: {
                  width: 1,
                  strokeColor: 'white',
                  strokeEnabled: true,
                  lineStyle: 'dash',
                },
                border: {
                  width: 1,
                  strokeColor: 'white',
                  strokeEnabled: true,
                  lineStyle: 'dash',
                },
                fill: {
                  fillEnabled: true,
                  fillColor: 'rgba(255, 255, 255, 0.5)',
                },
                balloon: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: 'white',
                  },
                  border: {
                    width: 1,
                    strokeColor: 'darkgray',
                    strokeEnabled: true,
                    lineStyle: 'solid',
                  },
                  fill: {
                    fillEnabled: true,
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
              },
              measureTool: {
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontFamily: 'Calibri',
                  fontSize: 11,
                  fillColor: 'white',
                },
                fill: {
                  fillColor: 'rgba(0, 0, 0, 0.8)',
                },
              },
              abstract: {
                line: {
                  strokeColor: 'white',
                  width: 1,
                },
                fill: {
                  fillColor: 'rgba(255, 255, 255, 0.3)',
                },
                text: {
                  fontFamily: 'Calibri',
                  fontSize: 12,
                  fillColor: 'white',
                  decoration: '',
                },
              },
              trendAngle: {
                line: {
                  strokeColor: 'white',
                  width: 1,
                },
                text: {
                  fontFamily: 'Calibri',
                  fontSize: 12,
                  fillColor: 'white',
                  decoration: '',
                },
                arc: {
                  strokeColor: 'white',
                  width: 1,
                  lineStyle: 'dot',
                },
                horizontalLine: {
                  strokeColor: 'white',
                  width: 1,
                  lineStyle: 'dot',
                },
              },
              abstractMarker: {
                fill: {
                  fillColor: 'white',
                },
              },
              fibonacci: {
                trendLine: {
                  strokeColor: 'white',
                  width: 1,
                  lineStyle: 'dash',
                },
                line: {
                  strokeColor: 'white',
                  width: 1,
                },
                fill: {
                  fillColor: 'rgba(255, 255, 255, 0.3)',
                },
                text: {
                  fontFamily: 'Calibri',
                  fontSize: 12,
                  fillColor: 'white',
                },
              },
              arrowUp: {
                fill: {
                  fillColor: 'limegreen',
                },
              },
              arrowDown: {
                fill: {
                  fillColor: 'red',
                },
              },
              text: {
                text: {
                  fontFamily: 'Calibri',
                  fontSize: 12,
                  fillColor: '#FFFFFF',
                  decoration: '',
                },
              },
              image: {
                noImage: {
                  line: {
                    strokeColor: 'red',
                    width: 1,
                  },
                },
              },
            },
            tooltip: {
              text: {
                fontFamily: 'Calibri',
                fontSize: 12,
                fillColor: 'white',
                fontWeight: 'normal',
                fontStyle: 'normal',
                decoration: '',
              },
              border: {
                enabled: true,
                width: 1,
                color: 'orange',
                lineStyle: 'solid',
              },
              fill: {
                enabled: true,
                color: 'rgb(44, 44, 44)',
              },
            },
            spread: {
              ask: {
                line: {
                  width: 1,
                  strokeColor: '#00D533',
                },
                valueMarker: {
                  fill: {
                    fillColor: '#00D533',
                  },
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fontStyle: 'normal',
                    fillColor: 'white',
                  },
                },
              },
              bid: {
                line: {
                  width: 1,
                  strokeColor: '#F20500',
                },
                valueMarker: {
                  fill: {
                    fillColor: '#F20500',
                  },
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fontStyle: 'normal',
                    fillColor: 'white',
                  },
                },
              },
            },
            highlightedColumn: {
              fill: {
                fillColor: 'rgba(90, 130, 182, 0.45)',
              },
            },
            button: {
              accept: {
                fill: {
                  fillColor: 'white',
                },
                line: {
                  strokeColor: 'green',
                  width: 1,
                },
              },
              cancel: {
                fill: {
                  fillColor: 'white',
                },
                line: {
                  strokeColor: 'red',
                  width: 1,
                },
              },
              none: {
                fill: {
                  fillColor: 'white',
                },
              },
            },
            positionSideMarker: {
              showPLInfo: true,
              includeRealizedPL: true,
              displayUnit: 'points',
              positivePLFontColor: 'white',
              positivePLBackgroundColor: 'black',
              negativePLFontColor: 'red',
              negativePLBackgroundColor: 'black',
              positionBarLength: 100,
              positionBarUnit: 'pixels',
              short: {
                length: 1,
                lengthUnit: 'pixels',
                lineColor: '#FF0000',
                lineType: 'solid',
                line: {
                  strokeColor: 'red',
                  width: 1,
                },
                kind: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fontStyle: 'normal',
                    fillColor: 'white',
                    decoration: '',
                  },
                },
                quantity: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                  },
                },
                price: {
                  fill: {
                    fillColor: 'red',
                  },
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                  },
                },
              },
              long: {
                length: 1,
                lengthUnit: 'pixels',
                lineColor: '#0C62F7',
                lineType: 'solid',
                line: {
                  strokeColor: 'green',
                  width: 1,
                },
                kind: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fontStyle: 'normal',
                    fillColor: 'white',
                    decoration: '',
                  },
                },
                quantity: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                  },
                },
                price: {
                  fill: {
                    fillColor: 'blue',
                  },
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                  },
                },
              },
            },
            orderSideMarker: {
              buy: {
                stop: {
                  length: 1,
                  lineType: 'solid',
                  lineColor: '#33537C',
                },
                limit: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#0C62F7',
                },
                market: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#0C62F7',
                },
                stopLimit: {
                  length: 1,
                  lineType: 'dotted',
                  lineColor: '#33537C',
                },
              },
              sell: {
                stop: {
                  length: 1,
                  lineType: 'solid',
                  lineColor: '#C93B3B',
                },
                limit: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#FF0000',
                },
                market: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#FF0000',
                },
                stopLimit: {
                  length: 1,
                  lineType: 'dotted',
                  lineColor: '#C93B3B',
                },
              },
              ocoStopLimit: '#FFFF00',
              ocoStopOrder: '#FFFF00',
              orderBarUnit: 'pixels',
              orderBarLength: 100,
            },
            orderChartMarker: {
              showTopBottomMarkers: true,
              showMarkerAtExecutionPrice: true,
              showTextWithSizeAndPrice: true,
              buy: {
                stroke: {
                  strokeEnabled: true,
                  width: 1,
                  strokeColor: 'white',
                },
                fill: {
                  fillColor: 'blue',
                },
                markerType: 'triangle',
                tooltip: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 12,
                    fillColor: 'blue',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    decoration: '',
                  },
                  border: {
                    enabled: false,
                    width: 1,
                    color: 'orange',
                    lineStyle: 'solid',
                  },
                  fill: {
                    enabled: true,
                    color: 'black',
                  },
                },
              },
              sell: {
                stroke: {
                  strokeEnabled: true,
                  width: 1,
                  strokeColor: 'white',
                },
                fill: {
                  fillColor: 'red',
                },
                markerType: 'square',
                tooltip: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 12,
                    fillColor: 'red',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    decoration: '',
                  },
                  border: {
                    enabled: false,
                    width: 1,
                    color: 'orange',
                    lineStyle: 'solid',
                  },
                  fill: {
                    enabled: true,
                    color: 'black',
                  },
                },
              },
            },
          },
          locale: 'en',
          enableKeyboardEvents: false,
          enableMouseEvents: true,
          showBarInfoInTitle: true,
          priceStyle: 'candle',
          showInstrumentWatermark: false,
          xGridVisible: true,
          yGridVisible: true,
          scrollEnabled: true,
          zoomEnabled: true,
          zoomAutoSave: false,
          scale: 2,
        },
        timeFrame: {
          interval: 5,
          periodicity: '',
        },
        periodToLoad: {
          interval: 3,
          periodicity: 'd',
        },
        priceStyle: {
          className: 'candle',
          options: {},
        },
        dateScale: {
          allowPartialRecords: true,
          firstVisibleRecord: 311,
          lastVisibleRecord: 411,
          minVisibleRecords: 5,
          textPadding: {
            top: null,
            left: 3,
            right: 3,
            bottom: 3,
          },
          height: 15,
          useManualHeight: false,
          scrollKind: 'autoscaled',
          zoomKind: 'autoscaled',
          zoomMode: 'pin_mouse',
          autoScrollKind: 'visible_bar',
          autoScrollMode: 'new_bar',
          rightAdditionalSpaceRatio: 0.5,
          majorTickMarkLength: 5,
          minorTickMarkLength: 3,
          formatter: {
            className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
            locale: 'en',
            timeInterval: 300000,
          },
          calibrator: {
            className: 'StockChartX.CustomDateScaleCalibrator',
            options: {},
          },
        },
        valueScales: [
          {
            showLeftPanel: false,
            showRightPanel: true,
            width: 100,
            useManualWidth: false,
          },
        ],
        crossHair: {
          crossHairType: 'crossBars',
        },
        chartPanelsContainer: {
          newPanelHeightRatio: 0.2,
          panelPadding: {
            top: 10,
            left: 5,
            right: 5,
            bottom: 10,
          },
          panels: [
            {
              options: {
                minHeightRatio: 0.05,
                maxHeightRatio: 1,
                heightRatio: 1,
                showXGrid: true,
                showYGrid: true,
                moveDirection: 'any',
                moveKind: 'normal',
                state: 0,
                visible: true,
                showIndicatorTitles: true,
                drawValueScale: true,
              },
              valueScales: [
                {
                  options: {
                    minVisibleValue: 6036,
                    maxVisibleValue: 6062,
                    minAllowedValue: null,
                    maxAllowedValue: null,
                    minAllowedValueRatio: 0.8,
                    maxAllowedValueRatio: 0.8,
                    minValueRangeRatio: 0.1,
                    maxValueRangeRatio: 5,
                    majorTickMarkLength: 3,
                    minorTickMarkLength: 3,
                    padding: {
                      top: 3,
                      left: 6,
                      right: 3,
                      bottom: 3,
                    },
                  },
                  formatter: {
                    className: 'StockChartX.IntlNumberFormat',
                    options: {
                      locale: 'en',
                      numberingSystem: 'latn',
                      style: 'decimal',
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: 'auto',
                      notation: 'standard',
                      signDisplay: 'auto',
                      roundingIncrement: 1,
                      roundingMode: 'halfExpand',
                      roundingPriority: 'auto',
                      trailingZeroDisplay: 'auto',
                    },
                  },
                  calibrator: {
                    className: 'StockChartX.IntervalValueScaleCalibrator',
                    options: {
                      suffix: null,
                      divider: null,
                    },
                  },
                },
              ],
            },
          ],
        },
        instrumentComparisonHandler: {
          instrumentsOptions: [],
        },
        indicators: [
          {
            panelHeightRatio: 1,
            showParamsInTitle: true,
            allowContextMenu: true,
            showValueMarkers: true,
            showValuesInTitle: true,
            allowSettingsDialog: false,
            visible: true,
            showTitle: true,
            showLineLevels: false,
            coloredVolumeTheme: null,
            zIndex: 1000,
            parameters: {
              'Line Color': '#0C62F7',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            panelIndex: 0,
            className: 'VolumeProfile',
            _prevParams: {
              'Line Color': '#0C62F7',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
          },
        ],
        barsCount: false,
        plots: {
          VolumeProfile: {
            general: {
              type: 'volume',
              period: {
                type: 'every',
                value: 1,
                unit: 'day',
                date: null,
              },
              hide: {
                enabled: false,
                value: 'lastProfile',
              },
              vaCorrelation: 0.7,
              smoothed: {
                enabled: false,
                value: 9,
              },
              customTickSize: {
                enabled: false,
                value: 5,
              },
              calculateXProfiles: 999,
              align: 'right',
              width: {
                value: 70,
                unit: 'pixel',
              },
              margin: {
                value: 4,
                unit: 'pixel',
              },
              sessions: {
                current: {
                  enabled: true,
                  width: {
                    value: 70,
                    unit: 'pixel',
                  },
                },
                prev: {
                  enabled: true,
                  width: {
                    value: 70,
                    unit: 'pixel',
                  },
                },
                days: {
                  enabled: true,
                  count: 10,
                  includeCurrentSession: false,
                  width: {
                    value: 70,
                    unit: 'pixel',
                  },
                },
              },
              transparency: 35,
            },
            graphics: {
              summaryEnabled: true,
              showPrices: true,
              summaryFont: {
                fontFamily: 'Open Sans',
                fontSize: 12,
                fillColor: '#0C62F7',
                fontWeight: '400',
              },
            },
            session: null,
            profile: {
              type: 'solidgram',
              color: {
                type: 'profileColor',
                value: '#0C62F7',
              },
              widthCorrelation: 0.5,
              extendNakedPocs: true,
              extendNaked: {
                enabled: false,
                type: 'closesHighsLows',
                strokeTheme: {
                  width: 1,
                  strokeColor: '#0C62F7',
                  lineStyle: 'solid',
                },
              },
              vaInsideOpacity: 0.5,
              vaOutsideOpacity: 0.5,
              profileColor: '',
            },
            lines: {
              current: {
                poc: {
                  enabled: true,
                  strokeTheme: {
                    width: 1,
                    strokeColor: '#FFFF00',
                    lineStyle: 'solid',
                  },
                  labelEnabled: false,
                },
                va: {
                  enabled: true,
                  strokeTheme: {
                    width: 1,
                    strokeColor: '#FFFF00',
                    lineStyle: 'solid',
                  },
                  labelEnabled: false,
                },
              },
              prev: {
                poc: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    strokeColor: '#8D8E30',
                    lineStyle: 'solid',
                  },
                },
                va: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    strokeColor: '#8D8E30',
                    lineStyle: 'solid',
                  },
                },
              },
              dev: {
                poc: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    strokeColor: '#8D8E30',
                    lineStyle: 'solid',
                  },
                },
                va: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    strokeColor: '#8D8E30',
                    lineStyle: 'solid',
                  },
                },
              },
            },
            eth: {
              session: null,
              profile: {
                type: 'solidgram',
                color: {
                  type: 'profileColor',
                  value: '#0C62F7',
                },
                widthCorrelation: 0.5,
                extendNakedPocs: true,
                extendNaked: {
                  enabled: false,
                  type: 'closesHighsLows',
                  strokeTheme: {
                    width: 1,
                    strokeColor: '#0C62F7',
                    lineStyle: 'solid',
                  },
                },
                vaInsideOpacity: 0.5,
                vaOutsideOpacity: 0.5,
                profileColor: '',
              },
              lines: {
                current: {
                  poc: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#FFFF00',
                      lineStyle: 'solid',
                    },
                    labelEnabled: false,
                  },
                  va: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#FFFF00',
                      lineStyle: 'solid',
                    },
                    labelEnabled: false,
                  },
                },
                prev: {
                  poc: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#8D8E30',
                      lineStyle: 'solid',
                    },
                  },
                  va: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#8D8E30',
                      lineStyle: 'solid',
                    },
                  },
                },
                dev: {
                  poc: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#8D8E30',
                      lineStyle: 'solid',
                    },
                  },
                  va: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#8D8E30',
                      lineStyle: 'solid',
                    },
                  },
                },
              },
            },
            splitProfile: false,
            overlayEthOverRth: false,
            highlight: {
              poc: {
                enabled: true,
                value: '#A1A2A5',
              },
              va: {
                enabled: false,
                value: '#A1A2A5',
              },
            },
          },
        },
        drawings: [
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            panelIndex: 0,
            valueScaleIndex: 0,
            className: 'orderchartmarker',
          },
        ],
      },
      intervalOptions: [
        {
          active: false,
          period: 'RevBars',
          timeFrames: [
            {
              interval: 4,
              periodicity: 'revs',
            },
            {
              interval: 8,
              periodicity: 'revs',
            },
            {
              interval: 12,
              periodicity: 'revs',
            },
            {
              interval: 16,
              periodicity: 'revs',
            },
          ],
          periodicities: ['revs'],
        },
        {
          active: false,
          period: 'Seconds',
          timeFrames: [
            {
              interval: 30,
              periodicity: 's',
            },
            {
              interval: 40,
              periodicity: 's',
            },
          ],
          periodicities: ['s'],
        },
        {
          active: false,
          period: 'Minutes',
          timeFrames: [
            {
              interval: 1,
              periodicity: '',
            },
            {
              interval: 3,
              periodicity: '',
            },
            {
              interval: 5,
              periodicity: '',
            },
            {
              interval: 15,
              periodicity: '',
            },
            {
              interval: 30,
              periodicity: '',
            },
          ],
          periodicities: [''],
        },
        {
          active: false,
          period: 'Hours',
          timeFrames: [
            {
              interval: 1,
              periodicity: 'h',
            },
            {
              interval: 2,
              periodicity: 'h',
            },
            {
              interval: 3,
              periodicity: 'h',
            },
            {
              interval: 4,
              periodicity: 'h',
            },
          ],
          periodicities: ['h'],
        },
        {
          active: false,
          period: 'Days',
          timeFrames: [
            {
              interval: 1,
              periodicity: 'd',
            },
            {
              interval: 1,
              periodicity: 'w',
            },
            {
              interval: 1,
              periodicity: 'm',
            },
          ],
          periodicities: ['d', 'm', 'w', 'y'],
        },
      ],
      periodOptions: [
        {
          active: false,
          period: 'Days',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'd',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'd',
            },
            {
              disabled: false,
              interval: 5,
              periodicity: 'd',
            },
          ],
          periodicity: 'd',
        },
        {
          active: false,
          period: 'Weeks',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'w',
            },
            {
              disabled: false,
              interval: 2,
              periodicity: 'w',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'w',
            },
          ],
          periodicity: 'w',
        },
        {
          active: false,
          period: 'Months',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'm',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'm',
            },
            {
              disabled: false,
              interval: 6,
              periodicity: 'm',
            },
          ],
          periodicity: 'm',
        },
      ],
      componentInstanceId: 1730733147214,
      settings: {
        general: {
          font: {
            fontSize: 10,
            textColor: '#fff',
            fontFamily: 'Arial',
          },
          gradient1: 'rgb(27, 29, 34)',
          gradient2: 'rgb(27, 29, 34)',
          gridColor: '#24262C',
          lineColor: '#fff',
          wickColor: '#fff',
          upCandleColor: '#FFE8E8',
          dateScaleColor: 'rgb(27, 29, 34)',
          downCandleColor: '#C93B3B',
          valueScaleColor: 'rgb(27, 29, 34)',
          upCandleBorderColor: '#D0D0D2',
          downCandleBorderColor: '#D0D0D2',
          upCandleBorderColorEnabled: false,
          downCandleBorderColorEnabled: false,
        },
        session: {
          sessionEnabled: true,
          sessionTemplate: {
            id: 3,
            name: 'US ETH+RTH',
            exchange: 'CME',
            timezoneId: 'America/New_York',
            workingTimes: [
              {
                startDay: 0,
                startTime: 64800000,
                endDay: 1,
                endTime: 61200000,
              },
              {
                startDay: 1,
                startTime: 64800000,
                endDay: 2,
                endTime: 61200000,
              },
              {
                startDay: 2,
                startTime: 64800000,
                endDay: 3,
                endTime: 61200000,
              },
              {
                startDay: 3,
                startTime: 64800000,
                endDay: 4,
                endTime: 61200000,
              },
              {
                startDay: 4,
                startTime: 64800000,
                endDay: 5,
                endTime: 61200000,
              },
            ],
            connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
          },
        },
        trading: {
          tif: {
            DAY: true,
            GTC: true,
            default: 'DAY',
          },
          trading: {
            showPLInfo: true,
            displayUnit: 'points',
            orderBarUnit: 'pixels',
            showOHLVInfo: false,
            bracketButton: true,
            orderBarLength: 100,
            tradingBarUnit: 'pixels',
            showOrderConfirm: true,
            tradingBarLength: 40,
            includeRealizedPL: true,
            showCancelConfirm: true,
            showWorkingOrders: true,
            negativePLFontColor: 'red',
            positivePLFontColor: 'white',
            showInstrumentChange: false,
            negativePLBackgroundColor: 'black',
            positivePLBackgroundColor: 'black',
          },
          orderArea: {
            formData: {
              quantity: 10,
              stopLoss: {
                stopLoss: false,
                type: 'StopMarket',
              },
              takeProfit: {
                takeProfit: false,
              },
            },
            amountButtons: [
              {
                value: 1,
              },
              {
                black: true,
                value: 1,
              },
              {
                value: 3,
              },
              {
                value: 5,
              },
              {
                value: 10,
              },
              {
                value: 25,
              },
            ],
            settings: {
              buyButtonsBackgroundColor: '#0C62F7',
              flatButtonsBackgroundColor: '#51535A',
              buyButtonsFontColor: '#D0D0D2',
              flatButtonsFontColor: '#D0D0D2',
              sellButtonsBackgroundColor: '#C93B3B',
              cancelButtonBackgroundColor: '#51535A',
              sellButtonsFontColor: '#D0D0D2',
              cancelButtonFontColor: '#D0D0D2',
              closePositionFontColor: '#D0D0D2',
              closePositionBackgroundColor: '#51535A',
              icebergBackgroundColor: '#51535A',
              icebergFontColor: '#fff',
              formSettings: {
                showIcebergButton: true,
                showFlattenButton: true,
                closePositionButton: true,
                showLiquidateButton: true,
                showCancelButton: true,
                showBuyButton: true,
                showSellButton: true,
                showBracket: true,
                showPLInfo: true,
              },
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              flatten: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              orderArea: {
                showPLInfo: true,
              },
              cancelButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              icebergButton: {
                font: '#fff',
                enabled: true,
                background: '#51535A',
              },
              buyMarketButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#0C62F7',
              },
              sellMarketButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#C93B3B',
              },
              closePositionButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              showLiquidateButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
            },
          },
          chartMarkers: {
            buyMarkerType: 'triangle',
            buyMarkerColor: 'blue',
            sellMarkerType: 'square',
            sellMarkerColor: 'red',
            buyMarkerFontWeight: 12,
            sellMarkerFontWeight: 12,
            dataBoxBackgroundColor: 'black',
            showBarMarkerTobBottom: true,
            showBarTextWithSizePrice: true,
            showBarMarketExecutionPrice: true,
          },
          ordersColors: {
            buy: {
              stop: {
                length: 1,
                lineType: 'solid',
                lineColor: '#33537C',
              },
              limit: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#0C62F7',
              },
              market: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#0C62F7',
              },
              stopLimit: {
                length: 1,
                lineType: 'dotted',
                lineColor: '#33537C',
              },
            },
            sell: {
              stop: {
                length: 1,
                lineType: 'solid',
                lineColor: '#C93B3B',
              },
              limit: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#FF0000',
              },
              market: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#FF0000',
              },
              stopLimit: {
                length: 1,
                lineType: 'dotted',
                lineColor: '#C93B3B',
              },
            },
            ocoStopLimit: '#FFFF00',
            ocoStopOrder: '#FFFF00',
            orderBarUnit: 'pixels',
            orderBarLength: 100,
          },
        },
        valueScale: {
          valueScale: {
            isAutomatic: 'automatic',
            pixelsPrice: 2,
          },
        },
      },
    },
    tabState: {
      id: '1730733147232_0.9148566808085228',
      maximizable: true,
      minimizable: true,
      resizable: true,
      minWidth: 350,
      minHeight: 150,
      allowPopup: true,
      visible: true,
      width: 522.0365601480007,
      height: 367.8527526855469,
      component: {
        state: {
          isToolbarVisible: false,
          showOHLV: false,
          showChanges: false,
          enableOrderForm: false,
          link: 0.36923744970654826,
          showCancelConfirm: true,
          instrument: {
            id: 'ESZ4.CME',
            type: 'Future',
            symbol: 'ESZ4',
            currency: 'USD',
            exchange: 'CME',
            tickSize: 0.25,
            precision: 2,
            expiration: '2024-12-20T00:00:00',
            description: 'E-Mini S&P 500 Dec24',
            productCode: 'ES',
            connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
            contractSize: 50,
            instrumentTimePeriod: 'Dec24',
            stringTypeRepresentation: 'Future',
          },
          timeFrame: {
            interval: 5,
            periodicity: '',
          },
          periodToLoad: {
            interval: 3,
            periodicity: 'd',
          },
          stockChartXState: {
            chart: {
              theme: {
                name: 'Dark',
                chart: {
                  background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
                  border: {
                    width: 1,
                    strokeColor: 'grey',
                    lineStyle: 'solid',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontFamily: 'Arial',
                      fontSize: 70,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                    details: {
                      fontFamily: 'Arial',
                      fontSize: 40,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                  },
                },
                splitter: {
                  fillColor: '#292C38',
                  hoverFillColor: 'white',
                },
                chartPanel: {
                  grid: {
                    width: 1,
                    strokeColor: '#24262C',
                  },
                  title: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fontStyle: 'normal',
                    fillColor: 'white',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontFamily: 'Arial',
                      fontSize: 70,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                    details: {
                      fontFamily: 'Arial',
                      fontSize: 40,
                      fontStyle: 'normal',
                      fillColor: 'white',
                    },
                  },
                },
                tradingPanel: {
                  tradingBarUnit: 'pixels',
                  tradingBarLength: 40,
                  buyPanelBackground: 'rgba(12,98,247, 0.3)',
                  sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
                  buyMarkerBackground: '#0C62F7',
                  sellMarkerBackground: '#C93B3B',
                  markerFontColor: '#fff',
                  showPLInfo: true,
                  includeRealizedPL: true,
                  displayUnit: 'points',
                  positivePLFontColor: 'white',
                  positivePLBackgroundColor: 'black',
                  negativePLFontColor: 'red',
                  negativePLBackgroundColor: 'black',
                },
                chartMarkers: {
                  showBarMarkerTobBottom: true,
                  showBarMarketExecutionPrice: true,
                  showBarTextWithSizePrice: true,
                  dataBoxBackgroundColor: 'black',
                  buyMarkerColor: 'blue',
                  buyMarkerType: 'triangle',
                  buyMarkerFontWeight: 1,
                  sellMarkerColor: 'red',
                  sellMarkerType: 'square',
                  sellMarkerFontWeight: 1,
                },
                valueScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  text: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fontStyle: 'normal',
                    fillColor: '#fff',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  border: {
                    width: 0,
                    strokeColor: 'darkgray',
                    lineStyle: 'solid',
                  },
                  valueMarker: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: '#0C62F7',
                    },
                  },
                },
                dateScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  text: {
                    fontFamily: 'Arial',
                    fontSize: 10,
                    fillColor: '#fff',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  border: {
                    width: 0,
                    strokeColor: 'darkgray',
                    lineStyle: 'solid',
                  },
                  dateMarker: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: '#FFFFFF',
                    },
                    fill: {
                      fillColor: 'green',
                    },
                    stroke: {
                      strokePriority: 'color',
                      strokeColor: '#696969',
                      width: 1,
                      lineStyle: 'solid',
                      lineJoin: 'miter',
                      lineCap: 'butt',
                    },
                  },
                  breakLine: {
                    stroke: {
                      strokeColor: '#545454',
                      width: 1,
                      lineStyle: 'dash',
                    },
                  },
                },
                crossHair: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 11,
                    fillColor: '#000000',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'darkgray',
                    lineStyle: 'dash',
                  },
                  fill: {
                    fillColor: '#FFFFFF',
                  },
                },
                zoomIn: {
                  border: {
                    width: 1,
                    strokeColor: 'white',
                    lineStyle: 'solid',
                  },
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                plot: {
                  point: {
                    dot: {
                      fill: {
                        fillColor: 'white',
                      },
                    },
                  },
                  line: {
                    simple: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    mountain: {
                      line: {
                        width: 1,
                        strokeColor: '#fff',
                      },
                      fill: {
                        linearGradient: [
                          'rgba(63, 167, 225, 0.4) ',
                          'rgba(63, 167, 225, 0)',
                        ],
                      },
                    },
                    step: {
                      width: 1,
                      strokeColor: 'white',
                    },
                  },
                  histogram: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    coloredLine: {
                      upBar: {
                        width: 1,
                        strokeColor: 'grey',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                    column: {
                      line: {
                        strokeEnabled: false,
                        width: 1,
                        strokeColor: 'white',
                      },
                      fill: {
                        fillColor: 'blue',
                      },
                    },
                    coloredColumn: {
                      upBar: {
                        line: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: 'green',
                        },
                      },
                      downBar: {
                        line: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: 'red',
                        },
                      },
                    },
                  },
                  bar: {
                    OHLC: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    HLC: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    HL: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    coloredOHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#FFE8E8',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#C93B3B',
                      },
                    },
                    coloredHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    coloredHL: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    candle: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                    },
                    heikinAshi: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                      },
                    },
                    renko: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: 'white',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    lineBreak: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillEnabled: true,
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillEnabled: true,
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    hollowCandle: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      upHollowCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downHollowCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    pointAndFigure: {
                      upCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    kagi: {
                      upCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: true,
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    candleVolume: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                      },
                    },
                    equiVolume: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                    equiVolumeShadow: {
                      upCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                      },
                      downCandle: {
                        border: {
                          strokeEnabled: false,
                          width: 1,
                          strokeColor: '#D0D0D2',
                        },
                        fill: {
                          fillColor: '#C93B3B',
                        },
                      },
                    },
                  },
                  indicator: {
                    line1: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'white',
                    },
                    line2: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#3FA7E1',
                    },
                    line3: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#E85050',
                    },
                    line4: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0000FF',
                    },
                    line5: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'yellow',
                    },
                    histogram: {
                      color: '#32CD32',
                    },
                    blackText: {
                      fontFamily: 'Arial',
                      fontSize: 10,
                      fontStyle: 'normal',
                      fillColor: 'black',
                    },
                    levels: {
                      line1: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FF0000',
                      },
                      line2: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#32CD32',
                      },
                      line3: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFD500',
                      },
                      line4: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0000FF',
                      },
                      line5: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#808080',
                      },
                    },
                  },
                },
                selectionMarker: {
                  line: {
                    strokeColor: 'white',
                    width: 1,
                  },
                  fill: {
                    fillColor: 'black',
                  },
                },
                drawing: {
                  note: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: 'orange',
                    },
                    centerPointFill: {
                      fillColor: 'rgb(68, 68, 68)',
                    },
                  },
                  measure: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                      lineStyle: 'dash',
                    },
                    border: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                      lineStyle: 'dash',
                    },
                    fill: {
                      fillEnabled: true,
                      fillColor: 'rgba(255, 255, 255, 0.5)',
                    },
                    balloon: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                      },
                      border: {
                        width: 1,
                        strokeColor: 'darkgray',
                        strokeEnabled: true,
                        lineStyle: 'solid',
                      },
                      fill: {
                        fillEnabled: true,
                        fillColor: 'rgba(255, 255, 255, 0.5)',
                      },
                    },
                  },
                  measureTool: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 11,
                      fillColor: 'white',
                    },
                    fill: {
                      fillColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  },
                  abstract: {
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                    },
                  },
                  trendAngle: {
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                    },
                    arc: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dot',
                    },
                    horizontalLine: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dot',
                    },
                  },
                  abstractMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                  fibonacci: {
                    trendLine: {
                      strokeColor: 'white',
                      width: 1,
                      lineStyle: 'dash',
                    },
                    line: {
                      strokeColor: 'white',
                      width: 1,
                    },
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: 'white',
                    },
                  },
                  arrowUp: {
                    fill: {
                      fillColor: 'limegreen',
                    },
                  },
                  arrowDown: {
                    fill: {
                      fillColor: 'red',
                    },
                  },
                  text: {
                    text: {
                      fontFamily: 'Calibri',
                      fontSize: 12,
                      fillColor: '#FFFFFF',
                      decoration: '',
                    },
                  },
                  image: {
                    noImage: {
                      line: {
                        strokeColor: 'red',
                        width: 1,
                      },
                    },
                  },
                },
                tooltip: {
                  text: {
                    fontFamily: 'Calibri',
                    fontSize: 12,
                    fillColor: 'white',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    decoration: '',
                  },
                  border: {
                    enabled: true,
                    width: 1,
                    color: 'orange',
                    lineStyle: 'solid',
                  },
                  fill: {
                    enabled: true,
                    color: 'rgb(44, 44, 44)',
                  },
                },
                spread: {
                  ask: {
                    line: {
                      width: 1,
                      strokeColor: '#00D533',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#00D533',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                      },
                    },
                  },
                  bid: {
                    line: {
                      width: 1,
                      strokeColor: '#F20500',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#F20500',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                      },
                    },
                  },
                },
                highlightedColumn: {
                  fill: {
                    fillColor: 'rgba(90, 130, 182, 0.45)',
                  },
                },
                button: {
                  accept: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      strokeColor: 'green',
                      width: 1,
                    },
                  },
                  cancel: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      strokeColor: 'red',
                      width: 1,
                    },
                  },
                  none: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                positionSideMarker: {
                  showPLInfo: true,
                  includeRealizedPL: true,
                  displayUnit: 'points',
                  positivePLFontColor: 'white',
                  positivePLBackgroundColor: 'black',
                  negativePLFontColor: 'red',
                  negativePLBackgroundColor: 'black',
                  positionBarLength: 100,
                  positionBarUnit: 'pixels',
                  short: {
                    length: 1,
                    lengthUnit: 'pixels',
                    lineColor: '#FF0000',
                    lineType: 'solid',
                    line: {
                      strokeColor: 'red',
                      width: 1,
                    },
                    kind: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    quantity: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    price: {
                      fill: {
                        fillColor: 'red',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                  },
                  long: {
                    length: 1,
                    lengthUnit: 'pixels',
                    lineColor: '#0C62F7',
                    lineType: 'solid',
                    line: {
                      strokeColor: 'green',
                      width: 1,
                    },
                    kind: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fontStyle: 'normal',
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    quantity: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                    price: {
                      fill: {
                        fillColor: 'blue',
                      },
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                      },
                    },
                  },
                },
                orderSideMarker: {
                  buy: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#33537C',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#33537C',
                    },
                  },
                  sell: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#C93B3B',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#C93B3B',
                    },
                  },
                  ocoStopLimit: '#FFFF00',
                  ocoStopOrder: '#FFFF00',
                  orderBarUnit: 'pixels',
                  orderBarLength: 100,
                },
                orderChartMarker: {
                  showTopBottomMarkers: true,
                  showMarkerAtExecutionPrice: true,
                  showTextWithSizeAndPrice: true,
                  buy: {
                    stroke: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'blue',
                    },
                    markerType: 'triangle',
                    tooltip: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 12,
                        fillColor: 'blue',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        decoration: '',
                      },
                      border: {
                        enabled: false,
                        width: 1,
                        color: 'orange',
                        lineStyle: 'solid',
                      },
                      fill: {
                        enabled: true,
                        color: 'black',
                      },
                    },
                  },
                  sell: {
                    stroke: {
                      strokeEnabled: true,
                      width: 1,
                      strokeColor: 'white',
                    },
                    fill: {
                      fillColor: 'red',
                    },
                    markerType: 'square',
                    tooltip: {
                      text: {
                        fontFamily: 'Calibri',
                        fontSize: 12,
                        fillColor: 'red',
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        decoration: '',
                      },
                      border: {
                        enabled: false,
                        width: 1,
                        color: 'orange',
                        lineStyle: 'solid',
                      },
                      fill: {
                        enabled: true,
                        color: 'black',
                      },
                    },
                  },
                },
              },
              locale: 'en',
              enableKeyboardEvents: false,
              enableMouseEvents: true,
              showBarInfoInTitle: true,
              priceStyle: 'candle',
              showInstrumentWatermark: false,
              xGridVisible: true,
              yGridVisible: true,
              scrollEnabled: true,
              zoomEnabled: true,
              zoomAutoSave: false,
              scale: 2,
            },
            timeFrame: {
              interval: 5,
              periodicity: '',
            },
            periodToLoad: {
              interval: 3,
              periodicity: 'd',
            },
            priceStyle: {
              className: 'candle',
              options: {},
            },
            dateScale: {
              allowPartialRecords: true,
              firstVisibleRecord: 311,
              lastVisibleRecord: 411,
              minVisibleRecords: 5,
              textPadding: {
                top: null,
                left: 3,
                right: 3,
                bottom: 3,
              },
              height: 15,
              useManualHeight: false,
              scrollKind: 'autoscaled',
              zoomKind: 'autoscaled',
              zoomMode: 'pin_mouse',
              autoScrollKind: 'visible_bar',
              autoScrollMode: 'new_bar',
              rightAdditionalSpaceRatio: 0.5,
              majorTickMarkLength: 5,
              minorTickMarkLength: 3,
              formatter: {
                className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
                locale: 'en',
                timeInterval: 300000,
              },
              calibrator: {
                className: 'StockChartX.CustomDateScaleCalibrator',
                options: {},
              },
            },
            valueScales: [
              {
                showLeftPanel: false,
                showRightPanel: true,
                width: 100,
                useManualWidth: false,
              },
            ],
            crossHair: {
              crossHairType: 'crossBars',
            },
            chartPanelsContainer: {
              newPanelHeightRatio: 0.2,
              panelPadding: {
                top: 10,
                left: 5,
                right: 5,
                bottom: 10,
              },
              panels: [
                {
                  options: {
                    minHeightRatio: 0.05,
                    maxHeightRatio: 1,
                    heightRatio: 1,
                    showXGrid: true,
                    showYGrid: true,
                    moveDirection: 'any',
                    moveKind: 'normal',
                    state: 0,
                    visible: true,
                    showIndicatorTitles: true,
                    drawValueScale: true,
                  },
                  valueScales: [
                    {
                      options: {
                        minVisibleValue: 6036,
                        maxVisibleValue: 6062,
                        minAllowedValue: null,
                        maxAllowedValue: null,
                        minAllowedValueRatio: 0.8,
                        maxAllowedValueRatio: 0.8,
                        minValueRangeRatio: 0.1,
                        maxValueRangeRatio: 5,
                        majorTickMarkLength: 3,
                        minorTickMarkLength: 3,
                        padding: {
                          top: 3,
                          left: 6,
                          right: 3,
                          bottom: 3,
                        },
                      },
                      formatter: {
                        className: 'StockChartX.IntlNumberFormat',
                        options: {
                          locale: 'en',
                          numberingSystem: 'latn',
                          style: 'decimal',
                          minimumIntegerDigits: 1,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          useGrouping: 'auto',
                          notation: 'standard',
                          signDisplay: 'auto',
                          roundingIncrement: 1,
                          roundingMode: 'halfExpand',
                          roundingPriority: 'auto',
                          trailingZeroDisplay: 'auto',
                        },
                      },
                      calibrator: {
                        className: 'StockChartX.IntervalValueScaleCalibrator',
                        options: {
                          suffix: null,
                          divider: null,
                        },
                      },
                    },
                  ],
                },
              ],
            },
            instrumentComparisonHandler: {
              instrumentsOptions: [],
            },
            indicators: [
              {
                panelHeightRatio: 1,
                showParamsInTitle: true,
                allowContextMenu: true,
                showValueMarkers: true,
                showValuesInTitle: true,
                allowSettingsDialog: false,
                visible: true,
                showTitle: true,
                showLineLevels: false,
                coloredVolumeTheme: null,
                zIndex: 1000,
                parameters: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                panelIndex: 0,
                className: 'VolumeProfile',
                _prevParams: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
              },
            ],
            barsCount: false,
            plots: {
              VolumeProfile: {
                general: {
                  type: 'volume',
                  period: {
                    type: 'every',
                    value: 1,
                    unit: 'day',
                    date: null,
                  },
                  hide: {
                    enabled: false,
                    value: 'lastProfile',
                  },
                  vaCorrelation: 0.7,
                  smoothed: {
                    enabled: false,
                    value: 9,
                  },
                  customTickSize: {
                    enabled: false,
                    value: 5,
                  },
                  calculateXProfiles: 999,
                  align: 'right',
                  width: {
                    value: 70,
                    unit: 'pixel',
                  },
                  margin: {
                    value: 4,
                    unit: 'pixel',
                  },
                  sessions: {
                    current: {
                      enabled: true,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                    prev: {
                      enabled: true,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                    days: {
                      enabled: true,
                      count: 10,
                      includeCurrentSession: false,
                      width: {
                        value: 70,
                        unit: 'pixel',
                      },
                    },
                  },
                  transparency: 35,
                },
                graphics: {
                  summaryEnabled: true,
                  showPrices: true,
                  summaryFont: {
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fillColor: '#0C62F7',
                    fontWeight: '400',
                  },
                },
                session: null,
                profile: {
                  type: 'solidgram',
                  color: {
                    type: 'profileColor',
                    value: '#0C62F7',
                  },
                  widthCorrelation: 0.5,
                  extendNakedPocs: true,
                  extendNaked: {
                    enabled: false,
                    type: 'closesHighsLows',
                    strokeTheme: {
                      width: 1,
                      strokeColor: '#0C62F7',
                      lineStyle: 'solid',
                    },
                  },
                  vaInsideOpacity: 0.5,
                  vaOutsideOpacity: 0.5,
                  profileColor: '',
                },
                lines: {
                  current: {
                    poc: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#FFFF00',
                        lineStyle: 'solid',
                      },
                      labelEnabled: false,
                    },
                    va: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#FFFF00',
                        lineStyle: 'solid',
                      },
                      labelEnabled: false,
                    },
                  },
                  prev: {
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                  },
                  dev: {
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#8D8E30',
                        lineStyle: 'solid',
                      },
                    },
                  },
                },
                eth: {
                  session: null,
                  profile: {
                    type: 'solidgram',
                    color: {
                      type: 'profileColor',
                      value: '#0C62F7',
                    },
                    widthCorrelation: 0.5,
                    extendNakedPocs: true,
                    extendNaked: {
                      enabled: false,
                      type: 'closesHighsLows',
                      strokeTheme: {
                        width: 1,
                        strokeColor: '#0C62F7',
                        lineStyle: 'solid',
                      },
                    },
                    vaInsideOpacity: 0.5,
                    vaOutsideOpacity: 0.5,
                    profileColor: '',
                  },
                  lines: {
                    current: {
                      poc: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                        labelEnabled: false,
                      },
                      va: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#FFFF00',
                          lineStyle: 'solid',
                        },
                        labelEnabled: false,
                      },
                    },
                    prev: {
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                    },
                    dev: {
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          strokeColor: '#8D8E30',
                          lineStyle: 'solid',
                        },
                      },
                    },
                  },
                },
                splitProfile: false,
                overlayEthOverRth: false,
                highlight: {
                  poc: {
                    enabled: true,
                    value: '#A1A2A5',
                  },
                  va: {
                    enabled: false,
                    value: '#A1A2A5',
                  },
                },
              },
            },
            drawings: [
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                panelIndex: 0,
                valueScaleIndex: 0,
                className: 'orderchartmarker',
              },
            ],
          },
          intervalOptions: [
            {
              active: false,
              period: 'RevBars',
              timeFrames: [
                {
                  interval: 4,
                  periodicity: 'revs',
                },
                {
                  interval: 8,
                  periodicity: 'revs',
                },
                {
                  interval: 12,
                  periodicity: 'revs',
                },
                {
                  interval: 16,
                  periodicity: 'revs',
                },
              ],
              periodicities: ['revs'],
            },
            {
              active: false,
              period: 'Seconds',
              timeFrames: [
                {
                  interval: 30,
                  periodicity: 's',
                },
                {
                  interval: 40,
                  periodicity: 's',
                },
              ],
              periodicities: ['s'],
            },
            {
              active: false,
              period: 'Minutes',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: '',
                },
                {
                  interval: 3,
                  periodicity: '',
                },
                {
                  interval: 5,
                  periodicity: '',
                },
                {
                  interval: 15,
                  periodicity: '',
                },
                {
                  interval: 30,
                  periodicity: '',
                },
              ],
              periodicities: [''],
            },
            {
              active: false,
              period: 'Hours',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'h',
                },
                {
                  interval: 2,
                  periodicity: 'h',
                },
                {
                  interval: 3,
                  periodicity: 'h',
                },
                {
                  interval: 4,
                  periodicity: 'h',
                },
              ],
              periodicities: ['h'],
            },
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 1,
                  periodicity: 'm',
                },
              ],
              periodicities: ['d', 'm', 'w', 'y'],
            },
          ],
          periodOptions: [
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 5,
                  periodicity: 'd',
                },
              ],
              periodicity: 'd',
            },
            {
              active: false,
              period: 'Weeks',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 2,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'w',
                },
              ],
              periodicity: 'w',
            },
            {
              active: false,
              period: 'Months',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 6,
                  periodicity: 'm',
                },
              ],
              periodicity: 'm',
            },
          ],
          componentInstanceId: 1730733147214,
          settings: {
            general: {
              font: {
                fontSize: 10,
                textColor: '#fff',
                fontFamily: 'Arial',
              },
              gradient1: 'rgb(27, 29, 34)',
              gradient2: 'rgb(27, 29, 34)',
              gridColor: '#24262C',
              lineColor: '#fff',
              wickColor: '#fff',
              upCandleColor: '#FFE8E8',
              dateScaleColor: 'rgb(27, 29, 34)',
              downCandleColor: '#C93B3B',
              valueScaleColor: 'rgb(27, 29, 34)',
              upCandleBorderColor: '#D0D0D2',
              downCandleBorderColor: '#D0D0D2',
              upCandleBorderColorEnabled: false,
              downCandleBorderColorEnabled: false,
            },
            session: {
              sessionEnabled: true,
              sessionTemplate: {
                id: 3,
                name: 'US ETH+RTH',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                workingTimes: [
                  {
                    startDay: 0,
                    startTime: 64800000,
                    endDay: 1,
                    endTime: 61200000,
                  },
                  {
                    startDay: 1,
                    startTime: 64800000,
                    endDay: 2,
                    endTime: 61200000,
                  },
                  {
                    startDay: 2,
                    startTime: 64800000,
                    endDay: 3,
                    endTime: 61200000,
                  },
                  {
                    startDay: 3,
                    startTime: 64800000,
                    endDay: 4,
                    endTime: 61200000,
                  },
                  {
                    startDay: 4,
                    startTime: 64800000,
                    endDay: 5,
                    endTime: 61200000,
                  },
                ],
                connectionId: 'c288af9a-d4c1-4e4a-af1d-b034fbca8776',
              },
            },
            trading: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              trading: {
                showPLInfo: true,
                displayUnit: 'points',
                orderBarUnit: 'pixels',
                showOHLVInfo: false,
                bracketButton: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: true,
                tradingBarLength: 40,
                includeRealizedPL: true,
                showCancelConfirm: true,
                showWorkingOrders: true,
                negativePLFontColor: 'red',
                positivePLFontColor: 'white',
                showInstrumentChange: false,
                negativePLBackgroundColor: 'black',
                positivePLBackgroundColor: 'black',
              },
              orderArea: {
                formData: {
                  quantity: 10,
                  stopLoss: {
                    stopLoss: false,
                    type: 'StopMarket',
                  },
                  takeProfit: {
                    takeProfit: false,
                  },
                },
                amountButtons: [
                  {
                    value: 1,
                  },
                  {
                    black: true,
                    value: 1,
                  },
                  {
                    value: 3,
                  },
                  {
                    value: 5,
                  },
                  {
                    value: 10,
                  },
                  {
                    value: 25,
                  },
                ],
                settings: {
                  buyButtonsBackgroundColor: '#0C62F7',
                  flatButtonsBackgroundColor: '#51535A',
                  buyButtonsFontColor: '#D0D0D2',
                  flatButtonsFontColor: '#D0D0D2',
                  sellButtonsBackgroundColor: '#C93B3B',
                  cancelButtonBackgroundColor: '#51535A',
                  sellButtonsFontColor: '#D0D0D2',
                  cancelButtonFontColor: '#D0D0D2',
                  closePositionFontColor: '#D0D0D2',
                  closePositionBackgroundColor: '#51535A',
                  icebergBackgroundColor: '#51535A',
                  icebergFontColor: '#fff',
                  formSettings: {
                    showIcebergButton: true,
                    showFlattenButton: true,
                    closePositionButton: true,
                    showLiquidateButton: true,
                    showCancelButton: true,
                    showBuyButton: true,
                    showSellButton: true,
                    showBracket: true,
                    showPLInfo: true,
                  },
                  tif: {
                    DAY: true,
                    GTC: true,
                    default: 'DAY',
                  },
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  orderArea: {
                    showPLInfo: true,
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                },
              },
              chartMarkers: {
                buyMarkerType: 'triangle',
                buyMarkerColor: 'blue',
                sellMarkerType: 'square',
                sellMarkerColor: 'red',
                buyMarkerFontWeight: 12,
                sellMarkerFontWeight: 12,
                dataBoxBackgroundColor: 'black',
                showBarMarkerTobBottom: true,
                showBarTextWithSizePrice: true,
                showBarMarketExecutionPrice: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
                orderBarUnit: 'pixels',
                orderBarLength: 100,
              },
            },
            valueScale: {
              valueScale: {
                isAutomatic: 'automatic',
                pixelsPrice: 2,
              },
            },
          },
          preferredAccounts: {
            'd4135277-3f43-4d35-a646-8d7b2c7673b1': 'S1Nov208384304',
            'd428b3e6-bede-4e2a-bab6-325dcd1339e7': '42432ppr3',
            'e1c9560d-3714-4320-b114-34fd00c258c5': 'FTT-GT-52444',
            'c288af9a-d4c1-4e4a-af1d-b034fbca8776': 'APEX-41272-106',
          },
        },
        name: 'chart',
      },
    },
    id: 1733175140003,
    name: 'Default',
    type: 'chart',
    isDefault: true,
  },
  {
    id: 1733175140004,
    name: '1min ',
    type: 'chart',
    state: {
      link: 0.36923744970654826,
      settings: {
        general: {
          font: {
            fontSize: 10,
            textColor: '#fff',
            fontFamily: 'Arial',
          },
          gradient1: 'rgb(27, 29, 34)',
          gradient2: 'rgb(27, 29, 34)',
          gridColor: '#24262C',
          lineColor: '#fff',
          wickColor: '#fff',
          upCandleColor: '#FFE8E8',
          dateScaleColor: 'rgb(27, 29, 34)',
          downCandleColor: '#C93B3B',
          valueScaleColor: 'rgb(27, 29, 34)',
          upCandleBorderColor: '#D0D0D2',
          downCandleBorderColor: '#D0D0D2',
          upCandleBorderColorEnabled: false,
          downCandleBorderColorEnabled: false,
        },
        session: {
          sessionEnabled: true,
          sessionTemplate: {
            id: 628,
            name: 'US Extended Trading Hours',
            exchange: 'CME',
            timezoneId: 'America/New_York',
            workingTimes: [
              {
                endDay: 5,
                endTime: 61200000,
                startDay: 4,
                startTime: 64800000,
                tradingDay: 5,
              },
              {
                endDay: 4,
                endTime: 61200000,
                startDay: 3,
                startTime: 64800000,
                tradingDay: 4,
              },
              {
                endDay: 3,
                endTime: 61200000,
                startDay: 2,
                startTime: 64800000,
                tradingDay: 3,
              },
              {
                endDay: 2,
                endTime: 61200000,
                startDay: 1,
                startTime: 64800000,
                tradingDay: 2,
              },
              {
                endDay: 1,
                endTime: 61200000,
                startDay: 0,
                startTime: 64800000,
                tradingDay: 1,
              },
            ],
          },
        },
        trading: {
          tif: {
            DAY: true,
            GTC: true,
            default: 'DAY',
          },
          trading: {
            showPLInfo: true,
            displayUnit: 'points',
            orderBarUnit: 'pixels',
            showOHLVInfo: false,
            bracketButton: true,
            orderBarLength: 100,
            tradingBarUnit: 'pixels',
            showOrderConfirm: true,
            tradingBarLength: 40,
            includeRealizedPL: true,
            showCancelConfirm: true,
            showWorkingOrders: true,
            negativePLFontColor: 'red',
            positivePLFontColor: 'white',
            showInstrumentChange: false,
            negativePLBackgroundColor: 'black',
            positivePLBackgroundColor: 'black',
          },
          orderArea: {
            formData: {
              quantity: 10,
              stopLoss: {
                type: 'StopMarket',
                stopLoss: false,
              },
              takeProfit: {
                takeProfit: false,
              },
            },
            settings: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              flatten: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              orderArea: {
                showPLInfo: true,
              },
              cancelButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              formSettings: {
                showPLInfo: true,
                showBracket: true,
                showBuyButton: true,
                showSellButton: true,
                showCancelButton: true,
                showFlattenButton: true,
                showIcebergButton: true,
                closePositionButton: true,
                showLiquidateButton: true,
              },
              icebergButton: {
                font: '#fff',
                enabled: true,
                background: '#51535A',
              },
              buyMarketButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#0C62F7',
              },
              icebergFontColor: '#fff',
              sellMarketButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#C93B3B',
              },
              buyButtonsFontColor: '#D0D0D2',
              closePositionButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              showLiquidateButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              flatButtonsFontColor: '#D0D0D2',
              sellButtonsFontColor: '#D0D0D2',
              cancelButtonFontColor: '#D0D0D2',
              closePositionFontColor: '#D0D0D2',
              icebergBackgroundColor: '#51535A',
              buyButtonsBackgroundColor: '#0C62F7',
              flatButtonsBackgroundColor: '#51535A',
              sellButtonsBackgroundColor: '#C93B3B',
              cancelButtonBackgroundColor: '#51535A',
              closePositionBackgroundColor: '#51535A',
            },
            amountButtons: [
              {
                value: 1,
              },
              {
                black: true,
                value: 1,
              },
              {
                value: 3,
              },
              {
                value: 5,
              },
              {
                value: 10,
              },
              {
                value: 25,
              },
            ],
          },
          chartMarkers: {
            buyMarkerType: 'triangle',
            buyMarkerColor: 'blue',
            sellMarkerType: 'square',
            sellMarkerColor: 'red',
            buyMarkerFontWeight: 12,
            sellMarkerFontWeight: 12,
            dataBoxBackgroundColor: 'black',
            showBarMarkerTobBottom: true,
            showBarTextWithSizePrice: true,
            showBarMarketExecutionPrice: true,
          },
          ordersColors: {
            buy: {
              stop: {
                length: 1,
                lineType: 'solid',
                lineColor: '#33537C',
              },
              limit: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#0C62F7',
              },
              market: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#0C62F7',
              },
              stopLimit: {
                length: 1,
                lineType: 'dotted',
                lineColor: '#33537C',
              },
            },
            sell: {
              stop: {
                length: 1,
                lineType: 'solid',
                lineColor: '#C93B3B',
              },
              limit: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#FF0000',
              },
              market: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#FF0000',
              },
              stopLimit: {
                length: 1,
                lineType: 'dotted',
                lineColor: '#C93B3B',
              },
            },
            ocoStopLimit: '#FFFF00',
            ocoStopOrder: '#FFFF00',
            orderBarUnit: 'pixels',
            orderBarLength: 100,
          },
        },
        valueScale: {
          valueScale: {
            isAutomatic: 'pixels-price',
            pixelsPrice: 2,
          },
        },
      },
      showOHLV: false,
      timeFrame: {
        interval: 5,
        periodicity: '',
      },
      instrument: {
        id: 'ESZ4.CME',
        type: 'Future',
        symbol: 'ESZ4',
        currency: 'USD',
        exchange: 'CME',
        tickSize: 0.25,
        precision: 2,
        expiration: '2024-12-20T00:00:00',
        description: 'E-Mini S&P 500 Dec24',
        productCode: 'ES',
        connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
        contractSize: 50,
        instrumentTimePeriod: 'Dec24',
        stringTypeRepresentation: 'Future',
      },
      showChanges: false,
      periodToLoad: {
        interval: 3,
        periodicity: 'd',
      },
      periodOptions: [
        {
          active: false,
          period: 'Days',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'd',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'd',
            },
            {
              disabled: false,
              interval: 5,
              periodicity: 'd',
            },
          ],
          periodicity: 'd',
        },
        {
          active: false,
          period: 'Weeks',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'w',
            },
            {
              disabled: false,
              interval: 2,
              periodicity: 'w',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'w',
            },
          ],
          periodicity: 'w',
        },
        {
          active: false,
          period: 'Months',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'm',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'm',
            },
            {
              disabled: false,
              interval: 6,
              periodicity: 'm',
            },
          ],
          periodicity: 'm',
        },
      ],
      enableOrderForm: false,
      intervalOptions: [
        {
          active: false,
          period: 'RevBars',
          timeFrames: [
            {
              interval: 4,
              periodicity: 'revs',
            },
            {
              interval: 8,
              periodicity: 'revs',
            },
            {
              interval: 12,
              periodicity: 'revs',
            },
            {
              interval: 16,
              periodicity: 'revs',
            },
          ],
          periodicities: ['revs'],
        },
        {
          active: false,
          period: 'Seconds',
          timeFrames: [
            {
              interval: 30,
              periodicity: 's',
            },
            {
              interval: 40,
              periodicity: 's',
            },
          ],
          periodicities: ['s'],
        },
        {
          active: false,
          period: 'Minutes',
          timeFrames: [
            {
              interval: 1,
              periodicity: '',
            },
            {
              interval: 3,
              periodicity: '',
            },
            {
              interval: 5,
              periodicity: '',
            },
            {
              interval: 15,
              periodicity: '',
            },
            {
              interval: 30,
              periodicity: '',
            },
          ],
          periodicities: [''],
        },
        {
          active: false,
          period: 'Hours',
          timeFrames: [
            {
              interval: 1,
              periodicity: 'h',
            },
            {
              interval: 2,
              periodicity: 'h',
            },
            {
              interval: 3,
              periodicity: 'h',
            },
            {
              interval: 4,
              periodicity: 'h',
            },
          ],
          periodicities: ['h'],
        },
        {
          active: false,
          period: 'Days',
          timeFrames: [
            {
              interval: 1,
              periodicity: 'd',
            },
            {
              interval: 1,
              periodicity: 'w',
            },
            {
              interval: 1,
              periodicity: 'm',
            },
          ],
          periodicities: ['d', 'm', 'w', 'y'],
        },
      ],
      isToolbarVisible: false,
      stockChartXState: {
        chart: {
          scale: 2,
          theme: {
            name: 'Dark',
            plot: {
              bar: {
                HL: {
                  width: 1,
                  strokeColor: 'white',
                },
                HLC: {
                  width: 1,
                  strokeColor: 'white',
                },
                OHLC: {
                  width: 1,
                  strokeColor: '#fff',
                },
                kagi: {
                  upCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#FFE8E8',
                      strokeEnabled: true,
                    },
                  },
                  downCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#C93B3B',
                      strokeEnabled: true,
                    },
                  },
                },
                renko: {
                  upCandle: {
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    border: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    border: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: false,
                    },
                  },
                },
                candle: {
                  upCandle: {
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                coloredHL: {
                  upBar: {
                    width: 1,
                    strokeColor: '#3FA7E1',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#E85050',
                  },
                },
                lineBreak: {
                  upCandle: {
                    fill: {
                      fillColor: '#FFE8E8',
                      fillEnabled: true,
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                      fillEnabled: true,
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                coloredHLC: {
                  upBar: {
                    width: 1,
                    strokeColor: '#3FA7E1',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#E85050',
                  },
                },
                equiVolume: {
                  upCandle: {
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                heikinAshi: {
                  upCandle: {
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                coloredOHLC: {
                  upBar: {
                    width: 1,
                    strokeColor: '#FFE8E8',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#C93B3B',
                  },
                },
                candleVolume: {
                  upCandle: {
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                hollowCandle: {
                  upCandle: {
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  upHollowCandle: {
                    wick: {
                      width: 1,
                      strokeColor: '#FFE8E8',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#FFE8E8',
                    },
                  },
                  downHollowCandle: {
                    wick: {
                      width: 1,
                      strokeColor: '#C93B3B',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#C93B3B',
                    },
                  },
                },
                pointAndFigure: {
                  upCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#FFE8E8',
                      strokeEnabled: true,
                    },
                  },
                  downCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#C93B3B',
                      strokeEnabled: true,
                    },
                  },
                },
                equiVolumeShadow: {
                  upCandle: {
                    fill: {
                      fillColor: '#FFE8E8',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
              },
              line: {
                step: {
                  width: 1,
                  strokeColor: 'white',
                },
                simple: {
                  width: 1,
                  strokeColor: '#fff',
                },
                mountain: {
                  fill: {
                    linearGradient: [
                      'rgba(63, 167, 225, 0.4) ',
                      'rgba(63, 167, 225, 0)',
                    ],
                  },
                  line: {
                    width: 1,
                    strokeColor: '#fff',
                  },
                },
              },
              point: {
                dot: {
                  fill: {
                    fillColor: 'white',
                  },
                },
              },
              histogram: {
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                column: {
                  fill: {
                    fillColor: 'blue',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'white',
                    strokeEnabled: false,
                  },
                },
                coloredLine: {
                  upBar: {
                    width: 1,
                    strokeColor: 'grey',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: 'red',
                  },
                },
                coloredColumn: {
                  upBar: {
                    fill: {
                      fillColor: 'green',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: false,
                    },
                  },
                  downBar: {
                    fill: {
                      fillColor: 'red',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: false,
                    },
                  },
                },
              },
              indicator: {
                line1: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: 'white',
                },
                line2: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#3FA7E1',
                },
                line3: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#E85050',
                },
                line4: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#0000FF',
                },
                line5: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: 'yellow',
                },
                levels: {
                  line1: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FF0000',
                  },
                  line2: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#32CD32',
                  },
                  line3: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FFD500',
                  },
                  line4: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#0000FF',
                  },
                  line5: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#808080',
                  },
                },
                blackText: {
                  fontSize: 10,
                  fillColor: 'black',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
                histogram: {
                  color: '#32CD32',
                },
              },
            },
            chart: {
              border: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: 'grey',
              },
              background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
              instrumentWatermark: {
                symbol: {
                  fontSize: 70,
                  fillColor: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
                details: {
                  fontSize: 40,
                  fillColor: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
              },
            },
            button: {
              none: {
                fill: {
                  fillColor: 'white',
                },
              },
              accept: {
                fill: {
                  fillColor: 'white',
                },
                line: {
                  width: 1,
                  strokeColor: 'green',
                },
              },
              cancel: {
                fill: {
                  fillColor: 'white',
                },
                line: {
                  width: 1,
                  strokeColor: 'red',
                },
              },
            },
            spread: {
              ask: {
                line: {
                  width: 1,
                  strokeColor: '#00D533',
                },
                valueMarker: {
                  fill: {
                    fillColor: '#00D533',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    fontFamily: 'Calibri',
                  },
                },
              },
              bid: {
                line: {
                  width: 1,
                  strokeColor: '#F20500',
                },
                valueMarker: {
                  fill: {
                    fillColor: '#F20500',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    fontFamily: 'Calibri',
                  },
                },
              },
            },
            zoomIn: {
              fill: {
                fillColor: 'rgba(255, 255, 255, 0.5)',
              },
              border: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: 'white',
              },
            },
            drawing: {
              note: {
                fill: {
                  fillColor: 'orange',
                },
                text: {
                  fontSize: 11,
                  fillColor: 'white',
                  fontFamily: 'Calibri',
                },
                centerPointFill: {
                  fillColor: 'rgb(68, 68, 68)',
                },
              },
              text: {
                text: {
                  fontSize: 12,
                  fillColor: '#FFFFFF',
                  decoration: '',
                  fontFamily: 'Calibri',
                },
              },
              image: {
                noImage: {
                  line: {
                    width: 1,
                    strokeColor: 'red',
                  },
                },
              },
              arrowUp: {
                fill: {
                  fillColor: 'limegreen',
                },
              },
              measure: {
                fill: {
                  fillColor: 'rgba(255, 255, 255, 0.5)',
                  fillEnabled: true,
                },
                line: {
                  width: 1,
                  lineStyle: 'dash',
                  strokeColor: 'white',
                  strokeEnabled: true,
                },
                border: {
                  width: 1,
                  lineStyle: 'dash',
                  strokeColor: 'white',
                  strokeEnabled: true,
                },
                balloon: {
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                    fillEnabled: true,
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontFamily: 'Calibri',
                  },
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                    strokeEnabled: true,
                  },
                },
              },
              abstract: {
                fill: {
                  fillColor: 'rgba(255, 255, 255, 0.3)',
                },
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontSize: 12,
                  fillColor: 'white',
                  decoration: '',
                  fontFamily: 'Calibri',
                },
              },
              arrowDown: {
                fill: {
                  fillColor: 'red',
                },
              },
              fibonacci: {
                fill: {
                  fillColor: 'rgba(255, 255, 255, 0.3)',
                },
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontSize: 12,
                  fillColor: 'white',
                  fontFamily: 'Calibri',
                },
                trendLine: {
                  width: 1,
                  lineStyle: 'dash',
                  strokeColor: 'white',
                },
              },
              trendAngle: {
                arc: {
                  width: 1,
                  lineStyle: 'dot',
                  strokeColor: 'white',
                },
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontSize: 12,
                  fillColor: 'white',
                  decoration: '',
                  fontFamily: 'Calibri',
                },
                horizontalLine: {
                  width: 1,
                  lineStyle: 'dot',
                  strokeColor: 'white',
                },
              },
              measureTool: {
                fill: {
                  fillColor: 'rgba(0, 0, 0, 0.8)',
                },
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontSize: 11,
                  fillColor: 'white',
                  fontFamily: 'Calibri',
                },
              },
              abstractMarker: {
                fill: {
                  fillColor: 'white',
                },
              },
            },
            tooltip: {
              fill: {
                color: 'rgb(44, 44, 44)',
                enabled: true,
              },
              text: {
                fontSize: 12,
                fillColor: 'white',
                fontStyle: 'normal',
                decoration: '',
                fontFamily: 'Calibri',
                fontWeight: 'normal',
              },
              border: {
                color: 'orange',
                width: 1,
                enabled: true,
                lineStyle: 'solid',
              },
            },
            splitter: {
              fillColor: '#292C38',
              hoverFillColor: 'white',
            },
            crossHair: {
              fill: {
                fillColor: '#FFFFFF',
              },
              line: {
                width: 1,
                lineStyle: 'dash',
                strokeColor: 'darkgray',
              },
              text: {
                fontSize: 11,
                fillColor: '#000000',
                fontFamily: 'Calibri',
              },
            },
            dateScale: {
              fill: {
                fillColor: 'rgb(27, 29, 34)',
              },
              line: {
                width: 1,
                strokeColor: '#505050',
              },
              text: {
                fontSize: 10,
                fillColor: '#fff',
                fontFamily: 'Arial',
              },
              border: {
                width: 0,
                lineStyle: 'solid',
                strokeColor: 'darkgray',
              },
              breakLine: {
                stroke: {
                  width: 1,
                  lineStyle: 'dash',
                  strokeColor: '#545454',
                },
              },
              dateMarker: {
                fill: {
                  fillColor: 'green',
                },
                text: {
                  fontSize: 11,
                  fillColor: '#FFFFFF',
                  fontFamily: 'Calibri',
                },
                stroke: {
                  width: 1,
                  lineCap: 'butt',
                  lineJoin: 'miter',
                  lineStyle: 'solid',
                  strokeColor: '#696969',
                  strokePriority: 'color',
                },
              },
            },
            chartPanel: {
              grid: {
                width: 1,
                strokeColor: '#24262C',
              },
              title: {
                fontSize: 11,
                fillColor: 'white',
                fontStyle: 'normal',
                fontFamily: 'Calibri',
              },
              instrumentWatermark: {
                symbol: {
                  fontSize: 70,
                  fillColor: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
                details: {
                  fontSize: 40,
                  fillColor: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
              },
            },
            valueScale: {
              fill: {
                fillColor: 'rgb(27, 29, 34)',
              },
              line: {
                width: 1,
                strokeColor: '#505050',
              },
              text: {
                fontSize: 10,
                fillColor: '#fff',
                fontStyle: 'normal',
                fontFamily: 'Arial',
              },
              border: {
                width: 0,
                lineStyle: 'solid',
                strokeColor: 'darkgray',
              },
              valueMarker: {
                fill: {
                  fillColor: '#0C62F7',
                },
                text: {
                  fontSize: 11,
                  fillColor: 'white',
                  fontFamily: 'Calibri',
                },
              },
            },
            chartMarkers: {
              buyMarkerType: 'triangle',
              buyMarkerColor: 'blue',
              sellMarkerType: 'square',
              sellMarkerColor: 'red',
              buyMarkerFontWeight: 1,
              sellMarkerFontWeight: 1,
              dataBoxBackgroundColor: 'black',
              showBarMarkerTobBottom: true,
              showBarTextWithSizePrice: true,
              showBarMarketExecutionPrice: true,
            },
            tradingPanel: {
              showPLInfo: true,
              displayUnit: 'points',
              tradingBarUnit: 'pixels',
              markerFontColor: '#fff',
              tradingBarLength: 40,
              includeRealizedPL: true,
              buyPanelBackground: 'rgba(12,98,247, 0.3)',
              buyMarkerBackground: '#0C62F7',
              negativePLFontColor: 'red',
              positivePLFontColor: 'white',
              sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
              sellMarkerBackground: '#C93B3B',
              negativePLBackgroundColor: 'black',
              positivePLBackgroundColor: 'black',
            },
            orderSideMarker: {
              buy: {
                stop: {
                  length: 1,
                  lineType: 'solid',
                  lineColor: '#33537C',
                },
                limit: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#0C62F7',
                },
                market: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#0C62F7',
                },
                stopLimit: {
                  length: 1,
                  lineType: 'dotted',
                  lineColor: '#33537C',
                },
              },
              sell: {
                stop: {
                  length: 1,
                  lineType: 'solid',
                  lineColor: '#C93B3B',
                },
                limit: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#FF0000',
                },
                market: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#FF0000',
                },
                stopLimit: {
                  length: 1,
                  lineType: 'dotted',
                  lineColor: '#C93B3B',
                },
              },
              ocoStopLimit: '#FFFF00',
              ocoStopOrder: '#FFFF00',
              orderBarUnit: 'pixels',
              orderBarLength: 100,
            },
            selectionMarker: {
              fill: {
                fillColor: 'black',
              },
              line: {
                width: 1,
                strokeColor: 'white',
              },
            },
            orderChartMarker: {
              buy: {
                fill: {
                  fillColor: 'blue',
                },
                stroke: {
                  width: 1,
                  strokeColor: 'white',
                  strokeEnabled: true,
                },
                tooltip: {
                  fill: {
                    color: 'black',
                    enabled: true,
                  },
                  text: {
                    fontSize: 12,
                    fillColor: 'blue',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                    fontWeight: 'normal',
                  },
                  border: {
                    color: 'orange',
                    width: 1,
                    enabled: false,
                    lineStyle: 'solid',
                  },
                },
                markerType: 'triangle',
              },
              sell: {
                fill: {
                  fillColor: 'red',
                },
                stroke: {
                  width: 1,
                  strokeColor: 'white',
                  strokeEnabled: true,
                },
                tooltip: {
                  fill: {
                    color: 'black',
                    enabled: true,
                  },
                  text: {
                    fontSize: 12,
                    fillColor: 'red',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                    fontWeight: 'normal',
                  },
                  border: {
                    color: 'orange',
                    width: 1,
                    enabled: false,
                    lineStyle: 'solid',
                  },
                },
                markerType: 'square',
              },
              showTopBottomMarkers: true,
              showTextWithSizeAndPrice: true,
              showMarkerAtExecutionPrice: true,
            },
            highlightedColumn: {
              fill: {
                fillColor: 'rgba(90, 130, 182, 0.45)',
              },
            },
            positionSideMarker: {
              long: {
                kind: {
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                line: {
                  width: 1,
                  strokeColor: 'green',
                },
                price: {
                  fill: {
                    fillColor: 'blue',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                length: 1,
                lineType: 'solid',
                quantity: {
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                lineColor: '#0C62F7',
                lengthUnit: 'pixels',
              },
              short: {
                kind: {
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                line: {
                  width: 1,
                  strokeColor: 'red',
                },
                price: {
                  fill: {
                    fillColor: 'red',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                length: 1,
                lineType: 'solid',
                quantity: {
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                lineColor: '#FF0000',
                lengthUnit: 'pixels',
              },
              showPLInfo: true,
              displayUnit: 'points',
              positionBarUnit: 'pixels',
              includeRealizedPL: true,
              positionBarLength: 100,
              negativePLFontColor: 'red',
              positivePLFontColor: 'white',
              negativePLBackgroundColor: 'black',
              positivePLBackgroundColor: 'black',
            },
          },
          locale: 'en',
          priceStyle: 'candle',
          zoomEnabled: true,
          xGridVisible: true,
          yGridVisible: true,
          zoomAutoSave: false,
          scrollEnabled: true,
          enableMouseEvents: true,
          showBarInfoInTitle: true,
          enableKeyboardEvents: false,
          showInstrumentWatermark: false,
        },
        plots: {
          VolumeProfile: {
            eth: {
              lines: {
                dev: {
                  va: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#8D8E30',
                    },
                  },
                  poc: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#8D8E30',
                    },
                  },
                },
                prev: {
                  va: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#8D8E30',
                    },
                  },
                  poc: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#8D8E30',
                    },
                  },
                },
                current: {
                  va: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                    labelEnabled: false,
                  },
                  poc: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                    labelEnabled: false,
                  },
                },
              },
              profile: {
                type: 'solidgram',
                color: {
                  type: 'profileColor',
                  value: '#0C62F7',
                },
                extendNaked: {
                  type: 'closesHighsLows',
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#0C62F7',
                  },
                },
                profileColor: '',
                extendNakedPocs: true,
                vaInsideOpacity: 0.5,
                vaOutsideOpacity: 0.5,
                widthCorrelation: 0.5,
              },
              session: null,
            },
            lines: {
              dev: {
                va: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#8D8E30',
                  },
                },
                poc: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#8D8E30',
                  },
                },
              },
              prev: {
                va: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#8D8E30',
                  },
                },
                poc: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#8D8E30',
                  },
                },
              },
              current: {
                va: {
                  enabled: true,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FFFF00',
                  },
                  labelEnabled: false,
                },
                poc: {
                  enabled: true,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FFFF00',
                  },
                  labelEnabled: false,
                },
              },
            },
            general: {
              hide: {
                value: 'lastProfile',
                enabled: false,
              },
              type: 'volume',
              align: 'right',
              width: {
                unit: 'pixel',
                value: 70,
              },
              margin: {
                unit: 'pixel',
                value: 4,
              },
              period: {
                date: null,
                type: 'every',
                unit: 'day',
                value: 1,
              },
              sessions: {
                days: {
                  count: 10,
                  width: {
                    unit: 'pixel',
                    value: 70,
                  },
                  enabled: true,
                  includeCurrentSession: false,
                },
                prev: {
                  width: {
                    unit: 'pixel',
                    value: 70,
                  },
                  enabled: true,
                },
                current: {
                  width: {
                    unit: 'pixel',
                    value: 70,
                  },
                  enabled: true,
                },
              },
              smoothed: {
                value: 9,
                enabled: false,
              },
              transparency: 35,
              vaCorrelation: 0.7,
              customTickSize: {
                value: 5,
                enabled: false,
              },
              calculateXProfiles: 999,
            },
            profile: {
              type: 'solidgram',
              color: {
                type: 'profileColor',
                value: '#0C62F7',
              },
              extendNaked: {
                type: 'closesHighsLows',
                enabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#0C62F7',
                },
              },
              profileColor: '',
              extendNakedPocs: true,
              vaInsideOpacity: 0.5,
              vaOutsideOpacity: 0.5,
              widthCorrelation: 0.5,
            },
            session: null,
            graphics: {
              showPrices: true,
              summaryFont: {
                fontSize: 12,
                fillColor: '#0C62F7',
                fontFamily: 'Open Sans',
                fontWeight: '400',
              },
              summaryEnabled: true,
            },
            highlight: {
              va: {
                value: '#A1A2A5',
                enabled: false,
              },
              poc: {
                value: '#A1A2A5',
                enabled: true,
              },
            },
            splitProfile: false,
            overlayEthOverRth: false,
          },
        },
        drawings: [
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
        ],
        barsCount: false,
        crossHair: {
          crossHairType: 'crossBars',
        },
        dateScale: {
          height: 15,
          zoomKind: 'autoscaled',
          zoomMode: 'pin_mouse',
          formatter: {
            locale: 'en',
            className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
            timeInterval: 300000,
          },
          calibrator: {
            options: {},
            className: 'StockChartX.CustomDateScaleCalibrator',
          },
          scrollKind: 'autoscaled',
          textPadding: {
            top: null,
            left: 3,
            right: 3,
            bottom: 3,
          },
          autoScrollKind: 'visible_bar',
          autoScrollMode: 'new_bar',
          useManualHeight: false,
          lastVisibleRecord: 448,
          minVisibleRecords: 5,
          firstVisibleRecord: 348,
          allowPartialRecords: true,
          majorTickMarkLength: 5,
          minorTickMarkLength: 3,
          rightAdditionalSpaceRatio: 0.5,
        },
        timeFrame: {
          interval: 5,
          periodicity: '',
        },
        indicators: [
          {
            zIndex: 1000,
            visible: true,
            className: 'VolumeProfile',
            showTitle: true,
            panelIndex: 0,
            parameters: {
              'Line Color': '#0C62F7',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            _prevParams: {
              'Line Color': '#0C62F7',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            showLineLevels: false,
            allowContextMenu: true,
            panelHeightRatio: 1,
            showValueMarkers: true,
            showParamsInTitle: true,
            showValuesInTitle: true,
            coloredVolumeTheme: null,
            allowSettingsDialog: false,
          },
        ],
        priceStyle: {
          options: {},
          className: 'candle',
        },
        valueScales: [
          {
            width: 100,
            showLeftPanel: false,
            showRightPanel: true,
            useManualWidth: false,
          },
        ],
        periodToLoad: {
          interval: 3,
          periodicity: 'd',
        },
        chartPanelsContainer: {
          panels: [
            {
              options: {
                state: 0,
                visible: true,
                moveKind: 'normal',
                showXGrid: true,
                showYGrid: true,
                heightRatio: 1,
                moveDirection: 'any',
                drawValueScale: true,
                maxHeightRatio: 1,
                minHeightRatio: 0.05,
                showIndicatorTitles: true,
              },
              valueScales: [
                {
                  options: {
                    padding: {
                      top: 3,
                      left: 6,
                      right: 3,
                      bottom: 3,
                    },
                    maxAllowedValue: null,
                    maxVisibleValue: 6079.813,
                    minAllowedValue: null,
                    minVisibleValue: 6023.187,
                    maxValueRangeRatio: 5,
                    minValueRangeRatio: 0.1,
                    majorTickMarkLength: 3,
                    minorTickMarkLength: 3,
                    maxAllowedValueRatio: 0.8,
                    minAllowedValueRatio: 0.8,
                  },
                  formatter: {
                    options: {
                      style: 'decimal',
                      locale: 'en',
                      notation: 'standard',
                      signDisplay: 'auto',
                      useGrouping: 'auto',
                      roundingMode: 'halfExpand',
                      numberingSystem: 'latn',
                      roundingPriority: 'auto',
                      roundingIncrement: 1,
                      trailingZeroDisplay: 'auto',
                      minimumIntegerDigits: 1,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    },
                    className: 'StockChartX.IntlNumberFormat',
                  },
                  calibrator: {
                    options: {
                      suffix: null,
                      divider: null,
                    },
                    className: 'StockChartX.IntervalValueScaleCalibrator',
                  },
                },
              ],
            },
          ],
          panelPadding: {
            top: 10,
            left: 5,
            right: 5,
            bottom: 10,
          },
          newPanelHeightRatio: 0.2,
        },
        instrumentComparisonHandler: {
          instrumentsOptions: [],
        },
      },
      preferredAccounts: {
        'c288af9a-d4c1-4e4a-af1d-b034fbca8776': 'APEX-41272-106',
        'd4135277-3f43-4d35-a646-8d7b2c7673b1': 'S1Nov208384304',
        'd428b3e6-bede-4e2a-bab6-325dcd1339e7': '42432ppr3',
        'e1c9560d-3714-4320-b114-34fd00c258c5': 'FTT-GT-52444',
      },
      showCancelConfirm: true,
    },
    tabState: {
      id: '1730733147232_0.9148566808085228',
      width: 506.2783814370632,
      height: 367.8527526855469,
      visible: true,
      minWidth: 350,
      component: {
        name: 'chart',
        state: {
          link: 0.36923744970654826,
          settings: {
            general: {
              font: {
                fontSize: 10,
                textColor: '#fff',
                fontFamily: 'Arial',
              },
              gradient1: 'rgb(27, 29, 34)',
              gradient2: 'rgb(27, 29, 34)',
              gridColor: '#24262C',
              lineColor: '#fff',
              wickColor: '#fff',
              upCandleColor: '#FFE8E8',
              dateScaleColor: 'rgb(27, 29, 34)',
              downCandleColor: '#C93B3B',
              valueScaleColor: 'rgb(27, 29, 34)',
              upCandleBorderColor: '#D0D0D2',
              downCandleBorderColor: '#D0D0D2',
              upCandleBorderColorEnabled: false,
              downCandleBorderColorEnabled: false,
            },
            session: {
              sessionEnabled: true,
              sessionTemplate: {
                id: 628,
                name: 'US Extended Trading Hours',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                workingTimes: [
                  {
                    endDay: 5,
                    endTime: 61200000,
                    startDay: 4,
                    startTime: 64800000,
                    tradingDay: 5,
                  },
                  {
                    endDay: 4,
                    endTime: 61200000,
                    startDay: 3,
                    startTime: 64800000,
                    tradingDay: 4,
                  },
                  {
                    endDay: 3,
                    endTime: 61200000,
                    startDay: 2,
                    startTime: 64800000,
                    tradingDay: 3,
                  },
                  {
                    endDay: 2,
                    endTime: 61200000,
                    startDay: 1,
                    startTime: 64800000,
                    tradingDay: 2,
                  },
                  {
                    endDay: 1,
                    endTime: 61200000,
                    startDay: 0,
                    startTime: 64800000,
                    tradingDay: 1,
                  },
                ],
              },
            },
            trading: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              trading: {
                showPLInfo: true,
                displayUnit: 'points',
                orderBarUnit: 'pixels',
                showOHLVInfo: false,
                bracketButton: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: true,
                tradingBarLength: 40,
                includeRealizedPL: true,
                showCancelConfirm: true,
                showWorkingOrders: true,
                negativePLFontColor: 'red',
                positivePLFontColor: 'white',
                showInstrumentChange: false,
                negativePLBackgroundColor: 'black',
                positivePLBackgroundColor: 'black',
              },
              orderArea: {
                formData: {
                  quantity: 10,
                  stopLoss: {
                    type: 'StopMarket',
                    stopLoss: false,
                  },
                  takeProfit: {
                    takeProfit: false,
                  },
                },
                settings: {
                  tif: {
                    DAY: true,
                    GTC: true,
                    default: 'DAY',
                  },
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  orderArea: {
                    showPLInfo: true,
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  formSettings: {
                    showPLInfo: true,
                    showBracket: true,
                    showBuyButton: true,
                    showSellButton: true,
                    showCancelButton: true,
                    showFlattenButton: true,
                    showIcebergButton: true,
                    closePositionButton: true,
                    showLiquidateButton: true,
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  icebergFontColor: '#fff',
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  buyButtonsFontColor: '#D0D0D2',
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  flatButtonsFontColor: '#D0D0D2',
                  sellButtonsFontColor: '#D0D0D2',
                  cancelButtonFontColor: '#D0D0D2',
                  closePositionFontColor: '#D0D0D2',
                  icebergBackgroundColor: '#51535A',
                  buyButtonsBackgroundColor: '#0C62F7',
                  flatButtonsBackgroundColor: '#51535A',
                  sellButtonsBackgroundColor: '#C93B3B',
                  cancelButtonBackgroundColor: '#51535A',
                  closePositionBackgroundColor: '#51535A',
                },
                amountButtons: [
                  {
                    value: 1,
                  },
                  {
                    black: true,
                    value: 1,
                  },
                  {
                    value: 3,
                  },
                  {
                    value: 5,
                  },
                  {
                    value: 10,
                  },
                  {
                    value: 25,
                  },
                ],
              },
              chartMarkers: {
                buyMarkerType: 'triangle',
                buyMarkerColor: 'blue',
                sellMarkerType: 'square',
                sellMarkerColor: 'red',
                buyMarkerFontWeight: 12,
                sellMarkerFontWeight: 12,
                dataBoxBackgroundColor: 'black',
                showBarMarkerTobBottom: true,
                showBarTextWithSizePrice: true,
                showBarMarketExecutionPrice: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
                orderBarUnit: 'pixels',
                orderBarLength: 100,
              },
            },
            valueScale: {
              valueScale: {
                isAutomatic: 'pixels-price',
                pixelsPrice: 2,
              },
            },
          },
          showOHLV: false,
          timeFrame: {
            interval: 5,
            periodicity: '',
          },
          instrument: {
            id: 'ESZ4.CME',
            type: 'Future',
            symbol: 'ESZ4',
            currency: 'USD',
            exchange: 'CME',
            tickSize: 0.25,
            precision: 2,
            expiration: '2024-12-20T00:00:00',
            description: 'E-Mini S&P 500 Dec24',
            productCode: 'ES',
            connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
            contractSize: 50,
            instrumentTimePeriod: 'Dec24',
            stringTypeRepresentation: 'Future',
          },
          showChanges: false,
          periodToLoad: {
            interval: 3,
            periodicity: 'd',
          },
          periodOptions: [
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 5,
                  periodicity: 'd',
                },
              ],
              periodicity: 'd',
            },
            {
              active: false,
              period: 'Weeks',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 2,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'w',
                },
              ],
              periodicity: 'w',
            },
            {
              active: false,
              period: 'Months',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 6,
                  periodicity: 'm',
                },
              ],
              periodicity: 'm',
            },
          ],
          enableOrderForm: false,
          intervalOptions: [
            {
              active: false,
              period: 'RevBars',
              timeFrames: [
                {
                  interval: 4,
                  periodicity: 'revs',
                },
                {
                  interval: 8,
                  periodicity: 'revs',
                },
                {
                  interval: 12,
                  periodicity: 'revs',
                },
                {
                  interval: 16,
                  periodicity: 'revs',
                },
              ],
              periodicities: ['revs'],
            },
            {
              active: false,
              period: 'Seconds',
              timeFrames: [
                {
                  interval: 30,
                  periodicity: 's',
                },
                {
                  interval: 40,
                  periodicity: 's',
                },
              ],
              periodicities: ['s'],
            },
            {
              active: false,
              period: 'Minutes',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: '',
                },
                {
                  interval: 3,
                  periodicity: '',
                },
                {
                  interval: 5,
                  periodicity: '',
                },
                {
                  interval: 15,
                  periodicity: '',
                },
                {
                  interval: 30,
                  periodicity: '',
                },
              ],
              periodicities: [''],
            },
            {
              active: false,
              period: 'Hours',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'h',
                },
                {
                  interval: 2,
                  periodicity: 'h',
                },
                {
                  interval: 3,
                  periodicity: 'h',
                },
                {
                  interval: 4,
                  periodicity: 'h',
                },
              ],
              periodicities: ['h'],
            },
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 1,
                  periodicity: 'm',
                },
              ],
              periodicities: ['d', 'm', 'w', 'y'],
            },
          ],
          isToolbarVisible: false,
          stockChartXState: {
            chart: {
              scale: 2,
              theme: {
                name: 'Dark',
                plot: {
                  bar: {
                    HL: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    HLC: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    OHLC: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    kagi: {
                      upCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                          strokeEnabled: true,
                        },
                      },
                      downCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                          strokeEnabled: true,
                        },
                      },
                    },
                    renko: {
                      upCandle: {
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        border: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                    },
                    candle: {
                      upCandle: {
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredHL: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    lineBreak: {
                      upCandle: {
                        fill: {
                          fillColor: '#FFE8E8',
                          fillEnabled: true,
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                          fillEnabled: true,
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    equiVolume: {
                      upCandle: {
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    heikinAshi: {
                      upCandle: {
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredOHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#FFE8E8',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#C93B3B',
                      },
                    },
                    candleVolume: {
                      upCandle: {
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    hollowCandle: {
                      upCandle: {
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      upHollowCandle: {
                        wick: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                        },
                      },
                      downHollowCandle: {
                        wick: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    pointAndFigure: {
                      upCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#FFE8E8',
                          strokeEnabled: true,
                        },
                      },
                      downCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                          strokeEnabled: true,
                        },
                      },
                    },
                    equiVolumeShadow: {
                      upCandle: {
                        fill: {
                          fillColor: '#FFE8E8',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                  },
                  line: {
                    step: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    simple: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    mountain: {
                      fill: {
                        linearGradient: [
                          'rgba(63, 167, 225, 0.4) ',
                          'rgba(63, 167, 225, 0)',
                        ],
                      },
                      line: {
                        width: 1,
                        strokeColor: '#fff',
                      },
                    },
                  },
                  point: {
                    dot: {
                      fill: {
                        fillColor: 'white',
                      },
                    },
                  },
                  histogram: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    column: {
                      fill: {
                        fillColor: 'blue',
                      },
                      line: {
                        width: 1,
                        strokeColor: 'white',
                        strokeEnabled: false,
                      },
                    },
                    coloredLine: {
                      upBar: {
                        width: 1,
                        strokeColor: 'grey',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                    coloredColumn: {
                      upBar: {
                        fill: {
                          fillColor: 'green',
                        },
                        line: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                      downBar: {
                        fill: {
                          fillColor: 'red',
                        },
                        line: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                    },
                  },
                  indicator: {
                    line1: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'white',
                    },
                    line2: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#3FA7E1',
                    },
                    line3: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#E85050',
                    },
                    line4: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0000FF',
                    },
                    line5: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'yellow',
                    },
                    levels: {
                      line1: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FF0000',
                      },
                      line2: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#32CD32',
                      },
                      line3: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFD500',
                      },
                      line4: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0000FF',
                      },
                      line5: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#808080',
                      },
                    },
                    blackText: {
                      fontSize: 10,
                      fillColor: 'black',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    histogram: {
                      color: '#32CD32',
                    },
                  },
                },
                chart: {
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'grey',
                  },
                  background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
                  instrumentWatermark: {
                    symbol: {
                      fontSize: 70,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    details: {
                      fontSize: 40,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                  },
                },
                button: {
                  none: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                  accept: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'green',
                    },
                  },
                  cancel: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'red',
                    },
                  },
                },
                spread: {
                  ask: {
                    line: {
                      width: 1,
                      strokeColor: '#00D533',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#00D533',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        fontFamily: 'Calibri',
                      },
                    },
                  },
                  bid: {
                    line: {
                      width: 1,
                      strokeColor: '#F20500',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#F20500',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        fontFamily: 'Calibri',
                      },
                    },
                  },
                },
                zoomIn: {
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'white',
                  },
                },
                drawing: {
                  note: {
                    fill: {
                      fillColor: 'orange',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                    centerPointFill: {
                      fillColor: 'rgb(68, 68, 68)',
                    },
                  },
                  text: {
                    text: {
                      fontSize: 12,
                      fillColor: '#FFFFFF',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                  },
                  image: {
                    noImage: {
                      line: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                  },
                  arrowUp: {
                    fill: {
                      fillColor: 'limegreen',
                    },
                  },
                  measure: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.5)',
                      fillEnabled: true,
                    },
                    line: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    border: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    balloon: {
                      fill: {
                        fillColor: 'rgba(255, 255, 255, 0.5)',
                        fillEnabled: true,
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontFamily: 'Calibri',
                      },
                      border: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: 'darkgray',
                        strokeEnabled: true,
                      },
                    },
                  },
                  abstract: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                  },
                  arrowDown: {
                    fill: {
                      fillColor: 'red',
                    },
                  },
                  fibonacci: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                    trendLine: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                    },
                  },
                  trendAngle: {
                    arc: {
                      width: 1,
                      lineStyle: 'dot',
                      strokeColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                    horizontalLine: {
                      width: 1,
                      lineStyle: 'dot',
                      strokeColor: 'white',
                    },
                  },
                  measureTool: {
                    fill: {
                      fillColor: 'rgba(0, 0, 0, 0.8)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                  },
                  abstractMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                tooltip: {
                  fill: {
                    color: 'rgb(44, 44, 44)',
                    enabled: true,
                  },
                  text: {
                    fontSize: 12,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                    fontWeight: 'normal',
                  },
                  border: {
                    color: 'orange',
                    width: 1,
                    enabled: true,
                    lineStyle: 'solid',
                  },
                },
                splitter: {
                  fillColor: '#292C38',
                  hoverFillColor: 'white',
                },
                crossHair: {
                  fill: {
                    fillColor: '#FFFFFF',
                  },
                  line: {
                    width: 1,
                    lineStyle: 'dash',
                    strokeColor: 'darkgray',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: '#000000',
                    fontFamily: 'Calibri',
                  },
                },
                dateScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  text: {
                    fontSize: 10,
                    fillColor: '#fff',
                    fontFamily: 'Arial',
                  },
                  border: {
                    width: 0,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                  },
                  breakLine: {
                    stroke: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: '#545454',
                    },
                  },
                  dateMarker: {
                    fill: {
                      fillColor: 'green',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: '#FFFFFF',
                      fontFamily: 'Calibri',
                    },
                    stroke: {
                      width: 1,
                      lineCap: 'butt',
                      lineJoin: 'miter',
                      lineStyle: 'solid',
                      strokeColor: '#696969',
                      strokePriority: 'color',
                    },
                  },
                },
                chartPanel: {
                  grid: {
                    width: 1,
                    strokeColor: '#24262C',
                  },
                  title: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    fontFamily: 'Calibri',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontSize: 70,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    details: {
                      fontSize: 40,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                  },
                },
                valueScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  text: {
                    fontSize: 10,
                    fillColor: '#fff',
                    fontStyle: 'normal',
                    fontFamily: 'Arial',
                  },
                  border: {
                    width: 0,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                  },
                  valueMarker: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                  },
                },
                chartMarkers: {
                  buyMarkerType: 'triangle',
                  buyMarkerColor: 'blue',
                  sellMarkerType: 'square',
                  sellMarkerColor: 'red',
                  buyMarkerFontWeight: 1,
                  sellMarkerFontWeight: 1,
                  dataBoxBackgroundColor: 'black',
                  showBarMarkerTobBottom: true,
                  showBarTextWithSizePrice: true,
                  showBarMarketExecutionPrice: true,
                },
                tradingPanel: {
                  showPLInfo: true,
                  displayUnit: 'points',
                  tradingBarUnit: 'pixels',
                  markerFontColor: '#fff',
                  tradingBarLength: 40,
                  includeRealizedPL: true,
                  buyPanelBackground: 'rgba(12,98,247, 0.3)',
                  buyMarkerBackground: '#0C62F7',
                  negativePLFontColor: 'red',
                  positivePLFontColor: 'white',
                  sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
                  sellMarkerBackground: '#C93B3B',
                  negativePLBackgroundColor: 'black',
                  positivePLBackgroundColor: 'black',
                },
                orderSideMarker: {
                  buy: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#33537C',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#33537C',
                    },
                  },
                  sell: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#C93B3B',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#C93B3B',
                    },
                  },
                  ocoStopLimit: '#FFFF00',
                  ocoStopOrder: '#FFFF00',
                  orderBarUnit: 'pixels',
                  orderBarLength: 100,
                },
                selectionMarker: {
                  fill: {
                    fillColor: 'black',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'white',
                  },
                },
                orderChartMarker: {
                  buy: {
                    fill: {
                      fillColor: 'blue',
                    },
                    stroke: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    tooltip: {
                      fill: {
                        color: 'black',
                        enabled: true,
                      },
                      text: {
                        fontSize: 12,
                        fillColor: 'blue',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                        fontWeight: 'normal',
                      },
                      border: {
                        color: 'orange',
                        width: 1,
                        enabled: false,
                        lineStyle: 'solid',
                      },
                    },
                    markerType: 'triangle',
                  },
                  sell: {
                    fill: {
                      fillColor: 'red',
                    },
                    stroke: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    tooltip: {
                      fill: {
                        color: 'black',
                        enabled: true,
                      },
                      text: {
                        fontSize: 12,
                        fillColor: 'red',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                        fontWeight: 'normal',
                      },
                      border: {
                        color: 'orange',
                        width: 1,
                        enabled: false,
                        lineStyle: 'solid',
                      },
                    },
                    markerType: 'square',
                  },
                  showTopBottomMarkers: true,
                  showTextWithSizeAndPrice: true,
                  showMarkerAtExecutionPrice: true,
                },
                highlightedColumn: {
                  fill: {
                    fillColor: 'rgba(90, 130, 182, 0.45)',
                  },
                },
                positionSideMarker: {
                  long: {
                    kind: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    line: {
                      width: 1,
                      strokeColor: 'green',
                    },
                    price: {
                      fill: {
                        fillColor: 'blue',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    length: 1,
                    lineType: 'solid',
                    quantity: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    lineColor: '#0C62F7',
                    lengthUnit: 'pixels',
                  },
                  short: {
                    kind: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    line: {
                      width: 1,
                      strokeColor: 'red',
                    },
                    price: {
                      fill: {
                        fillColor: 'red',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    length: 1,
                    lineType: 'solid',
                    quantity: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    lineColor: '#FF0000',
                    lengthUnit: 'pixels',
                  },
                  showPLInfo: true,
                  displayUnit: 'points',
                  positionBarUnit: 'pixels',
                  includeRealizedPL: true,
                  positionBarLength: 100,
                  negativePLFontColor: 'red',
                  positivePLFontColor: 'white',
                  negativePLBackgroundColor: 'black',
                  positivePLBackgroundColor: 'black',
                },
              },
              locale: 'en',
              priceStyle: 'candle',
              zoomEnabled: true,
              xGridVisible: true,
              yGridVisible: true,
              zoomAutoSave: false,
              scrollEnabled: true,
              enableMouseEvents: true,
              showBarInfoInTitle: true,
              enableKeyboardEvents: false,
              showInstrumentWatermark: false,
            },
            plots: {
              VolumeProfile: {
                eth: {
                  lines: {
                    dev: {
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                    },
                    prev: {
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                    },
                    current: {
                      va: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#FFFF00',
                        },
                        labelEnabled: false,
                      },
                      poc: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#FFFF00',
                        },
                        labelEnabled: false,
                      },
                    },
                  },
                  profile: {
                    type: 'solidgram',
                    color: {
                      type: 'profileColor',
                      value: '#0C62F7',
                    },
                    extendNaked: {
                      type: 'closesHighsLows',
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0C62F7',
                      },
                    },
                    profileColor: '',
                    extendNakedPocs: true,
                    vaInsideOpacity: 0.5,
                    vaOutsideOpacity: 0.5,
                    widthCorrelation: 0.5,
                  },
                  session: null,
                },
                lines: {
                  dev: {
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#8D8E30',
                      },
                    },
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#8D8E30',
                      },
                    },
                  },
                  prev: {
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#8D8E30',
                      },
                    },
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#8D8E30',
                      },
                    },
                  },
                  current: {
                    va: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFFF00',
                      },
                      labelEnabled: false,
                    },
                    poc: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFFF00',
                      },
                      labelEnabled: false,
                    },
                  },
                },
                general: {
                  hide: {
                    value: 'lastProfile',
                    enabled: false,
                  },
                  type: 'volume',
                  align: 'right',
                  width: {
                    unit: 'pixel',
                    value: 70,
                  },
                  margin: {
                    unit: 'pixel',
                    value: 4,
                  },
                  period: {
                    date: null,
                    type: 'every',
                    unit: 'day',
                    value: 1,
                  },
                  sessions: {
                    days: {
                      count: 10,
                      width: {
                        unit: 'pixel',
                        value: 70,
                      },
                      enabled: true,
                      includeCurrentSession: false,
                    },
                    prev: {
                      width: {
                        unit: 'pixel',
                        value: 70,
                      },
                      enabled: true,
                    },
                    current: {
                      width: {
                        unit: 'pixel',
                        value: 70,
                      },
                      enabled: true,
                    },
                  },
                  smoothed: {
                    value: 9,
                    enabled: false,
                  },
                  transparency: 35,
                  vaCorrelation: 0.7,
                  customTickSize: {
                    value: 5,
                    enabled: false,
                  },
                  calculateXProfiles: 999,
                },
                profile: {
                  type: 'solidgram',
                  color: {
                    type: 'profileColor',
                    value: '#0C62F7',
                  },
                  extendNaked: {
                    type: 'closesHighsLows',
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0C62F7',
                    },
                  },
                  profileColor: '',
                  extendNakedPocs: true,
                  vaInsideOpacity: 0.5,
                  vaOutsideOpacity: 0.5,
                  widthCorrelation: 0.5,
                },
                session: null,
                graphics: {
                  showPrices: true,
                  summaryFont: {
                    fontSize: 12,
                    fillColor: '#0C62F7',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                  },
                  summaryEnabled: true,
                },
                highlight: {
                  va: {
                    value: '#A1A2A5',
                    enabled: false,
                  },
                  poc: {
                    value: '#A1A2A5',
                    enabled: true,
                  },
                },
                splitProfile: false,
                overlayEthOverRth: false,
              },
            },
            drawings: [
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
            ],
            barsCount: false,
            crossHair: {
              crossHairType: 'crossBars',
            },
            dateScale: {
              height: 15,
              zoomKind: 'autoscaled',
              zoomMode: 'pin_mouse',
              formatter: {
                locale: 'en',
                className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
                timeInterval: 300000,
              },
              calibrator: {
                options: {},
                className: 'StockChartX.CustomDateScaleCalibrator',
              },
              scrollKind: 'autoscaled',
              textPadding: {
                top: null,
                left: 3,
                right: 3,
                bottom: 3,
              },
              autoScrollKind: 'visible_bar',
              autoScrollMode: 'new_bar',
              useManualHeight: false,
              lastVisibleRecord: 448,
              minVisibleRecords: 5,
              firstVisibleRecord: 348,
              allowPartialRecords: true,
              majorTickMarkLength: 5,
              minorTickMarkLength: 3,
              rightAdditionalSpaceRatio: 0.5,
            },
            timeFrame: {
              interval: 5,
              periodicity: '',
            },
            indicators: [
              {
                zIndex: 1000,
                visible: true,
                className: 'VolumeProfile',
                showTitle: true,
                panelIndex: 0,
                parameters: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                _prevParams: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                showLineLevels: false,
                allowContextMenu: true,
                panelHeightRatio: 1,
                showValueMarkers: true,
                showParamsInTitle: true,
                showValuesInTitle: true,
                coloredVolumeTheme: null,
                allowSettingsDialog: false,
              },
            ],
            priceStyle: {
              options: {},
              className: 'candle',
            },
            valueScales: [
              {
                width: 100,
                showLeftPanel: false,
                showRightPanel: true,
                useManualWidth: false,
              },
            ],
            periodToLoad: {
              interval: 3,
              periodicity: 'd',
            },
            chartPanelsContainer: {
              panels: [
                {
                  options: {
                    state: 0,
                    visible: true,
                    moveKind: 'normal',
                    showXGrid: true,
                    showYGrid: true,
                    heightRatio: 1,
                    moveDirection: 'any',
                    drawValueScale: true,
                    maxHeightRatio: 1,
                    minHeightRatio: 0.05,
                    showIndicatorTitles: true,
                  },
                  valueScales: [
                    {
                      options: {
                        padding: {
                          top: 3,
                          left: 6,
                          right: 3,
                          bottom: 3,
                        },
                        maxAllowedValue: null,
                        maxVisibleValue: 6079.813,
                        minAllowedValue: null,
                        minVisibleValue: 6023.187,
                        maxValueRangeRatio: 5,
                        minValueRangeRatio: 0.1,
                        majorTickMarkLength: 3,
                        minorTickMarkLength: 3,
                        maxAllowedValueRatio: 0.8,
                        minAllowedValueRatio: 0.8,
                      },
                      formatter: {
                        options: {
                          style: 'decimal',
                          locale: 'en',
                          notation: 'standard',
                          signDisplay: 'auto',
                          useGrouping: 'auto',
                          roundingMode: 'halfExpand',
                          numberingSystem: 'latn',
                          roundingPriority: 'auto',
                          roundingIncrement: 1,
                          trailingZeroDisplay: 'auto',
                          minimumIntegerDigits: 1,
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        },
                        className: 'StockChartX.IntlNumberFormat',
                      },
                      calibrator: {
                        options: {
                          suffix: null,
                          divider: null,
                        },
                        className: 'StockChartX.IntervalValueScaleCalibrator',
                      },
                    },
                  ],
                },
              ],
              panelPadding: {
                top: 10,
                left: 5,
                right: 5,
                bottom: 10,
              },
              newPanelHeightRatio: 0.2,
            },
            instrumentComparisonHandler: {
              instrumentsOptions: [],
            },
          },
          preferredAccounts: {
            'c288af9a-d4c1-4e4a-af1d-b034fbca8776': 'APEX-41272-106',
            'd4135277-3f43-4d35-a646-8d7b2c7673b1': 'S1Nov208384304',
            'd428b3e6-bede-4e2a-bab6-325dcd1339e7': '42432ppr3',
            'e1c9560d-3714-4320-b114-34fd00c258c5': 'FTT-GT-52444',
          },
          showCancelConfirm: true,
        },
      },
      minHeight: 150,
      resizable: true,
      allowPopup: true,
      maximizable: true,
      minimizable: true,
    },
    isDefault: false,
  },
  {
    id: 1733175140005,
    name: 'Footprint Chart',
    type: 'chart',
    state: {
      link: 0.5337737957836111,
      settings: {
        general: {
          font: {
            fontSize: 10,
            textColor: '#fff',
            fontFamily: 'Arial',
          },
          gradient1: 'rgb(27, 29, 34)',
          gradient2: 'rgb(27, 29, 34)',
          gridColor: '#24262C',
          lineColor: '#fff',
          wickColor: '#fff',
          upCandleColor: '#0C62F7',
          dateScaleColor: 'rgb(27, 29, 34)',
          downCandleColor: '#C93B3B',
          valueScaleColor: 'rgb(27, 29, 34)',
          upCandleBorderColor: '#D0D0D2',
          downCandleBorderColor: '#D0D0D2',
          upCandleBorderColorEnabled: false,
          downCandleBorderColorEnabled: false,
        },
        session: {
          sessionEnabled: true,
          sessionTemplate: {
            id: 5,
            name: '24/7',
            exchange: 'CME',
            timezoneId: 'America/New_York',
            workingTimes: [
              {
                endDay: 2,
                endTime: 86100000,
                startDay: 2,
                startTime: 0,
              },
              {
                endDay: 3,
                endTime: 86100000,
                startDay: 3,
                startTime: 0,
              },
              {
                endDay: 4,
                endTime: 86100000,
                startDay: 4,
                startTime: 0,
              },
              {
                endDay: 1,
                endTime: 86100000,
                startDay: 1,
                startTime: 0,
              },
              {
                endDay: 5,
                endTime: 86100000,
                startDay: 5,
                startTime: 0,
              },
              {
                endDay: 6,
                endTime: 86100000,
                startDay: 6,
                startTime: 0,
              },
              {
                endDay: 0,
                endTime: 86100000,
                startDay: 0,
                startTime: 0,
              },
            ],
          },
        },
        trading: {
          tif: {
            DAY: true,
            GTC: true,
            default: 'DAY',
          },
          trading: {
            showPLInfo: true,
            displayUnit: 'points',
            orderBarUnit: 'pixels',
            showOHLVInfo: false,
            bracketButton: true,
            orderBarLength: 100,
            tradingBarUnit: 'pixels',
            showOrderConfirm: true,
            tradingBarLength: 40,
            includeRealizedPL: true,
            showCancelConfirm: true,
            showWorkingOrders: true,
            negativePLFontColor: 'red',
            positivePLFontColor: 'white',
            showInstrumentChange: false,
            negativePLBackgroundColor: 'black',
            positivePLBackgroundColor: 'black',
          },
          orderArea: {
            formData: {
              quantity: 1,
              stopLoss: {
                type: 'StopMarket',
                stopLoss: false,
              },
              takeProfit: {
                takeProfit: false,
              },
            },
            settings: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              flatten: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              orderArea: {
                showPLInfo: true,
              },
              cancelButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              formSettings: {
                showPLInfo: true,
                showBracket: true,
                showBuyButton: true,
                showSellButton: true,
                showCancelButton: true,
                showFlattenButton: true,
                showIcebergButton: true,
                closePositionButton: true,
                showLiquidateButton: true,
              },
              icebergButton: {
                font: '#fff',
                enabled: true,
                background: '#51535A',
              },
              buyMarketButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#0C62F7',
              },
              icebergFontColor: '#fff',
              sellMarketButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#C93B3B',
              },
              buyButtonsFontColor: '#D0D0D2',
              closePositionButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              showLiquidateButton: {
                font: '#D0D0D2',
                enabled: true,
                background: '#51535A',
              },
              flatButtonsFontColor: '#D0D0D2',
              sellButtonsFontColor: '#D0D0D2',
              cancelButtonFontColor: '#D0D0D2',
              closePositionFontColor: '#D0D0D2',
              icebergBackgroundColor: '#51535A',
              buyButtonsBackgroundColor: '#0C62F7',
              flatButtonsBackgroundColor: '#51535A',
              sellButtonsBackgroundColor: '#C93B3B',
              cancelButtonBackgroundColor: '#51535A',
              closePositionBackgroundColor: '#51535A',
            },
            amountButtons: [
              {
                value: 1,
              },
              {
                black: true,
                value: 1,
              },
              {
                value: 3,
              },
              {
                value: 5,
              },
              {
                value: 10,
              },
              {
                value: 25,
              },
            ],
          },
          chartMarkers: {
            buyMarkerType: 'triangle',
            buyMarkerColor: 'blue',
            sellMarkerType: 'square',
            sellMarkerColor: 'red',
            buyMarkerFontWeight: 12,
            sellMarkerFontWeight: 12,
            dataBoxBackgroundColor: 'black',
            showBarMarkerTobBottom: true,
            showBarTextWithSizePrice: true,
            showBarMarketExecutionPrice: true,
          },
          ordersColors: {
            buy: {
              stop: {
                length: 1,
                lineType: 'solid',
                lineColor: '#33537C',
              },
              limit: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#0C62F7',
              },
              market: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#0C62F7',
              },
              stopLimit: {
                length: 1,
                lineType: 'dotted',
                lineColor: '#33537C',
              },
            },
            sell: {
              stop: {
                length: 1,
                lineType: 'solid',
                lineColor: '#C93B3B',
              },
              limit: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#FF0000',
              },
              market: {
                length: 1,
                lineType: 'dashed',
                lineColor: '#FF0000',
              },
              stopLimit: {
                length: 1,
                lineType: 'dotted',
                lineColor: '#C93B3B',
              },
            },
            ocoStopLimit: '#FFFF00',
            ocoStopOrder: '#FFFF00',
            orderBarUnit: 'pixels',
            orderBarLength: 100,
          },
        },
        valueScale: {
          valueScale: {
            isAutomatic: 'pixels-price',
            pixelsPrice: 10,
          },
        },
      },
      showOHLV: false,
      timeFrame: {
        interval: 12,
        periodicity: 'revs',
      },
      instrument: {
        id: 'ESZ4.CME',
        type: 'Future',
        symbol: 'ESZ4',
        currency: 'USD',
        exchange: 'CME',
        tickSize: 0.25,
        precision: 2,
        expiration: '2024-12-20T00:00:00',
        description: 'E-Mini S&P 500 Dec24',
        productCode: 'ES',
        connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
        contractSize: 50,
        instrumentTimePeriod: 'Dec24',
        stringTypeRepresentation: 'Future',
      },
      showChanges: false,
      periodToLoad: {
        disabled: false,
        interval: 1,
        periodicity: 'd',
      },
      periodOptions: [
        {
          active: false,
          period: 'Days',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'd',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'd',
            },
            {
              disabled: false,
              interval: 5,
              periodicity: 'd',
            },
          ],
          periodicity: 'd',
        },
        {
          active: false,
          period: 'Weeks',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'w',
            },
            {
              disabled: false,
              interval: 2,
              periodicity: 'w',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'w',
            },
          ],
          periodicity: 'w',
        },
        {
          active: false,
          period: 'Months',
          timeFrames: [
            {
              disabled: false,
              interval: 1,
              periodicity: 'm',
            },
            {
              disabled: false,
              interval: 3,
              periodicity: 'm',
            },
            {
              disabled: false,
              interval: 6,
              periodicity: 'm',
            },
          ],
          periodicity: 'm',
        },
      ],
      showChartForm: true,
      enableOrderForm: false,
      intervalOptions: [
        {
          active: false,
          period: 'RevBars',
          timeFrames: [
            {
              interval: 4,
              periodicity: 'revs',
            },
            {
              interval: 8,
              periodicity: 'revs',
            },
            {
              interval: 12,
              periodicity: 'revs',
            },
            {
              interval: 16,
              periodicity: 'revs',
            },
          ],
          periodicities: ['revs'],
        },
        {
          active: false,
          period: 'Seconds',
          timeFrames: [
            {
              interval: 30,
              periodicity: 's',
            },
            {
              interval: 40,
              periodicity: 's',
            },
          ],
          periodicities: ['s'],
        },
        {
          active: false,
          period: 'Minutes',
          timeFrames: [
            {
              interval: 1,
              periodicity: '',
            },
            {
              interval: 3,
              periodicity: '',
            },
            {
              interval: 5,
              periodicity: '',
            },
            {
              interval: 15,
              periodicity: '',
            },
            {
              interval: 30,
              periodicity: '',
            },
          ],
          periodicities: [''],
        },
        {
          active: false,
          period: 'Hours',
          timeFrames: [
            {
              interval: 1,
              periodicity: 'h',
            },
            {
              interval: 2,
              periodicity: 'h',
            },
            {
              interval: 3,
              periodicity: 'h',
            },
            {
              interval: 4,
              periodicity: 'h',
            },
          ],
          periodicities: ['h'],
        },
        {
          active: false,
          period: 'Days',
          timeFrames: [
            {
              interval: 1,
              periodicity: 'd',
            },
            {
              interval: 1,
              periodicity: 'w',
            },
            {
              interval: 1,
              periodicity: 'm',
            },
          ],
          periodicities: ['d', 'm', 'w', 'y'],
        },
      ],
      isToolbarVisible: false,
      stockChartXState: {
        chart: {
          scale: 2,
          theme: {
            name: 'Dark',
            plot: {
              bar: {
                HL: {
                  width: 1,
                  strokeColor: 'white',
                },
                HLC: {
                  width: 1,
                  strokeColor: 'white',
                },
                OHLC: {
                  width: 1,
                  strokeColor: '#fff',
                },
                kagi: {
                  upCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#0C62F7',
                      strokeEnabled: true,
                    },
                  },
                  downCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#C93B3B',
                      strokeEnabled: true,
                    },
                  },
                },
                renko: {
                  upCandle: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    border: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    border: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: false,
                    },
                  },
                },
                candle: {
                  upCandle: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                coloredHL: {
                  upBar: {
                    width: 1,
                    strokeColor: '#3FA7E1',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#E85050',
                  },
                },
                lineBreak: {
                  upCandle: {
                    fill: {
                      fillColor: '#0C62F7',
                      fillEnabled: true,
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                      fillEnabled: true,
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                coloredHLC: {
                  upBar: {
                    width: 1,
                    strokeColor: '#3FA7E1',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#E85050',
                  },
                },
                equiVolume: {
                  upCandle: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                heikinAshi: {
                  upCandle: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    wick: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                coloredOHLC: {
                  upBar: {
                    width: 1,
                    strokeColor: '#0C62F7',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: '#C93B3B',
                  },
                },
                candleVolume: {
                  upCandle: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
                hollowCandle: {
                  upCandle: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    wick: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  upHollowCandle: {
                    wick: {
                      width: 1,
                      strokeColor: '#0C62F7',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#0C62F7',
                    },
                  },
                  downHollowCandle: {
                    wick: {
                      width: 1,
                      strokeColor: '#C93B3B',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#C93B3B',
                    },
                  },
                },
                pointAndFigure: {
                  upCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#0C62F7',
                      strokeEnabled: true,
                    },
                  },
                  downCandle: {
                    border: {
                      width: 1,
                      strokeColor: '#C93B3B',
                      strokeEnabled: true,
                    },
                  },
                },
                equiVolumeShadow: {
                  upCandle: {
                    fill: {
                      fillColor: '#0C62F7',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                  downCandle: {
                    fill: {
                      fillColor: '#C93B3B',
                    },
                    border: {
                      width: 1,
                      strokeColor: '#D0D0D2',
                      strokeEnabled: false,
                    },
                  },
                },
              },
              line: {
                step: {
                  width: 1,
                  strokeColor: 'white',
                },
                simple: {
                  width: 1,
                  strokeColor: '#fff',
                },
                mountain: {
                  fill: {
                    linearGradient: [
                      'rgba(63, 167, 225, 0.4) ',
                      'rgba(63, 167, 225, 0)',
                    ],
                  },
                  line: {
                    width: 1,
                    strokeColor: '#fff',
                  },
                },
              },
              point: {
                dot: {
                  fill: {
                    fillColor: 'white',
                  },
                },
              },
              histogram: {
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                column: {
                  fill: {
                    fillColor: 'blue',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'white',
                    strokeEnabled: false,
                  },
                },
                coloredLine: {
                  upBar: {
                    width: 1,
                    strokeColor: 'grey',
                  },
                  downBar: {
                    width: 1,
                    strokeColor: 'red',
                  },
                },
                coloredColumn: {
                  upBar: {
                    fill: {
                      fillColor: 'green',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: false,
                    },
                  },
                  downBar: {
                    fill: {
                      fillColor: 'red',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: false,
                    },
                  },
                },
              },
              indicator: {
                line1: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: 'white',
                },
                line2: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#3FA7E1',
                },
                line3: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#E85050',
                },
                line4: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#0000FF',
                },
                line5: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: 'yellow',
                },
                levels: {
                  line1: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FF0000',
                  },
                  line2: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#32CD32',
                  },
                  line3: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FFD500',
                  },
                  line4: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#0000FF',
                  },
                  line5: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#808080',
                  },
                },
                blackText: {
                  fontSize: 10,
                  fillColor: 'black',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
                histogram: {
                  color: '#32CD32',
                },
              },
            },
            chart: {
              border: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: 'grey',
              },
              background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
              instrumentWatermark: {
                symbol: {
                  fontSize: 70,
                  fillColor: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
                details: {
                  fontSize: 40,
                  fillColor: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
              },
            },
            button: {
              none: {
                fill: {
                  fillColor: 'white',
                },
              },
              accept: {
                fill: {
                  fillColor: 'white',
                },
                line: {
                  width: 1,
                  strokeColor: 'green',
                },
              },
              cancel: {
                fill: {
                  fillColor: 'white',
                },
                line: {
                  width: 1,
                  strokeColor: 'red',
                },
              },
            },
            spread: {
              ask: {
                line: {
                  width: 1,
                  strokeColor: '#00D533',
                },
                valueMarker: {
                  fill: {
                    fillColor: '#00D533',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    fontFamily: 'Calibri',
                  },
                },
              },
              bid: {
                line: {
                  width: 1,
                  strokeColor: '#F20500',
                },
                valueMarker: {
                  fill: {
                    fillColor: '#F20500',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    fontFamily: 'Calibri',
                  },
                },
              },
            },
            zoomIn: {
              fill: {
                fillColor: 'rgba(255, 255, 255, 0.5)',
              },
              border: {
                width: 1,
                lineStyle: 'solid',
                strokeColor: 'white',
              },
            },
            drawing: {
              note: {
                fill: {
                  fillColor: 'orange',
                },
                text: {
                  fontSize: 11,
                  fillColor: 'white',
                  fontFamily: 'Calibri',
                },
                centerPointFill: {
                  fillColor: 'rgb(68, 68, 68)',
                },
              },
              text: {
                text: {
                  fontSize: 12,
                  fillColor: '#FFFFFF',
                  decoration: '',
                  fontFamily: 'Calibri',
                },
              },
              image: {
                noImage: {
                  line: {
                    width: 1,
                    strokeColor: 'red',
                  },
                },
              },
              arrowUp: {
                fill: {
                  fillColor: 'limegreen',
                },
              },
              measure: {
                fill: {
                  fillColor: 'rgba(255, 255, 255, 0.5)',
                  fillEnabled: true,
                },
                line: {
                  width: 1,
                  lineStyle: 'dash',
                  strokeColor: 'white',
                  strokeEnabled: true,
                },
                border: {
                  width: 1,
                  lineStyle: 'dash',
                  strokeColor: 'white',
                  strokeEnabled: true,
                },
                balloon: {
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                    fillEnabled: true,
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontFamily: 'Calibri',
                  },
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                    strokeEnabled: true,
                  },
                },
              },
              abstract: {
                fill: {
                  fillColor: 'rgba(255, 255, 255, 0.3)',
                },
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontSize: 12,
                  fillColor: 'white',
                  decoration: '',
                  fontFamily: 'Calibri',
                },
              },
              arrowDown: {
                fill: {
                  fillColor: 'red',
                },
              },
              fibonacci: {
                fill: {
                  fillColor: 'rgba(255, 255, 255, 0.3)',
                },
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontSize: 12,
                  fillColor: 'white',
                  fontFamily: 'Calibri',
                },
                trendLine: {
                  width: 1,
                  lineStyle: 'dash',
                  strokeColor: 'white',
                },
              },
              trendAngle: {
                arc: {
                  width: 1,
                  lineStyle: 'dot',
                  strokeColor: 'white',
                },
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontSize: 12,
                  fillColor: 'white',
                  decoration: '',
                  fontFamily: 'Calibri',
                },
                horizontalLine: {
                  width: 1,
                  lineStyle: 'dot',
                  strokeColor: 'white',
                },
              },
              measureTool: {
                fill: {
                  fillColor: 'rgba(0, 0, 0, 0.8)',
                },
                line: {
                  width: 1,
                  strokeColor: 'white',
                },
                text: {
                  fontSize: 11,
                  fillColor: 'white',
                  fontFamily: 'Calibri',
                },
              },
              abstractMarker: {
                fill: {
                  fillColor: 'white',
                },
              },
            },
            tooltip: {
              fill: {
                color: 'rgb(44, 44, 44)',
                enabled: true,
              },
              text: {
                fontSize: 12,
                fillColor: 'white',
                fontStyle: 'normal',
                decoration: '',
                fontFamily: 'Calibri',
                fontWeight: 'normal',
              },
              border: {
                color: 'orange',
                width: 1,
                enabled: true,
                lineStyle: 'solid',
              },
            },
            splitter: {
              fillColor: '#292C38',
              hoverFillColor: 'white',
            },
            crossHair: {
              fill: {
                fillColor: '#FFFFFF',
              },
              line: {
                width: 1,
                lineStyle: 'dash',
                strokeColor: 'darkgray',
              },
              text: {
                fontSize: 11,
                fillColor: '#000000',
                fontFamily: 'Calibri',
              },
            },
            dateScale: {
              fill: {
                fillColor: 'rgb(27, 29, 34)',
              },
              line: {
                width: 1,
                strokeColor: '#505050',
              },
              text: {
                fontSize: 10,
                fillColor: '#fff',
                fontFamily: 'Arial',
              },
              border: {
                width: 0,
                lineStyle: 'solid',
                strokeColor: 'darkgray',
              },
              breakLine: {
                stroke: {
                  width: 1,
                  lineStyle: 'dash',
                  strokeColor: '#545454',
                },
              },
              dateMarker: {
                fill: {
                  fillColor: 'green',
                },
                text: {
                  fontSize: 11,
                  fillColor: '#FFFFFF',
                  fontFamily: 'Calibri',
                },
                stroke: {
                  width: 1,
                  lineCap: 'butt',
                  lineJoin: 'miter',
                  lineStyle: 'solid',
                  strokeColor: '#696969',
                  strokePriority: 'color',
                },
              },
            },
            chartPanel: {
              grid: {
                width: 1,
                strokeColor: '#24262C',
              },
              title: {
                fontSize: 11,
                fillColor: 'white',
                fontStyle: 'normal',
                fontFamily: 'Calibri',
              },
              instrumentWatermark: {
                symbol: {
                  fontSize: 70,
                  fillColor: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
                details: {
                  fontSize: 40,
                  fillColor: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'Arial',
                },
              },
            },
            valueScale: {
              fill: {
                fillColor: 'rgb(27, 29, 34)',
              },
              line: {
                width: 1,
                strokeColor: '#505050',
              },
              text: {
                fontSize: 10,
                fillColor: '#fff',
                fontStyle: 'normal',
                fontFamily: 'Arial',
              },
              border: {
                width: 0,
                lineStyle: 'solid',
                strokeColor: 'darkgray',
              },
              valueMarker: {
                fill: {
                  fillColor: 'white',
                },
                text: {
                  fontSize: 11,
                  fillColor: 'black',
                  fontFamily: 'Calibri',
                },
              },
            },
            chartMarkers: {
              buyMarkerType: 'triangle',
              buyMarkerColor: 'blue',
              sellMarkerType: 'square',
              sellMarkerColor: 'red',
              buyMarkerFontWeight: 1,
              sellMarkerFontWeight: 1,
              dataBoxBackgroundColor: 'black',
              showBarMarkerTobBottom: true,
              showBarTextWithSizePrice: true,
              showBarMarketExecutionPrice: true,
            },
            tradingPanel: {
              showPLInfo: true,
              displayUnit: 'points',
              tradingBarUnit: 'pixels',
              markerFontColor: '#fff',
              tradingBarLength: 40,
              includeRealizedPL: true,
              buyPanelBackground: 'rgba(12,98,247, 0.3)',
              buyMarkerBackground: '#0C62F7',
              negativePLFontColor: 'red',
              positivePLFontColor: 'white',
              sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
              sellMarkerBackground: '#C93B3B',
              negativePLBackgroundColor: 'black',
              positivePLBackgroundColor: 'black',
            },
            orderSideMarker: {
              buy: {
                stop: {
                  length: 1,
                  lineType: 'solid',
                  lineColor: '#33537C',
                },
                limit: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#0C62F7',
                },
                market: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#0C62F7',
                },
                stopLimit: {
                  length: 1,
                  lineType: 'dotted',
                  lineColor: '#33537C',
                },
              },
              sell: {
                stop: {
                  length: 1,
                  lineType: 'solid',
                  lineColor: '#C93B3B',
                },
                limit: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#FF0000',
                },
                market: {
                  length: 1,
                  lineType: 'dashed',
                  lineColor: '#FF0000',
                },
                stopLimit: {
                  length: 1,
                  lineType: 'dotted',
                  lineColor: '#C93B3B',
                },
              },
              ocoStopLimit: '#FFFF00',
              ocoStopOrder: '#FFFF00',
              orderBarUnit: 'pixels',
              orderBarLength: 100,
            },
            selectionMarker: {
              fill: {
                fillColor: 'black',
              },
              line: {
                width: 1,
                strokeColor: 'white',
              },
            },
            orderChartMarker: {
              buy: {
                fill: {
                  fillColor: 'blue',
                },
                stroke: {
                  width: 1,
                  strokeColor: 'white',
                  strokeEnabled: true,
                },
                tooltip: {
                  fill: {
                    color: 'black',
                    enabled: true,
                  },
                  text: {
                    fontSize: 12,
                    fillColor: 'blue',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                    fontWeight: 'normal',
                  },
                  border: {
                    color: 'orange',
                    width: 1,
                    enabled: false,
                    lineStyle: 'solid',
                  },
                },
                markerType: 'triangle',
              },
              sell: {
                fill: {
                  fillColor: 'red',
                },
                stroke: {
                  width: 1,
                  strokeColor: 'white',
                  strokeEnabled: true,
                },
                tooltip: {
                  fill: {
                    color: 'black',
                    enabled: true,
                  },
                  text: {
                    fontSize: 12,
                    fillColor: 'red',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                    fontWeight: 'normal',
                  },
                  border: {
                    color: 'orange',
                    width: 1,
                    enabled: false,
                    lineStyle: 'solid',
                  },
                },
                markerType: 'square',
              },
              showTopBottomMarkers: true,
              showTextWithSizeAndPrice: true,
              showMarkerAtExecutionPrice: true,
            },
            highlightedColumn: {
              fill: {
                fillColor: 'rgba(90, 130, 182, 0.45)',
              },
            },
            positionSideMarker: {
              long: {
                kind: {
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                line: {
                  width: 1,
                  strokeColor: 'green',
                },
                price: {
                  fill: {
                    fillColor: 'blue',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                length: 1,
                lineType: 'solid',
                quantity: {
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                lineColor: '#0C62F7',
                lengthUnit: 'pixels',
              },
              short: {
                kind: {
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                line: {
                  width: 1,
                  strokeColor: 'red',
                },
                price: {
                  fill: {
                    fillColor: 'red',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                length: 1,
                lineType: 'solid',
                quantity: {
                  text: {
                    fontSize: 11,
                    fillColor: 'white',
                    decoration: '',
                    fontFamily: 'Calibri',
                  },
                },
                lineColor: '#FF0000',
                lengthUnit: 'pixels',
              },
              showPLInfo: true,
              displayUnit: 'points',
              positionBarUnit: 'pixels',
              includeRealizedPL: true,
              positionBarLength: 100,
              negativePLFontColor: 'red',
              positivePLFontColor: 'white',
              negativePLBackgroundColor: 'black',
              positivePLBackgroundColor: 'black',
            },
          },
          locale: 'en',
          priceStyle: 'candle',
          zoomEnabled: true,
          xGridVisible: true,
          yGridVisible: true,
          zoomAutoSave: false,
          scrollEnabled: true,
          enableMouseEvents: true,
          showBarInfoInTitle: true,
          enableKeyboardEvents: false,
          showInstrumentWatermark: false,
        },
        plots: {
          VWAP: {
            bands: {
              band1: {
                isShow: false,
                styleLine: {
                  width: 1,
                  lineStyle: 'solid',
                },
                coefficient: 1,
                strokeColor: '#FFFF00',
              },
              band2: {
                isShow: false,
                styleLine: {
                  width: 1,
                  lineStyle: 'solid',
                },
                coefficient: 2,
                strokeColor: '#FFFF00',
              },
              band3: {
                isShow: false,
                styleLine: {
                  width: 1,
                  lineStyle: 'solid',
                },
                coefficient: 3,
                strokeColor: '#FFFF00',
              },
            },
            label: {
              width: {
                unit: 'pixel',
                value: 400,
              },
              margin: {
                unit: 'pixel',
                value: 0,
              },
              fontSize: 10,
              fillColor: 'rgb(0,0,0,1)',
              lineStyle: {
                width: 1,
                lineStyle: 'solid',
              },
              fontFamily: 'Open Sans',
              fontWeight: '400',
              isShowVWAPLabel: false,
            },
            general: {
              styleLine: {
                style: 'hollow',
                lineStyle: {
                  width: 1,
                  lineStyle: 'solid',
                },
                strokeColor: '#00E5DD',
              },
              customTimes: null,
              customDuration: 'day',
              isShowCustomDuration: false,
            },
          },
          Footprint: {
            font: {
              fontSize: 11,
              fillColor: '#666666',
              fontFamily: 'Open Sans',
              fontWeight: '400',
            },
            main: {
              mode: 'volume',
              fillColor: '#3131316d',
              strokeColor: '#85857A00',
              barStrokeColor: '#85857A00  ',
              closeOpenColor: '#1ADE5D',
              customTickSize: {
                value: 1,
                enabled: false,
              },
              openCloseColor: '#FF2E00',
              enableOpenClose: true,
            },
            text: {
              margin: 4,
              hideSides: false,
              separator: 'x',
              textStyle: 'bidAsk',
              initialBarWidth: 80,
              barWidthTextThreshold: 60,
              barHeightTextThreshold: 5,
            },
            profile: {
              filterValue: 4000,
              strokeWidth: 1,
              profileFilter: 'autoMaxProfile',
              autoProfilingRange: 'visible',
              showPocOnlyOnBiggestBar: false,
            },
            intraBar: {
              barColor: '#A9A9A9',
              barStyle: 'volumeProfileBars',
              barPocColor: '#FFA500',
              barAlignment: 'left',
              buyPocBarColor: 'gold',
              sellPocBarColor: 'gold',
              buyShadeBarColor: '#0C62F7',
              buyVolumeBarColor: 'silver',
              sellShadeBarColor: '#E95050',
              sellVolumeBarColor: 'silver',
              lastPriceOutlineColor: '#e8e8e8',
              useDeltaShadingColors: true,
            },
            pullback: {
              enabled: true,
              fillColor: '#3F3F3F',
              textColor: '#E5E5E5',
              strokeColor: 'transparent',
              currentBidAskColor: '#8B6D00',
            },
            volumeFilter: {
              mode: 'greaterThan',
              value: 100,
              enabled: false,
            },
            deltaImbalance: {
              enabled: true,
              threshold: 3.5,
              weakAskVolumeColor: '#0C62F7',
              weakBidVolumeColor: '#0C62F7',
              enableWeakAskVolume: false,
              enableWeakBidVolume: false,
              strongAskVolumeColor: '#00BD62',
              strongBidVolumeColor: '#FF6347',
            },
          },
          PriceStats: {
            eth: {
              lines: {
                dev: {
                  va: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                  },
                  poc: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                  },
                },
                prev: {
                  va: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#8D8E30',
                    },
                  },
                  poc: {
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#8D8E30',
                    },
                  },
                },
                current: {
                  va: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                    labelEnabled: false,
                  },
                  poc: {
                    enabled: true,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                    labelEnabled: false,
                  },
                },
              },
              profile: {
                type: 'solidgram',
                color: {
                  type: 'profileColor',
                  value: '#0C62F7',
                },
                extendNaked: {
                  type: 'closesHighsLows',
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#0C62F7',
                  },
                },
                profileColor: '',
                extendNakedPocs: true,
                vaInsideOpacity: 0.5,
                vaOutsideOpacity: 0.5,
                widthCorrelation: 0.5,
              },
              session: null,
            },
            lines: {
              dev: {
                va: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#A1A2A5',
                  },
                },
                poc: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FE00E5',
                  },
                },
              },
              prev: {
                va: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#A1A2A5',
                  },
                },
                poc: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#A1A2A5',
                  },
                },
              },
              current: {
                va: {
                  enabled: false,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#A1A2A5',
                  },
                  labelEnabled: false,
                },
                poc: {
                  enabled: true,
                  strokeTheme: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: '#FE00E5',
                  },
                  labelEnabled: false,
                },
              },
            },
            general: {
              hide: {
                value: 'lastProfile',
                enabled: false,
              },
              type: 'volume',
              align: 'right',
              width: {
                unit: 'pixel',
                value: 70,
              },
              margin: {
                unit: 'pixel',
                value: 4,
              },
              period: {
                date: null,
                type: 'every',
                unit: 'day',
                value: 1,
              },
              sessions: {
                days: {
                  count: 10,
                  width: {
                    unit: 'pixel',
                    value: 100,
                  },
                  enabled: false,
                  includeCurrentSession: false,
                },
                prev: {
                  width: {
                    unit: 'pixel',
                    value: 125,
                  },
                  enabled: true,
                },
                current: {
                  width: {
                    unit: 'pixel',
                    value: 125,
                  },
                  enabled: true,
                },
              },
              smoothed: {
                value: 9,
                enabled: false,
              },
              transparency: 35,
              vaCorrelation: 0.7,
              customTickSize: {
                value: 1,
                enabled: true,
              },
              calculateXProfiles: 999,
            },
            profile: {
              type: 'solidgram',
              color: {
                type: 'heatMap',
                value: '#0C62F7',
              },
              extendNaked: {
                type: 'closesHighsLows',
                enabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#0C62F7',
                },
              },
              profileColor: '',
              extendNakedPocs: true,
              vaInsideOpacity: 0.5,
              vaOutsideOpacity: 0.5,
              widthCorrelation: 0.5,
            },
            session: {
              id: 628,
              name: 'US Extended Trading Hours',
              exchange: 'CME',
              isDefault: true,
              timezoneId: 'America/New_York',
              workingTimes: [
                {
                  id: 0,
                  endDay: 5,
                  endTime: 61200000,
                  startDay: 4,
                  sessionId: 628,
                  startTime: 64800000,
                  tradingDay: 5,
                },
                {
                  id: 0,
                  endDay: 4,
                  endTime: 61200000,
                  startDay: 3,
                  sessionId: 628,
                  startTime: 64800000,
                  tradingDay: 4,
                },
                {
                  id: 0,
                  endDay: 3,
                  endTime: 61200000,
                  startDay: 2,
                  sessionId: 628,
                  startTime: 64800000,
                  tradingDay: 3,
                },
                {
                  id: 0,
                  endDay: 2,
                  endTime: 61200000,
                  startDay: 1,
                  sessionId: 628,
                  startTime: 64800000,
                  tradingDay: 2,
                },
                {
                  id: 0,
                  endDay: 1,
                  endTime: 61200000,
                  startDay: 0,
                  sessionId: 628,
                  startTime: 64800000,
                  tradingDay: 1,
                },
              ],
            },
            graphics: {
              showPrices: true,
              summaryFont: {
                fontSize: 12,
                fillColor: '#0C62F7',
                fontFamily: 'Open Sans',
                fontWeight: '400',
              },
              summaryEnabled: true,
            },
            highlight: {
              va: {
                value: '#A1A2A5',
                enabled: false,
              },
              poc: {
                value: '#FE00E5',
                enabled: true,
              },
            },
            splitProfile: false,
            overlayEthOverRth: false,
          },
          SessionStats: {
            font: {
              fontSize: 10,
              fillColor: '#000000',
              fontFamily: 'Open Sans',
              fontWeight: '400',
            },
            lines: {
              ibLow: {
                label: 'IB Low',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#FFFF00',
                },
                labelEnabled: true,
              },
              ethLow: {
                label: 'ETH Low',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#BC0606',
                },
                labelEnabled: true,
              },
              ethMid: {
                label: 'ETH Mid',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#005B9C',
                },
                labelEnabled: true,
              },
              ibHigh: {
                label: 'IB High',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#FFFF00',
                },
                labelEnabled: true,
              },
              rthLow: {
                label: 'RTH Low',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#BC0606',
                },
                labelEnabled: true,
              },
              rthMid: {
                label: 'RTH Mid',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#1E4EF6',
                },
                labelEnabled: true,
              },
              ethHigh: {
                label: 'ETH High',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#006534',
                },
                labelEnabled: true,
              },
              rthHigh: {
                label: 'RTH High',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#006534',
                },
                labelEnabled: true,
              },
              rthOpen: {
                label: 'RTH Open',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#007A76',
                },
                labelEnabled: true,
              },
              prevWkLow: {
                label: 'Prev WK Low',
                enabled: false,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#FFFF00',
                },
                labelEnabled: true,
              },
              rthSettle: {
                label: 'RTH Settle',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#8B6D00',
                },
                labelEnabled: true,
              },
              prevRthLow: {
                label: 'Prev RTH Low',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#FF6C1C',
                },
                labelEnabled: true,
              },
              prevWkHigh: {
                label: 'Prev WK High',
                enabled: false,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#FFFF00',
                },
                labelEnabled: true,
              },
              prevRthHigh: {
                label: 'Prev RTH High',
                enabled: true,
                devEnabled: false,
                strokeTheme: {
                  width: 1,
                  lineStyle: 'solid',
                  strokeColor: '#00BD62',
                },
                labelEnabled: true,
              },
            },
            general: {
              style: 'line',
              width: {
                unit: 'pixel',
                value: 200,
              },
              margin: {
                unit: 'pixel',
                value: 10,
              },
            },
            ethSessionId: 2,
            rthSessionId: 1,
            workingTimes: {
              eth: {
                id: 2,
                name: 'US ETH',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
                workingTimes: [
                  {
                    endDay: 1,
                    endTime: 34200000,
                    startDay: 0,
                    startTime: 64800000,
                  },
                  {
                    endDay: 2,
                    endTime: 34200000,
                    startDay: 1,
                    startTime: 64800000,
                  },
                  {
                    endDay: 3,
                    endTime: 34200000,
                    startDay: 2,
                    startTime: 64800000,
                  },
                  {
                    endDay: 4,
                    endTime: 34200000,
                    startDay: 3,
                    startTime: 64800000,
                  },
                  {
                    endDay: 5,
                    endTime: 34200000,
                    startDay: 4,
                    startTime: 64800000,
                  },
                ],
              },
              rth: {
                id: 1,
                name: 'US RTH',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
                workingTimes: [
                  {
                    endDay: 1,
                    endTime: 61200000,
                    startDay: 1,
                    startTime: 34200000,
                  },
                  {
                    endDay: 2,
                    endTime: 61200000,
                    startDay: 2,
                    startTime: 34200000,
                  },
                  {
                    endDay: 3,
                    endTime: 61200000,
                    startDay: 3,
                    startTime: 34200000,
                  },
                  {
                    endDay: 4,
                    endTime: 61200000,
                    startDay: 4,
                    startTime: 34200000,
                  },
                  {
                    endDay: 5,
                    endTime: 61200000,
                    startDay: 5,
                    startTime: 34200000,
                  },
                ],
              },
            },
          },
        },
        drawings: [
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'html',
              text: '<div>Buy 15 <br> @5809.35</div>',
              theme: {
                fill: {
                  color: 'black',
                  enabled: true,
                },
                text: {
                  fontSize: 12,
                  fillColor: 'blue',
                  fontStyle: 'normal',
                  decoration: '',
                  fontFamily: 'Calibri',
                  fontWeight: 'normal',
                },
                border: {
                  color: 'orange',
                  width: 1,
                  enabled: false,
                  lineStyle: 'solid',
                },
              },
              position: 'bottom',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [
                {
                  date: '2024-10-28T16:05:27.627Z',
                  value: 5871.718139773895,
                },
              ],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'horizontalLine',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [
                {
                  date: '2024-10-28T15:57:08.149Z',
                  value: 5872.248715313463,
                },
                {
                  date: '2024-10-28T16:13:42.872Z',
                  value: 5870.899537512847,
                },
              ],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'rectangle',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'html',
              text: '<div>Buy 20 <br> @5774.38</div>',
              theme: {
                fill: {
                  color: 'black',
                  enabled: true,
                },
                text: {
                  fontSize: 12,
                  fillColor: 'blue',
                  fontStyle: 'normal',
                  decoration: '',
                  fontFamily: 'Calibri',
                  fontWeight: 'normal',
                },
                border: {
                  color: 'orange',
                  width: 1,
                  enabled: false,
                  lineStyle: 'solid',
                },
              },
              position: 'bottom',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'html',
              text: '<div>Sell 20 <br> @5776.13</div>',
              theme: {
                fill: {
                  color: 'black',
                  enabled: true,
                },
                text: {
                  fontSize: 12,
                  fillColor: 'red',
                  fontStyle: 'normal',
                  decoration: '',
                  fontFamily: 'Calibri',
                  fontWeight: 'normal',
                },
                border: {
                  color: 'orange',
                  width: 1,
                  enabled: false,
                  lineStyle: 'solid',
                },
              },
              position: 'top',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'html',
              text: '<div>Sell 20 <br> @5911.75</div>',
              theme: {
                fill: {
                  color: 'black',
                  enabled: true,
                },
                text: {
                  fontSize: 12,
                  fillColor: 'red',
                  fontStyle: 'normal',
                  decoration: '',
                  fontFamily: 'Calibri',
                  fontWeight: 'normal',
                },
                border: {
                  color: 'orange',
                  width: 1,
                  enabled: false,
                  lineStyle: 'solid',
                },
              },
              position: 'top',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
          {
            options: {
              locked: false,
              points: [{}],
              zIndex: 2000,
              visible: true,
              resizable: true,
              magnetMode: 'none',
              selectable: true,
              magnetPoint: 'bar',
              createPointBehavior: {
                x: 'date',
                y: 'value',
              },
            },
            tooltip: {
              kind: 'text',
              text: '',
            },
            className: 'orderchartmarker',
            panelIndex: 0,
            valueScaleIndex: 0,
          },
        ],
        barsCount: false,
        crossHair: {
          crossHairType: 'crossBars',
        },
        dateScale: {
          height: 15,
          zoomKind: 'autoscaled',
          zoomMode: 'pin_mouse',
          formatter: {
            locale: 'en',
            className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
            timeInterval: 12,
          },
          calibrator: {
            options: {},
            className: 'StockChartX.CustomDateScaleCalibrator',
          },
          scrollKind: 'autoscaled',
          textPadding: {
            top: null,
            left: 3,
            right: 3,
            bottom: 3,
          },
          autoScrollKind: 'visible_bar',
          autoScrollMode: 'new_bar',
          useManualHeight: false,
          lastVisibleRecord: 0,
          minVisibleRecords: 5,
          firstVisibleRecord: 1,
          allowPartialRecords: true,
          majorTickMarkLength: 5,
          minorTickMarkLength: 3,
          rightAdditionalSpaceRatio: 0.5,
        },
        timeFrame: {
          interval: 12,
          periodicity: 'revs',
        },
        indicators: [
          {
            zIndex: 997,
            visible: true,
            className: 'Footprint',
            showTitle: true,
            panelIndex: 0,
            parameters: {
              'Line Color': '#3452F1',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            _prevParams: {
              'Line Color': '#3452F1',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            showLineLevels: false,
            allowContextMenu: true,
            panelHeightRatio: 1,
            showValueMarkers: true,
            showParamsInTitle: true,
            showValuesInTitle: true,
            coloredVolumeTheme: null,
            allowSettingsDialog: false,
          },
          {
            zIndex: 998,
            visible: true,
            className: 'PriceStats',
            showTitle: true,
            panelIndex: 0,
            parameters: {
              'Line Color': '#0C62F7',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            _prevParams: {
              'Line Color': '#0C62F7',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            showLineLevels: false,
            allowContextMenu: true,
            panelHeightRatio: 1,
            showValueMarkers: true,
            showParamsInTitle: true,
            showValuesInTitle: true,
            coloredVolumeTheme: null,
            allowSettingsDialog: false,
          },
          {
            zIndex: 999,
            visible: true,
            className: 'VWAP',
            showTitle: true,
            panelIndex: 0,
            parameters: {
              Periods: 0,
              'Line Color': 'white',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            _prevParams: {
              Periods: 0,
              'Line Color': 'white',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            showLineLevels: false,
            allowContextMenu: true,
            panelHeightRatio: 1,
            showValueMarkers: true,
            showParamsInTitle: true,
            showValuesInTitle: true,
            coloredVolumeTheme: null,
            allowSettingsDialog: false,
          },
          {
            zIndex: 1000,
            visible: true,
            className: 'SessionStats',
            showTitle: true,
            panelIndex: 0,
            parameters: {
              'Line Color': '#3452F1',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            _prevParams: {
              'Line Color': '#3452F1',
              'Line Style': 'solid',
              'Line Width': 1,
              'Line Enabled': true,
            },
            showLineLevels: false,
            allowContextMenu: true,
            panelHeightRatio: 1,
            showValueMarkers: true,
            showParamsInTitle: true,
            showValuesInTitle: true,
            coloredVolumeTheme: null,
            allowSettingsDialog: false,
          },
        ],
        priceStyle: {
          options: {
            showValueLines: false,
            extendValueLines: false,
          },
          className: 'candle',
        },
        valueScales: [
          {
            width: 100,
            showLeftPanel: false,
            showRightPanel: true,
            useManualWidth: false,
          },
        ],
        periodToLoad: {
          disabled: false,
          interval: 1,
          periodicity: 'd',
        },
        chartPanelsContainer: {
          panels: [
            {
              options: {
                state: 0,
                visible: true,
                moveKind: 'normal',
                showXGrid: true,
                showYGrid: true,
                heightRatio: 1,
                moveDirection: 'any',
                drawValueScale: true,
                maxHeightRatio: 1,
                minHeightRatio: 0.05,
                showIndicatorTitles: true,
              },
              valueScales: [
                {
                  options: {
                    padding: {
                      top: 3,
                      left: 6,
                      right: 3,
                      bottom: 3,
                    },
                    maxAllowedValue: null,
                    maxVisibleValue: 11.863,
                    minAllowedValue: null,
                    minVisibleValue: -11.863,
                    maxValueRangeRatio: 5,
                    minValueRangeRatio: 0.1,
                    majorTickMarkLength: 3,
                    minorTickMarkLength: 3,
                    maxAllowedValueRatio: 0.8,
                    minAllowedValueRatio: 0.8,
                  },
                  formatter: {
                    options: {
                      style: 'decimal',
                      locale: 'en',
                      notation: 'standard',
                      signDisplay: 'auto',
                      useGrouping: 'auto',
                      roundingMode: 'halfExpand',
                      numberingSystem: 'latn',
                      roundingPriority: 'auto',
                      roundingIncrement: 1,
                      trailingZeroDisplay: 'auto',
                      minimumIntegerDigits: 1,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    },
                    className: 'StockChartX.IntlNumberFormat',
                  },
                  calibrator: {
                    options: {
                      suffix: null,
                      divider: null,
                    },
                    className: 'StockChartX.IntervalValueScaleCalibrator',
                  },
                },
              ],
            },
          ],
          panelPadding: {
            top: 10,
            left: 5,
            right: 5,
            bottom: 10,
          },
          newPanelHeightRatio: 0.2,
        },
        instrumentComparisonHandler: {
          instrumentsOptions: [],
        },
      },
      preferredAccounts: {
        'c288af9a-d4c1-4e4a-af1d-b034fbca8776': 'APEX-41272-106',
        'd4135277-3f43-4d35-a646-8d7b2c7673b1': 'S1Nov208384304',
        'd428b3e6-bede-4e2a-bab6-325dcd1339e7': '42432ppr',
        'e1c9560d-3714-4320-b114-34fd00c258c5': 'FTT-GT-8925',
      },
      showCancelConfirm: true,
      componentInstanceId: 1698770407970,
    },
    tabState: {
      id: '1698770407984_0.7079912167002815',
      width: 1289.9638671875,
      height: 997.75,
      visible: true,
      minWidth: 350,
      component: {
        name: 'chart',
        state: {
          link: 0.5337737957836111,
          settings: {
            general: {
              font: {
                fontSize: 10,
                textColor: '#fff',
                fontFamily: 'Arial',
              },
              gradient1: 'rgb(27, 29, 34)',
              gradient2: 'rgb(27, 29, 34)',
              gridColor: '#24262C',
              lineColor: '#fff',
              wickColor: '#fff',
              upCandleColor: '#0C62F7',
              dateScaleColor: 'rgb(27, 29, 34)',
              downCandleColor: '#C93B3B',
              valueScaleColor: 'rgb(27, 29, 34)',
              upCandleBorderColor: '#D0D0D2',
              downCandleBorderColor: '#D0D0D2',
              upCandleBorderColorEnabled: false,
              downCandleBorderColorEnabled: false,
            },
            session: {
              sessionEnabled: true,
              sessionTemplate: {
                id: 5,
                name: '24/7',
                exchange: 'CME',
                timezoneId: 'America/New_York',
                workingTimes: [
                  {
                    endDay: 2,
                    endTime: 86100000,
                    startDay: 2,
                    startTime: 0,
                  },
                  {
                    endDay: 3,
                    endTime: 86100000,
                    startDay: 3,
                    startTime: 0,
                  },
                  {
                    endDay: 4,
                    endTime: 86100000,
                    startDay: 4,
                    startTime: 0,
                  },
                  {
                    endDay: 1,
                    endTime: 86100000,
                    startDay: 1,
                    startTime: 0,
                  },
                  {
                    endDay: 5,
                    endTime: 86100000,
                    startDay: 5,
                    startTime: 0,
                  },
                  {
                    endDay: 6,
                    endTime: 86100000,
                    startDay: 6,
                    startTime: 0,
                  },
                  {
                    endDay: 0,
                    endTime: 86100000,
                    startDay: 0,
                    startTime: 0,
                  },
                ],
              },
            },
            trading: {
              tif: {
                DAY: true,
                GTC: true,
                default: 'DAY',
              },
              trading: {
                showPLInfo: true,
                displayUnit: 'points',
                orderBarUnit: 'pixels',
                showOHLVInfo: false,
                bracketButton: true,
                orderBarLength: 100,
                tradingBarUnit: 'pixels',
                showOrderConfirm: true,
                tradingBarLength: 40,
                includeRealizedPL: true,
                showCancelConfirm: true,
                showWorkingOrders: true,
                negativePLFontColor: 'red',
                positivePLFontColor: 'white',
                showInstrumentChange: false,
                negativePLBackgroundColor: 'black',
                positivePLBackgroundColor: 'black',
              },
              orderArea: {
                formData: {
                  quantity: 1,
                  stopLoss: {
                    type: 'StopMarket',
                    stopLoss: false,
                  },
                  takeProfit: {
                    takeProfit: false,
                  },
                },
                settings: {
                  tif: {
                    DAY: true,
                    GTC: true,
                    default: 'DAY',
                  },
                  flatten: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  orderArea: {
                    showPLInfo: true,
                  },
                  cancelButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  formSettings: {
                    showPLInfo: true,
                    showBracket: true,
                    showBuyButton: true,
                    showSellButton: true,
                    showCancelButton: true,
                    showFlattenButton: true,
                    showIcebergButton: true,
                    closePositionButton: true,
                    showLiquidateButton: true,
                  },
                  icebergButton: {
                    font: '#fff',
                    enabled: true,
                    background: '#51535A',
                  },
                  buyMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#0C62F7',
                  },
                  icebergFontColor: '#fff',
                  sellMarketButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#C93B3B',
                  },
                  buyButtonsFontColor: '#D0D0D2',
                  closePositionButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  showLiquidateButton: {
                    font: '#D0D0D2',
                    enabled: true,
                    background: '#51535A',
                  },
                  flatButtonsFontColor: '#D0D0D2',
                  sellButtonsFontColor: '#D0D0D2',
                  cancelButtonFontColor: '#D0D0D2',
                  closePositionFontColor: '#D0D0D2',
                  icebergBackgroundColor: '#51535A',
                  buyButtonsBackgroundColor: '#0C62F7',
                  flatButtonsBackgroundColor: '#51535A',
                  sellButtonsBackgroundColor: '#C93B3B',
                  cancelButtonBackgroundColor: '#51535A',
                  closePositionBackgroundColor: '#51535A',
                },
                amountButtons: [
                  {
                    value: 1,
                  },
                  {
                    black: true,
                    value: 1,
                  },
                  {
                    value: 3,
                  },
                  {
                    value: 5,
                  },
                  {
                    value: 10,
                  },
                  {
                    value: 25,
                  },
                ],
              },
              chartMarkers: {
                buyMarkerType: 'triangle',
                buyMarkerColor: 'blue',
                sellMarkerType: 'square',
                sellMarkerColor: 'red',
                buyMarkerFontWeight: 12,
                sellMarkerFontWeight: 12,
                dataBoxBackgroundColor: 'black',
                showBarMarkerTobBottom: true,
                showBarTextWithSizePrice: true,
                showBarMarketExecutionPrice: true,
              },
              ordersColors: {
                buy: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#33537C',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#0C62F7',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#33537C',
                  },
                },
                sell: {
                  stop: {
                    length: 1,
                    lineType: 'solid',
                    lineColor: '#C93B3B',
                  },
                  limit: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  market: {
                    length: 1,
                    lineType: 'dashed',
                    lineColor: '#FF0000',
                  },
                  stopLimit: {
                    length: 1,
                    lineType: 'dotted',
                    lineColor: '#C93B3B',
                  },
                },
                ocoStopLimit: '#FFFF00',
                ocoStopOrder: '#FFFF00',
                orderBarUnit: 'pixels',
                orderBarLength: 100,
              },
            },
            valueScale: {
              valueScale: {
                isAutomatic: 'pixels-price',
                pixelsPrice: 10,
              },
            },
          },
          showOHLV: false,
          timeFrame: {
            interval: 12,
            periodicity: 'revs',
          },
          instrument: {
            id: 'ESZ4.CME',
            type: 'Future',
            symbol: 'ESZ4',
            currency: 'USD',
            exchange: 'CME',
            tickSize: 0.25,
            precision: 2,
            expiration: '2024-12-20T00:00:00',
            description: 'E-Mini S&P 500 Dec24',
            productCode: 'ES',
            connectionId: 'e1c9560d-3714-4320-b114-34fd00c258c5',
            contractSize: 50,
            instrumentTimePeriod: 'Dec24',
            stringTypeRepresentation: 'Future',
          },
          showChanges: false,
          periodToLoad: {
            disabled: false,
            interval: 1,
            periodicity: 'd',
          },
          periodOptions: [
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'd',
                },
                {
                  disabled: false,
                  interval: 5,
                  periodicity: 'd',
                },
              ],
              periodicity: 'd',
            },
            {
              active: false,
              period: 'Weeks',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 2,
                  periodicity: 'w',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'w',
                },
              ],
              periodicity: 'w',
            },
            {
              active: false,
              period: 'Months',
              timeFrames: [
                {
                  disabled: false,
                  interval: 1,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 3,
                  periodicity: 'm',
                },
                {
                  disabled: false,
                  interval: 6,
                  periodicity: 'm',
                },
              ],
              periodicity: 'm',
            },
          ],
          showChartForm: true,
          enableOrderForm: false,
          intervalOptions: [
            {
              active: false,
              period: 'RevBars',
              timeFrames: [
                {
                  interval: 4,
                  periodicity: 'revs',
                },
                {
                  interval: 8,
                  periodicity: 'revs',
                },
                {
                  interval: 12,
                  periodicity: 'revs',
                },
                {
                  interval: 16,
                  periodicity: 'revs',
                },
              ],
              periodicities: ['revs'],
            },
            {
              active: false,
              period: 'Seconds',
              timeFrames: [
                {
                  interval: 30,
                  periodicity: 's',
                },
                {
                  interval: 40,
                  periodicity: 's',
                },
              ],
              periodicities: ['s'],
            },
            {
              active: false,
              period: 'Minutes',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: '',
                },
                {
                  interval: 3,
                  periodicity: '',
                },
                {
                  interval: 5,
                  periodicity: '',
                },
                {
                  interval: 15,
                  periodicity: '',
                },
                {
                  interval: 30,
                  periodicity: '',
                },
              ],
              periodicities: [''],
            },
            {
              active: false,
              period: 'Hours',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'h',
                },
                {
                  interval: 2,
                  periodicity: 'h',
                },
                {
                  interval: 3,
                  periodicity: 'h',
                },
                {
                  interval: 4,
                  periodicity: 'h',
                },
              ],
              periodicities: ['h'],
            },
            {
              active: false,
              period: 'Days',
              timeFrames: [
                {
                  interval: 1,
                  periodicity: 'd',
                },
                {
                  interval: 1,
                  periodicity: 'w',
                },
                {
                  interval: 1,
                  periodicity: 'm',
                },
              ],
              periodicities: ['d', 'm', 'w', 'y'],
            },
          ],
          isToolbarVisible: false,
          stockChartXState: {
            chart: {
              scale: 2,
              theme: {
                name: 'Dark',
                plot: {
                  bar: {
                    HL: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    HLC: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    OHLC: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    kagi: {
                      upCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                          strokeEnabled: true,
                        },
                      },
                      downCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                          strokeEnabled: true,
                        },
                      },
                    },
                    renko: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                    },
                    candle: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredHL: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    lineBreak: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                          fillEnabled: true,
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                          fillEnabled: true,
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#3FA7E1',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#E85050',
                      },
                    },
                    equiVolume: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    heikinAshi: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: 'white',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    coloredOHLC: {
                      upBar: {
                        width: 1,
                        strokeColor: '#0C62F7',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: '#C93B3B',
                      },
                    },
                    candleVolume: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                    hollowCandle: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        wick: {
                          width: 1,
                          strokeColor: '#fff',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      upHollowCandle: {
                        wick: {
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                        },
                      },
                      downHollowCandle: {
                        wick: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                        },
                      },
                    },
                    pointAndFigure: {
                      upCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#0C62F7',
                          strokeEnabled: true,
                        },
                      },
                      downCandle: {
                        border: {
                          width: 1,
                          strokeColor: '#C93B3B',
                          strokeEnabled: true,
                        },
                      },
                    },
                    equiVolumeShadow: {
                      upCandle: {
                        fill: {
                          fillColor: '#0C62F7',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                      downCandle: {
                        fill: {
                          fillColor: '#C93B3B',
                        },
                        border: {
                          width: 1,
                          strokeColor: '#D0D0D2',
                          strokeEnabled: false,
                        },
                      },
                    },
                  },
                  line: {
                    step: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    simple: {
                      width: 1,
                      strokeColor: '#fff',
                    },
                    mountain: {
                      fill: {
                        linearGradient: [
                          'rgba(63, 167, 225, 0.4) ',
                          'rgba(63, 167, 225, 0)',
                        ],
                      },
                      line: {
                        width: 1,
                        strokeColor: '#fff',
                      },
                    },
                  },
                  point: {
                    dot: {
                      fill: {
                        fillColor: 'white',
                      },
                    },
                  },
                  histogram: {
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    column: {
                      fill: {
                        fillColor: 'blue',
                      },
                      line: {
                        width: 1,
                        strokeColor: 'white',
                        strokeEnabled: false,
                      },
                    },
                    coloredLine: {
                      upBar: {
                        width: 1,
                        strokeColor: 'grey',
                      },
                      downBar: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                    coloredColumn: {
                      upBar: {
                        fill: {
                          fillColor: 'green',
                        },
                        line: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                      downBar: {
                        fill: {
                          fillColor: 'red',
                        },
                        line: {
                          width: 1,
                          strokeColor: 'white',
                          strokeEnabled: false,
                        },
                      },
                    },
                  },
                  indicator: {
                    line1: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'white',
                    },
                    line2: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#3FA7E1',
                    },
                    line3: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#E85050',
                    },
                    line4: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0000FF',
                    },
                    line5: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: 'yellow',
                    },
                    levels: {
                      line1: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FF0000',
                      },
                      line2: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#32CD32',
                      },
                      line3: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FFD500',
                      },
                      line4: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0000FF',
                      },
                      line5: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#808080',
                      },
                    },
                    blackText: {
                      fontSize: 10,
                      fillColor: 'black',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    histogram: {
                      color: '#32CD32',
                    },
                  },
                },
                chart: {
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'grey',
                  },
                  background: ['rgb(27, 29, 34)', 'rgb(27, 29, 34)'],
                  instrumentWatermark: {
                    symbol: {
                      fontSize: 70,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    details: {
                      fontSize: 40,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                  },
                },
                button: {
                  none: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                  accept: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'green',
                    },
                  },
                  cancel: {
                    fill: {
                      fillColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'red',
                    },
                  },
                },
                spread: {
                  ask: {
                    line: {
                      width: 1,
                      strokeColor: '#00D533',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#00D533',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        fontFamily: 'Calibri',
                      },
                    },
                  },
                  bid: {
                    line: {
                      width: 1,
                      strokeColor: '#F20500',
                    },
                    valueMarker: {
                      fill: {
                        fillColor: '#F20500',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        fontFamily: 'Calibri',
                      },
                    },
                  },
                },
                zoomIn: {
                  fill: {
                    fillColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  border: {
                    width: 1,
                    lineStyle: 'solid',
                    strokeColor: 'white',
                  },
                },
                drawing: {
                  note: {
                    fill: {
                      fillColor: 'orange',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                    centerPointFill: {
                      fillColor: 'rgb(68, 68, 68)',
                    },
                  },
                  text: {
                    text: {
                      fontSize: 12,
                      fillColor: '#FFFFFF',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                  },
                  image: {
                    noImage: {
                      line: {
                        width: 1,
                        strokeColor: 'red',
                      },
                    },
                  },
                  arrowUp: {
                    fill: {
                      fillColor: 'limegreen',
                    },
                  },
                  measure: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.5)',
                      fillEnabled: true,
                    },
                    line: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    border: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    balloon: {
                      fill: {
                        fillColor: 'rgba(255, 255, 255, 0.5)',
                        fillEnabled: true,
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontFamily: 'Calibri',
                      },
                      border: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: 'darkgray',
                        strokeEnabled: true,
                      },
                    },
                  },
                  abstract: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                  },
                  arrowDown: {
                    fill: {
                      fillColor: 'red',
                    },
                  },
                  fibonacci: {
                    fill: {
                      fillColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                    trendLine: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: 'white',
                    },
                  },
                  trendAngle: {
                    arc: {
                      width: 1,
                      lineStyle: 'dot',
                      strokeColor: 'white',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'white',
                      decoration: '',
                      fontFamily: 'Calibri',
                    },
                    horizontalLine: {
                      width: 1,
                      lineStyle: 'dot',
                      strokeColor: 'white',
                    },
                  },
                  measureTool: {
                    fill: {
                      fillColor: 'rgba(0, 0, 0, 0.8)',
                    },
                    line: {
                      width: 1,
                      strokeColor: 'white',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'white',
                      fontFamily: 'Calibri',
                    },
                  },
                  abstractMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                  },
                },
                tooltip: {
                  fill: {
                    color: 'rgb(44, 44, 44)',
                    enabled: true,
                  },
                  text: {
                    fontSize: 12,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    decoration: '',
                    fontFamily: 'Calibri',
                    fontWeight: 'normal',
                  },
                  border: {
                    color: 'orange',
                    width: 1,
                    enabled: true,
                    lineStyle: 'solid',
                  },
                },
                splitter: {
                  fillColor: '#292C38',
                  hoverFillColor: 'white',
                },
                crossHair: {
                  fill: {
                    fillColor: '#FFFFFF',
                  },
                  line: {
                    width: 1,
                    lineStyle: 'dash',
                    strokeColor: 'darkgray',
                  },
                  text: {
                    fontSize: 11,
                    fillColor: '#000000',
                    fontFamily: 'Calibri',
                  },
                },
                dateScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  text: {
                    fontSize: 10,
                    fillColor: '#fff',
                    fontFamily: 'Arial',
                  },
                  border: {
                    width: 0,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                  },
                  breakLine: {
                    stroke: {
                      width: 1,
                      lineStyle: 'dash',
                      strokeColor: '#545454',
                    },
                  },
                  dateMarker: {
                    fill: {
                      fillColor: 'green',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: '#FFFFFF',
                      fontFamily: 'Calibri',
                    },
                    stroke: {
                      width: 1,
                      lineCap: 'butt',
                      lineJoin: 'miter',
                      lineStyle: 'solid',
                      strokeColor: '#696969',
                      strokePriority: 'color',
                    },
                  },
                },
                chartPanel: {
                  grid: {
                    width: 1,
                    strokeColor: '#24262C',
                  },
                  title: {
                    fontSize: 11,
                    fillColor: 'white',
                    fontStyle: 'normal',
                    fontFamily: 'Calibri',
                  },
                  instrumentWatermark: {
                    symbol: {
                      fontSize: 70,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                    details: {
                      fontSize: 40,
                      fillColor: 'white',
                      fontStyle: 'normal',
                      fontFamily: 'Arial',
                    },
                  },
                },
                valueScale: {
                  fill: {
                    fillColor: 'rgb(27, 29, 34)',
                  },
                  line: {
                    width: 1,
                    strokeColor: '#505050',
                  },
                  text: {
                    fontSize: 10,
                    fillColor: '#fff',
                    fontStyle: 'normal',
                    fontFamily: 'Arial',
                  },
                  border: {
                    width: 0,
                    lineStyle: 'solid',
                    strokeColor: 'darkgray',
                  },
                  valueMarker: {
                    fill: {
                      fillColor: 'white',
                    },
                    text: {
                      fontSize: 11,
                      fillColor: 'black',
                      fontFamily: 'Calibri',
                    },
                  },
                },
                chartMarkers: {
                  buyMarkerType: 'triangle',
                  buyMarkerColor: 'blue',
                  sellMarkerType: 'square',
                  sellMarkerColor: 'red',
                  buyMarkerFontWeight: 1,
                  sellMarkerFontWeight: 1,
                  dataBoxBackgroundColor: 'black',
                  showBarMarkerTobBottom: true,
                  showBarTextWithSizePrice: true,
                  showBarMarketExecutionPrice: true,
                },
                tradingPanel: {
                  showPLInfo: true,
                  displayUnit: 'points',
                  tradingBarUnit: 'pixels',
                  markerFontColor: '#fff',
                  tradingBarLength: 40,
                  includeRealizedPL: true,
                  buyPanelBackground: 'rgba(12,98,247, 0.3)',
                  buyMarkerBackground: '#0C62F7',
                  negativePLFontColor: 'red',
                  positivePLFontColor: 'white',
                  sellPanelBackground: 'rgba(201, 59, 59, 0.3)',
                  sellMarkerBackground: '#C93B3B',
                  negativePLBackgroundColor: 'black',
                  positivePLBackgroundColor: 'black',
                },
                orderSideMarker: {
                  buy: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#33537C',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#0C62F7',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#33537C',
                    },
                  },
                  sell: {
                    stop: {
                      length: 1,
                      lineType: 'solid',
                      lineColor: '#C93B3B',
                    },
                    limit: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    market: {
                      length: 1,
                      lineType: 'dashed',
                      lineColor: '#FF0000',
                    },
                    stopLimit: {
                      length: 1,
                      lineType: 'dotted',
                      lineColor: '#C93B3B',
                    },
                  },
                  ocoStopLimit: '#FFFF00',
                  ocoStopOrder: '#FFFF00',
                  orderBarUnit: 'pixels',
                  orderBarLength: 100,
                },
                selectionMarker: {
                  fill: {
                    fillColor: 'black',
                  },
                  line: {
                    width: 1,
                    strokeColor: 'white',
                  },
                },
                orderChartMarker: {
                  buy: {
                    fill: {
                      fillColor: 'blue',
                    },
                    stroke: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    tooltip: {
                      fill: {
                        color: 'black',
                        enabled: true,
                      },
                      text: {
                        fontSize: 12,
                        fillColor: 'blue',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                        fontWeight: 'normal',
                      },
                      border: {
                        color: 'orange',
                        width: 1,
                        enabled: false,
                        lineStyle: 'solid',
                      },
                    },
                    markerType: 'triangle',
                  },
                  sell: {
                    fill: {
                      fillColor: 'red',
                    },
                    stroke: {
                      width: 1,
                      strokeColor: 'white',
                      strokeEnabled: true,
                    },
                    tooltip: {
                      fill: {
                        color: 'black',
                        enabled: true,
                      },
                      text: {
                        fontSize: 12,
                        fillColor: 'red',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                        fontWeight: 'normal',
                      },
                      border: {
                        color: 'orange',
                        width: 1,
                        enabled: false,
                        lineStyle: 'solid',
                      },
                    },
                    markerType: 'square',
                  },
                  showTopBottomMarkers: true,
                  showTextWithSizeAndPrice: true,
                  showMarkerAtExecutionPrice: true,
                },
                highlightedColumn: {
                  fill: {
                    fillColor: 'rgba(90, 130, 182, 0.45)',
                  },
                },
                positionSideMarker: {
                  long: {
                    kind: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    line: {
                      width: 1,
                      strokeColor: 'green',
                    },
                    price: {
                      fill: {
                        fillColor: 'blue',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    length: 1,
                    lineType: 'solid',
                    quantity: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    lineColor: '#0C62F7',
                    lengthUnit: 'pixels',
                  },
                  short: {
                    kind: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        fontStyle: 'normal',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    line: {
                      width: 1,
                      strokeColor: 'red',
                    },
                    price: {
                      fill: {
                        fillColor: 'red',
                      },
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    length: 1,
                    lineType: 'solid',
                    quantity: {
                      text: {
                        fontSize: 11,
                        fillColor: 'white',
                        decoration: '',
                        fontFamily: 'Calibri',
                      },
                    },
                    lineColor: '#FF0000',
                    lengthUnit: 'pixels',
                  },
                  showPLInfo: true,
                  displayUnit: 'points',
                  positionBarUnit: 'pixels',
                  includeRealizedPL: true,
                  positionBarLength: 100,
                  negativePLFontColor: 'red',
                  positivePLFontColor: 'white',
                  negativePLBackgroundColor: 'black',
                  positivePLBackgroundColor: 'black',
                },
              },
              locale: 'en',
              priceStyle: 'candle',
              zoomEnabled: true,
              xGridVisible: true,
              yGridVisible: true,
              zoomAutoSave: false,
              scrollEnabled: true,
              enableMouseEvents: true,
              showBarInfoInTitle: true,
              enableKeyboardEvents: false,
              showInstrumentWatermark: false,
            },
            plots: {
              VWAP: {
                bands: {
                  band1: {
                    isShow: false,
                    styleLine: {
                      width: 1,
                      lineStyle: 'solid',
                    },
                    coefficient: 1,
                    strokeColor: '#FFFF00',
                  },
                  band2: {
                    isShow: false,
                    styleLine: {
                      width: 1,
                      lineStyle: 'solid',
                    },
                    coefficient: 2,
                    strokeColor: '#FFFF00',
                  },
                  band3: {
                    isShow: false,
                    styleLine: {
                      width: 1,
                      lineStyle: 'solid',
                    },
                    coefficient: 3,
                    strokeColor: '#FFFF00',
                  },
                },
                label: {
                  width: {
                    unit: 'pixel',
                    value: 400,
                  },
                  margin: {
                    unit: 'pixel',
                    value: 0,
                  },
                  fontSize: 10,
                  fillColor: 'rgb(0,0,0,1)',
                  lineStyle: {
                    width: 1,
                    lineStyle: 'solid',
                  },
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                  isShowVWAPLabel: false,
                },
                general: {
                  styleLine: {
                    style: 'hollow',
                    lineStyle: {
                      width: 1,
                      lineStyle: 'solid',
                    },
                    strokeColor: '#00E5DD',
                  },
                  customTimes: null,
                  customDuration: 'day',
                  isShowCustomDuration: false,
                },
              },
              Footprint: {
                font: {
                  fontSize: 11,
                  fillColor: '#666666',
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                },
                main: {
                  mode: 'volume',
                  fillColor: '#3131316d',
                  strokeColor: '#85857A00',
                  barStrokeColor: '#85857A00  ',
                  closeOpenColor: '#1ADE5D',
                  customTickSize: {
                    value: 1,
                    enabled: false,
                  },
                  openCloseColor: '#FF2E00',
                  enableOpenClose: true,
                },
                text: {
                  margin: 4,
                  hideSides: false,
                  separator: 'x',
                  textStyle: 'bidAsk',
                  initialBarWidth: 80,
                  barWidthTextThreshold: 60,
                  barHeightTextThreshold: 5,
                },
                profile: {
                  filterValue: 4000,
                  strokeWidth: 1,
                  profileFilter: 'autoMaxProfile',
                  autoProfilingRange: 'visible',
                  showPocOnlyOnBiggestBar: false,
                },
                intraBar: {
                  barColor: '#A9A9A9',
                  barStyle: 'volumeProfileBars',
                  barPocColor: '#FFA500',
                  barAlignment: 'left',
                  buyPocBarColor: 'gold',
                  sellPocBarColor: 'gold',
                  buyShadeBarColor: '#0C62F7',
                  buyVolumeBarColor: 'silver',
                  sellShadeBarColor: '#E95050',
                  sellVolumeBarColor: 'silver',
                  lastPriceOutlineColor: '#e8e8e8',
                  useDeltaShadingColors: true,
                },
                pullback: {
                  enabled: true,
                  fillColor: '#3F3F3F',
                  textColor: '#E5E5E5',
                  strokeColor: 'transparent',
                  currentBidAskColor: '#8B6D00',
                },
                volumeFilter: {
                  mode: 'greaterThan',
                  value: 100,
                  enabled: false,
                },
                deltaImbalance: {
                  enabled: true,
                  threshold: 3.5,
                  weakAskVolumeColor: '#0C62F7',
                  weakBidVolumeColor: '#0C62F7',
                  enableWeakAskVolume: false,
                  enableWeakBidVolume: false,
                  strongAskVolumeColor: '#00BD62',
                  strongBidVolumeColor: '#FF6347',
                },
              },
              PriceStats: {
                eth: {
                  lines: {
                    dev: {
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#FFFF00',
                        },
                      },
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#FFFF00',
                        },
                      },
                    },
                    prev: {
                      va: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                      poc: {
                        enabled: false,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#8D8E30',
                        },
                      },
                    },
                    current: {
                      va: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#FFFF00',
                        },
                        labelEnabled: false,
                      },
                      poc: {
                        enabled: true,
                        strokeTheme: {
                          width: 1,
                          lineStyle: 'solid',
                          strokeColor: '#FFFF00',
                        },
                        labelEnabled: false,
                      },
                    },
                  },
                  profile: {
                    type: 'solidgram',
                    color: {
                      type: 'profileColor',
                      value: '#0C62F7',
                    },
                    extendNaked: {
                      type: 'closesHighsLows',
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#0C62F7',
                      },
                    },
                    profileColor: '',
                    extendNakedPocs: true,
                    vaInsideOpacity: 0.5,
                    vaOutsideOpacity: 0.5,
                    widthCorrelation: 0.5,
                  },
                  session: null,
                },
                lines: {
                  dev: {
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#A1A2A5',
                      },
                    },
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FE00E5',
                      },
                    },
                  },
                  prev: {
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#A1A2A5',
                      },
                    },
                    poc: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#A1A2A5',
                      },
                    },
                  },
                  current: {
                    va: {
                      enabled: false,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#A1A2A5',
                      },
                      labelEnabled: false,
                    },
                    poc: {
                      enabled: true,
                      strokeTheme: {
                        width: 1,
                        lineStyle: 'solid',
                        strokeColor: '#FE00E5',
                      },
                      labelEnabled: false,
                    },
                  },
                },
                general: {
                  hide: {
                    value: 'lastProfile',
                    enabled: false,
                  },
                  type: 'volume',
                  align: 'right',
                  width: {
                    unit: 'pixel',
                    value: 70,
                  },
                  margin: {
                    unit: 'pixel',
                    value: 4,
                  },
                  period: {
                    date: null,
                    type: 'every',
                    unit: 'day',
                    value: 1,
                  },
                  sessions: {
                    days: {
                      count: 10,
                      width: {
                        unit: 'pixel',
                        value: 100,
                      },
                      enabled: false,
                      includeCurrentSession: false,
                    },
                    prev: {
                      width: {
                        unit: 'pixel',
                        value: 125,
                      },
                      enabled: true,
                    },
                    current: {
                      width: {
                        unit: 'pixel',
                        value: 125,
                      },
                      enabled: true,
                    },
                  },
                  smoothed: {
                    value: 9,
                    enabled: false,
                  },
                  transparency: 35,
                  vaCorrelation: 0.7,
                  customTickSize: {
                    value: 1,
                    enabled: true,
                  },
                  calculateXProfiles: 999,
                },
                profile: {
                  type: 'solidgram',
                  color: {
                    type: 'heatMap',
                    value: '#0C62F7',
                  },
                  extendNaked: {
                    type: 'closesHighsLows',
                    enabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#0C62F7',
                    },
                  },
                  profileColor: '',
                  extendNakedPocs: true,
                  vaInsideOpacity: 0.5,
                  vaOutsideOpacity: 0.5,
                  widthCorrelation: 0.5,
                },
                session: {
                  id: 628,
                  name: 'US Extended Trading Hours',
                  exchange: 'CME',
                  isDefault: true,
                  timezoneId: 'America/New_York',
                  workingTimes: [
                    {
                      id: 0,
                      endDay: 5,
                      endTime: 61200000,
                      startDay: 4,
                      sessionId: 628,
                      startTime: 64800000,
                      tradingDay: 5,
                    },
                    {
                      id: 0,
                      endDay: 4,
                      endTime: 61200000,
                      startDay: 3,
                      sessionId: 628,
                      startTime: 64800000,
                      tradingDay: 4,
                    },
                    {
                      id: 0,
                      endDay: 3,
                      endTime: 61200000,
                      startDay: 2,
                      sessionId: 628,
                      startTime: 64800000,
                      tradingDay: 3,
                    },
                    {
                      id: 0,
                      endDay: 2,
                      endTime: 61200000,
                      startDay: 1,
                      sessionId: 628,
                      startTime: 64800000,
                      tradingDay: 2,
                    },
                    {
                      id: 0,
                      endDay: 1,
                      endTime: 61200000,
                      startDay: 0,
                      sessionId: 628,
                      startTime: 64800000,
                      tradingDay: 1,
                    },
                  ],
                },
                graphics: {
                  showPrices: true,
                  summaryFont: {
                    fontSize: 12,
                    fillColor: '#0C62F7',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                  },
                  summaryEnabled: true,
                },
                highlight: {
                  va: {
                    value: '#A1A2A5',
                    enabled: false,
                  },
                  poc: {
                    value: '#FE00E5',
                    enabled: true,
                  },
                },
                splitProfile: false,
                overlayEthOverRth: false,
              },
              SessionStats: {
                font: {
                  fontSize: 10,
                  fillColor: '#000000',
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                },
                lines: {
                  ibLow: {
                    label: 'IB Low',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                    labelEnabled: true,
                  },
                  ethLow: {
                    label: 'ETH Low',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#BC0606',
                    },
                    labelEnabled: true,
                  },
                  ethMid: {
                    label: 'ETH Mid',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#005B9C',
                    },
                    labelEnabled: true,
                  },
                  ibHigh: {
                    label: 'IB High',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                    labelEnabled: true,
                  },
                  rthLow: {
                    label: 'RTH Low',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#BC0606',
                    },
                    labelEnabled: true,
                  },
                  rthMid: {
                    label: 'RTH Mid',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#1E4EF6',
                    },
                    labelEnabled: true,
                  },
                  ethHigh: {
                    label: 'ETH High',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#006534',
                    },
                    labelEnabled: true,
                  },
                  rthHigh: {
                    label: 'RTH High',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#006534',
                    },
                    labelEnabled: true,
                  },
                  rthOpen: {
                    label: 'RTH Open',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#007A76',
                    },
                    labelEnabled: true,
                  },
                  prevWkLow: {
                    label: 'Prev WK Low',
                    enabled: false,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                    labelEnabled: true,
                  },
                  rthSettle: {
                    label: 'RTH Settle',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#8B6D00',
                    },
                    labelEnabled: true,
                  },
                  prevRthLow: {
                    label: 'Prev RTH Low',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FF6C1C',
                    },
                    labelEnabled: true,
                  },
                  prevWkHigh: {
                    label: 'Prev WK High',
                    enabled: false,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#FFFF00',
                    },
                    labelEnabled: true,
                  },
                  prevRthHigh: {
                    label: 'Prev RTH High',
                    enabled: true,
                    devEnabled: false,
                    strokeTheme: {
                      width: 1,
                      lineStyle: 'solid',
                      strokeColor: '#00BD62',
                    },
                    labelEnabled: true,
                  },
                },
                general: {
                  style: 'line',
                  width: {
                    unit: 'pixel',
                    value: 200,
                  },
                  margin: {
                    unit: 'pixel',
                    value: 10,
                  },
                },
                ethSessionId: 2,
                rthSessionId: 1,
                workingTimes: {
                  eth: {
                    id: 2,
                    name: 'US ETH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
                    workingTimes: [
                      {
                        endDay: 1,
                        endTime: 34200000,
                        startDay: 0,
                        startTime: 64800000,
                      },
                      {
                        endDay: 2,
                        endTime: 34200000,
                        startDay: 1,
                        startTime: 64800000,
                      },
                      {
                        endDay: 3,
                        endTime: 34200000,
                        startDay: 2,
                        startTime: 64800000,
                      },
                      {
                        endDay: 4,
                        endTime: 34200000,
                        startDay: 3,
                        startTime: 64800000,
                      },
                      {
                        endDay: 5,
                        endTime: 34200000,
                        startDay: 4,
                        startTime: 64800000,
                      },
                    ],
                  },
                  rth: {
                    id: 1,
                    name: 'US RTH',
                    exchange: 'CME',
                    timezoneId: 'America/New_York',
                    connectionId: 'd4135277-3f43-4d35-a646-8d7b2c7673b1',
                    workingTimes: [
                      {
                        endDay: 1,
                        endTime: 61200000,
                        startDay: 1,
                        startTime: 34200000,
                      },
                      {
                        endDay: 2,
                        endTime: 61200000,
                        startDay: 2,
                        startTime: 34200000,
                      },
                      {
                        endDay: 3,
                        endTime: 61200000,
                        startDay: 3,
                        startTime: 34200000,
                      },
                      {
                        endDay: 4,
                        endTime: 61200000,
                        startDay: 4,
                        startTime: 34200000,
                      },
                      {
                        endDay: 5,
                        endTime: 61200000,
                        startDay: 5,
                        startTime: 34200000,
                      },
                    ],
                  },
                },
              },
            },
            drawings: [
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'html',
                  text: '<div>Buy 15 <br> @5809.35</div>',
                  theme: {
                    fill: {
                      color: 'black',
                      enabled: true,
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'blue',
                      fontStyle: 'normal',
                      decoration: '',
                      fontFamily: 'Calibri',
                      fontWeight: 'normal',
                    },
                    border: {
                      color: 'orange',
                      width: 1,
                      enabled: false,
                      lineStyle: 'solid',
                    },
                  },
                  position: 'bottom',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [
                    {
                      date: '2024-10-28T16:05:27.627Z',
                      value: 5871.718139773895,
                    },
                  ],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'horizontalLine',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [
                    {
                      date: '2024-10-28T15:57:08.149Z',
                      value: 5872.248715313463,
                    },
                    {
                      date: '2024-10-28T16:13:42.872Z',
                      value: 5870.899537512847,
                    },
                  ],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'rectangle',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'html',
                  text: '<div>Buy 20 <br> @5774.38</div>',
                  theme: {
                    fill: {
                      color: 'black',
                      enabled: true,
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'blue',
                      fontStyle: 'normal',
                      decoration: '',
                      fontFamily: 'Calibri',
                      fontWeight: 'normal',
                    },
                    border: {
                      color: 'orange',
                      width: 1,
                      enabled: false,
                      lineStyle: 'solid',
                    },
                  },
                  position: 'bottom',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'html',
                  text: '<div>Sell 20 <br> @5776.13</div>',
                  theme: {
                    fill: {
                      color: 'black',
                      enabled: true,
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'red',
                      fontStyle: 'normal',
                      decoration: '',
                      fontFamily: 'Calibri',
                      fontWeight: 'normal',
                    },
                    border: {
                      color: 'orange',
                      width: 1,
                      enabled: false,
                      lineStyle: 'solid',
                    },
                  },
                  position: 'top',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'html',
                  text: '<div>Sell 20 <br> @5911.75</div>',
                  theme: {
                    fill: {
                      color: 'black',
                      enabled: true,
                    },
                    text: {
                      fontSize: 12,
                      fillColor: 'red',
                      fontStyle: 'normal',
                      decoration: '',
                      fontFamily: 'Calibri',
                      fontWeight: 'normal',
                    },
                    border: {
                      color: 'orange',
                      width: 1,
                      enabled: false,
                      lineStyle: 'solid',
                    },
                  },
                  position: 'top',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
              {
                options: {
                  locked: false,
                  points: [{}],
                  zIndex: 2000,
                  visible: true,
                  resizable: true,
                  magnetMode: 'none',
                  selectable: true,
                  magnetPoint: 'bar',
                  createPointBehavior: {
                    x: 'date',
                    y: 'value',
                  },
                },
                tooltip: {
                  kind: 'text',
                  text: '',
                },
                className: 'orderchartmarker',
                panelIndex: 0,
                valueScaleIndex: 0,
              },
            ],
            barsCount: false,
            crossHair: {
              crossHairType: 'crossBars',
            },
            dateScale: {
              height: 15,
              zoomKind: 'autoscaled',
              zoomMode: 'pin_mouse',
              formatter: {
                locale: 'en',
                className: 'StockChartX.CustomTimeIntervalDateTimeFormat',
                timeInterval: 12,
              },
              calibrator: {
                options: {},
                className: 'StockChartX.CustomDateScaleCalibrator',
              },
              scrollKind: 'autoscaled',
              textPadding: {
                top: null,
                left: 3,
                right: 3,
                bottom: 3,
              },
              autoScrollKind: 'visible_bar',
              autoScrollMode: 'new_bar',
              useManualHeight: false,
              lastVisibleRecord: 0,
              minVisibleRecords: 5,
              firstVisibleRecord: 1,
              allowPartialRecords: true,
              majorTickMarkLength: 5,
              minorTickMarkLength: 3,
              rightAdditionalSpaceRatio: 0.5,
            },
            timeFrame: {
              interval: 12,
              periodicity: 'revs',
            },
            indicators: [
              {
                zIndex: 997,
                visible: true,
                className: 'Footprint',
                showTitle: true,
                panelIndex: 0,
                parameters: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                _prevParams: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                showLineLevels: false,
                allowContextMenu: true,
                panelHeightRatio: 1,
                showValueMarkers: true,
                showParamsInTitle: true,
                showValuesInTitle: true,
                coloredVolumeTheme: null,
                allowSettingsDialog: false,
              },
              {
                zIndex: 998,
                visible: true,
                className: 'PriceStats',
                showTitle: true,
                panelIndex: 0,
                parameters: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                _prevParams: {
                  'Line Color': '#0C62F7',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                showLineLevels: false,
                allowContextMenu: true,
                panelHeightRatio: 1,
                showValueMarkers: true,
                showParamsInTitle: true,
                showValuesInTitle: true,
                coloredVolumeTheme: null,
                allowSettingsDialog: false,
              },
              {
                zIndex: 999,
                visible: true,
                className: 'VWAP',
                showTitle: true,
                panelIndex: 0,
                parameters: {
                  Periods: 0,
                  'Line Color': 'white',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                _prevParams: {
                  Periods: 0,
                  'Line Color': 'white',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                showLineLevels: false,
                allowContextMenu: true,
                panelHeightRatio: 1,
                showValueMarkers: true,
                showParamsInTitle: true,
                showValuesInTitle: true,
                coloredVolumeTheme: null,
                allowSettingsDialog: false,
              },
              {
                zIndex: 1000,
                visible: true,
                className: 'SessionStats',
                showTitle: true,
                panelIndex: 0,
                parameters: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                _prevParams: {
                  'Line Color': '#3452F1',
                  'Line Style': 'solid',
                  'Line Width': 1,
                  'Line Enabled': true,
                },
                showLineLevels: false,
                allowContextMenu: true,
                panelHeightRatio: 1,
                showValueMarkers: true,
                showParamsInTitle: true,
                showValuesInTitle: true,
                coloredVolumeTheme: null,
                allowSettingsDialog: false,
              },
            ],
            priceStyle: {
              options: {
                showValueLines: false,
                extendValueLines: false,
              },
              className: 'candle',
            },
            valueScales: [
              {
                width: 100,
                showLeftPanel: false,
                showRightPanel: true,
                useManualWidth: false,
              },
            ],
            periodToLoad: {
              disabled: false,
              interval: 1,
              periodicity: 'd',
            },
            chartPanelsContainer: {
              panels: [
                {
                  options: {
                    state: 0,
                    visible: true,
                    moveKind: 'normal',
                    showXGrid: true,
                    showYGrid: true,
                    heightRatio: 1,
                    moveDirection: 'any',
                    drawValueScale: true,
                    maxHeightRatio: 1,
                    minHeightRatio: 0.05,
                    showIndicatorTitles: true,
                  },
                  valueScales: [
                    {
                      options: {
                        padding: {
                          top: 3,
                          left: 6,
                          right: 3,
                          bottom: 3,
                        },
                        maxAllowedValue: null,
                        maxVisibleValue: 11.863,
                        minAllowedValue: null,
                        minVisibleValue: -11.863,
                        maxValueRangeRatio: 5,
                        minValueRangeRatio: 0.1,
                        majorTickMarkLength: 3,
                        minorTickMarkLength: 3,
                        maxAllowedValueRatio: 0.8,
                        minAllowedValueRatio: 0.8,
                      },
                      formatter: {
                        options: {
                          style: 'decimal',
                          locale: 'en',
                          notation: 'standard',
                          signDisplay: 'auto',
                          useGrouping: 'auto',
                          roundingMode: 'halfExpand',
                          numberingSystem: 'latn',
                          roundingPriority: 'auto',
                          roundingIncrement: 1,
                          trailingZeroDisplay: 'auto',
                          minimumIntegerDigits: 1,
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        },
                        className: 'StockChartX.IntlNumberFormat',
                      },
                      calibrator: {
                        options: {
                          suffix: null,
                          divider: null,
                        },
                        className: 'StockChartX.IntervalValueScaleCalibrator',
                      },
                    },
                  ],
                },
              ],
              panelPadding: {
                top: 10,
                left: 5,
                right: 5,
                bottom: 10,
              },
              newPanelHeightRatio: 0.2,
            },
            instrumentComparisonHandler: {
              instrumentsOptions: [],
            },
          },
          preferredAccounts: {
            'c288af9a-d4c1-4e4a-af1d-b034fbca8776': 'APEX-41272-106',
            'd4135277-3f43-4d35-a646-8d7b2c7673b1': 'S1Nov208384304',
            'd428b3e6-bede-4e2a-bab6-325dcd1339e7': '42432ppr',
            'e1c9560d-3714-4320-b114-34fd00c258c5': 'FTT-GT-8925',
          },
          showCancelConfirm: true,
          componentInstanceId: 1698770407970,
        },
      },
      minHeight: 150,
      resizable: true,
      allowPopup: true,
      maximizable: true,
      minimizable: true,
    },
    isDefault: false,
  },
];
