<div class="modal-window">
  <div (click)="close($event)" class="modal-window__overlay">Click to close</div>

  <div class="modal-window__panel user-feedback">
    <h2 class="user-feedback__heading">Send Feedback</h2>

    <p class="text-center mt-4 mb-4">
      Would you like to see a new feature?<br />You noticed a bug in our
      platform?
    </p>

    <form [formGroup]="form">

      <div>
        <label class="custom-checkbox mb-1">
          <input type="checkbox" formControlName="consentToAttachTradeLog" />
          <span>Attach your recent trading activity logs to this report</span>
        </label>
      </div>

      <p class="user-feedback__label-wrapper">
        <label for="message" class="user-feedback__label"
          >Share your thougts with us!</label
        >
      </p>

      <div>
        <textarea
          class="user-feedback__message"
          id="message"
          name="message"
          formControlName="message"
          placeholder="Your message"
        ></textarea>
        <p
          *ngIf="isFormSubmitted && form.get('message').hasError('required')"
          class="user-feedback__error"
        >
          This field is required.
        </p>
      </div>

      <div class="user-feedback__buttons-wrapper">
        <button
          type="button"
          (click)="close($event)"
          class="user-feedback__button user-feedback__button--cancel"
        >
          Cancel
        </button>
        <button
          type="button"
          (click)="submit()"
          class="user-feedback__button user-feedback__button--submit"
        >
          Submit
        </button>
      </div>
    </form>

    <p class="modal-window__close-button-wrapper">
      <a
        href="#"
        (click)="close($event)"
        class="modal-window__close-button icon-close-window"
        >Close window.</a
      >
    </p>
  </div>
</div>
