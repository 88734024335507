import { Id, StringHelper } from 'base-components';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  getPrice,
  IOrder,
  OrdersFeed,
  OrderSide,
  OrderStatus,
  OrderType,
} from 'trading';

import { ChartObjects } from './chart-objects';

declare const StockChartX: any;

const { uncapitalize } = StringHelper;

export class OrderChartMarkers extends ChartObjects<IOrder> {
  protected _modal: NzModalService;
  protected _dataFeed = this._injector.get(OrdersFeed);
  protected _visible = false;
  protected _markers: any[] = [];
  protected _ordersById: Map<string, any> = new Map<string, any>();
  private _markerBarIndexByDate: Map<Date, number> = new Map<Date, number>();
  private _updateIntervalId: number;
  private _needsToBeRedrawn: boolean = false;

  get requestParams() {
    return {
      symbol: this._instance.instrument.symbol,
      exchange: this._instance.instrument.exchange,
      accountId: this._instance.accountId,
      hideStopped: true,
    };
  }

  init() {
    super.init();
    if (this._updateIntervalId) {
      clearInterval(this._updateIntervalId);
    }
    this._updateIntervalId = setInterval(() => {
      if (this._needsToBeRedrawn) {
        requestAnimationFrame(() => this.update());
      }
    }, 1000);

    this._modal = this._injector.get(NzModalService);
    this._chart.on(StockChartX.ChartEvent.HISTORY_LOADED, () => this.update());

    this._instance.$ordersFetched.subscribe((orders: IOrder[]): void => {
      const filteredOrders = orders.filter(
        (o: IOrder) =>
          this.isInstrumentMatching(o) && o.status === OrderStatus.Filled,
      );
      if (filteredOrders.length === this.items.length) {
        return;
      }

      this.items = filteredOrders as any;

      filteredOrders.forEach((order: IOrder): void => {
        this.handle(order);
      });
      requestAnimationFrame(() => this.update());
      this._onDataLoaded();
    });
  }

  isInstrumentMatching(order: IOrder): boolean {
    const chartSymbol: string =
      this._instance?.instrument?.tradingSymbol ||
      this._instance?.instrument?.symbol;
    const orderSymbol: string =
      order?.instrument?.tradingSymbol || order?.instrument?.symbol;

    return chartSymbol === orderSymbol;
  }

  createMarker(model) {
    const order = this._map(model);

    this._ordersById.set(order.id, order);
    this._needsToBeRedrawn = true;

    return null;
  }

  refresh() {
    super.refresh();
    this._markers.forEach((marker) => {
      marker.remove();
    });
    this._markers = [];
    this._ordersById.clear();
    this._markerBarIndexByDate.clear();
  }

  update() {
    this._needsToBeRedrawn = false;
    // fix the issue on chart reload
    if (!this._isChartHaveData()) {
      return null;
    }

    // update all markers
    // @todo use `this.items` (pre-filtered) instead re-filtering `this._ordersById` ?
    let orders: any[] = Array.from(this._ordersById.values());
    orders = orders.filter(
      (o: IOrder) =>
        this.isInstrumentMatching(o) && o.status === OrderStatus.Filled,
    );

    orders.forEach((order) => {
      const side: string = order.side.toLowerCase();
      let markerBarIndex: number = this._markerBarIndexByDate.get(order.date);
      if (!markerBarIndex) {
        markerBarIndex = this._markerIndex(order.date);
        this._markerBarIndexByDate.set(order.date, markerBarIndex);
      }
      const symbol: string = order.instrument.symbol;
      const existingMarker = this._markers.find(
        (m) =>
          m.index === markerBarIndex && m.side === side && m.symbol === symbol,
      );
      const markerCollectionIndex: number = this._markers.findIndex(
        (m) =>
          m.index === markerBarIndex && m.side === side && m.symbol === symbol,
      );

      const shouldOrderBeShownAsMarker: boolean =
        this._getMarkerVisibility(order);

      if (existingMarker) {
        const marker = this._markers[markerCollectionIndex];
        marker.addOrUpdateOrder(order);
      } else {
        if (shouldOrderBeShownAsMarker) {
          this._createSCXMarker(order);
        } else {
          this._markers[markerCollectionIndex].remove();
          this._markers[markerCollectionIndex] = null;
        }
      }
    });
  }

  private _getMarkerVisibility(order: IOrder): boolean {
    const accountId: Id = order.accountId || order.account.id;

    return accountId === this._instance.accountId;
  }

  private _createSCXMarker(order) {
    const marker = new StockChartX.OrderChartMarker({
      order,
    });

    this._markers.push(marker);

    // containsObject
    // removeObject
    marker.chartPanel = this._chart.mainPanel;
    this._chart.mainPanel.addObjects(marker);
  }

  getOrders(side?: OrderSide) {
    return Object.values(this._markersMap)
      .filter((item: any) => {
        if (!side) return true;
        if (item.order.isOco) return false;
        return item.order.side === side;
      })
      .map((item: any) => item.order);
  }

  private _isChartHaveData() {
    return this._chart?.dataManager?.barDataSeries()?.date?.length > 0;
  }

  private _markerIndex(date: Date) {
    return this._chart.dataManager.barDataSeries().date.floorIndex(date);
  }

  destroy() {
    super.destroy();
    this._chart.off(StockChartX.ChartEvent.HISTORY_LOADED, () => this.update());
    if (this._updateIntervalId) {
      clearInterval(this._updateIntervalId);
    }
  }

  protected _isValid(item: IOrder) {
    return ![OrderStatus.Canceled, OrderStatus.Rejected].includes(item.status);
  }

  protected _map(item: IOrder, _price?) {
    const kindMap = {
      [OrderType.StopMarket]: 'stop',
    };

    const date: Date = new Date(item.timestamp);
    const _item: any = {
      ...item,
      side: item.side.toLocaleLowerCase(),
      date,
      quantity: item.quantity, // + (item?.filledQuantity ?? 0),
      price: getPrice(item),
      action: uncapitalize(item.side),
      iceQuantity: item.iceQuantity,
      kind: kindMap[item.type] || uncapitalize(item.type),
      state: statusMap[item.status],
      markerIndex: this._markerIndex(date),
    };

    return _item;
  }

  shouldBarBeVisible(): boolean {
    return super.shouldBarBeVisible() && this._visible;
  }

  setVisibility(visible: boolean) {
    if (this._visible !== visible) {
      Object.values(this._markersMap).forEach((item: any) => {
        if (!item.order.isOco) item.visible = visible;
      });
      this._visible = visible;
    }
  }

  protected _loadItems() {
    if (!this._instance.accountId) {
      return;
    }

    this._instance.fetchOrders().then(() => {
      // Orders have been fetched, nothing else to do here
    });
  }
}

const sideMap = {
  buy: OrderSide.Buy,
  sell: OrderSide.Sell,
};

const statusMap = {
  [OrderStatus.Canceled]: 'pendingCancel',
  [OrderStatus.Rejected]: 'pendingCancel',
  [OrderStatus.Pending]: 'pendingReplace',
  [OrderStatus.New]: 'pendingSubmit',
  [OrderStatus.Filled]: 'submitted',
  [OrderStatus.PartialFilled]: 'accepted',
};
